import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useRouteMatch } from 'react-router';
import { useStore, useSelector, shallowEqual } from 'react-redux';
import { Button, Typography, TextField, CircularProgress, Grid } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import StickyHeader from '../StickyHeader';
import CheckmarkCircleIcon from '../icons/CheckmarkCircleIcon';
import LocationPinIcon from '../icons/SectionIcons/LocationPinIcon';
import LabeledField from '../form/LabeledField';
import ListingStatusChip from '../matching/ListingStatusChip';
import PhoneInput from '../form/inputs/PhoneInput';
import ExpandableTypography from '../form/ExpandableTypography';
import LoadingButton from '../form/LoadingButton';
import StatesDropdown from '../form/dropdowns/StatesDropdown';
import { ActionCreators as SiteActions } from '../../store/Site';
import { ActionCreators as AssessmentActions } from '../../store/Assessment';
import * as CandidatesAPI from '../../scripts/candidates';
import * as ListingsAPI from '../../scripts/listings';
import * as UsersAPI from '../../scripts/user';
import * as ContactsAPI from '../../scripts/contacts';
import Job1Background from '../../img/match-share-backgrounds/job-1.png';
import CaseIcon from '../icons/SectionIcons/CaseIcon';
import GlobeIcon from '../icons/GlobeIcon';
import * as Utility from '../../scripts/utility';
import { palette } from '../../BobcatTheme';
import {
	validatePasswords,
	PasswordRequirementsValidation,
	PasswordMatchingValidation,
} from '../form/PasswordValidation';
import ViewSkillsBlock from '../../pages/jobs/JobBlocks/ViewSkillsBlock';
import { Panel } from '../Panel';
import { SkillChip } from '../../pages/candidates/CandidateBlocks/ViewSkillsBlock';
import { InterestIcon } from './RateJobClientInterestModal';
import JobViewDetailsBlock from '../../pages/jobs/JobBlocks/ViewDetailsBlock';
import JobViewExperienceBlock from '../../pages/jobs/JobBlocks/ViewExperienceBlock';
import JobViewLocationBlock from '../../pages/jobs/JobBlocks/ViewLocationBlock';
import JobViewDescriptionBlock from '../../pages/jobs/JobBlocks/ViewDescriptionBlock';

import CompanyViewDetailsBlock from '../../pages/jobs/CompanyBlocks/ViewDetailsBlock';
import CompanyViewFinancialBlock from '../../pages/jobs/CompanyBlocks/ViewFinancialBlock';
import CompanyViewBenefitsBlock from '../../pages/jobs/CompanyBlocks/ViewBenefitsBlock';
import CompanyViewProductsServicesBlock from '../../pages/jobs/CompanyBlocks/ViewProductsServicesBlock';
import useVerifyAuthorization from '../../hooks/useVerifyAuthorization';

const SharedJobModal = ({ match, candidateId, refetchMatches, hideRating, adminView, ...props }) => {
	const store = useStore();
	const params = useParams();
	const { data, user } = useSelector(state => state.site, shallowEqual);

	const [activeTab, setActiveTab] = useState('skills');

	// For admins: manually fetch the listing and company.
	const [overrideListing, setOverrideListing] = useState(props?.overrideListing);
	useEffect(() => {
		if (match?.listing?.id) {
			CandidatesAPI.getCandidateMatch({
				candidateId,
				matchId: match?.id,
			}).then(newMatch => {
				const newListing = newMatch?.listing;
				console.log({
					newListing,
				});
				if (newListing) {
					setOverrideListing(newListing);
				}
				return null;
			});

			// ListingsAPI.getListing(match?.listing?.id, result => {
			// 	if (!result) {
			// 		return null;
			// 	}
			// 	const newListing = result.data.data;
			// 	// console.log({
			// 	// 	newListing,
			// 	// });
			// 	if (newListing) {
			// 		setOverrideListing(newListing);
			// 	}
			// 	return null;
			// });
		}
	}, []);

	const listing = overrideListing || match?.listing;
	const company = overrideListing?.client_corporation || listing?.client_corporation;
	const isLimited = match?.access_level?.id !== 3 && !adminView;
	const { isAuthorized: isAuthorizedAdmin, loading: isLoadingAuthorization } = useVerifyAuthorization({
		role: 'admin',
	});
	useEffect(() => {
		// Don't trigger a view if the current user is an admin.
		if (!isLoadingAuthorization && !isAuthorizedAdmin) {
			if (match?.id && candidateId) {
				// Set the match as viewed.
				CandidatesAPI.postCandidateMatchView({
					candidateId,
					matchId: match?.id,
				})
					.then(() => {
						console.log('Marked match as viewed.');

						refetchMatches({
							candidateId,
						});
					})
					.catch(console.log);
			}
		}
	}, [!!match?.id, candidateId, isLoadingAuthorization, isAuthorizedAdmin]);

	if (!listing) {
		return null;
	}

	return (
		<div
			style={{
				position: 'relative',
			}}
		>
			<div>
				<StickyHeader
					offset={200}
					style={{
						width: 750,
						left: '50%',
						right: 'auto',
						marginLeft: -375,
					}}
					scrollElement={document.querySelector('.modal')}
				>
					{isLimited ? (
						<Typography
							variant="body2"
							style={{
								color: '#fff',
								marginLeft: -5,
								fontWeight: 700,
								fontSize: 14,
								textWrap: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
						>
							{listing.position_title || `Job #${listing?.id}`}
						</Typography>
					) : (
						<Typography
							variant="body2"
							style={{
								color: '#fff',
								marginLeft: -5,
								fontWeight: 700,
								fontSize: 14,
								textWrap: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
						>
							{listing.position_title || `Job #${listing?.id}`} | {listing.company} |{' '}
							{Utility.formatMoney(listing.salary_rate_min, { hideDecimal: true })} -{' '}
							{Utility.formatMoney(listing.salary_rate_max, { hideDecimal: true })}
						</Typography>
					)}
				</StickyHeader>
			</div>
			<div
				style={{
					position: 'absolute',
					top: 20,
					right: -40,
					zIndex: 100,
				}}
			>
				<ListingStatusChip listing={listing} />
			</div>
			<div
				style={{
					margin: '-75px -67px 0 -67px',
					padding: '75px 67px 60px 67px',
					background: `url(${Job1Background}) center center no-repeat`,
					backgroundColor: '#013B7F',
					backgroundSize: 'cover',
					minHeight: 200,
					color: '#fff',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<CaseIcon size={24} color="var(--blue-100)" />
					<div style={{ width: 10 }} />
					<Typography
						variant="h2"
						className="font-lato-bold"
						style={{
							textAlign: 'center',
							fontSize: 20,
							paddingBottom: 5,
							color: '#fff',
						}}
					>
						{listing.position_title} at{' '}
						{isLimited ? (
							<span
								style={{
									filter: isLimited ? 'blur(4px)' : 'none',
									webkitFilter: isLimited ? 'blur(4px)' : 'none',
								}}
							>
								CompanyName
							</span>
						) : (
							<>{listing.company}</>
						)}
					</Typography>
				</div>
				{listing.professional_experience_years ? (
					<Typography
						style={{
							// opacity: 0.8,
							color: 'var(--blue-100)',
						}}
					>
						{listing.professional_experience_years}+ years experience
					</Typography>
				) : null}
				{listing.total_leadership_years ? (
					<Typography
						style={{
							// opacity: 0.8,
							color: 'var(--blue-100)',
						}}
					>
						{listing.total_leadership_years}+ years leadership experience
					</Typography>
				) : null}

				<div
					style={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<Typography
						style={{
							// color: '#FFD335',
							color: '#fff',
							fontSize: 20,
							fontWeight: 500,
						}}
					>
						{isLimited ? (
							<span
								style={{
									filter: isLimited ? 'blur(4px)' : 'none',
									webkitFilter: isLimited ? 'blur(4px)' : 'none',
								}}
							>
								$999,999 - $999,999
							</span>
						) : (
							<span>
								{Utility.formatMoney(listing.salary_rate_min, { hideDecimal: true })} -{' '}
								{Utility.formatMoney(listing.salary_rate_max, { hideDecimal: true })}
							</span>
						)}
					</Typography>

					{/* If the job offers a work visa, display it. */}
					{listing.work_visa_type_id === 1 ? (
						<>
							<div style={{ width: 15 }} />
							<GlobeIcon color="#fff" />
							<div style={{ width: 5 }} />
							<Typography
								style={{
									color: '#fff',
									fontSize: 20,
									fontWeight: 500,
								}}
							>
								Visa Offered
							</Typography>
						</>
					) : null}
				</div>

				<div style={{ height: 10 }} />
				<Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
					{(listing.skills || [])
						.filter(x => x.hero_skill)
						.map(skill => (
							<SkillChip
								baseColor="blue"
								key={skill?.id}
								colorOverride="#fff"
								classOverride="white-text"
								skill={skill}
								style={{
									background: '#3290ff',
									filter: 'drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15))',
								}}
							/>
						))}
				</Grid>
				{overrideListing && (
					<>
						<div style={{ paddingTop: 10, paddingBottom: 15, position: 'relative', width: '100%' }}>
							{/* <div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ height: 1, flex: 1, backgroundColor: '#fff', marginRight: 20 }} />
								<Typography variant="body2" style={{ color: '#fff', width: 'auto' }}>
									Match Highlights
								</Typography>
								<div style={{ height: 1, flex: 1, backgroundColor: '#fff', marginLeft: 20 }} />
							</div> */}
						</div>
						<Grid container spacing={2} alignContent="center" justifyContent="center">
							{listing?.industries?.length ? (
								<Grid item xs={4}>
									<HighlightCard
										color="blue"
										icon={<Icons.Business size={20} style={{ color: 'var(--blue-600)' }} />}
										// title="Industry"
										title={`${listing?.industries?.[0]?.label?.replace('Technology - ', '')}${
											listing?.industries?.length > 1 ? ' +' : ''
										}`}
									/>
								</Grid>
							) : null}
							{listing?.client_corporation?.business_sizes?.length ? (
								<Grid item xs={4}>
									<HighlightCard
										color="blue"
										icon={<Icons.AccountCircle size={20} style={{ color: 'var(--blue-600)' }} />}
										// title="Business Size"
										title={listing?.client_corporation?.business_sizes?.[0]?.label}
									/>
								</Grid>
							) : null}

							{listing?.work_location_preferences?.length ? (
								<Grid item xs={4}>
									<HighlightCard
										color="blue"
										icon={<LocationPinIcon size={20} color="var(--blue-600)" />}
										// title="Work Location"
										title={Utility.getConsolidatedLocationPreferences(listing?.work_location_preferences)?.join(' | ')}
									/>
								</Grid>
							) : null}

							{listing?.client_corporation?.year_founded ? (
								<Grid item xs={4}>
									<HighlightCard
										color="blue"
										icon={<Icons.CalendarToday size={20} style={{ color: 'var(--blue-600)' }} />}
										title={`Year Founded: ${listing?.client_corporation?.year_founded || 'N/A'}`}
									/>
								</Grid>
							) : null}

							{listing?.role ? (
								<Grid item xs={4}>
									<HighlightCard
										color="blue"
										icon={<CaseIcon size={20} color="var(--blue-600)" />}
										// title="Type"
										title={listing?.role?.label}
									/>
								</Grid>
							) : null}
							{listing?.work_visa_types ? (
								<Grid item xs={4}>
									<HighlightCard
										color="blue"
										icon={<Icons.Language size={20} style={{ color: 'var(--blue-600)' }} />}
										// title="Work Visa Sponsorship"
										title={`Work Visa: ${listing?.work_visa_types?.label}`}
									/>
								</Grid>
							) : null}
						</Grid>
					</>
				)}
			</div>
			<div
				style={{
					minHeight: 150,
					backgroundColor: '#fff',
					margin: '0 -67px 0 -67px',
					padding: '25px 67px 25px 67px',
				}}
			>
				<LabeledField label="Company Summary" className="color-muted" removeMargin close style={{ minWidth: 120 }}>
					<ExpandableTypography text={company?.company_summary_external} />
				</LabeledField>
				<div style={{ height: 20 }} />
				<LabeledField
					label="Job Summary (Abstract)"
					className="color-muted"
					removeMargin
					close
					style={{ minWidth: 120 }}
				>
					<ExpandableTypography text={listing.abstract} />
				</LabeledField>
			</div>
			<div
				style={{
					backgroundColor: 'var(--grey-50)',
					paddingTop: 5,
					paddingBottom: 5,
				}}
			>
				<CustomTabBar
					activeTab={activeTab}
					setActiveTab={newTab => {
						setActiveTab(newTab);
					}}
					tabs={[
						{
							id: 'skills',
							label: 'Skills',
						},
						{
							id: 'job-info',
							label: 'Job Info',
						},
						{
							id: 'company-info',
							label: 'Company Info',
						},
						{
							id: 'job-description',
							label: 'Job Description',
						},
					]}
				/>
				<div style={{ height: 20 }} />

				{activeTab === 'skills' ? (
					<Panel topBarColor="blue">
						<ViewSkillsBlock job={listing} setJob={null} data={data} groupByRanking disableEdit />
					</Panel>
				) : activeTab === 'job-info' ? (
					<>
						<Panel topBarColor="blue">
							<JobViewDetailsBlock
								disableEdit
								// minimized={false}
								// setMinimized={null}
								job={listing}
								setJob={null}
								data={data}
								comparisonCandidate={null}
								isLimited={isLimited}
							/>
						</Panel>
						<Panel topBarColor="blue">
							<JobViewExperienceBlock
								disableEdit
								// minimized={false}
								// setMinimized={null}
								job={listing}
								setJob={null}
								data={data}
								comparisonCandidate={null}
								isLimited={isLimited}
							/>
						</Panel>
						<Panel topBarColor="blue">
							<JobViewLocationBlock
								disableEdit
								// minimized={false}
								// setMinimized={null}
								job={listing}
								setJob={null}
								comparisonCandidate={null}
								data={data}
								isLimited={isLimited}
							/>
						</Panel>
					</>
				) : activeTab === 'company-info' ? (
					<>
						<Panel topBarColor="blue">
							<CompanyViewDetailsBlock
								job={listing}
								setJob={null}
								company={company}
								setCompany={null}
								data={data}
								disableEdit
								isLimited={isLimited}
								condensedColumns
							/>
						</Panel>
						{isLimited ? null : (
							<Panel topBarColor="blue">
								<CompanyViewProductsServicesBlock
									job={listing}
									setJob={null}
									company={company}
									setCompany={null}
									data={data}
									disableEdit
									isLimited={isLimited}
								/>
							</Panel>
						)}
						<Panel topBarColor="blue">
							<CompanyViewBenefitsBlock
								job={listing}
								setJob={null}
								company={company}
								setCompany={null}
								data={data}
								disableEdit
								isLimited={isLimited}
							/>
						</Panel>

						{isLimited ? null : (
							<Panel topBarColor="blue">
								<CompanyViewFinancialBlock
									job={listing}
									setJob={null}
									company={company}
									setCompany={null}
									data={data}
									disableEdit
									isLimited={isLimited}
								/>
							</Panel>
						)}
					</>
				) : activeTab === 'job-description' ? (
					<Panel topBarColor="blue">
						<JobViewDescriptionBlock job={listing} setJob={null} data={data} disableEdit isLimited={isLimited} />
					</Panel>
				) : null}
			</div>
			{!hideRating && (
				<InterestRatingBlock
					match={match}
					onSave={async levelId => {
						// setEditingCandidateMatch(true);
						try {
							await CandidatesAPI.patchCandidateMatchInterest({
								candidateId,
								matchId: match?.id,
								interestLevelId: levelId,
							});

							await refetchMatches({
								candidateId,
							});
						} catch (e) {
							console.log(e);
						} finally {
							// setEditingCandidateMatch(false);
							// handleClose();
						}
						console.log('Saving!');
					}}
				/>
			)}
		</div>
	);
};

export const HighlightCard = ({ title, value, icon, color, subtitle }) => {
	return (
		<div
			style={{
				height: 75,
				backgroundColor: color === 'blue' ? '#EEF6FF' : '#F2FDFC',
				borderRadius: 8,
				padding: 8,
				textAlign: 'center',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			{icon}
			{!title || !subtitle ? <div style={{ height: 5 }} /> : null}
			<Typography
				variant="body2"
				style={{
					color: color === 'blue' ? '#135C94' : '#20867F',
					fontWeight: 600,
					fontSize: 13,
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					width: '100%',
				}}
			>
				{title}
			</Typography>
			<Typography
				variant="body2"
				style={{
					color: color === 'blue' ? '#2095EF' : '#20867F',
					fontWeight: 600,
					fontSize: 13,
					marginTop: 0,
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					width: '100%',
				}}
			>
				{subtitle}
			</Typography>
		</div>
	);
};

const CustomTabBar = ({ activeTab, setActiveTab, tabs }) => {
	return (
		<div
			style={{
				borderBottom: '1px solid #ddd',
				paddingTop: 15,
				// paddingLeft: 40,
			}}
		>
			{tabs?.map((tab, index) => {
				const isActive = tab?.id === activeTab;

				return (
					<div
						style={{
							borderBottom: isActive ? `2px solid ${palette?.primary?.main}` : `2px solid transparent`,
							marginLeft: 0, // index === 0 ? -28 : 0,
							display: 'inline-block',
							marginRight: 20,
						}}
					>
						<Button
							color="primary"
							className="no-border-radius"
							onClick={() => {
								setActiveTab(tab?.id);
							}}
							style={{
								marginLeft: 0,
								marginRight: 0,
								paddingLeft: 0,
								paddingRight: 0,
								background: 'transparent',
								minWidth: 0,
							}}
						>
							<Typography
								variant="body"
								style={{ marginRight: 0, color: isActive ? '' : '#34414E', fontWeight: isActive ? 700 : 300 }}
							>
								{tab?.label}
							</Typography>
						</Button>
					</div>
				);
			})}
		</div>
	);
};

const InterestRatingBlock = ({ match, onSave, ...props }) => {
	const { data } = useSelector(state => state.site, shallowEqual);
	const store = useStore();
	const [selectedInterestLevelId, setSelectedInterestLevelId] = useState(match?.interest_level_id);
	const [isLoading, setLoading] = useState(false);

	const isCandidateMatch = match?.destination_model.toLowerCase().includes('listing');

	return (
		<div
			style={{
				position: 'fixed',
				padding: 15,
				backgroundColor: '#fff',
				right: '170px',
				top: 'calc(50vh - 200px)',
				borderRadius: 8,
				width: 190,
			}}
		>
			{[...(data?.interestLevels || [])]
				.reverse()
				.slice(0, 4)
				.map((level, index) => (
					// eslint-disable-next-line jsx-a11y/no-static-element-interactions
					<div
						key={level?.id}
						className="interest-level"
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							cursor: 'pointer',
							padding: 8,
							opacity: selectedInterestLevelId === level.id ? 1 : 0.7,
						}}
						onClick={async () => {
							if (!isLoading) {
								try {
									setLoading(true);
									setSelectedInterestLevelId(level.id);
									await onSave(level.id);
								} catch (e) {
									console.log(e);
								} finally {
									setLoading(false);
								}
							}
						}}
					>
						<div style={{ width: 28, height: 28, flexGrow: 0 }}>
							<InterestIcon
								interestLevel={level}
								interestLevelId={level.id}
								size={28}
								level={data.interestLevels?.length - index}
								color={selectedInterestLevelId === level.id ? 'var(--primaryColor)' : 'var(--mutedTextColor)'}
							/>
						</div>
						<div style={{ width: 10 }} />
						<Typography variant="body2" style={{ marginTop: '0.5rem' }}>
							{level?.label}
						</Typography>
					</div>
				))}
		</div>
	);
};

SharedJobModal.propTypes = {};

SharedJobModal.defaultProps = {};

export default SharedJobModal;
