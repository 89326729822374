import React, { useEffect, useState, useMemo } from 'react';
import { useStore, useSelector, shallowEqual, dispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import Page from '../../components/page/Page';
import StickyHeader from '../../components/StickyHeader';
import { Panel } from '../../components/Panel';
import JobCardList from '../../components/matching/JobCardList';
import * as CandidatesAPI from '../../scripts/candidates';
import { ActionCreators as SiteActions } from '../../store/Site';
import * as Utility from '../../scripts/utility';
import ViewSummaryBlock from './CandidateBlocks/ViewSummaryBlock';
import ViewExperienceBlock from './CandidateBlocks/ViewExperienceBlock';
import ViewResumeBlock from './CandidateBlocks/ViewResumeBlock';
import ViewPreferencesBlock from './CandidateBlocks/ViewPreferencesBlock';
import ViewAchievementsBlock from './CandidateBlocks/ViewAchievementsBlock';
import ViewSkillsBlock from './CandidateBlocks/ViewSkillsBlock';
import ViewWorkAuthorizationBlock from './CandidateBlocks/ViewWorkAuthorizationBlock';
import ViewLocationPreferencesBlock from './CandidateBlocks/ViewLocationPreferencesBlock';
import ViewHeaderBlock from './CandidateBlocks/ViewHeaderBlock';
import { useContextStore } from '../../store/ContextStore';
import CandidateLogoLinks from '../../components/matching/CandidateLogoLinks';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const CandidateView = () => {
	const store = useStore();
	const params = useParams();
	const candidateID = params.id;
	const [candidate, _setCandidate] = useState({});
	const setCandidate = newCandidate => {
		_setCandidate(newCandidate);
	};
	const [moreMenu, setMoreMenu] = useState({ anchor: null, listing: {} });
	const [listings, setListings] = useState([]);

	useDocumentTitle(
		candidate && candidate.first_name
			? `${candidate.first_name} ${candidate.last_name} 
		- ${candidate.current_title || 'N/A'} - ${candidate.current_employer || 'N/A'} |  NauMatch`
			: 'Candidate | NauMatch',
	);

	const {
		candidateMatches: matches,
		setCandidateMatches: setMatches,
		refetchMatches,
		loadingCandidateMatches: loadingMatches,
	} = useContextStore();
	const { data, alert, sidebarWidth } = useSelector(state => state.site, shallowEqual);
	const bestSkills = useMemo(() => {
		return candidate?.skills?.filter(skill => skill.skill_experience_id === 5);
	}, [candidate.skills]);
	const advancedSkills = useMemo(() => {
		return candidate?.skills?.filter(skill => skill.skill_experience_id === 4);
	}, [candidate.skills]);
	const intermediateSkills = useMemo(() => {
		return candidate?.skills?.filter(skill => skill.skill_experience_id === 3);
	}, [candidate.skills]);
	const basicSkills = useMemo(() => {
		return candidate?.skills?.filter(skill => skill.skill_experience_id === 2);
	}, [candidate.skills]);
	const getTheJobSkills = useMemo(() => {
		return candidate?.skills?.filter(skill => skill.get_the_job);
	}, [candidate.skills]);
	const enjoyTheJobSkills = useMemo(() => {
		return candidate?.skills?.filter(skill => skill.enjoy_the_job);
	}, [candidate.skills]);
	const improveOnTheJobSkills = useMemo(() => {
		return candidate?.skills?.filter(skill => skill.improve_on_job);
	}, [candidate.skills]);
	const learnOnTheJobSkills = useMemo(() => {
		return candidate?.skills?.filter(skill => skill.learn_on_job);
	}, [candidate.skills]);

	const refreshCandidate = () => {
		CandidatesAPI.getCandidate(candidateID, response => {
			if (!response) {
				store.dispatch(SiteActions.showAlert('Could not load the candidate. Refresh the page to try again.', 'error'));
				return;
			}
			setCandidate(response.data.data);
		});
	};

	// Get page data on load
	useEffect(() => {
		refreshCandidate();

		setMatches(null);
		refetchMatches({ candidateId: candidateID });

		// Get mock listings list
		// ListingsAPI.getListings({}, response => {
		// 	// console.log({ response });
		// 	setLoadingListings(false);
		// 	setListings(response?.data?.data);

		// 	console.log(JSON.stringify(response?.data?.data, null, 2));
		// });
	}, []);

	if (Object.keys(candidate).length === 0) return <Page />;

	return (
		<Page header={<ViewHeaderBlock candidate={candidate} setCandidate={setCandidate} data={data} displayBackArrow />}>
			<div>
				<StickyHeader
					style={{
						width: `calc(100% - ${sidebarWidth}px)`,
						justifyContent: 'flex-start',
					}}
				>
					<Typography
						variant="body2"
						style={{
							color: '#fff',
							marginLeft: -5,
							fontWeight: 700,
							fontSize: 14,
						}}
					>
						{candidate.first_name} {candidate.last_name}
						{candidate.desired_position
							? ` | ${candidate.desired_position}`
							: candidate.specialty_id
							? ` | ${Utility.getItemLabel(data.specialties, candidate.specialty_id)}`
							: null}
					</Typography>
					<div style={{ marginLeft: 10 }}>
						<CandidateLogoLinks candidate={candidate} setCandidate={setCandidate} />
					</div>
				</StickyHeader>
			</div>

			<Grid container spacing={4}>
				<Grid item md={12} lg={12} style={{ marginBottom: -16, paddingBottom: 0 }}>
					<Panel topBarColor="teal">
						<ViewSummaryBlock candidate={candidate} setCandidate={setCandidate} defaultMinimized />
					</Panel>
				</Grid>
				<Grid item md={12} lg={6}>
					<Panel topBarColor="teal">
						<ViewPreferencesBlock candidate={candidate} setCandidate={setCandidate} data={data} />
					</Panel>
					<Panel topBarColor="teal">
						<ViewLocationPreferencesBlock candidate={candidate} setCandidate={setCandidate} data={data} />
					</Panel>
					<Panel topBarColor="teal">
						<ViewExperienceBlock candidate={candidate} setCandidate={setCandidate} data={data} />
					</Panel>
					<Panel topBarColor="teal">
						<ViewAchievementsBlock candidate={candidate} setCandidate={setCandidate} data={data} />
					</Panel>
					<Panel topBarColor="teal">
						<ViewWorkAuthorizationBlock candidate={candidate} setCandidate={setCandidate} data={data} />
					</Panel>
				</Grid>
				<Grid item md={12} lg={6}>
					{/* {process.env.REACT_APP_FEATURE_MATCHING ? ( */}
					<>
						<JobCardList
							roleOverride="recruiter"
							refetchMatches={refetchMatches}
							candidateId={candidateID}
							loading={loadingMatches}
							matches={matches}
							title="Saved Matches"
							small
						/>
					</>
					{/* ) : null} */}

					<Panel topBarColor="teal">
						<ViewSkillsBlock
							candidate={candidate}
							setCandidate={setCandidate}
							data={data}
							displaySkillsWithoutExperience
						/>
					</Panel>

					{/* 
					{process.env.REACT_APP_FEATURE_MATCHING ? null : (
						<Panel topBarColor="teal">
							<PanelHeader style={{ display: 'flex', alignItems: 'center' }}>
								<MeterIcon size={20} />
								<div style={{ width: 10 }} />
								<Typography variant="h3">Skills</Typography>
							</PanelHeader>
							<PanelContent>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<LabeledField label="Category" className="color-muted" close>
											<Typography variant="body2">
												{Utility.getItemLabel(data.categories, candidate.professional_category_id) || Utility.nb}
											</Typography>
										</LabeledField>
									</Grid>

									<Grid item xs={6}>
										<LabeledField label="Specialty" className="color-muted" close>
											<Typography variant="body2">
												{Utility.getItemLabel(data.specialties, candidate.specialty_id) || Utility.nb}
											</Typography>
										</LabeledField>
									</Grid>
								</Grid>
								{[...data.skillExperienceLevels].reverse().map(level => (
									<LabeledField key={level.name} label={level.label} className="color-muted">
										{(candidate.skills || []).map(skill =>
											skill.experience.name === level.name ? (
												<Chip key={skill.id} label={Utility.getFromObj(skill, 'skill', 'label')} color="primary" />
											) : null,
										)}
									</LabeledField>
								))}
							</PanelContent>
						</Panel>
					)}

					{process.env.REACT_APP_FEATURE_MATCHING ? null : (
						<Panel topBarColor="teal">
							<PanelHeader style={{ display: 'flex', alignItems: 'center' }}>
								<MeterIcon size={20} />
								<div style={{ width: 10 }} />
								<Typography variant="h3">Skill Story</Typography>
							</PanelHeader>
							<PanelContent>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<LabeledField label="Land The Job" className="color-muted">
											{!!getTheJobSkills &&
												getTheJobSkills.map(skill => (
													<>
														<Chip label={skill.skill.label} color="primary" className="no-bottom-margin" />
														<VerticalSpacer height={0.5} />
													</>
												))}
										</LabeledField>
									</Grid>
									<Grid item xs={6}>
										<LabeledField label="Experience" className="color-muted">
											{!!getTheJobSkills &&
												getTheJobSkills.map(skill => (
													<Typography>
														{typeof skill.experience_years === 'number'
															? `${skill.experience_years} year${skill.experience_years !== 1 ? 's' : ''}`
															: ''}
														<VerticalSpacer height={1.5} />
													</Typography>
												))}
										</LabeledField>
									</Grid>
								</Grid>
								<LabeledField label="Enjoy Using" className="color-muted">
									{!!enjoyTheJobSkills &&
										enjoyTheJobSkills.map(skill => <Chip key={skill.id} label={skill.skill.label} color="primary" />)}
								</LabeledField>
								<LabeledField label="Improve" className="color-muted">
									{!!improveOnTheJobSkills &&
										improveOnTheJobSkills.map(skill => (
											<Chip key={skill.id} label={skill.skill.label} color="primary" />
										))}
								</LabeledField>
								<LabeledField label="Learn" className="color-muted">
									{!!learnOnTheJobSkills &&
										learnOnTheJobSkills.map(skill => <Chip key={skill.id} label={skill.skill.label} color="primary" />)}
								</LabeledField>
							</PanelContent>
						</Panel>
					)} */}

					<Panel topBarColor="teal">
						<ViewResumeBlock
							displayDocumentManagement
							candidate={candidate}
							refreshCandidate={refreshCandidate}
							data={data}
						/>
					</Panel>
				</Grid>
			</Grid>
		</Page>
	);
};

export default CandidateView;
