import React, { useEffect, useState, useMemo } from 'react';
import { useStore, useSelector, shallowEqual } from 'react-redux';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { Grid, Typography, Button, TextField, ButtonBase } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as Icons from '@material-ui/icons';
import PlusIcon from '../../components/icons/PlusIcon';
import Page from '../../components/page/Page';
import * as PageHeader from '../../components/page/PageHeader';
import BackArrow from '../../components/page/BackArrow';
import Section from '../../components/Section';
import VerticalSpacer from '../../components/VerticalSpacer';
import LabeledField from '../../components/form/LabeledField';
import DateInput from '../../components/form/inputs/DateInput';
import FileInput from '../../components/form/inputs/FileInput';
import LoadingButton from '../../components/form/LoadingButton';
import AreasOfStudyDropdown from '../../components/form/dropdowns/AreasOfStudyDropdown';
import CategoryDropdown from '../../components/form/dropdowns/CategoryDropdown';
import CompensationTypesDropdown from '../../components/form/dropdowns/CompensationTypesDropdown';
import Dropdown from '../../components/form/dropdowns/Dropdown';
import EducationDropdown from '../../components/form/dropdowns/EducationDropdown';
import IndustryExperienceDropdown from '../../components/form/dropdowns/IndustryExperienceDropdown';
import JobOpeningsDropdown from '../../components/form/dropdowns/JobOpeningsDropdown';
import SkillDropdown from '../../components/form/dropdowns/SkillDropdown';
import SpecialtyDropdown from '../../components/form/dropdowns/SpecialtyDropdown';
import StatesDropdown from '../../components/form/dropdowns/StatesDropdown';
import TravelWillingnessDropdown from '../../components/form/dropdowns/TravelWillingnessDropdown';
import WeeksDropdown from '../../components/form/dropdowns/WeeksDropdown';
import WorkLocationDropdown from '../../components/form/dropdowns/WorkLocationDropdown';
import WorkVisaDropdown from '../../components/form/dropdowns/WorkVisaDropdown';
import YearsDropdown from '../../components/form/dropdowns/YearsDropdown';
import { ActionCreators as SiteActions } from '../../store/Site';
import * as ListingsAPI from '../../scripts/listings';
import * as SkillsAPI from '../../scripts/skills';
import * as Utility from '../../scripts/utility';

const JobEdit = () => {
	const store = useStore();
	const history = useHistory();
	const params = useParams();
	const listingID = params.id;
	const [isLoading, setIsLoading] = useState(false);
	const [job, setJob] = useState({});
	const [validSkills, setValidSkills] = useState([]);
	const { data, alert } = useSelector(state => state.site, shallowEqual);

	const workLocationPreferenceValue = useMemo(
		() => job?.work_location_preferences?.map(item => (item.id ? item.id : item)),
		[job.work_location_preferences],
	);
	const hasRemoteSelection = useMemo(() => workLocationPreferenceValue?.includes(1), [workLocationPreferenceValue]);
	const hasLocalSelection = useMemo(() => workLocationPreferenceValue?.some(pid => [2, 3, 4, 5].includes(pid)), [
		workLocationPreferenceValue,
	]);

	const setListingLocations = (index, name, value) => {
		const updatedOfficeLocations = [...job.office_locations];
		updatedOfficeLocations[index] = { ...(job.office_locations || [])[index], [name]: value };
		setJob({
			...job,
			office_locations: updatedOfficeLocations,
		});
	};

	const addOfficeLocation = () => {
		const updatedOfficeLocations = [...(job.office_locations || []), { city: null, state_id: null }];
		setJob({
			...job,
			office_locations: updatedOfficeLocations,
		});
	};

	const removeOfficeLocation = location => {
		const updatedOfficeLocations = job.office_locations.filter(
			l => !(l.city === location.city && l.state_id === location.state_id),
		);
		setJob({
			...job,
			office_locations: updatedOfficeLocations,
		});
	};

	const updateJob = ev => {
		const descriptionReset = ev.target.name === 'descriptionFile' && ev.target.value ? { description: '' } : {};
		let newValue = { [ev.target.name]: ev.target.value };
		// Reset leadership years if leadership role is none
		if (ev.target.name === 'recent_leadership_role_id' && (ev.target.value === 1 || ev.target.value === null))
			newValue = { ...newValue, total_leadership_years: 0, recent_leadership_role_years: 0 };
		setJob({ ...job, ...newValue, ...descriptionReset });
	};

	const updateJobDate = (date, name) => {
		setJob({ ...job, [name]: date });
	};

	const onJobUpdate = response => {
		setIsLoading(false);
		if (!response) {
			store.dispatch(SiteActions.showAlert('An error ocurred saving the job. Please try again.', 'error'));
			return;
		}
		history.push(`/job/${listingID}`);
	};

	const validHeroSkills = job?.skills?.map(s => ({ ...s, id: s.skill_id }));
	// const validHeroSkills = useMemo(
	// 	() =>
	// 		// return expert or advanced skills
	// 		job?.skills
	// 			?.filter(
	// 				s => [4, 5].includes(s.skill_experience_id), // expert or advanced skills
	// 			)
	// 			.map(s => ({ ...s, id: s.skill_id })),
	// 	[job.skills],
	// );

	const validBonusSkills = validSkills;
	// const validBonusSkills = useMemo(
	// 	() => validSkills.filter(option => !job.skills?.find(s => s.skill_id === option.id)),
	// 	[validSkills, job.skills],
	// );

	const heroSkills = useMemo(() => job?.skills?.filter(skill => skill.hero_skill), [job.skills]);
	const requiredSkills = useMemo(() => job?.skills?.filter(skill => skill.required_skill), [job.skills]);
	const preferredSkills = useMemo(() => job?.skills?.filter(skill => skill.preferred_skill), [job.skills]);
	const bonusSkills = useMemo(() => job?.skills?.filter(skill => skill.bonus_skill), [job.skills]);

	const selectHeroSkill = (skillId, replacedSkillId) => {
		const updatedSkills = job.skills.map(s => {
			if (s.skill_id === replacedSkillId) return { ...s, hero_skill: false };
			if (s.skill_id === skillId) return { ...s, hero_skill: true };
			return s;
		});
		setJob(s => ({ ...s, skills: updatedSkills }));
	};

	const setSkillExperience = (skill, experienceYears) => {
		const updatedSkills = job.skills.map(s =>
			s.skill_id === skill.skill_id ? { ...skill, experience_years: experienceYears } : s,
		);
		setJob(c => ({
			...c,
			skills: updatedSkills,
		}));
	};

	const selectSkill = (skillStoryName, skillIds) => {
		// create an array of skills that are new; not already in job.skills
		const newSkills = skillIds
			.filter(sid => !job.skills?.find(s => s.skill_id === sid))
			.map(sid => {
				return {
					skill_id: sid,
					[skillStoryName]: true,
					skill_experience_id: 1,
					experience_years: 0,
					skill: validSkills?.find(s => s.id === sid),
				};
			});
		// for each input skill (skillIds) set the listing.skill story name to true
		const updatedSkills = job.skills
			.map(s => ({ ...s, [skillStoryName]: skillIds.includes(s.skill_id) ? true : undefined }))
			.concat(newSkills);

		setJob(c => ({ ...c, skills: updatedSkills }));
	};

	const updateSkills = (levelId, skillIds) => {
		const removedSkillIds = job.skills
			.filter(s => s.skill_experience_id === levelId)
			.filter(s => !skillIds.includes(s.skill_id))
			.map(s => s.skill_id);

		const newSkills = skillIds
			.filter(skillId => !job.skills.find(s => s.skill_id === skillId))
			.map(skillId => ({
				skill_id: skillId,
				skill: validSkills.find(vs => vs.id === skillId),
				skill_experience_id: levelId,
				experience_years: 0,
			}));

		const updatedSkills = job.skills
			.filter(s => !removedSkillIds.includes(s.skill_id)) // remove skills
			.map(s => (skillIds.includes(s.skill_id) ? { ...s, skill_experience_id: levelId } : s)) // update xp id
			.concat(newSkills);
		setJob(j => ({ ...j, skills: updatedSkills }));
	};

	const saveSkills = (response, err) => {
		if (!response) {
			setIsLoading(false);
			if (Utility.getFromObj(err, 'response', 'data', 'errors', 'file')) {
				store.dispatch(
					SiteActions.showAlert(
						`An error ocurred saving the job. ${Utility.getFromObj(err, 'response', 'data', 'errors', 'file', 0)}`,
						'error',
					),
				);
			} else {
				store.dispatch(SiteActions.showAlert('An error ocurred saving the job. Please try again.', 'error'));
			}
			return;
		}
		const skills = job?.skills; // SkillsAPI.joinSkills(data.skillExperienceLevels, data.skills, job);
		// console.log({ joinedSkills: skills, skills: job.skills });
		ListingsAPI.addListingSkills(listingID, skills, onJobUpdate);
	};

	const saveDescription = response => {
		if (!response) {
			setIsLoading(false);
			store.dispatch(SiteActions.showAlert('An error ocurred saving the job. Please try again.', 'error'));
		}
		if (job.descriptionFile) ListingsAPI.uploadListingJobOrder(listingID, job.descriptionFile, saveSkills);
		else if (job.description !== job.original_description && job.description)
			ListingsAPI.uploadListingTextJobOrder(listingID, job.description, saveSkills);
		else saveSkills(true);
	};

	const saveJob = ev => {
		setIsLoading(true);
		ListingsAPI.updateListing(listingID, ListingsAPI.getForgedListing(job), saveDescription);
	};

	// Get page data on load
	useEffect(() => {
		ListingsAPI.getListing(listingID, response => {
			if (!response) return;
			let resp = response.data.data;
			// Categorize skills
			resp = { ...resp, ...SkillsAPI.splitSkills(data.skillExperienceLevels, resp.skills) };
			// Save original description
			resp.original_description = resp.description;
			setJob(resp);
		});

		if (!validSkills.length) {
			ListingsAPI.getValidSkills(listingID, response => {
				if (response) setValidSkills(response.data.data);
			});
		}
	}, [data.states]);

	if (Object.keys(job).length === 0) return <Page />;

	return (
		<Page>
			<PageHeader.Header>
				<PageHeader.Left>
					<BackArrow to={`/job/${listingID}`} />
					<Typography variant="h1">{job.position_title || `Job #${listingID}`}</Typography>
				</PageHeader.Left>

				<PageHeader.Right>
					<NavLink to={`/job/${listingID}`}>
						<Button variant="text" color="primary">
							Discard Changes
						</Button>
					</NavLink>
					<LoadingButton loading={isLoading} variant="contained" color="primary" onClick={saveJob}>
						Save Changes
					</LoadingButton>
				</PageHeader.Right>
			</PageHeader.Header>

			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}

			<Grid container spacing={8}>
				<Grid item md={12} lg={6}>
					<Typography variant="h3" component="h2" gutterBottom>
						Job Details
					</Typography>
					<Section>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<LabeledField label="Title" removeMargin>
									<TextField
										variant="outlined"
										name="position_title"
										value={job.position_title || ''}
										onChange={updateJob}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Status" removeMargin>
									<Dropdown
										variant="outlined"
										name="role_type"
										value={job.status_id}
										data={data.jobStatuses}
										onChange={updateJob}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Type" removeMargin>
									<Dropdown
										variant="outlined"
										name="role_type"
										value={job.role_type}
										data={data.employmentPreferences}
										onChange={updateJob}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Openings" removeMargin>
									<JobOpeningsDropdown
										variant="outlined"
										name="openings"
										value={job.openings || ''}
										onChange={updateJob}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Desired Start Date" removeMargin>
									<DateInput
										variant="outlined"
										name="start_date"
										value={
											typeof job.start_date === 'object' ? job.start_date : Utility.formatDate(job.start_date, true)
										}
										onChange={updateJobDate}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							{[1, 2].includes(job.role_type) ? (
								<Grid item xs={6}>
									<LabeledField label="Contract Duration" removeMargin>
										<WeeksDropdown
											variant="outlined"
											name="contract_duration"
											value={job.contract_duration}
											onChange={updateJob}
											fullWidth
										/>
									</LabeledField>
								</Grid>
							) : null}
							<Grid item xs={6}>
								<LabeledField label="Salary Range" removeMargin>
									<Grid container>
										<Grid item xs={5}>
											<TextField
												variant="outlined"
												name="salary_rate_min"
												value={job.salary_rate_min}
												onChange={updateJob}
												fullWidth
											/>
										</Grid>
										<Grid xs={2} />
										<Grid item xs={5}>
											<TextField
												variant="outlined"
												name="salary_rate_max"
												value={job.salary_rate_max}
												onChange={updateJob}
												fullWidth
											/>
										</Grid>
									</Grid>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Other Compensation" removeMargin>
									<CompensationTypesDropdown
										variant="outlined"
										name="other_compensation_types"
										value={job.other_compensation_types?.map(t => (t.id ? t.id : t))}
										onChange={updateJob}
										fullWidth
										multiple
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Travel" removeMargin>
									<TravelWillingnessDropdown
										variant="outlined"
										name="travel_willingness_type_id"
										value={job.travel_willingness_type_id}
										onChange={updateJob}
										fullWidth
										placeholder="Choose from our list"
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Work Visa Sponsorship">
									<WorkVisaDropdown
										variant="outlined"
										name="work_visa_type_id"
										value={job.work_visa_type_id}
										onChange={updateJob}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={12}>
								<LabeledField label="Additional Details" removeMargin>
									<TextField
										variant="outlined"
										name="additional_job_detail"
										value={job.additional_job_detail || ''}
										onChange={updateJob}
										fullWidth
									/>
								</LabeledField>
							</Grid>
						</Grid>
					</Section>
					<Typography variant="h3" component="h2" gutterBottom>
						Job Description
					</Typography>
					<Section>
						<LabeledField label="Job Description">
							<TextField
								variant="outlined"
								name="description"
								value={job.description || ''}
								onChange={updateJob}
								rows={10}
								multiline
								fullWidth
								disabled={Boolean(Utility.getFromObj(job, 'descriptionFile'))}
							/>
							<VerticalSpacer height={0.5} />
							<div className="text-right">
								<FileInput name="descriptionFile" onChange={updateJob}>
									Upload Job Description
								</FileInput>
							</div>
						</LabeledField>
					</Section>
					<Typography variant="h3" component="h2" gutterBottom>
						Skill Story
					</Typography>
					<Section>
						<Typography className="text-bold">Required Skills</Typography>
						<Typography>
							From the skill pool you provided, please select the skills that a candidate MUST possess to be considered
							for this role.
						</Typography>
						<VerticalSpacer height={1} />
						<SkillDropdown
							tags
							variant="outlined"
							color="primary"
							name="required_skill"
							value={requiredSkills.map(s => (s.skill_id ? s.skill_id : s))}
							override={job.skills}
							onChange={ev => selectSkill(ev.target.name, ev.target.value)}
							fullWidth
						/>
						<VerticalSpacer height={3} />
						<Typography className="text-bold">Hero Skills</Typography>
						<Typography>
							Please select up to three skills that are viewed as the most critical. These are the skills that are most
							important to successful performance in this role.
						</Typography>
						<VerticalSpacer height={1} />
						{[1, 2, 3].map((v, i) => {
							return (
								<Grid container spacing={2} key={`heroSkill-${heroSkills[i]?.skill_id || v}`}>
									<Grid item xs={7}>
										<SkillDropdown
											variant="outlined"
											name="hero_skill"
											value={heroSkills[i]?.skill_id}
											override={validHeroSkills}
											onChange={ev => selectHeroSkill(ev.target.value, heroSkills[i]?.skill_id)}
											fullWidth
										/>
									</Grid>
									<Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
										<Typography variant="body1" className="text-right text-medium">
											Experience
										</Typography>
									</Grid>
									<Grid item xs={3}>
										<YearsDropdown
											variant="outlined"
											name="hero_skill_years"
											value={heroSkills[i]?.experience_years}
											onChange={ev => setSkillExperience(heroSkills[i], ev.target.value)}
											disableClearable={false}
											fullWidth
										/>
									</Grid>
								</Grid>
							);
						})}
						<VerticalSpacer height={3} />
						<Typography className="text-bold">Preferred Skills</Typography>
						<Typography>
							What are the preferred skills that will help separate top-tier candidates from mid-level candidates?
						</Typography>
						<VerticalSpacer height={1} />
						<SkillDropdown
							tags
							variant="outlined"
							color="primary"
							name="preferred_skill"
							value={preferredSkills.map(s => (s.skill_id ? s.skill_id : s))}
							override={validSkills}
							onChange={ev => selectSkill(ev.target.name, ev.target.value)}
							fullWidth
						/>
						<VerticalSpacer height={3} />
						<Typography className="text-bold">Bonus Skills</Typography>
						<Typography>What skills, in or outside of the role scope, do you view as bonus skills?</Typography>
						<VerticalSpacer height={1} />
						<SkillDropdown
							tags
							variant="outlined"
							color="primary"
							name="bonus_skill"
							value={bonusSkills.map(s => (s.skill_id ? s.skill_id : s))}
							override={validBonusSkills}
							onChange={ev => selectSkill(ev.target.name, ev.target.value)}
							fullWidth
						/>
					</Section>
					<Typography variant="h3" component="h2" gutterBottom>
						Admin
					</Typography>
					<Section>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<LabeledField label="Job Priority">
									<Dropdown
										variant="outlined"
										name="priority_id"
										value={job.priority_id || ''}
										data={data.listingPriorities || []}
										onChange={updateJob}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6} />
						</Grid>
					</Section>
				</Grid>
				<Grid item md={12} lg={6}>
					<Typography variant="h3" component="h2" gutterBottom>
						Experience
					</Typography>
					<Section>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<LabeledField label="Professional Experience" removeMargin>
									<YearsDropdown
										variant="outlined"
										name="professional_experience_years"
										value={job.professional_experience_years}
										onChange={updateJob}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Leadership Experience" removeMargin>
									<Dropdown
										variant="outlined"
										name="recent_leadership_role_id"
										value={job.recent_leadership_role_id}
										data={data.leadershipExperienceRoles}
										onChange={updateJob}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Leadership Years" removeMargin>
									<YearsDropdown
										variant="outlined"
										name="total_leadership_years"
										value={job.total_leadership_years}
										onChange={updateJob}
										disabled={job?.recent_leadership_role_id === 1 || job?.recent_leadership_role_id === null}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Education" removeMargin>
									<EducationDropdown
										variant="outlined"
										name="education_type_id"
										value={job.education_type_id}
										onChange={updateJob}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Industry Experience Preference (optional)">
									<IndustryExperienceDropdown
										variant="outlined"
										name="industries"
										value={(Utility.getFromObj(job, 'industries') || []).map(i => (i.id ? i.id : i))}
										onChange={updateJob}
										placeholder={Utility.getFromObj(job, 'industries')?.length > 0 ? '' : 'Select all that apply'}
										fullWidth
										multiple
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Preferred Area(s) of Study" removeMargin>
									<AreasOfStudyDropdown
										variant="outlined"
										name="study_types"
										value={job.study_types?.map(t => (t.id ? t.id : t))}
										onChange={updateJob}
										fullWidth
										multiple
									/>
								</LabeledField>
							</Grid>

							<Grid item xs={6}>
								<LabeledField label="Professional Category">
									<CategoryDropdown
										variant="outlined"
										name="professional_category_id"
										value={Utility.getFromObj(job, 'professional_category_id')}
										onChange={updateJob}
										fullWidth
										// error={!Utility.getFromObj(listing, 'professional_category_id')}
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Specialty">
									{/* TODO: if leadership allocation >= 75% then specialty should be "management" */}
									<SpecialtyDropdown
										variant="outlined"
										name="specialty_id"
										value={
											job.leadership_allocation_type_id === 3 || job.leadership_allocation_type_id === 4
												? null
												: job.specialty_id
										}
										placeholder="Choose from our list"
										category={job.professional_category_id}
										onChange={updateJob}
										disabled={
											!job.professional_category_id ||
											job.leadership_allocation_type_id === 3 ||
											job.leadership_allocation_type_id === 4
										}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={12}>
								<LabeledField label="Additional Experience Details" removeMargin>
									<TextField
										variant="outlined"
										name="additional_experience_details"
										value={job.additional_experience_details || ''}
										onChange={updateJob}
										fullWidth
									/>
								</LabeledField>
							</Grid>
						</Grid>
					</Section>
					<Typography variant="h3" component="h2" gutterBottom>
						Work Location
					</Typography>
					<Section>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<LabeledField label="Work Locations" removeMargin>
									<WorkLocationDropdown
										variant="outlined"
										name="work_location_preferences"
										value={job?.work_location_preferences?.map(item => (item.id ? item.id : item))}
										onChange={updateJob}
										fullWidth
										multiple
										renderOption={(option, { selected }) => {
											return (
												<div>
													<Typography bold className="text-bold grey-300">
														{option.label}
													</Typography>
													<Typography bold className="grey-300">
														{option.description}
													</Typography>
												</div>
											);
										}}
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Approved Remote Locations" removeMargin>
									<StatesDropdown
										variant="outlined"
										name="remote_states"
										placeholder={job?.remote_states?.length > 0 ? '' : 'Select all that apply'}
										value={
											hasRemoteSelection
												? job?.remote_states?.map(item => (item.state_id ? item.state_id : item))
												: null
										}
										onChange={updateJob}
										disabled={!hasRemoteSelection}
										fullWidth
										multiple
										useID
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Candidate Relocation" removeMargin>
									<Dropdown
										variant="outlined"
										name="offer_relocation"
										value={job.offer_relocation}
										data={data.offerRelocation}
										onChange={updateJob}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Eligible Office Sites" required={hasLocalSelection}>
									{(job?.office_locations?.length ? job.office_locations : [{ city: null, state_id: null }]).map(
										(location, index) => (
											<Grid container spacing={2} key={location?.state_id + location?.city}>
												<Grid item xs={7}>
													<TextField
														variant="outlined"
														name="city"
														placeholder="City"
														value={job?.office_locations[index]?.city || ''}
														onChange={e => setListingLocations(index, e.target.name, e.target.value)}
														disabled={!hasLocalSelection}
														// error={hasLocalSelection && !job?.office_locations[index]?.city}
														fullWidth
													/>
												</Grid>
												<Grid item xs={4}>
													<StatesDropdown
														variant="outlined"
														name="state_id"
														placeholder="State"
														value={job?.office_locations[index]?.state_id}
														onChange={e => setListingLocations(index, e.target.name, e.target.value)}
														disabled={!hasLocalSelection}
														error={hasLocalSelection && !job?.office_locations[index]?.state_id}
														fullWidth
														useAbbreviation
														useID
													/>
												</Grid>
												<Grid
													item
													xs={1}
													style={{
														display: 'flex',
														flexDirection: 'column',
														alignItems: 'center',
														justifyContent: 'center',
													}}
												>
													<ButtonBase className="button-cancel" onClick={() => removeOfficeLocation(location)}>
														<Icons.Cancel style={{ marginTop: -3 }} />
													</ButtonBase>
												</Grid>
											</Grid>
										),
									)}
									<VerticalSpacer height={0.5} />
									<ButtonBase
										className="button-show-more"
										onClick={() => addOfficeLocation()}
										disabled={!hasLocalSelection}
									>
										<PlusIcon />
										ADD LOCATION
									</ButtonBase>
								</LabeledField>
							</Grid>

							<Grid item xs={12}>
								<LabeledField label="Additional Location Details" removeMargin>
									<TextField
										variant="outlined"
										name="additional_work_location_details"
										value={job.additional_work_location_details || ''}
										onChange={updateJob}
										fullWidth
									/>
								</LabeledField>
							</Grid>
						</Grid>
					</Section>
					<Typography variant="h3" component="h2" gutterBottom>
						Skills by Aptitude
					</Typography>
					<Section>
						{[...data.skillExperienceLevels]
							.reverse()
							.filter(x => x.id !== 1) /* Filter out the "Uncategorized" experience level. */
							.map(level => (
								<LabeledField
									key={level.name}
									label={
										{
											Expert: 'Best Skills',
											Advanced: 'Advanced Skills',
											Intermediate: 'Intermediate Skills',
											Novice: 'Basic Skills',
										}[level.label] || level.label
									}
								>
									<SkillDropdown
										tags
										variant="outlined"
										color="primary"
										name={`skills_${level.name}`}
										value={job.skills
											.filter(s => s.skill_experience_id === level.id && !s.skill.needs_review)
											.map(s => s.skill_id)}
										override={validSkills}
										onChange={e => {
											updateSkills(level.id, e.target.value);
										}}
										// error={!updatedCandidate?.skills?.filter(s => s.skill_experience_id === level.id)}
										fullWidth
									/>
								</LabeledField>
							))}
						{/* {[...data.skillExperienceLevels]
							.reverse()
							.filter(x => x.id !== 1)
							.map(level => (
								<LabeledField
									key={level.name}
									label={
										{
											Expert: 'Best Skills',
											Advanced: 'Advanced Skills',
											Intermediate: 'Intermediate Skills',
											Novice: 'Basic Skills',
										}[level.label] || level.label
									}
								>
									<SkillDropdown
										tags
										variant="outlined"
										color="primary"
										name={`skills_${level.name}`}
										value={job.skills
											.filter(s => s.skill_experience_id === level.id && !s.skill.needs_review)
											.map(s => s.skill_id)}
										override={validSkills}
										onChange={e => {
											updateJob(level.id, e.target.value);
										}}
										fullWidth
									/>
								</LabeledField>
							))} */}
					</Section>
				</Grid>
			</Grid>
		</Page>
	);
};

export default JobEdit;
