import React from 'react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Strike from '@tiptap/extension-strike';
import Code from '@tiptap/extension-code';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import { RichTextReadOnly } from 'mui-tiptap';

function ReadOnlyTextEditor({ content }) {
	return (
		<RichTextReadOnly content={content} extensions={[StarterKit, Underline, Strike, Code, BulletList, OrderedList]} />
	);
}

export default ReadOnlyTextEditor;
