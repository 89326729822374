import React, { useEffect, useState, useMemo } from 'react';
import { useStore, useSelector, shallowEqual } from 'react-redux';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { Grid, Typography, Button, TextField, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import Page from '../../components/page/Page';
import * as PageHeader from '../../components/page/PageHeader';
import BackArrow from '../../components/page/BackArrow';
import Section from '../../components/Section';
import VerticalSpacer from '../../components/VerticalSpacer';
import LabeledField from '../../components/form/LabeledField';
import DateInput from '../../components/form/inputs/DateInput';
import PhoneInput from '../../components/form/inputs/PhoneInput';
import FileInput from '../../components/form/inputs/FileInput';
import LoadingButton from '../../components/form/LoadingButton';
import CategoryDropdown from '../../components/form/dropdowns/CategoryDropdown';
import Dropdown from '../../components/form/dropdowns/Dropdown';
import SalaryDropdown from '../../components/form/dropdowns/SalaryDropdown';
import SkillDropdown from '../../components/form/dropdowns/SkillDropdown';
import StatesDropdown from '../../components/form/dropdowns/StatesDropdown';
import SpecialtyDropdown from '../../components/form/dropdowns/SpecialtyDropdown';
import IndustryExperienceDropdown from '../../components/form/dropdowns/IndustryExperienceDropdown';
import TravelWillingnessDropdown from '../../components/form/dropdowns/TravelWillingnessDropdown';
import WillingToRelocateDropdown from '../../components/form/dropdowns/WillingToRelocateDropdown';
import WorkLocationDropdown from '../../components/form/dropdowns/WorkLocationDropdown';
import WorkVisaDropdown from '../../components/form/dropdowns/WorkVisaDropdown';
import YearsDropdown from '../../components/form/dropdowns/YearsDropdown';
import { ActionCreators as SiteActions } from '../../store/Site';
import * as CandidatesAPI from '../../scripts/candidates';
import * as SkillsAPI from '../../scripts/skills';
import * as SiteAPI from '../../scripts/site';
import * as Utility from '../../scripts/utility';

const CandidateEdit = () => {
	const store = useStore();
	const history = useHistory();
	const params = useParams();
	const candidateID = params.id;
	const [isLoading, setIsLoading] = useState(false);
	const [candidate, setCandidate] = useState({});
	const [validSkills, setValidSkills] = useState([]);
	const { data, alert } = useSelector(state => state.site, shallowEqual);

	const workLocationPreferenceValue = useMemo(() => candidate?.work_location_preferences?.map(p => (p.id ? p.id : p)), [
		candidate.work_location_preferences,
	]);

	const hasLocalSelection = useMemo(() => workLocationPreferenceValue?.some(pid => [2, 3, 4, 5].includes(pid)), [
		workLocationPreferenceValue,
	]);

	const validGetTheJobSkills = candidate?.skills
		// ?.filter(
		// 	s => [4, 5].includes(s.skill_experience_id), // expert or advanced skills
		// )
		?.map(s => ({ ...s, id: s.skill_id }));

	const getTheJobSkills = useMemo(() => candidate?.skills?.filter(skill => skill.get_the_job), [candidate.skills]);
	const enjoyTheJobSkills = useMemo(() => candidate?.skills?.filter(skill => skill.enjoy_the_job), [candidate.skills]);
	const improveOnTheJobSkills = useMemo(() => candidate?.skills?.filter(skill => skill.improve_on_job), [
		candidate.skills,
	]);
	const learnOnTheJobSkills = useMemo(() => candidate?.skills?.filter(skill => skill.learn_on_job), [candidate.skills]);

	const selectGetJobSkill = (skillId, replacedSkillId) => {
		const updatedSkills = candidate.skills.map(s => {
			if (s.skill_id === replacedSkillId) return { ...s, get_the_job: false };
			if (s.skill_id === skillId) return { ...s, get_the_job: true };
			return s;
		});
		setCandidate(s => ({ ...s, skills: updatedSkills }));
	};

	const setSkillExperience = (skill, experienceYears) => {
		if (skill) {
			const updatedSkills = candidate.skills.map(s =>
				s.skill_id === skill.skill_id ? { ...skill, experience_years: experienceYears } : s,
			);
			setCandidate(c => ({
				...c,
				skills: updatedSkills,
			}));
		}
	};

	const updateCandidate = ev => {
		const resumeReset = ev.target.name === 'resumeFile' && ev.target.value ? { resume: '' } : {};

		let newValue = { [ev.target.name]: ev.target.value };

		// Reset leadership years if leadership role is none
		if (ev.target.name === 'recent_leadership_role_id' && (ev.target.value === 1 || ev.target.value === null))
			newValue = { ...newValue, total_leadership_years: 0 };

		setCandidate({ ...candidate, ...newValue, ...resumeReset });
	};

	const updateCandidateDate = (date, name) => {
		setCandidate({
			...candidate,
			[name]: date,
		});
	};

	// const removeCandidateSkill = (level, skill) => {
	// 	setCandidate({ ...candidate, [level]: (candidate[level] || []).filter(s => s !== skill) });
	// };

	const onCandidateUpdate = response => {
		setIsLoading(false);

		if (!response) {
			store.dispatch(SiteActions.showAlert('An error ocurred saving the candidate. Please try again.', 'error'));
			return;
		}

		SiteAPI.updateSkills(store);
		history.push(`/candidate/${candidateID}`);
	};

	const selectSkill = (skillStoryName, skillIds) => {
		// create an array of skills that are new; not already in updatedCandidate.skills
		const newSkills = skillIds
			.filter(sid => !candidate.skills.find(s => s.skill_id === sid))
			.map(sid => {
				return {
					skill_id: sid,
					[skillStoryName]: true,
					skill_experience_id: 1,
					experience_years: 0,
					skill: validSkills.find(s => s.id === sid),
				};
			});
		// for each input skill (skillIds) set the candidate.skill story name to true
		const updatedSkills = candidate.skills
			.map(s => ({ ...s, [skillStoryName]: skillIds.includes(s.skill_id) ? true : undefined }))
			.concat(newSkills);
		setCandidate(c => ({ ...c, skills: updatedSkills }));
	};

	const updateSkills = (levelId, skillIds) => {
		const removedSkillIds = candidate.skills
			.filter(s => s.skill_experience_id === levelId)
			.filter(s => !skillIds.includes(s.skill_id))
			.map(s => s.skill_id);

		const newSkills = skillIds
			.filter(skillId => !candidate.skills.find(s => s.skill_id === skillId))
			.map(skillId => ({
				skill_id: skillId,
				skill: validSkills.find(vs => vs.id === skillId),
				skill_experience_id: levelId,
				experience_years: 0,
			}));

		const updatedSkills = candidate.skills
			.filter(s => !removedSkillIds.includes(s.skill_id)) // remove skills
			.map(s => (skillIds.includes(s.skill_id) ? { ...s, skill_experience_id: levelId } : s)) // update xp id
			.concat(newSkills);
		setCandidate(c => ({ ...c, skills: updatedSkills }));
	};

	const saveSkills = (response, err) => {
		if (!response) {
			setIsLoading(false);

			if (Utility.getFromObj(err, 'response', 'data', 'errors', 'file')) {
				store.dispatch(
					SiteActions.showAlert(
						`An error ocurred saving the candidate. ${Utility.getFromObj(
							err,
							'response',
							'data',
							'errors',
							'file',
							0,
						)}`,
						'error',
					),
				);
			} else {
				store.dispatch(SiteActions.showAlert('An error ocurred saving the candidate. Please try again.', 'error'));
			}
			return;
		}

		CandidatesAPI.addCandidateSkills(candidate.id, candidate.skills, skillResponse => {
			if (!skillResponse) {
				setIsLoading(false);
				store.dispatch(SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error'));
				return;
			}
			history.push(`/candidate/${candidateID}`);
		});
	};

	const saveResume = response => {
		if (!response) {
			setIsLoading(false);
			store.dispatch(SiteActions.showAlert('An error ocurred saving the candidate. Please try again.', 'error'));
		}

		if (candidate.resumeFile) CandidatesAPI.uploadCandidateResume(candidateID, candidate.resumeFile, saveSkills);
		else if (candidate.resume !== candidate.original_resume && candidate.resume)
			CandidatesAPI.uploadCandidateTextResume(candidateID, candidate.resume, saveSkills);
		else saveSkills(true);
	};

	const saveCandidate = ev => {
		setIsLoading(true);
		CandidatesAPI.updateCandidate(candidateID, CandidatesAPI.getForgedCandidate(candidate), saveResume);
	};

	// Get page data on load
	useEffect(() => {
		CandidatesAPI.getCandidate(candidateID, response => {
			if (!response) return;

			let resp = response.data.data;

			// Categorize skills
			resp = { ...resp, ...SkillsAPI.splitSkills(data.skillExperienceLevels, resp.skills) };

			// Save original resume
			resp.original_resume = resp.resume;

			// Turn locations into IDs for dropdown
			resp.desired_locations = resp.desired_locations.map(l => l.state_id);

			setCandidate(resp);
		});

		if (!validSkills.length) {
			CandidatesAPI.getValidSkills(candidateID, response => {
				if (response) setValidSkills(response.data.data);
			});
		}
	}, [data.skillExperienceLevels]);

	if (Object.keys(candidate).length === 0) return <Page />;

	return (
		<Page>
			<PageHeader.Header>
				<PageHeader.Left>
					<BackArrow to={`/candidate/${candidateID}`} />
					<Typography variant="h1">{`${candidate.first_name || ''} ${candidate.last_name || ''}`}</Typography>
				</PageHeader.Left>

				<PageHeader.Right>
					<NavLink to={`/candidate/${candidateID}`}>
						<Button variant="text" color="primary">
							Discard Changes
						</Button>
					</NavLink>
					<LoadingButton loading={isLoading} variant="contained" color="primary" onClick={saveCandidate}>
						Save Changes
					</LoadingButton>
				</PageHeader.Right>
			</PageHeader.Header>

			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}

			<Grid container spacing={8}>
				<Grid item md={12} lg={6}>
					<Typography variant="h3" component="h2" gutterBottom>
						Experience
					</Typography>
					<Section>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<LabeledField label="Current Title" removeMargin>
									<TextField
										variant="outlined"
										name="current_title"
										value={Utility.getFromObj(candidate, 'current_title')}
										onChange={updateCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Current Employer" removeMargin>
									<TextField
										variant="outlined"
										name="current_employer"
										value={candidate.current_employer || candidate.current_employment || ''}
										onChange={updateCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Professional Experience" removeMargin>
									<YearsDropdown
										variant="outlined"
										name="professional_experience_years"
										value={Utility.getFromObj(candidate, 'professional_experience_years')}
										onChange={updateCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Employment Type" removeMargin>
									<Dropdown
										variant="outlined"
										name="current_employment_type_id"
										value={Utility.getFromObj(candidate, 'current_employment_type_id')}
										data={data.currentEmploymentTypes}
										onChange={updateCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Current or Most Recent Leadership" removeMargin>
									<TextField
										variant="outlined"
										name="leadership_title"
										value={
											candidate.recent_leadership_role_id === 1 || candidate.recent_leadership_role_id === null
												? ''
												: candidate.leadership_title || ''
										}
										onChange={updateCandidate}
										placeholder="Enter most recent leadership title"
										disabled={candidate.recent_leadership_role_id === 1 || candidate.recent_leadership_role_id === null}
										error={
											candidate.recent_leadership_role_id &&
											candidate.recent_leadership_role_id !== 1 &&
											!candidate.leadership_title
										}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Current or Most Recent Leadership Level" removeMargin>
									<Dropdown
										variant="outlined"
										name="recent_leadership_role_id"
										value={Utility.getFromObj(candidate, 'recent_leadership_role_id')}
										data={data.leadershipExperienceRoles}
										onChange={updateCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Leadership Experience" removeMargin>
									<YearsDropdown
										variant="outlined"
										name="total_leadership_years"
										value={Utility.getFromObj(candidate, 'total_leadership_years')}
										onChange={updateCandidate}
										disabled={
											candidate?.recent_leadership_role_id === 1 || candidate?.recent_leadership_role_id === null
										}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Industry Sector Experience" removeMargin>
									<IndustryExperienceDropdown
										variant="outlined"
										name="industries"
										value={(Utility.getFromObj(candidate, 'industries') || []).map(item => (item.id ? item.id : item))}
										onChange={updateCandidate}
										fullWidth
										multiple
									/>
								</LabeledField>
							</Grid>
							{/* <Grid item xs={6}>
								<LabeledField label="Professional Category">
									<CategoryDropdown
										variant="outlined"
										name="professional_category_id"
										value={Utility.getFromObj(candidate, 'professional_category_id')}
										onChange={updateCandidate}
										fullWidth
										// error={!Utility.getFromObj(listing, 'professional_category_id')}
									/>
								</LabeledField>
							</Grid> */}
							{/* <Grid item xs={6}>
								<LabeledField label="Specialty">
									<SpecialtyDropdown
										variant="outlined"
										name="specialty_id"
										value={
											candidate.leadership_allocation_type_id === 3 || candidate.leadership_allocation_type_id === 4
												? null
												: candidate.specialty_id
										}
										placeholder="Choose from our list"
										category={candidate.professional_category_id}
										onChange={updateCandidate}
										disabled={
											!candidate.professional_category_id ||
											candidate.leadership_allocation_type_id === 3 ||
											candidate.leadership_allocation_type_id === 4
										}
										fullWidth
									/>
								</LabeledField>
							</Grid> */}
						</Grid>
					</Section>
					<Typography variant="h3" component="h2" gutterBottom>
						Job Preferences
					</Typography>
					<Section>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<LabeledField label="Desired Role" removeMargin>
									<TextField
										variant="outlined"
										name="desired_position"
										value={Utility.getFromObj(candidate, 'desired_position')}
										onChange={updateCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Employment Preference(s)" removeMargin>
									<Dropdown
										variant="outlined"
										name="employment_preference"
										value={candidate.employment_preference}
										data={data.employmentPreferences}
										onChange={updateCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Work Location Preference" removeMargin>
									<WorkLocationDropdown
										variant="outlined"
										name="work_location_preferences"
										value={workLocationPreferenceValue}
										// placeholder={workLocationPreferenceValue.length ? '' : 'Select all that apply'}
										onChange={updateCandidate}
										fullWidth
										multiple
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Willing to Relocate?" removeMargin>
									<WillingToRelocateDropdown
										variant="outlined"
										name="willing_to_relocate"
										value={hasLocalSelection ? candidate?.willing_to_relocate : null}
										// error={!candidate.willing_to_relocate}
										disabled={!hasLocalSelection}
										// placeholder="Choose from our list"
										onChange={updateCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Desired Work States">
									<StatesDropdown
										multiple
										useID
										variant="outlined"
										name="desired_locations"
										value={candidate.desired_locations}
										onChange={updateCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Minimum Salary Expectations" removeMargin>
									<SalaryDropdown
										variant="outlined"
										name="salary_expectation_type_id"
										value={candidate.salary_expectation_type_id}
										placeholder="Choose from our list"
										onChange={updateCandidate}
										// error={!candidate.salary_expectation_type_id}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Work Visa Sponsorship" removeMargin>
									<WorkVisaDropdown
										variant="outlined"
										name="work_visa_type_id"
										value={candidate?.work_visa_type_id}
										onChange={updateCandidate}
										placeholder="Choose from our list"
										// error={!candidate.work_visa_type_id}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Travel">
									<TravelWillingnessDropdown
										variant="outlined"
										name="travel_willingness_type_id"
										value={candidate.travel_willingness_type_id}
										placeholder="Choose from our list"
										onChange={updateCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={12}>
								<LabeledField label="Reasons Seeking New Role">
									<TextField
										variant="outlined"
										name="reason_seeking_new_role"
										value={Utility.getFromObj(candidate, 'reason_seeking_new_role')}
										onChange={updateCandidate}
										rows={2}
										multiline
										fullWidth
									/>
								</LabeledField>
							</Grid>
						</Grid>
					</Section>
				</Grid>
				<Grid item md={12} lg={6}>
					<Typography variant="h3" component="h2" gutterBottom>
						Candidate Info
					</Typography>
					<Section>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<LabeledField label="First Name">
									<TextField
										variant="outlined"
										name="first_name"
										value={Utility.getFromObj(candidate, 'first_name')}
										onChange={updateCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Last Name">
									<TextField
										variant="outlined"
										name="last_name"
										value={Utility.getFromObj(candidate, 'last_name')}
										onChange={updateCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Primary E-mail">
									<TextField
										variant="outlined"
										name="email"
										value={Utility.getFromObj(candidate, 'email')}
										onChange={updateCandidate}
										fullWidth
										disabled
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="LinkedIn">
									<TextField
										variant="outlined"
										name="linkedin_url"
										value={Utility.getFromObj(candidate, 'linkedin_url')}
										onChange={updateCandidate}
										fullWidth
										disabled
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Primary Phone">
									<TextField
										variant="outlined"
										name="phone"
										value={candidate.phone}
										onChange={updateCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="City">
									<TextField
										variant="outlined"
										name="city"
										value={candidate.city || ''}
										onChange={updateCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="State">
									<StatesDropdown
										variant="outlined"
										name="state"
										value={Utility.getFromObj(candidate, 'state')}
										onChange={updateCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid>
						</Grid>
					</Section>
					<Typography variant="h3" component="h2" gutterBottom>
						Resume
					</Typography>
					<Section>
						<LabeledField label="Resume / CV">
							<TextField
								variant="outlined"
								name="resume"
								value={Utility.getFromObj(candidate, 'resume')}
								onChange={updateCandidate}
								rows={10}
								multiline
								fullWidth
								disabled={Boolean(Utility.getFromObj(candidate, 'resumeFile'))}
							/>
							<VerticalSpacer height={0.5} />

							<div className="text-right">
								<FileInput name="resumeFile" onChange={updateCandidate}>
									Upload Resume
								</FileInput>
							</div>
						</LabeledField>
					</Section>

					<Typography variant="h3" component="h2" gutterBottom>
						Skills
					</Typography>
					<Section>
						<Grid container spacing={2}>
							{/* <Grid item xs={6}>
								<LabeledField label="Category">
									<CategoryDropdown
										variant="outlined"
										name="professional_category_id"
										value={candidate.professional_category_id}
										onChange={updateCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid> */}
							{/* <Grid item xs={6}>
								<LabeledField label="Specialty">
									<SpecialtyDropdown
										variant="outlined"
										name="specialty_id"
										value={candidate.specialty_id}
										category={candidate.professional_category_id}
										onChange={updateCandidate}
										fullWidth
									/>
								</LabeledField>
							</Grid> */}
						</Grid>
						{[...data.skillExperienceLevels]
							.reverse()
							.filter(x => x.id !== 1) /* Filter out the "Uncategorized" experience level. */
							.map(level => (
								<LabeledField
									key={level.name}
									label={
										{
											Expert: 'Best Skills',
											Advanced: 'Advanced Skills',
											Intermediate: 'Intermediate Skills',
											Novice: 'Basic Skills',
										}[level.label] || level.label
									}
								>
									<SkillDropdown
										tags
										variant="outlined"
										color="primary"
										name={`skills_${level.name}`}
										value={candidate.skills
											.filter(s => s.skill_experience_id === level.id && !s.skill.needs_review)
											.map(s => s.skill_id)}
										override={validSkills}
										onChange={e => {
											updateSkills(level.id, e.target.value);
										}}
										// error={!updatedCandidate?.skills?.filter(s => s.skill_experience_id === level.id)}
										fullWidth
									/>
								</LabeledField>
							))}
						{/* {[...data.skillExperienceLevels].reverse().map(level => (
							<LabeledField key={level.name} label={level.label}>
								<SkillDropdown
									tags
									variant="outlined"
									color="primary"
									name={`skills_${level.name}`}
									value={candidate[`skills_${level.name}`]}
									override={validSkills}
									onChange={updateCandidate}
									fullWidth
								/>
							</LabeledField>
						))} */}
					</Section>
					<Typography variant="h3" component="h2" gutterBottom>
						Skill Story
					</Typography>
					<Section>
						<Typography className="text-bold">Land the Job</Typography>
						<Typography>
							Select up to three high aptitude skills that are most critical to landing your next role. These should be
							skills that will be the foundation of your daily work.
						</Typography>
						<VerticalSpacer height={1} />
						{[1, 2, 3].map((v, i) => {
							return (
								<Grid container spacing={2} key={`getTheJobSkill-${getTheJobSkills[i]?.skill_id || v}`}>
									<Grid item xs={7}>
										<SkillDropdown
											variant="outlined"
											name="get_the_job"
											value={getTheJobSkills[i]?.skill_id}
											override={validGetTheJobSkills}
											onChange={ev => selectGetJobSkill(ev.target.value, getTheJobSkills[i]?.skill_id)}
											fullWidth
										/>
									</Grid>
									<Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
										<Typography variant="body1" className="text-right text-medium">
											Experience
										</Typography>
									</Grid>
									<Grid item xs={3}>
										<YearsDropdown
											variant="outlined"
											name="get_the_job_years"
											value={getTheJobSkills[i]?.experience_years}
											onChange={ev => setSkillExperience(getTheJobSkills[i], ev.target.value)}
											disableClearable={false}
											fullWidth
										/>
									</Grid>
								</Grid>
							);
						})}
						<VerticalSpacer height={3} />
						<Typography className="text-bold">Enjoy Using</Typography>
						<Typography>
							Select skills that you would most like to use regularly on the job. These can overlap with the skills that
							get you the job but they don&rsquo;t have to.
						</Typography>
						<VerticalSpacer height={1} />
						<SkillDropdown
							tags
							variant="outlined"
							color="primary"
							name="enjoy_the_job"
							value={enjoyTheJobSkills.map(s => (s.skill_id ? s.skill_id : s))}
							override={candidate.skills}
							onChange={ev => selectSkill(ev.target.name, ev.target.value)}
							fullWidth
						/>
						<VerticalSpacer height={3} />
						<Typography className="text-bold">Improve</Typography>
						<Typography>
							Select skills you&rsquo;d most like to improve on the job. These are skills you currently have and wish to
							further develop.
						</Typography>
						<VerticalSpacer height={1} />
						<SkillDropdown
							tags
							variant="outlined"
							color="primary"
							name="improve_on_job"
							value={improveOnTheJobSkills.map(s => (s.skill_id ? s.skill_id : s))}
							override={candidate.skills}
							onChange={ev => selectSkill(ev.target.name, ev.target.value)}
							fullWidth
						/>
						<VerticalSpacer height={3} />
						<Typography className="text-bold">Learn</Typography>
						<Typography>
							Add skills you&rsquo;d most like to acquire on the job. These are skills you don&rsquo;t have but want to
							learn.
						</Typography>
						<VerticalSpacer height={1} />
						<SkillDropdown
							tags
							variant="outlined"
							color="primary"
							name="learn_on_job"
							value={learnOnTheJobSkills.map(s => (s.skill_id ? s.skill_id : s))}
							override={validSkills}
							onChange={ev => selectSkill(ev.target.name, ev.target.value)}
							fullWidth
						/>
					</Section>
				</Grid>
			</Grid>
		</Page>
	);
};

export default CandidateEdit;
