import React from 'react';
import { Grid, Typography, Link } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import LabeledField from '../../../components/form/LabeledField';
import * as Utility from '../../../scripts/utility';
import ViewBlockWrapper from '../../candidates/CandidateBlocks/ViewBlockWrapper';
import { ActionCreators as SiteActions } from '../../../store/Site';
import EditMoreDetailsModal from './EditMoreDetailsModal';
import MoreDetailsIcon from '../../../components/icons/SectionIcons/MoreDetailsIcon';
import VerticalSpacer from '../../../components/VerticalSpacer';

const Block = ({ job, setJob, data, minimized, setMinimized, disableEdit, graphMatch }) => {
	const dispatch = useDispatch();

	return (
		<>
			<ViewBlockWrapper
				icon={MoreDetailsIcon}
				iconColor="var(--blue-500)"
				title="More Details"
				minimized={minimized}
				setMinimized={setMinimized}
				onEditClick={
					disableEdit
						? null
						: () => {
								dispatch(
									SiteActions.showModal(<EditMoreDetailsModal job={job} setJob={setJob} />, {
										className: 'modal-medium modal-no-padding modal-no-shadow',
									}),
								);
						  }
				}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Grid item xs={12}>
							<VerticalSpacer height={1} />
							<Typography variant="body2" color="primary" style={{ fontWeight: 500, marginBottom: '10px' }}>
								Job Q&A
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<>
								{(job.job_qa_questions || []).map((qa, index) => (
									<div key={qa?.job_qa_question_id}>
										<LabeledField label={`Question ${index + 1}`} className="color-muted" removeMargin close>
											<Typography style={{ fontWeight: 'bold' }}>{qa?.job_qa_question_id}</Typography>
											<Typography>{qa?.answer}</Typography>
										</LabeledField>
										<VerticalSpacer height={0.5} />
									</div>
								))}
							</>
						</Grid>
						<Typography variant="body2">{job.questions || Utility.nb}</Typography>
					</Grid>
					<Grid item xs={12}>
						<VerticalSpacer height={1} />
						<Typography variant="body2" color="primary" style={{ fontWeight: '500' }}>
							Job Content Links
						</Typography>
					</Grid>
					<Grid item xs={12}>
						{(job.job_content_links || []).map((link, index) => (
							<div key={index}>
								<Typography style={{ fontWeight: 'bold' }}>{link.name}</Typography>
								<Link
									href={
										link.url.startsWith('http://') || link.url.startsWith('https://') ? link.url : `https://${link.url}`
									}
									target="_blank"
								>
									<Typography>{link.url}</Typography>
								</Link>
								<VerticalSpacer height={0.5} />
							</div>
						))}
					</Grid>
				</Grid>
			</ViewBlockWrapper>
		</>
	);
};

export default Block;
