import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector, useStore } from 'react-redux';
import { Box, Button, Chip, CircularProgress, Fab, TextField, Typography } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import LabeledField from '../form/LabeledField';
import Dropdown from '../form/dropdowns/Dropdown';
import LoadingButton from '../form/LoadingButton';

import { ActionCreators as SiteActions } from '../../store/Site';
import * as Utility from '../../scripts/utility';
import * as CandidatesAPI from '../../scripts/candidates';

const CandidateSyncModal = ({ onSync, ...props }) => {
	const dispatch = useDispatch();
	const store = useStore();
	const [isLoadingCandidate, setLoadingCandidate] = useState(false);
	const [search, setSearch] = useState('');
	const [candidates, setCandidates] = useState();
	const [candidate, setCandidate] = useState();
	const [isLoading, setLoading] = useState(false);
	const { alert } = useSelector(state => state.site, shallowEqual);

	const findCandidate = () => {
		setLoadingCandidate(true);
		dispatch(SiteActions.hideAlert());

		CandidatesAPI.searchForCandidate(search, response => {
			setLoadingCandidate(false);

			if (!response) {
				dispatch(SiteActions.showAlert('An error ocurred searching for a candidate. Please try again.', 'error'));
				return;
			}

			if (response && !response.data.data.length) {
				dispatch(SiteActions.showAlert('No matching candidates found.', 'info'));
				return;
			}

			setCandidates(response.data.data);
		});
	};

	const getCandidates = () => {
		return candidates
			.sort((a, b) => Utility.alphaSort(a, b, 'full_name'))
			.map(c => ({
				id: c.vendor_candidate_id,
				label: c.full_name,
				new: !c.existing_candidate_id,
				status: c.status,
			}));
	};

	const onCandidateSync = (response, err) => {
		setLoading(false);

		if (!response) {
			const message = err?.response?.data?.message || 'An error ocurred when syncing the candidate. Please try again.';
			store.dispatch(SiteActions.showAlert(message, 'error'));
			return;
		}

		store.dispatch(SiteActions.hideAlert());
		store.dispatch(SiteActions.hideModal());
		if (typeof onSync === 'function') onSync();
	};

	const syncCandidate = ev => {
		setLoading(true);
		CandidatesAPI.syncFromBullhorn(candidate, {}, onCandidateSync);
	};

	return (
		<div className="text-center">
			<Typography variant="h2" style={{ marginBottom: '2.5rem' }}>
				Candidate Sync
			</Typography>

			<LabeledField label="Candidate Name or Bullhorn ID">
				<Box display="flex" justifyContent="stretch" marginBottom="48px">
					<TextField
						variant="outlined"
						value={search}
						// placeholder="Search by candidate name or ID"
						onChange={ev => setSearch(ev.target.value)}
						style={{ flex: 1, marginRight: 15 }}
					/>
					<Fab color="primary" onClick={findCandidate} aria-label="search">
						{isLoadingCandidate ? <CircularProgress size={24.5} style={{ color: 'white' }} /> : <Icons.Search />}
					</Fab>
				</Box>
			</LabeledField>

			{alert.show ? (
				<Alert
					severity={alert.color}
					onClose={ev => store.dispatch(SiteActions.hideAlert())}
					style={{ marginBottom: 48 }}
				>
					{alert.message}
				</Alert>
			) : null}

			{candidates ? (
				<Box marginBottom="48px">
					<LabeledField label="Candidate to Sync">
						<Dropdown
							data={getCandidates()}
							value={candidate}
							name="candidate"
							onChange={ev => setCandidate(ev.target.value)}
							renderOption={(option, state) => (
								<Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
									<Box flexGrow={1}>
										<Typography variant="caption" color="textSecondary" display="inline" style={{ marginRight: 5 }}>
											#{option.id}
										</Typography>
										<Typography display="inline">
											<b>{option.label}</b>
										</Typography>
										<Typography>{option.status}</Typography>
									</Box>
									{option.new ? null : <Chip color="primary" size="small" label="Existing" style={{ margin: 0 }} />}
								</Box>
							)}
						/>
					</LabeledField>
				</Box>
			) : null}

			<LoadingButton
				loading={isLoading}
				variant="contained"
				color="primary"
				onClick={syncCandidate}
				style={{ minWidth: 185 }}
				disabled={!candidate}
			>
				Sync Candidate
			</LoadingButton>
		</div>
	);
};

CandidateSyncModal.propTypes = {
	onSync: PropTypes.func,
};

CandidateSyncModal.defaultProps = {
	onSync: () => {},
};

export default CandidateSyncModal;
