import React from 'react';
import { Typography } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import CandidateLogoLinks from './CandidateLogoLinks';
import * as Utility from '../../scripts/utility';

const CandidateDetailsBlock = ({ currentCandidate, data, setCandidate }) => {
	return (
		<div style={{ marginTop: 14 }}>
			<div style={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}>
				<CandidateLogoLinks candidate={currentCandidate} setCandidate={setCandidate} />
				{currentCandidate.status_type_id ? (
					<Chip
						size="small"
						color="secondary"
						label={Utility.getFromObj(currentCandidate, 'status_type', 'label')}
						style={{ margin: 0, marginLeft: '1.5rem' }}
					/>
				) : null}
			</div>
			<Typography variant="body1" style={{ marginBottom: 0 }}>
				{currentCandidate.state
					? `${currentCandidate.city}, ${Utility.getFromObj(
							Utility.getIDFromObj(data.states, currentCandidate.state, 'name'),
							'abbreviation',
					  )} `
					: ''}
				{currentCandidate.owner
					? ` | Owner: ${currentCandidate.owner.first_name} ${currentCandidate.owner?.last_name}`
					: ''}
			</Typography>
			<div style={{ display: 'flex' }}>
				<Typography variant="body1">
					{currentCandidate.email ? (
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={`mailto:${currentCandidate.email}`}
							className="color-inherit text-underline text-normal"
						>
							{currentCandidate.email}
						</a>
					) : (
						Utility.na
					)}
				</Typography>
				{currentCandidate?.email && (
					<Typography variant="body1" style={{ marginInline: 4 }}>
						|
					</Typography>
				)}
				<Typography variant="body1">
					{currentCandidate.phone ? (
						<a href={`tel:${currentCandidate.phone}`} className="color-inherit text-underline text-normal">
							{currentCandidate.phone}
						</a>
					) : (
						<span style={{ fontStyle: 'italic' }}>no phone number</span>
					)}
				</Typography>
			</div>
		</div>
	);
};

export default CandidateDetailsBlock;
