import React, { useRef, useState, useEffect } from 'react';
import { useStore, useSelector, shallowEqual } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
	Typography,
	Card,
	Button,
	Chip,
	IconButton,
	Menu,
	MenuItem,
	ListItemIcon,
	Tooltip,
	Grid,
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import { useParams } from 'react-router';

import LabeledField from '../../components/form/LabeledField';
import Page from '../../components/page/Page';
import * as PageHeader from '../../components/page/PageHeader';
import Table from '../../components/Table';
import JobDeleteModal from '../../components/modals/JobDeleteModal';
import JobFilters from '../../components/filters/JobFilters';

import { ActionCreators as SiteActions } from '../../store/Site';
import * as ListingsAPI from '../../scripts/listings';
import * as Utility from '../../scripts/utility';
import { palette } from '../../BobcatTheme';
import * as CandidatesAPI from '../../scripts/candidates';
import CheckboxInput from '../../components/form/inputs/CheckboxInput';
import JobMatchCardItem from '../../components/matching/JobMatchCardItem';
import JobCardList from '../../components/matching/JobCardList';
import { useContextStore } from '../../store/ContextStore';

const CandidateMatches = () => {
	const store = useStore();
	const { data, alert } = useSelector(state => state.site, shallowEqual);
	const [filter, setFilter] = Utility.useStickyState({ isOpen: false, filters: {} }, 'admin-candidate-matches-filter');
	const table = useRef();
	const [graphMatches, setGraphMatches] = useState([]);

	const { candidateMatches: matches, setCandidateMatches: setMatches, refetchMatches } = useContextStore();

	const numFilters = Object.values(filter.filters || {}).filter(x => x).length;

	const openFilter = () => {
		setFilter({ ...filter, isOpen: true });
	};

	const closeFilter = () => {
		setFilter({ ...filter, isOpen: false });
	};

	const updateFilters = (ev, name) => {
		if (typeof ev === 'object' && typeof name === 'string') {
			setFilter({ ...filter, filters: { ...filter.filters, [name]: ev } });
		} else if (ev.target.value !== null && (ev.target.value.length || typeof ev.target.value === 'number')) {
			setFilter({ ...filter, filters: { ...filter.filters, [ev.target.name]: ev.target.value } });
		} else {
			setFilter({ ...filter, filters: { ...filter.filters, [ev.target.name]: undefined } });
		}
	};

	// const sendAssessment = ev => {
	// 	setMenu({ ...menu, anchor: null });

	// 	ListingsAPI.sendAssessment(menu.listing.id, (response, err) => {
	// 		if (!response) {
	// 			let message = 'The job assessment could not be sent. Please try again.';
	// 			if (err === 'contact') message = 'A contact could not be found for this job. The assessment was not sent.';

	// 			store.dispatch(SiteActions.showAlert(message, 'error'));
	// 			return;
	// 		}

	// 		store.dispatch(SiteActions.showAlert('The job assessment was sent.', 'success'));
	// 	});
	// };

	const params = useParams();
	const candidateID = params.id;
	const [candidate, setCandidate] = useState(null);
	const [listings, setListings] = useState([]);
	const [loadingListings, setLoadingListings] = useState(true);
	const [menu, setMenu] = useState({ anchor: null, listing: {} });

	// console.log({ filter });

	// Get the selected candidate
	useEffect(() => {
		CandidatesAPI.getCandidate(candidateID, response => {
			if (!response) {
				store.dispatch(SiteActions.showAlert('Could not load the candidate. Refresh the page to try again.', 'error'));
				return;
			}
			const newCandidate = response.data.data;
			setCandidate(newCandidate);
		});

		// Get mock listings
		// ListingsAPI.getListings({}, response => {
		// 	console.log({ response });
		// 	setListings(response?.data?.data);
		// 	setLoadingListings(false);
		// });

		CandidatesAPI.getCandidateRandomGraphMatches({ candidateId: candidateID }).then(async response => {
			console.log({ response });

			setGraphMatches(response?.data?.data);
			setLoadingListings(false);
		});

		// Get matches for the current candidate.
		refetchMatches({ candidateId: candidateID });
	}, []);

	const filteredGraphMatches = graphMatches;

	if (!candidate) {
		return null;
	}

	return (
		<Page filterPadding={filter.isOpen}>
			<PageHeader.Header>
				{candidate && (
					<Card style={{ width: '100%' }}>
						<Typography variant="h3" style={{ marginBottom: 15 }}>
							<NavLink to={`/candidate/${candidate.id}`} className="color-inherit">
								Match Testing: {candidate.first_name} {candidate.last_name}
							</NavLink>
						</Typography>
					</Card>
				)}

				{/* <PageHeader.Middle>
					<TextField
						variant="outlined"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Icons.Search style={{ color: 'var(--primaryColor)' }} />
								</InputAdornment>
							),
						}}
						fullWidth
					/>
				</PageHeader.Middle> */}

				<PageHeader.Right>
					{/* <Button
						variant="text"
						color="primary"
						onClick={ev => Utility.notImplemented(store)}
						startIcon={<Icons.GetApp />}
					>
						Export to XLS
					</Button> */}

					{/* <Button
						variant="contained"
						color="primary"
						onClick={() =>
							store.dispatch(
								SiteActions.showModal(<JobSyncModal category={menu.category} onSync={table.current.refreshData} />),
							)
						}
						startIcon={<Icons.Sync />}
					>
						Sync Job
					</Button> */}
				</PageHeader.Right>
			</PageHeader.Header>

			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}

			<JobCardList
				refetchMatches={refetchMatches}
				matches={matches}
				candidateId={candidateID}
				loading={loadingListings}
				graphMatches={filteredGraphMatches}
				pageSize={40}
				disableLink
				filterComponent={null}
				hideAdd
				hideItemDetails
				autoSelectAll
				enableSelectAll
				enableRefresh
				// listings={filteredListings}
			/>
		</Page>
	);
};

export default CandidateMatches;
