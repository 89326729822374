import React, { useEffect, useState, useMemo, Fragment } from 'react';
import { Grid, Typography, Button, Chip } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import LabeledField from '../../../components/form/LabeledField';
import * as Utility from '../../../scripts/utility';
import CaseIcon from '../../../components/icons/SectionIcons/CaseIcon';
import ViewBlockWrapper from '../../candidates/CandidateBlocks/ViewBlockWrapper';
import { ActionCreators as SiteActions } from '../../../store/Site';
import EditDetailsModal from './EditDetailsModal';
import ValidationFieldWrapper from '../../../components/matching/ValidationFieldWrapper';
import { SkillChip } from '../../candidates/CandidateBlocks/ViewSkillsBlock';
import ExpandableTypography from '../../../components/form/ExpandableTypography';

const Block = ({
	job,
	setJob,
	data,
	minimized,
	setMinimized,
	comparisonCandidate,
	disableEdit,
	isLimited,
	displayMatchValidation,
	graphMatch,
}) => {
	const { states } = data;
	const dispatch = useDispatch();

	return (
		<>
			<ViewBlockWrapper
				icon={CaseIcon}
				iconColor="var(--blue-500)"
				title="Details"
				secondaryTitle={
					graphMatch?.total_preference_score
						? `Score: ${Math.round(graphMatch?.total_preference_score * 100)}% (${Math.round(
								graphMatch?.completeness_score_preference * 100,
						  )}%)`
						: null
				}
				minimized={minimized}
				setMinimized={setMinimized}
				onEditClick={
					disableEdit
						? null
						: () => {
								dispatch(
									SiteActions.showModal(<EditDetailsModal job={job} setJob={setJob} />, {
										className: 'modal-medium modal-no-padding modal-no-shadow',
									}),
								);
						  }
				}
			>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<LabeledField label="Title" className="color-muted" removeMargin close>
							<Typography variant="body2">{job.position_title || Utility.nb}</Typography>
						</LabeledField>
					</Grid>

					{/* <Grid item xs={6}>
						<LabeledField label="Job Status" className="color-muted" close>
							<Typography variant="body2">
								{Utility.getFromObj(job, 'listing_status', 'label') || Utility.nb}
							</Typography>
						</LabeledField>
					</Grid> */}
					<Grid item xs={6}>
						<LabeledField label="Type" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate: comparisonCandidate,
									job,
									propertyName: 'role_type',
								})}
							>
								<Typography variant="body2">
									{Utility.getItemLabel(data.employmentPreferences, job.role_type) || Utility.nb}
								</Typography>
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>

					<Grid item xs={12}>
						<LabeledField label="Hero Skills" className="color-muted" removeMargin close>
							{(job.skills || [])
								.filter(x => x.hero_skill)
								.map(skill => (
									<SkillChip baseColor="blue" key={skill?.id} skill={skill} />
								))}
						</LabeledField>
					</Grid>

					{/* <LabeledField label="Desired Start Date" className="color-muted" close>
							<Typography variant="body2">{Utility.formatDate(job.start_date, true) || Utility.nb}</Typography>
						</LabeledField> */}
					<Grid item xs={6}>
						<LabeledField label="Salary Range" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate: comparisonCandidate,
									job,
									propertyName: 'salary',
								})}
							>
								<Typography variant="body2">
									{isLimited ? (
										<span
											style={{
												filter: isLimited ? 'blur(3px)' : 'none',
												webkitFilter: isLimited ? 'blur(3px)' : 'none',
											}}
										>
											$999,999 - $999,999
										</span>
									) : (
										<span>
											{Utility.formatMoney(job.salary_rate_min) || Utility.formatMoney(0)} -{' '}
											{Utility.formatMoney(job.salary_rate_max) || Utility.formatMoney(0)}
										</span>
									)}
								</Typography>
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>

					<Grid item xs={6}>
						<LabeledField label="Other Compensation" className="color-muted" removeMargin close>
							<Typography variant="body2">
								{isLimited ? (
									<span
										style={{
											filter: isLimited ? 'blur(3px)' : 'none',
											webkitFilter: isLimited ? 'blur(3px)' : 'none',
										}}
									>
										Details Hidden
									</span>
								) : (
									<span>
										{job?.other_compensation_types?.length
											? job?.other_compensation_types?.map(t => t.label).join(', ')
											: Utility.nb}
									</span>
								)}
							</Typography>
						</LabeledField>
					</Grid>

					<Grid item xs={6}>
						<LabeledField label="Value Propositions" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate: comparisonCandidate,
									job,
									propertyName: 'value_proposition_types',
								})}
							>
								<Typography variant="body2">
									{job?.value_proposition_types?.map(x => x?.value_proposition_type?.label)?.join(', ') || Utility.nb}
								</Typography>
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>

					<Grid item xs={6}>
						<LabeledField label="Work Visa Sponsorship" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate: comparisonCandidate,
									job,
									propertyName: 'work_visa',
								})}
							>
								<Typography variant="body2">{job?.work_visa_types?.label || Utility.nb}</Typography>
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>

					<Grid item xs={6}>
						<LabeledField label="Travel" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate: comparisonCandidate,
									job,
									propertyName: 'travel',
								})}
							>
								<Typography variant="body2">{job?.travel_willingness_type?.label || Utility.nb}</Typography>
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>

					<Grid item xs={6}>
						<LabeledField label="Openings" className="color-muted" removeMargin close>
							<ValidationFieldWrapper
								hide={!displayMatchValidation}
								validation={Utility.getMatchingValidation({
									data,
									candidate: comparisonCandidate,
									job,
									propertyName: 'openings',
								})}
							>
								<Typography variant="body2">{job?.openings || Utility.nb}</Typography>
							</ValidationFieldWrapper>
						</LabeledField>
					</Grid>

					<Grid item xs={12}>
						<LabeledField label="Job Summary (Abstract)" className="color-muted" removeMargin close>
							<ExpandableTypography numCharacters={250} variant="body2" text={job?.abstract || Utility.nb} />
						</LabeledField>
					</Grid>

					{/* <Grid item xs={6}>
						<LabeledField label="Openings" className="color-muted" close>
							<Typography variant="body2">{job.openings || Utility.nb}</Typography>
						</LabeledField>
						{[1, 2].includes(job.role_type) ? (
							<LabeledField label="Contract Duration" className="color-muted" close>
								<Typography variant="body2">
									{job.contract_duration ? `${job.contract_duration} Weeks` : Utility.nb}
								</Typography>
							</LabeledField>
						) : null}
					</Grid> */}
					<Grid item xs={12}>
						<LabeledField label="Additional Details" className="color-muted" removeMargin close>
							<ExpandableTypography
								numCharacters={250}
								variant="body2"
								text={job?.additional_job_detail || Utility.nb}
							/>
						</LabeledField>
					</Grid>
				</Grid>
			</ViewBlockWrapper>
		</>
	);
};

export default Block;
