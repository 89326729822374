import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, Tooltip, Typography } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import Dropdown from './Dropdown';
import VerticalSpacer from '../../VerticalSpacer';

const QADropdown = ({ data, candidate, job, onDelete, onComplete, question, idx, isLastQuestion, ...props }) => {
	const [qaQuestion, setQaQuestion] = useState({
		qa_question_id: question?.qa_question_id || '',
		answer: question?.answer || '',
		// isFeatured: false,
	});

	useEffect(() => {
		if (qaQuestion.qa_question_id && qaQuestion.answer) {
			onComplete(
				question.id,
				qaQuestion.qa_question_id,
				qaQuestion.answer,
				//   qaQuestion.isFeatured
			);
		}
	}, [qaQuestion]);

	const handleQuestionChange = e => {
		const newQuestionId = e.target.value;
		setQaQuestion(prevQuestion => ({
			...prevQuestion,
			qa_question_id: newQuestionId,
		}));
		onComplete(question.id, newQuestionId, qaQuestion.answer);
	};

	const handleAnswerChange = e => {
		const newAnswer = e.target.value;
		setQaQuestion(prevQuestion => ({
			...prevQuestion,
			answer: newAnswer,
		}));
		onComplete(question.id, qaQuestion.qa_question_id, newAnswer);
	};

	const handleDelete = () => {
		if (isLastQuestion) {
			setQaQuestion({
				qa_question_id: '',
				answer: '',
			});
		}
		onDelete(question.id);
	};

	const unansweredCandidateQuestions = data?.qaQuestions?.filter(
		q => !candidate?.qa_questions?.some(candidateQuestion => candidateQuestion.qa_question_id === q.id),
	);

	const unansweredJobQuestions = data?.jobQaQuestions?.filter(
		q => !job?.job_qa_questions?.some(jobQuestion => jobQuestion.job_qa_question_id === q.id),
	);

	return (
		<Grid key={qaQuestion.qa_question_id || idx} style={{ marginBottom: 20 }}>
			<Grid item xs={12}>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
					<div style={{ flex: 1, marginBottom: 10 }}>
						<Typography variant="body2">Question {idx + 1}</Typography>
						<VerticalSpacer height={0.5} />
						{candidate ? (
							<Dropdown
								data={qaQuestion.qa_question_id ? data.qaQuestions : unansweredCandidateQuestions}
								value={qaQuestion.qa_question_id}
								onChange={handleQuestionChange}
								placeholder={props?.placeholder || 'Select a question...'}
								name="qa_questions"
								{...props}
							/>
						) : (
							<Dropdown
								data={qaQuestion.qa_question_id ? data.jobQaQuestions : unansweredJobQuestions}
								value={qaQuestion.qa_question_id}
								onChange={handleQuestionChange}
								placeholder={props?.placeholder || 'Select a question...'}
								name="qa_questions"
								{...props}
							/>
						)}
					</div>
					<div style={{ width: 80, paddingLeft: 30 }}>
						<VerticalSpacer height={1} />
						<Tooltip arrow placement="top" title="Delete Question">
							<Button
								variant="text"
								color="#000"
								size="large"
								style={{
									margin: 0,
									padding: 4,
									minWidth: 0,
									borderRadius: 4,
									fontWeight: 400,
									height: 45,
								}}
								onClick={handleDelete}
							>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<Icons.Remove color="gray" size={20} />
								</div>
							</Button>
						</Tooltip>
					</div>
				</div>
			</Grid>
			<Grid item xs={11}>
				<VerticalSpacer height={1} />
				<Typography variant="body2">Answer</Typography>
				<VerticalSpacer height={0.5} />
				<TextField
					variant="outlined"
					name="answer"
					value={qaQuestion.answer}
					placeholder="Enter your answer here"
					onChange={handleAnswerChange}
					fullWidth
					multiline
					minRows={3}
				/>
			</Grid>
			{/* <FormControlLabel
				style={{ marginTop: -20 }}
				control={
					<Checkbox
						checked={qaQuestion.isFeatured}
						onChange={() => setQaQuestion({ ...qaQuestion, isFeatured: !qaQuestion.isFeatured })}
						color="primary"
					/>
				}
				label="Set as featured answer"
			/> */}
		</Grid>
	);
};

export default QADropdown;
