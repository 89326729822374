import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useStore, shallowEqual, useSelector } from 'react-redux';
import { Grid, Button, Typography, Popper, Paper, TextField, Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as Icons from '@material-ui/icons';
import { Panel, PanelHeader, PanelContent } from '../../../components/Panel';
import LoadingButton from '../../../components/form/LoadingButton';
import { ActionCreators as SiteActions } from '../../../store/Site';
import * as CandidatesAPI from '../../../scripts/candidates';
import * as Utility from '../../../scripts/utility';
import StarCheckmarkIcon from '../../../components/icons/StarCheckmarkIcon';
import LinkDropdown from '../../../components/form/dropdowns/LinkDropdown';
import QADropdown from '../../../components/form/dropdowns/QADropdown';
import VerticalSpacer from '../../../components/VerticalSpacer';
import Fence from '../../../components/Fence';
import TextFieldWithCounter from '../../../components/TextFieldWithCounter';
import AddAnotherButton from '../../../components/form/AddAnotherButton';

const EditAchievementsModal = ({ candidate, setCandidate }) => {
	const { data } = useSelector(state => state.site, shallowEqual);
	const store = useStore();
	const dispatch = useDispatch();
	const [isSaving, setIsSaving] = useState(false);
	const [achievementsPoppper, setAchievementsPopper] = useState({ open: false, anchor: null });
	const [qaPopper, setQaPopper] = useState({ open: false, anchor: null });
	const [linksPopper, setLinksPopper] = useState({ open: false, anchor: null });
	const initialCandidate = JSON.parse(JSON.stringify(candidate));
	initialCandidate.qa_questions = initialCandidate.qa_questions.map((q, index) => ({ ...q, id: index }));
	const [updatedCandidate, setUpdatedCandidate] = useState(initialCandidate);
	const [nextId, setNextId] = useState(initialCandidate.qa_questions.length || 0);
	const [qaNumFields, setQaNumFields] = useState(updatedCandidate?.qa_questions.length || 1);
	const [linkNumFields, setLinkNumFields] = useState(1);
	const candidateLinks = useMemo(() => updatedCandidate?.link_types || [], [updatedCandidate.link_types]);

	const saveLinkResponse = (linkId, url) => {
		const updateLink = candidateLinks.find(link => link.link_type_id === linkId);
		if (updateLink) {
			updateLink.url = url;
			setUpdatedCandidate(prevCandidate => ({ ...prevCandidate, link_types: candidateLinks }));
			return;
		}
		setUpdatedCandidate(prevCandidate => ({
			...prevCandidate,
			link_types: [
				...prevCandidate.link_types,
				{
					link_type_id: linkId,
					url,
				},
			],
		}));
	};

	const handleSaveOrUpdateQuestion = (id, questionId, answer) => {
		setUpdatedCandidate(prevCandidate => {
			const candidateQaQuestions = prevCandidate.qa_questions.map(q =>
				q.id === id ? { ...q, qa_question_id: questionId, answer } : q,
			);
			if (!candidateQaQuestions.some(q => q.id === id)) {
				candidateQaQuestions.push({ id, qa_question_id: questionId, answer });
			}
			return { ...prevCandidate, qa_questions: candidateQaQuestions };
		});
	};

	const handleDeleteQuestion = id => {
		setUpdatedCandidate(prevCandidate => {
			const updatedQuestions = prevCandidate.qa_questions.filter(q => q.id !== id);
			return { ...prevCandidate, qa_questions: updatedQuestions };
		});
	};

	const handleAddQuestion = () => {
		setUpdatedCandidate(prevCandidate => ({
			...prevCandidate,
			qa_questions: [...prevCandidate.qa_questions, { id: nextId, qa_question_id: '', answer: '' }],
		}));
		setNextId(prevId => prevId + 1);
		setQaNumFields(prevCount => prevCount + 1);
	};

	const updateCandidate = ev => {
		const newValue = {
			[ev.target.name]: ev.target.value,
		};
		setUpdatedCandidate({
			...updatedCandidate,
			...newValue,
		});
	};

	const saveCandidate = () => {
		setIsSaving(true);
		const seenQaQuestionIds = new Set();
		const uniqueQaQuestions = updatedCandidate.qa_questions
			.filter(q => {
				if (!seenQaQuestionIds.has(q.qa_question_id)) {
					seenQaQuestionIds.add(q.qa_question_id);
					return true;
				}
				return false;
			})
			.map(q => ({
				qa_question_id: q.qa_question_id,
				answer: q.answer,
			}));

		setUpdatedCandidate({ ...updatedCandidate, qa_questions: uniqueQaQuestions });
		const forgedCurrentCandidate = CandidatesAPI.getForgedCandidate(candidate);
		const forgedCandidate = CandidatesAPI.getForgedCandidate({
			...updatedCandidate,
			qa_questions: uniqueQaQuestions,
		});

		const differencesToSubmit = Utility.getEntityDifferences(forgedCurrentCandidate, forgedCandidate);

		CandidatesAPI.updateCandidate(candidate.id, differencesToSubmit, response => {
			if (!response) {
				setIsSaving(false);
				store.dispatch(SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error'));
				return;
			}
			const newCandidate = response.data.data;
			setCandidate(newCandidate);
			dispatch(SiteActions.hideModal());
		});
	};

	return (
		<Panel>
			<PanelHeader className="spread">
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<StarCheckmarkIcon size={26} className="teal-100" />
					<Typography variant="h6" component="h2" className="text-medium" style={{ paddingLeft: 10 }}>
						Achievements and Interests
					</Typography>
				</div>
			</PanelHeader>
			<PanelContent>
				<Grid container spacing={2} style={{ marginBottom: 40 }}>
					<Grid item xs={12}>
						<div style={{ display: 'flex', alignItems: 'center', gap: 4, marginBottom: 10 }}>
							<Typography variant="body2" color="primary" style={{ fontWeight: '500' }}>
								Achievements
							</Typography>
							<VerticalSpacer height={4} />
							<Icons.Info
								color="disabled"
								onFocus={ev => setAchievementsPopper({ open: true, anchor: ev.currentTarget })}
								onBlur={ev => setAchievementsPopper({ open: false, anchor: ev.currentTarget })}
								onMouseOver={ev => setAchievementsPopper({ open: true, anchor: ev.currentTarget })}
								onMouseOut={ev => setAchievementsPopper({ open: false, anchor: ev.currentTarget })}
							/>
							<Popper
								open={achievementsPoppper.open}
								anchorEl={achievementsPoppper.anchor}
								placement="right-start"
								transition
							>
								<Paper color="primary">
									<Fence width={17}>
										You&apos;re encouraged to brag - e.g. I was responsible for the go to market (GTM) strategy for a
										new AI service that expanded the user base by 150% in the first launch quarter.
									</Fence>
								</Paper>
							</Popper>
						</div>
						<Typography variant="body2">Achievements & Accomplishments</Typography>
						<VerticalSpacer height={0.5} />
						<TextFieldWithCounter
							variant="outlined"
							name="achievements"
							value={updatedCandidate?.achievements}
							placeholder="You're encouraged to brag - e.g. I was responsible for the go to market (GTM) 
							strategy for a new AI service that expanded the user base 150%."
							onChange={updateCandidate}
							fullWidth
							minRows={3}
							multiline
							inputProps={{
								maxLength: 300,
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<div style={{ display: 'flex', alignItems: 'center', gap: 4, marginBottom: 10 }}>
							<Typography variant="body2" color="primary" style={{ fontWeight: '500' }}>
								Q&A Questions
							</Typography>
							<VerticalSpacer height={4} />
							<Icons.Info
								color="disabled"
								onFocus={ev => setQaPopper({ open: true, anchor: ev.currentTarget })}
								onBlur={ev => setQaPopper({ open: false, anchor: ev.currentTarget })}
								onMouseOver={ev => setQaPopper({ open: true, anchor: ev.currentTarget })}
								onMouseOut={ev => setQaPopper({ open: false, anchor: ev.currentTarget })}
							/>
							<Popper open={qaPopper.open} anchorEl={qaPopper.anchor} placement="right-start" transition>
								<Paper color="primary">
									<Fence width={17}>
										Answer questions about you to stand out to hiring managers. Select your best answer to feature at
										the top of your profile.
									</Fence>
								</Paper>
							</Popper>
						</div>
						{(updatedCandidate.qa_questions.length > 0
							? updatedCandidate.qa_questions
							: [{ id: nextId, qa_question_id: '', answer: '' }]
						).map((q, idx) => (
							<QADropdown
								key={q.id}
								data={data}
								onComplete={(id, questionId, answer) => handleSaveOrUpdateQuestion(id, questionId, answer)}
								onDelete={handleDeleteQuestion}
								candidate={updatedCandidate}
								question={q}
								idx={idx}
								isLastQuestion={updatedCandidate.qa_questions.length <= 1}
							/>
						))}
						{qaNumFields < 7 && (
							<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<AddAnotherButton text="Add Another Q&A" onClick={handleAddQuestion} />
							</div>
						)}
					</Grid>
					<Grid item xs={12}>
						<div style={{ display: 'flex', alignItems: 'center', gap: 4, marginBottom: 10 }}>
							<Typography variant="body2" color="primary" style={{ fontWeight: '500' }}>
								Links
							</Typography>
							<VerticalSpacer height={4} />
							<Icons.Info
								color="disabled"
								onFocus={ev => setLinksPopper({ open: true, anchor: ev.currentTarget })}
								onBlur={ev => setLinksPopper({ open: false, anchor: ev.currentTarget })}
								onMouseOver={ev => setLinksPopper({ open: true, anchor: ev.currentTarget })}
								onMouseOut={ev => setLinksPopper({ open: false, anchor: ev.currentTarget })}
							/>
							<Popper open={linksPopper.open} anchorEl={linksPopper.anchor} placement="right-start" transition>
								<Paper color="primary">
									<Fence width={17}>
										Add links to useful websites, such as your LinkedIn, portfolio, projects, or GitHub. Hiring managers
										use this information to build a picture of you.
									</Fence>
								</Paper>
							</Popper>
						</div>
						<Grid container spacing={2}>
							<Grid item xs={5}>
								<Typography variant="body2">Link Type</Typography>
								<VerticalSpacer height={0.5} />
								<Box
									style={{
										border: '1px solid lightgray',
										borderRadius: 5,
										height: 43,
										paddingLeft: 17,
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<Typography variant="body2">LinkedIn</Typography>
								</Box>
							</Grid>
							<Grid item xs={1} />
							<Grid item xs={6}>
								<Typography variant="body2">Link URL</Typography>
								<VerticalSpacer height={0.5} />
								<TextField
									variant="outlined"
									name="linkedin_url"
									value={updatedCandidate.linkedin_url}
									placeholder="Add LinkedIn URL here"
									fullWidth
									onChange={updateCandidate}
								/>
							</Grid>
						</Grid>
						<VerticalSpacer height={0.5} />
						{(updatedCandidate?.link_types.length >= 1 || linkNumFields > updatedCandidate?.link_types.length
							? [
									...updatedCandidate.link_types,
									...Array.from({
										length: Math.min(Math.max(1, linkNumFields), 7) - updatedCandidate.link_types.length,
									}),
							  ]
							: Array.from({ length: 1 })
						).map((l, idx) => (
							<LinkDropdown
								data={data}
								onComplete={saveLinkResponse}
								onDelete={() => {
									setLinkNumFields(prevCount => prevCount - 1);
									setUpdatedCandidate({
										...updatedCandidate,
										link_types: updatedCandidate.link_types.filter((_, i) => i !== idx),
									});
								}}
								candidate={updatedCandidate}
								link={l}
								idx={idx}
							/>
						))}
						<VerticalSpacer height={1} />
						{linkNumFields < 6 && (
							<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<AddAnotherButton text="Add Another Link" onClick={() => setLinkNumFields(prev => prev + 1)} />
							</div>
						)}
					</Grid>
				</Grid>
				{alert.show ? (
					<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
						{alert.message}
					</Alert>
				) : null}
				<div className="text-right">
					<Button variant="outlined" color="primary" onClick={() => dispatch(SiteActions.hideModal())}>
						Cancel
					</Button>
					<LoadingButton loading={isSaving} variant="contained" color="primary" value={2} onClick={saveCandidate}>
						Save
					</LoadingButton>
				</div>
			</PanelContent>
		</Panel>
	);
};

EditAchievementsModal.propTypes = {
	candidate: PropTypes.shape({ id: PropTypes.string }),
	setCandidate: PropTypes.func,
};

EditAchievementsModal.defaultProps = {
	candidate: {},
	setCandidate: () => null,
};

export default EditAchievementsModal;
