import { Box } from '@material-ui/core';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Strike from '@tiptap/extension-strike';
import Code from '@tiptap/extension-code';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import {
	MenuButtonBold,
	MenuButtonItalic,
	MenuControlsContainer,
	MenuDivider,
	MenuSelectHeading,
	MenuButtonUnderline,
	MenuButtonStrikethrough,
	MenuButtonCode,
	MenuButtonBulletedList,
	MenuButtonOrderedList,
	RichTextEditor,
} from 'mui-tiptap';
import React, { useEffect, useRef } from 'react';

function DynamicTextEditor({ content, onChange, fieldName }) {
	const rteRef = useRef(null);

	const handleContentChange = () => {
		const newContent = rteRef.current?.editor?.getHTML();
		onChange({ target: { name: fieldName, value: newContent } });
	};

	useEffect(() => {
		const editor = rteRef.current?.editor;
		if (editor) {
			editor.on('update', handleContentChange);
		}
		return () => {
			if (editor) {
				editor.off('update', handleContentChange);
			}
		};
	}, []);

	return (
		<Box
			sx={{
				'& .MuiTiptap-RichTextContent-root .ProseMirror': { minHeight: '200px' },
				'& .MuiDivider-root': { marginTop: 0, marginBottom: 0 },
			}}
		>
			<RichTextEditor
				ref={rteRef}
				extensions={[StarterKit, Underline, Strike, Code, BulletList, OrderedList]}
				content={content}
				renderControls={() => (
					<MenuControlsContainer>
						<MenuSelectHeading />
						<MenuDivider />
						<MenuButtonBold />
						<MenuButtonItalic />
						<MenuButtonUnderline />
						<MenuButtonStrikethrough />
						<MenuButtonCode />
						<MenuDivider />
						<MenuButtonBulletedList />
						<MenuButtonOrderedList />
					</MenuControlsContainer>
				)}
			/>
		</Box>
	);
}

export default DynamicTextEditor;
