import React, { useState, useEffect, useRef } from 'react';
import { useStore, useSelector, shallowEqual } from 'react-redux';
import { useHistory, useParams, NavLink } from 'react-router-dom';
import { Button, Typography, TextField, Grid, Menu, MenuItem, ListItemIcon, IconButton } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import Page from '../../../components/page/Page';
import * as PageHeader from '../../../components/page/PageHeader';
import BackArrow from '../../../components/page/BackArrow';
import Section from '../../../components/Section';
import Table from '../../../components/Table';
import LabeledField from '../../../components/form/LabeledField';
import Dropdown from '../../../components/form/dropdowns/Dropdown';
import LoadingButton from '../../../components/form/LoadingButton';
import VerticalSpacer from '../../../components/VerticalSpacer';
import SovrenSkillAddModal from '../../../components/modals/SovrenSkillAddModal';
// import SovrenSkillEditModal from '../../../components/modals/SovrenSkillEditModal';
import SovrenSkillDeleteModal from '../../../components/modals/SovrenSkillDeleteModal';

import { ActionCreators as SiteActions } from '../../../store/Site';
import * as SkillsAPI from '../../../scripts/skills';
import * as SiteAPI from '../../../scripts/site';
import * as Utility from '../../../scripts/utility';
import SkillDropdown from '../../../components/form/dropdowns/SkillDropdown';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import useGlobalSkills from '../../../hooks/useGlobalSkills';

const SkillEdit = () => {
	useDocumentTitle('Data Manager - Skills');

	useGlobalSkills();
	const store = useStore();
	const history = useHistory();
	const params = useParams();
	const skillID = params.id;
	const [isLoading, setIsLoading] = useState(false);
	const [skill, setSkill] = useState({ suggested: false });
	const [menu, setMenu] = useState({ anchor: null, skill: {} });
	const sovrenTable = useRef();
	const originationTable = useRef();
	const { data, alert } = useSelector(state => state.site, shallowEqual);

	const onSkillUpdate = response => {
		setIsLoading(false);

		if (!response) {
			store.dispatch(
				SiteActions.showAlert('An error ocurred when changing the skill status. Please try again.', 'error'),
			);
			return;
		}

		SiteAPI.updateSkills(store);
		history.push(`/admin/skills`);
	};

	const changeStatus = response => {
		if (!response) {
			store.dispatch(
				SiteActions.showAlert('An error ocurred when saving the skill specialties. Please try again.', 'error'),
			);
			return;
		}

		if (skill.status === 'inactive') SkillsAPI.deleteSkill(skill.id, {}, onSkillUpdate);
		else onSkillUpdate(response);
	};

	const saveSpecialties = response => {
		if (!response) {
			store.dispatch(SiteActions.showAlert('An error ocurred saving the skill. Please try again.', 'error'));
			return;
		}

		if (skill.specialties.length) SkillsAPI.syncSkillSpecialties(skillID, skill.specialties, changeStatus);
		else changeStatus(response);
	};

	const saveSkill = ev => {
		setIsLoading(true);

		// Determine the Needs Review status
		let needsReview;
		if (!skill.deleted_at) needsReview = skill.status === 'pending';
		else needsReview = skill.needs_review;

		// Determine the Deleted status
		const deletedAt = skill.status === 'inactive' ? skill.deleted_at : null;

		SkillsAPI.updateSkill(
			skillID,
			{ ...skill, name: '', needs_review: needsReview, deleted_at: deletedAt },
			saveSpecialties,
		);
	};

	const originationHeaders = [
		{ id: 'sovren.od', value: 'Portal Record', noSort: true },
		// { id: 'sovren.label', value: 'Name', noSort: true },
		{ id: 'sovren.created_at', value: 'Created', noSort: true },
	];
	const originationRow = rows => {
		return rows
			.filter(sovren => {
				return !!sovren.originating_model_id;
			})
			.map(sovren => {
				let navLink = null;
				let objectType = null;

				if (sovren.originating_model_id) {
					if (sovren.originating_model_type.toLowerCase().includes('candidate')) {
						navLink = `/candidate/${sovren.originating_model_id}`;
						objectType = 'Candidate';
					}
					if (sovren.originating_model_type.toLowerCase().includes('listing')) {
						navLink = `/job/${sovren.originating_model_id}`;
						objectType = 'Job';
					}
				}

				return {
					...sovren,
					navLink,
					objectType,
				};
			})
			.map(sovren => {
				return [
					<>
						{sovren.navLink ? (
							<NavLink to={sovren.navLink} className="color-inherit" target="_blank">
								{sovren.objectType} {sovren.originating_model_id}
							</NavLink>
						) : null}
					</>,

					<>
						{Utility.formatDate(sovren.created_at)}{' '}
						<span className="color-muted">{Utility.formatTime(sovren.created_at)}</span>
					</>,
				];
			});
	};

	const closeMenu = () => {
		setMenu({ ...menu, anchor: null });
	};

	const sovrenHeaders = [
		{ id: 'sovren.od', value: 'Sovren ID', noSort: true },
		{ id: 'sovren.label', value: 'Name', noSort: true },
		{ id: 'sovren.created_at', value: 'Created', noSort: true },
		{ id: 'options', value: '', noSort: true },
	];

	const sovrenRow = rows => {
		return rows.map(sovren => {
			return [
				sovren.sovren_original_skill_id,
				sovren.label,
				Utility.formatDate(sovren.created_at),
				<IconButton onClick={ev => setMenu({ anchor: ev.target, skill: sovren })}>
					<Icons.MoreVert color="primary" />
				</IconButton>,
			];
		});
	};

	// Get page data on load
	useEffect(() => {
		SkillsAPI.getSkill(skillID, response => {
			if (!response) {
				store.dispatch(
					SiteActions.showAlert('An error ocurred when loading the skill data. Please refresh the page.', 'error'),
				);
				return;
			}

			const resp = response.data.data;

			if (resp.deleted_at) {
				setSkill({
					...resp,
					deleted_at: true,
				});
			} else {
				resp.specialties = resp.specialties.map(s => s.id);
				resp.status = SkillsAPI.calculateSkillStatus(resp).toLowerCase();

				setSkill(resp);
			}
		});
	}, []);

	if (Object.keys(skill).length === 0) {
		return <Page />;
	}
	if (skill.deleted_at) {
		return (
			<Page>
				<PageHeader.Header>
					<PageHeader.Left>
						<BackArrow to="/admin/skills" />
						<Typography variant="h1">Edit Skill</Typography>
					</PageHeader.Left>

					<PageHeader.Right />
				</PageHeader.Header>

				<Grid container spacing={8}>
					<Grid item md={12} lg={6}>
						<div style={{ height: 50 }} />
						<Typography variant="h3" component="h2" gutterBottom>
							Error: Skill has been deprecated.
						</Typography>
					</Grid>
				</Grid>
			</Page>
		);
	}

	return (
		<Page>
			<PageHeader.Header>
				<PageHeader.Left>
					<BackArrow to="/admin/skills" />
					<Typography variant="h1">Edit Skill</Typography>
				</PageHeader.Left>

				<PageHeader.Right>
					<Button
						variant="outlined"
						color="primary"
						// disabled={isLoading}
						onClick={async () => {
							store.dispatch(
								SiteActions.showModal(
									<SkillLinkModal
										onSubmit={async selectedSkillId => {
											SkillsAPI.deprecateSkill(skillID, selectedSkillId, results => {
												if (!results) {
													window.alert('Error linking skill');
												} else {
													store.dispatch(SiteActions.hideModal());
													window.location.href = `${window.location.origin}/admin/skill/${selectedSkillId}/edit`;
												}
											});
										}}
										// match={menu?.match}
										onClose={() => store.dispatch(SiteActions.hideModal())}
									/>,
									{
										className: 'modal-small',
									},
								),
							);
						}}
					>
						Link Skill
					</Button>
					<LoadingButton loading={isLoading} width={9} variant="contained" color="primary" onClick={saveSkill}>
						Save
					</LoadingButton>
				</PageHeader.Right>
			</PageHeader.Header>

			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}

			<Grid container spacing={8}>
				<Grid item md={12} lg={6}>
					<Typography variant="h3" component="h2" gutterBottom />
					<Section>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<LabeledField label="Skill Name">
									<TextField
										variant="outlined"
										name="label"
										value={skill.label || ''}
										onChange={ev => Utility.updateState(ev, skill, setSkill)}
										fullWidth
									/>
								</LabeledField>

								<LabeledField label="Specialties">
									<Dropdown
										multiple
										variant="outlined"
										name="specialties"
										value={skill.specialties || []}
										data={data.specialties}
										onChange={ev => Utility.updateState(ev, skill, setSkill)}
										fullWidth
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="ID" className="color-muted">
									<TextField variant="outlined" value={skill.id || ''} fullWidth disabled />
								</LabeledField>

								<LabeledField label="Status" className="color-muted">
									{/* <TextField
										variant="outlined"
										value={skill.needs_review ? 'Pending' : 'Active'}
										fullWidth
										disabled
									/> */}
									<Dropdown
										variant="outlined"
										name="status"
										value={skill.status || 'pending'}
										data={[
											{ id: 'pending', label: 'Pending' },
											{ id: 'active', label: 'Active' },
											{ id: 'inactive', label: 'Inactive' },
										]}
										onChange={ev => Utility.updateState(ev, skill, setSkill)}
										fullWidth
									/>
								</LabeledField>
							</Grid>
						</Grid>
					</Section>

					<div className="spread">
						<Typography variant="h3" component="h2">
							Skill Origination
						</Typography>
					</div>
					<VerticalSpacer height={1} />
					<Section>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Table
									onUpdate={SkillsAPI.getSovrenSkills}
									parameters={[skillID]}
									format={originationRow}
									headers={originationHeaders}
									ref={originationTable}
									noPagination
								/>
							</Grid>
						</Grid>
					</Section>

					<VerticalSpacer height={3} />
					<div className="spread">
						<Typography variant="h3" component="h2">
							Sovren Skills
						</Typography>

						<IconButton
							onClick={ev => {
								store.dispatch(
									SiteActions.showModal(
										<SovrenSkillAddModal skill={skill} onAdd={() => sovrenTable.current.refreshData()} />,
									),
								);
							}}
						>
							<Icons.Add color="primary" />
						</IconButton>
					</div>
					<VerticalSpacer height={1} />
					<Section>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Table
									onUpdate={SkillsAPI.getSovrenSkills}
									parameters={[skillID]}
									format={sovrenRow}
									headers={sovrenHeaders}
									ref={sovrenTable}
									noPagination
								/>
								<Menu anchorEl={menu.anchor} open={Boolean(menu.anchor)} onClose={closeMenu} keepMounted>
									{/* <MenuItem
										onClick={ev => {
											store.dispatch(
												SiteActions.showModal(
													<SovrenSkillEditModal
														skill={menu.skill}
														onEdit={() => sovrenTable.current.refreshData()}
													/>,
												),
											);
										}}
									>
										<ListItemIcon>
											<Icons.Create color="primary" />
										</ListItemIcon>
										Edit Sovren Skill
									</MenuItem> */}

									<MenuItem
										onClick={ev => {
											closeMenu();
											store.dispatch(
												SiteActions.showModal(
													<SovrenSkillDeleteModal
														skill={menu.skill}
														onDelete={() => sovrenTable.current.refreshData()}
													/>,
												),
											);
										}}
									>
										<ListItemIcon>
											<Icons.Delete color="secondary" />
										</ListItemIcon>
										Delete Sovren Skill
									</MenuItem>
								</Menu>
							</Grid>
						</Grid>
					</Section>

					<VerticalSpacer height={3} />
					<div className="spread">
						<Typography variant="h3" component="h2">
							PDL Skills
						</Typography>
					</div>
					<VerticalSpacer height={1} />
					<Section>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Table
									onUpdate={SkillsAPI.getPDLSkills}
									parameters={[skillID]}
									format={originationRow}
									headers={originationHeaders}
									ref={originationTable}
									noPagination
								/>
							</Grid>
						</Grid>
					</Section>

					<VerticalSpacer height={3} />
				</Grid>
			</Grid>
		</Page>
	);
};

export default SkillEdit;

const SkillLinkModal = ({ onSubmit, onClose }) => {
	const { data } = useSelector(state => state.site, shallowEqual);
	const skills = useGlobalSkills();
	const [selectedSkill, setSelectedSkill] = useState(null);
	return (
		<>
			<div className="spread">
				<Typography variant="h3" component="h2">
					Skill Link
				</Typography>
			</div>
			<VerticalSpacer height={1} />

			<Section>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<SkillDropdown
								name="linked-skill"
								style={{ flex: 1 }}
								value={selectedSkill}
								override={data.skills}
								onChange={e => {
									setSelectedSkill(e.target.value);
								}}
							/>
							<Button
								variant="contained"
								color="primary"
								// disabled={isLoading}
								onClick={async () => {
									onSubmit(selectedSkill);
								}}
							>
								Link Skill
							</Button>
						</div>
					</Grid>
				</Grid>
			</Section>
		</>
	);
};
