import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useStore, useSelector, shallowEqual } from 'react-redux';
import { Button, Typography } from '@material-ui/core';
import { Panel, PanelHeader, PanelContent } from '../../../components/Panel';
import StarCheckmarkIcon from '../../../components/icons/StarCheckmarkIcon';
import LabeledField from '../../../components/form/LabeledField';
import LoadingButton from '../../../components/form/LoadingButton';
import ElasticSkillDropdown from '../../../components/form/dropdowns/ElasticSkillDropdown';
import { ActionCreators as SiteActions } from '../../../store/Site';
import * as ListingsAPI from '../../../scripts/listings';
import * as Utility from '../../../scripts/utility';

const EditSkillsModal = ({ listing, setListing }) => {
	const store = useStore();
	const dispatch = useDispatch();
	const { data } = useSelector(state => state.site, shallowEqual);
	const [isSaving, setIsSaving] = useState(false);
	const [updatedListing, setUpdatedListing] = useState(listing);
	// const [validSkills, setValidSkills] = useState([]);

	const updateSkills = (levelId, skillIds, skillCache) => {
		const removedSkillIds = updatedListing.skills
			?.filter(s => s.skill_experience_id === levelId)
			?.filter(s => !skillIds.includes(s.skill_id))
			?.map(s => s.skill_id);

		const newSkills = skillIds
			?.filter(skillId => !updatedListing.skills.find(s => s.skill_id === skillId))
			?.map(skillId => ({
				skill_id: skillId,
				skill: skillCache[skillId] || { id: skillId },
				skill_experience_id: levelId,
				experience_years: 0,
			}));

		const updatedSkills = updatedListing.skills
			?.filter(s => !removedSkillIds.includes(s.skill_id)) // remove skills
			?.map(s => (skillIds.includes(s.skill_id) ? { ...s, skill_experience_id: levelId } : s)) // update xp id
			?.concat(newSkills);
		setUpdatedListing(c => ({ ...c, skills: updatedSkills }));
	};

	const saveListing = () => {
		setIsSaving(true);
		const forgedListing = ListingsAPI.getForgedListing(updatedListing);
		ListingsAPI.updateListing(listing.id, forgedListing, response => {
			if (!response) {
				setIsSaving(false);
				store.dispatch(SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error'));
				return;
			}
			const newListing = response.data.data;
			ListingsAPI.addListingSkills(listing.id, updatedListing.skills, skillResponse => {
				if (!skillResponse) {
					setIsSaving(false);
					store.dispatch(SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error'));
					return;
				}
				newListing.skills = skillResponse.data.data;
				setListing(newListing);
				dispatch(SiteActions.hideModal());
			});
		});
	};

	// useEffect(() => {
	// 	if (!validSkills.length) {
	// 		ListingsAPI.getValidSkills(listing.id, response => {
	// 			if (response) {
	// 				// const filteredSkills = response?.data?.data?.filter(s => skill)
	// 				setValidSkills(response.data.data);
	// 			}
	// 		});
	// 	}
	// }, [listing.id]);

	return (
		<Panel Icon={<StarCheckmarkIcon size={32} className="teal-100" />}>
			<PanelHeader className="spread">
				<Typography variant="body2" component="h2" className="text-bold" style={{ paddingTop: 4 }}>
					Job Skills By Aptitude
				</Typography>
			</PanelHeader>
			<PanelContent>
				{[...data.skillExperienceLevels]
					.reverse()
					.filter(x => x.id !== 1) /* Filter out the "Uncategorized" experience level. */
					.map(level => (
						<LabeledField
							key={level.name}
							label={
								{
									Expert: 'Best Skills',
									Advanced: 'Advanced Skills',
									Intermediate: 'Intermediate Skills',
									Novice: 'Basic Skills',
								}[level.label] || level.label
							}
						>
							<ElasticSkillDropdown
								tags
								variant="outlined"
								color="primary"
								name={`skills_${level.name}`}
								value={updatedListing.skills
									?.filter(s => s.skill_experience_id === level.id && !s.skill.needs_review)
									?.map(s => s.skill_id)}
								// override={validSkills}
								onChange={(e, skillCache) => {
									updateSkills(level.id, e.target.value, skillCache);
								}}
								fullWidth
							/>
						</LabeledField>
					))}
				<div className="text-right">
					<Button variant="outlined" color="primary" onClick={() => dispatch(SiteActions.hideModal())}>
						Cancel
					</Button>
					<LoadingButton loading={isSaving} variant="contained" color="primary" onClick={saveListing}>
						Save
					</LoadingButton>
				</div>
			</PanelContent>
		</Panel>
	);
};

EditSkillsModal.propTypes = {
	listing: PropTypes.shape({ id: PropTypes.string }),
	setListing: PropTypes.func,
};

EditSkillsModal.defaultProps = {
	listing: { id: null },
	setListing: () => null,
};

export default EditSkillsModal;
