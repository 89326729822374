import React from 'react';
import { Button, Grid, Typography, Chip } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Panel, PanelContent, PanelHeader } from '../../../components/Panel';
import PreferencesIcon from '../../../components/icons/PreferencesIcon';
import VerticalSpacer from '../../../components/VerticalSpacer';
import { ActionCreators as SiteActions } from '../../../store/Site';
import EditPreferencesModal from '../EditCandidateProfile/EditPreferencesModal';
import ExpandableTypography from '../../../components/form/ExpandableTypography';
import * as Utility from '../../../scripts/utility';

const Block = ({ candidate, setCandidate, isComplete, buttonRef, data }) => {
	const dispatch = useDispatch();
	return (
		<Panel id="Preferences">
			<PanelHeader className="spread">
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<PreferencesIcon size={32} className="teal-100" />
					<Typography variant="h6" component="h2" className="text-medium" style={{ paddingLeft: 10 }}>
						Preferences
					</Typography>
				</div>
				{!!candidate.assessment_completed_at && (
					<Button
						ref={buttonRef}
						variant={isComplete ? 'outlined' : 'containedTeal'}
						color="primary"
						size="small"
						value={2}
						onClick={() => {
							dispatch(
								SiteActions.showModal(<EditPreferencesModal candidate={candidate} setCandidate={setCandidate} />, {
									className: 'modal-medium modal-no-padding modal-no-shadow',
								}),
							);
						}}
					>
						{isComplete ? 'Edit' : 'Complete'}
					</Button>
				)}
			</PanelHeader>
			<PanelContent style={{ paddingInline: '63px' }}>
				<VerticalSpacer height={1} />
				<Grid container spacing={2}>
					<Grid item xs={12}>
						{candidate?.reason_seeking_new_role && (
							<>
								<Typography className="grey-250">Reason You&rsquo;re Seeking a New Role</Typography>
								<ExpandableTypography numCharacters={300} text={candidate?.reason_seeking_new_role} />
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
					<Grid item xs={6}>
						{candidate?.desired_position && (
							<>
								<Typography className="grey-250">Desired Role</Typography>
								<Typography>{candidate.desired_position}</Typography>
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
					<Grid item xs={6}>
						{candidate?.role_level_preferences?.length > 0 && (
							<>
								<Typography className="grey-250">Role Levels</Typography>
								<Typography>{candidate.role_level_preferences.map(p => p.label).join(', ')}</Typography>
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
					<Grid item xs={6}>
						{candidate?.salary_expectation_type?.label && (
							<>
								<Typography className="grey-250">Minimum Salary</Typography>
								<Typography>{candidate.salary_expectation_type.label}</Typography>
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
					<Grid item xs={6}>
						{candidate?.employment_preferences?.length > 0 && (
							<>
								<Typography className="grey-250">
									Role Type{candidate?.employment_preferences?.length === 1 ? '' : 's'}
								</Typography>
								<Typography>{candidate.employment_preferences.map(p => p.label).join(', ')}</Typography>
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
					<Grid item xs={12}>
						{candidate?.job_priority_types?.length > 0 && (
							<>
								<Typography className="grey-250">Next Role Priorities</Typography>
								<VerticalSpacer height={0.75} />
								<Typography>
									{candidate.job_priority_types.map(priority => (
										<Chip key={priority.id} label={priority.value_proposition_type.label} className="color-ready" />
									))}
								</Typography>
							</>
						)}
					</Grid>
					<Grid item xs={6}>
						{candidate?.categories?.length > 0 && (
							<>
								<Typography className="grey-250">Category</Typography>
								{candidate?.specialties?.map(specialty => {
									const categoryId = specialty?.category_id || specialty?.category?.id;
									return (
										<Typography variant="body2" key={categoryId}>
											{Utility.getItemLabel(data.categories, categoryId) || Utility.nb}
										</Typography>
									);
								})}
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
					<Grid item xs={6}>
						{candidate?.specialties?.length > 0 && (
							<>
								<Typography className="grey-250">Specialty</Typography>
								{candidate?.specialties?.map(
									({ id: specialtyId }) =>
										<Typography key={specialtyId}>{Utility.getItemLabel(data.specialties, specialtyId)}</Typography> ||
										Utility.nb,
								)}
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
					<Grid item xs={6}>
						{candidate?.industry_preferences?.length > 0 && (
							<>
								<Typography className="grey-250" style={{ marginBottom: 5 }}>
									Industries
								</Typography>
								{candidate?.industry_preferences?.map(({ id }) => {
									return <Chip key={id} label={Utility.getItemLabel(data.industries, id)} color="gray" />;
								})}
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
					<Grid item xs={6}>
						{candidate?.business_size_preferences?.length > 0 && (
							<>
								<Typography className="grey-250">Business Sizes</Typography>
								<Typography>
									{candidate?.business_size_preferences?.length === 8 &&
									candidate?.business_size_preferences
										?.map(s => s.id)
										.sort()
										.every((value, index) => value === [1, 2, 3, 4, 5, 6, 7, 8][index])
										? 'Any'
										: candidate.business_size_preferences
												.sort((a, b) => a.id - b.id)
												.map(t => t.label)
												.join(', ')}
								</Typography>
								<VerticalSpacer height={2} />
							</>
						)}
					</Grid>
				</Grid>
			</PanelContent>
		</Panel>
	);
};

export default Block;
