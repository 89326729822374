import React, { useState } from 'react';
import { Grid, Typography, Box, Tooltip, Button } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Panel } from '../../Panel';

import CandidateViewSkillsBlock from '../../../pages/candidates/CandidateBlocks/ViewSkillsBlock';
import JobViewSkillsBlock from '../../../pages/jobs/JobBlocks/ViewSkillsBlock';
import CandidateDetailsBlock from '../CandidateDetailsBlock';
import CandidateHeaderButtonGroup from '../CandidateHeaderButtonGroup';

import * as Utility from '../../../scripts/utility';
import SkillChip from '../MatchViewSkillChip';
import PivotTableIcon from '../../icons/PivotTableIcon';

const SkillsTab = ({ currentCandidate, setCandidate, currentJob, setCurrentJob, data, graphMatch }) => {
	// tabs: 'comparison' | 'blocks'
	const [subSkillsTab, setSubSkillsTab] = useState('comparison');
	const [isPivotHovered, setIsPivotHovered] = useState(false);

	const [isDetailsShown, setIsDetailsShown] = useState(false);
	const [activeSkillId, setActiveSkillId] = useState(null);
	const [jobSkillsMinimized, setJobSkillsMinimized] = useState(false);
	const [candidateSkillsMinimized, setCandidateSkillsMinimized] = useState(false);

	const [isHeroMinimized, setIsHeroMinimized] = useState(false);
	const [isRequiredMinimized, setIsRequiredMinimized] = useState(true);
	const [isPreferredMinimized, setIsPreferredMinimized] = useState(true);
	const [isAllMinimized, setIsAllMinimized] = useState(true);

	// final source of truth for the skills comes from currentJob.skills
	// if there's a skill that's not in the graphMatch,
	// that skill won't have any candidate similar skills shown and score will be 0%.
	// if there's a graphMatch skill that's not in currentJob.skills, it won't be shown
	const SKILLS = {
		hero: graphMatch
			? currentJob?.skills
					?.filter(s => s.hero_skill)
					.map(
						skill =>
							graphMatch?.all_match_data?.skill_match_data?.skill_level_scores.find(
								s => s.skill_id === skill.skill_id,
							) || {},
					)
					.filter(skill => Object.keys(skill).length !== 0)
					.sort((a, b) => b.aptitude - a.aptitude)
			: currentJob?.skills?.filter(s => s.hero_skill).sort((a, b) => b.skill_experience_id - a.skill_experience_id),
		required: graphMatch
			? currentJob?.skills
					?.filter(s => s.required_skill)
					?.map(
						skill =>
							graphMatch?.all_match_data?.skill_match_data?.skill_level_scores.find(
								s => s.skill_id === skill.skill_id,
							) || {},
					)
					.filter(skill => Object.keys(skill).length !== 0)
					.sort((a, b) => b.aptitude - a.aptitude)
			: currentJob?.skills?.filter(s => s.required_skill).sort((a, b) => b.skill_experience_id - a.skill_experience_id),
		preferred: graphMatch
			? currentJob?.skills
					?.filter(s => s.preferred_skill || s.bonus_skill)
					?.map(
						skill =>
							graphMatch?.all_match_data?.skill_match_data?.skill_level_scores.find(
								s => s.skill_id === skill.skill_id,
							) || {},
					)
					.filter(skill => Object.keys(skill).length !== 0)
					.sort((a, b) => b.aptitude - a.aptitude)
			: currentJob?.skills
					?.filter(s => s.preferred_skill || s.bonus_skill)
					.sort((a, b) => b.skill_experience_id - a.skill_experience_id),
		all: graphMatch
			? currentJob?.skills
					?.map(
						skill =>
							graphMatch?.all_match_data?.skill_match_data?.skill_level_scores.find(
								s => s.skill_id === skill.skill_id,
							) || {},
					)
					.filter(skill => Object.keys(skill).length !== 0)
					.sort((a, b) => b.aptitude - a.aptitude)
			: currentJob?.skills?.sort((a, b) => b.skill_experience_id - a.skill_experience_id),
	};

	const tableTopRowStyle = column => ({
		display: 'flex',
		flexDirection: 'column',
		minHeight: column === 'left' ? '100%' : null,
		paddingInline: 20,
		borderRadius: '3px 3px 0px 0px',
		borderTop: column === 'left' ? '3px solid var(--blue-500)' : '3px solid var(--teal-100)',
		backgroundColor: 'var(--matchRowLightBackgroundColor)',
		justifyContent: 'space-between',
	});

	const tableRowStyle = (index, column) => ({
		display: 'flex',
		justifyContent: column === 'left' ? 'space-between' : 'flex-start',
		alignItems: 'center',
		minHeight: '110%',
		marginTop: -4,
		paddingInline: 20,
		backgroundColor: index % 2 === 0 ? 'var(--matchRowDarkBackgroundColor)' : 'var(--matchRowLightBackgroundColor)',
		borderTop: '1px solid var(--matchRowBorderColor)',
	});

	const categorizedSkills = SKILLS.all?.filter(
		skill => skill?.aptitude !== Utility.getIdFromLabel(data.skillExperienceLevels, 'Uncategorized'),
	);
	const uncategorizedSkills = SKILLS.all?.filter(
		skill => skill?.aptitude === Utility.getIdFromLabel(data.skillExperienceLevels, 'Uncategorized'),
	);

	return (
		<>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginBlock: 10,
				}}
			>
				{subSkillsTab === 'comparison' ? (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<Typography variant="h3">Hero:</Typography>
						<Typography
							variant="h3"
							color="primary"
							style={{
								marginInline: 5,
							}}
						>
							{graphMatch?.all_match_data?.skill_match_data?.listing_level_scores?.priority_score !== null &&
							graphMatch?.all_match_data?.skill_match_data?.listing_level_scores?.priority_score !== undefined
								? `${Math.round(
										graphMatch?.all_match_data?.skill_match_data?.listing_level_scores?.priority_score * 100,
								  )}%`
								: 'N/A'}
						</Typography>
						<Button
							variant="text"
							size="small"
							onClick={() => setIsHeroMinimized(!isHeroMinimized)}
							style={{
								visibility: subSkillsTab === 'comparison' ? 'visible' : 'hidden',
								padding: '6px',
								minWidth: 0,
								margin: '-6px 6px',
							}}
						>
							<Icons.ChevronRight
								style={{
									fontSize: 24,
									color: 'var(--primaryTextColor)',
									marginRight: 0,
									transform: `rotate(${isHeroMinimized ? 0 : 90}deg)`,
									transition: 'transform .2s ease',
								}}
							/>
						</Button>
					</div>
				) : (
					<Typography variant="h3">Skills Side-by-Side</Typography>
				)}
				<div style={{ display: 'flex' }}>
					<Tooltip
						title={`Switch to ${subSkillsTab === 'blocks' ? 'Table' : 'Side-by-Side'} View`}
						placement="top"
						arrow
					>
						<Button
							variant="text"
							color="primary"
							type="button"
							onClick={() => setSubSkillsTab(subSkillsTab === 'comparison' ? 'blocks' : 'comparison')}
							onMouseOver={() => setIsPivotHovered(true)}
							onMouseOut={() => setIsPivotHovered(false)}
							style={{
								margin: '0px 5px',
								padding: 4,
								minWidth: 0,
								borderRadius: 4,
								backgroundColor: isPivotHovered ? '#EBF3FE' : '#F3F6F9',
								transform: `rotate(${subSkillsTab === 'blocks' ? 0 : 90}deg)`,
								transition: 'transform .2s ease',
							}}
						>
							<PivotTableIcon color={isPivotHovered || subSkillsTab === 'blocks' ? '#0076FF' : '#687180'} />
						</Button>
					</Tooltip>
				</div>
			</div>
			{subSkillsTab === 'blocks' && (
				<Grid container direction="row" spacing={1} alignItems="stretch">
					<Grid item xs={6}>
						<Panel topBarColor="blue">
							<JobViewSkillsBlock
								minimized={jobSkillsMinimized}
								setMinimized={setJobSkillsMinimized}
								job={currentJob}
								setJob={setCurrentJob}
								data={data}
								displaySkillsWithoutExperience
							/>
						</Panel>
					</Grid>
					<Grid item xs={6}>
						<Panel topBarColor="teal">
							<CandidateViewSkillsBlock
								minimized={candidateSkillsMinimized}
								setMinimized={setCandidateSkillsMinimized}
								candidate={currentCandidate}
								setCandidate={setCandidate}
								data={data}
								displaySkillsWithoutExperience
							/>
						</Panel>
					</Grid>
				</Grid>
			)}
			{subSkillsTab === 'comparison' && (
				<>
					<Grid container direction="row" spacing={1} alignItems="stretch">
						<Grid item xs={4}>
							<Box p={2} style={tableTopRowStyle('left')}>
								<Typography
									variant="h3"
									style={{
										marginTop: 6,
									}}
								>
									Job Skills
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={8}>
							<Box p={2} style={tableTopRowStyle('right')}>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										width: '100%',
									}}
								>
									<Typography
										variant="h3"
										style={{
											marginTop: 6,
										}}
									>
										{currentCandidate?.first_name || ''} {currentCandidate?.last_name || ''}
									</Typography>
									<CandidateHeaderButtonGroup
										isDetailsShown={isDetailsShown}
										setIsDetailsShown={setIsDetailsShown}
										graphMatch={graphMatch}
										currentCandidate={currentCandidate}
									/>
								</div>
								{isDetailsShown && (
									<CandidateDetailsBlock currentCandidate={currentCandidate} setCandidate={setCandidate} data={data} />
								)}
							</Box>
						</Grid>
					</Grid>
					{!isHeroMinimized &&
						SKILLS.hero?.map((skill, index) => {
							return (
								<Grid container direction="row" spacing={1} alignItems="stretch" key={skill?.skill_id}>
									{/* job */}
									<Grid item xs={4}>
										<Box p={2} style={tableRowStyle(index, 'left')}>
											<Typography
												variant="body1"
												style={{
													color: Utility.getScoreColor(skill?.priority_score),
												}}
											>
												{skill?.priority_score !== null &&
												skill?.priority_score !== undefined &&
												skill?.priority_score >= 0
													? `${Math.round(skill?.priority_score * 100)}%`
													: 'N/A'}
											</Typography>
											<SkillChip
												skill={skill}
												data={data}
												activeSkillId={activeSkillId}
												setActiveSkillId={setActiveSkillId}
											/>
										</Box>
									</Grid>
									{/* candidate */}
									<Grid item xs={8}>
										<Box p={2} style={tableRowStyle(index, 'right')}>
											{skill?.similar_skills
												?.sort((a, b) => b.similarity_score - a.similarity_score)
												.map(similarSkill => {
													const skillData = {
														...similarSkill,
														skill: currentCandidate?.skills?.find(s => s.skill_id === similarSkill?.skill_id),
													};
													return (
														<div
															key={similarSkill.skill_id}
															style={{
																marginRight: 5,
															}}
														>
															<SkillChip
																candidate
																skill={skillData}
																data={data}
																activeSkillId={activeSkillId}
																setActiveSkillId={setActiveSkillId}
															/>
														</div>
													);
												})}
										</Box>
									</Grid>
								</Grid>
							);
						})}
					<SkillSectionHeader
						title="Required"
						score={graphMatch?.all_match_data?.skill_match_data?.listing_level_scores?.required_score}
						graphMatch={graphMatch}
						minimized={isRequiredMinimized}
						setMinimized={setIsRequiredMinimized}
					/>
					{!isRequiredMinimized &&
						SKILLS.required?.map((skill, index) => {
							return (
								<Grid container direction="row" spacing={1} alignItems="stretch" key={skill?.skill_id}>
									{/* job */}
									<Grid item xs={4}>
										<Box p={2} style={tableRowStyle(index, 'left')}>
											<Typography
												variant="body1"
												style={{
													color: Utility.getScoreColor(skill?.required_score),
												}}
											>
												{skill?.required_score !== null &&
												skill?.required_score !== undefined &&
												skill?.required_score >= 0
													? `${Math.round(skill?.required_score * 100)}%`
													: 'N/A'}
											</Typography>
											<SkillChip
												skill={skill}
												data={data}
												activeSkillId={activeSkillId}
												setActiveSkillId={setActiveSkillId}
											/>
										</Box>
									</Grid>
									{/* candidate */}
									<Grid item xs={8}>
										<Box p={2} style={tableRowStyle(index, 'right')}>
											{skill?.similar_skills
												?.sort((a, b) => b.similarity_score - a.similarity_score)
												.map(similarSkill => {
													const skillData = {
														...similarSkill,
														skill: currentCandidate?.skills?.find(s => s.skill_id === similarSkill?.skill_id),
													};
													return (
														<div
															key={similarSkill.skill_id}
															style={{
																marginRight: 5,
															}}
														>
															<SkillChip
																candidate
																skill={skillData}
																data={data}
																activeSkillId={activeSkillId}
																setActiveSkillId={setActiveSkillId}
															/>
														</div>
													);
												})}
										</Box>
									</Grid>
								</Grid>
							);
						})}
					<SkillSectionHeader
						title="Preferred"
						score={graphMatch?.all_match_data?.skill_match_data?.listing_level_scores?.preferred_score}
						graphMatch={graphMatch}
						minimized={isPreferredMinimized}
						setMinimized={setIsPreferredMinimized}
					/>
					{!isPreferredMinimized &&
						SKILLS.preferred?.map((skill, index) => {
							return (
								<Grid container direction="row" spacing={1} alignItems="stretch" key={skill?.skill_id}>
									{/* job */}
									<Grid item xs={4}>
										<Box p={2} style={tableRowStyle(index, 'left')}>
											<Typography
												variant="body1"
												style={{
													color: Utility.getScoreColor(skill?.preferred_score),
												}}
											>
												{skill?.preferred_score !== null &&
												skill?.preferred_score !== undefined &&
												skill?.preferred_score >= 0
													? `${Math.round(skill?.preferred_score * 100)}%`
													: 'N/A'}
											</Typography>
											<SkillChip
												skill={skill}
												data={data}
												activeSkillId={activeSkillId}
												setActiveSkillId={setActiveSkillId}
											/>
										</Box>
									</Grid>
									{/* candidate */}
									<Grid item xs={8}>
										<Box p={2} style={tableRowStyle(index, 'right')}>
											{skill?.similar_skills
												?.sort((a, b) => b.similarity_score - a.similarity_score)
												.map(similarSkill => {
													const skillData = {
														...similarSkill,
														skill: currentCandidate?.skills?.find(s => s.skill_id === similarSkill?.skill_id),
													};
													return (
														<div
															key={similarSkill.skill_id}
															style={{
																marginRight: 5,
															}}
														>
															<SkillChip
																candidate
																skill={skillData}
																data={data}
																activeSkillId={activeSkillId}
																setActiveSkillId={setActiveSkillId}
															/>
														</div>
													);
												})}
										</Box>
									</Grid>
								</Grid>
							);
						})}
					<SkillSectionHeader
						title="All"
						score={graphMatch?.all_match_data?.skill_match_data?.listing_level_scores?.all_score}
						graphMatch={graphMatch}
						minimized={isAllMinimized}
						setMinimized={setIsAllMinimized}
					/>
					{!isAllMinimized && (
						<>
							{!!categorizedSkills?.length && (
								<>
									<Typography variant="button">Categorized</Typography>
									<Box marginTop={0.5} marginBottom={2}>
										{categorizedSkills?.map((skill, index) => {
											return (
												<Grid container direction="row" spacing={1} alignItems="stretch" key={skill?.skill_id}>
													{/* job */}
													<Grid item xs={4}>
														<Box p={2} style={tableRowStyle(index, 'left')}>
															<Typography
																variant="body1"
																style={{
																	color: Utility.getScoreColor(skill?.all_score),
																}}
															>
																{skill?.all_score !== null && skill?.all_score !== undefined && skill?.all_score >= 0
																	? `${Math.round(skill?.all_score * 100)}%`
																	: 'N/A'}
															</Typography>
															<SkillChip
																all
																skill={skill}
																data={data}
																activeSkillId={activeSkillId}
																setActiveSkillId={setActiveSkillId}
															/>
														</Box>
													</Grid>
													{/* candidate */}
													<Grid item xs={8}>
														<Box p={2} style={tableRowStyle(index, 'right')}>
															{skill?.similar_skills
																?.sort((a, b) => b.similarity_score - a.similarity_score)
																.map(similarSkill => {
																	const skillData = {
																		...similarSkill,
																		skill: currentCandidate?.skills?.find(s => s.skill_id === similarSkill?.skill_id),
																	};
																	return (
																		<div
																			key={similarSkill.skill_id}
																			style={{
																				marginRight: 5,
																			}}
																		>
																			<SkillChip
																				candidate
																				skill={skillData}
																				data={data}
																				activeSkillId={activeSkillId}
																				setActiveSkillId={setActiveSkillId}
																			/>
																		</div>
																	);
																})}
														</Box>
													</Grid>
												</Grid>
											);
										})}
									</Box>
								</>
							)}
							{!!uncategorizedSkills?.length && (
								<>
									<Typography variant="button">Uncategorized</Typography>
									<Box marginTop={0.5} marginBottom={2}>
										{uncategorizedSkills?.map((skill, index) => {
											return (
												<Grid container direction="row" spacing={1} alignItems="stretch" key={skill?.skill_id}>
													{/* job */}
													<Grid item xs={4}>
														<Box p={2} style={tableRowStyle(index, 'left')}>
															<Typography
																variant="body1"
																style={{
																	color: Utility.getScoreColor(skill?.all_score),
																}}
															>
																{skill?.all_score !== null && skill?.all_score !== undefined && skill?.all_score >= 0
																	? `${Math.round(skill?.all_score * 100)}%`
																	: 'N/A'}
															</Typography>
															<SkillChip
																all
																skill={skill}
																data={data}
																activeSkillId={activeSkillId}
																setActiveSkillId={setActiveSkillId}
															/>
														</Box>
													</Grid>
													{/* candidate */}
													<Grid item xs={8}>
														<Box p={2} style={tableRowStyle(index, 'right')}>
															{skill?.similar_skills
																?.sort((a, b) => b.similarity_score - a.similarity_score)
																.map(similarSkill => {
																	const skillData = {
																		...similarSkill,
																		skill: currentCandidate?.skills?.find(s => s.skill_id === similarSkill?.skill_id),
																	};
																	return (
																		<div
																			style={{
																				marginRight: 5,
																			}}
																			key={similarSkill.skill_id}
																		>
																			<SkillChip
																				candidate
																				skill={skillData}
																				data={data}
																				activeSkillId={activeSkillId}
																				setActiveSkillId={setActiveSkillId}
																			/>
																		</div>
																	);
																})}
														</Box>
													</Grid>
												</Grid>
											);
										})}
									</Box>
								</>
							)}
						</>
					)}
				</>
			)}
		</>
	);
};

const SkillSectionHeader = ({ graphMatch, score, title, minimized, setMinimized }) => {
	return (
		<>
			{graphMatch ? (
				score !== null && (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							marginBlock: 15,
						}}
					>
						<Typography variant="h3">{title}:</Typography>
						<Typography
							variant="h3"
							color="primary"
							style={{
								marginLeft: 5,
							}}
						>
							{Math.round(score * 100)}%
						</Typography>
						<Button
							variant="text"
							size="small"
							onClick={() => setMinimized(!minimized)}
							style={{
								padding: '6px',
								minWidth: 0,
								margin: '-6px 6px',
							}}
						>
							<Icons.ChevronRight
								style={{
									fontSize: 24,
									color: 'var(--primaryTextColor)',
									marginRight: 0,
									transform: `rotate(${minimized ? 0 : 90}deg)`,
									transition: 'transform .2s ease',
								}}
							/>
						</Button>
					</div>
				)
			) : (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						marginBlock: 15,
					}}
				>
					<Typography variant="h3">{title}:</Typography>
					<Typography
						variant="h3"
						color="primary"
						style={{
							marginLeft: 5,
						}}
					>
						N/A
					</Typography>
					<Button
						variant="text"
						size="small"
						onClick={() => setMinimized(!minimized)}
						style={{
							padding: '6px',
							minWidth: 0,
							margin: '-6px 6px',
						}}
					>
						<Icons.ChevronRight
							style={{
								fontSize: 24,
								color: 'var(--primaryTextColor)',
								marginRight: 0,
								transform: `rotate(${minimized ? 0 : 90}deg)`,
								transition: 'transform .2s ease',
							}}
						/>
					</Button>
				</div>
			)}
		</>
	);
};

export default SkillsTab;
