import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useStore, useSelector, shallowEqual } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
	Typography,
	Button,
	Chip,
	Checkbox,
	IconButton,
	Menu,
	MenuItem,
	ListItemIcon,
	Tooltip,
	Grid,
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import moment from 'moment';

import CheckboxInput from '../../components/form/inputs/CheckboxInput';
import Page from '../../components/page/Page';
import * as PageHeader from '../../components/page/PageHeader';
import Table from '../../components/Table';
import CandidateSyncModal from '../../components/modals/CandidateSyncModal';
import CandidateDeleteModal from '../../components/modals/CandidateDeleteModal';
import CandidateFilters from '../../components/filters/CandidateFilters';

import { ActionCreators as SiteActions } from '../../store/Site';
import * as CandidatesAPI from '../../scripts/candidates';
import * as Utility from '../../scripts/utility';

const Candidates = () => {
	const store = useStore();
	const { data, alert, user } = useSelector(state => state.site, shallowEqual);

	const [menu, setMenu] = useState({ anchor: null, candidate: {} });
	const [selectedItems, setSelectedItems] = useState([]);
	const [filter, setFilter] = Utility.useStickyState({ isOpen: false, filters: {} }, 'admin-candidates-filter');

	const [activeSaveFilterId, setActiveSaveFilterId] = Utility.useStickyState(
		null,
		'admin-candidates-active-save-filter-id',
	);
	const [savedFilters, setSavedFilters] = Utility.useStickyState([], 'admin-candidates-saved-filtersets');
	const table = useRef();

	const numFilters = Object.values(filter.filters || {}).filter(x => x).length;

	const filterIntakeCompleted = filter?.filters?.assessment_completed_min && filter?.filters?.assessment_completed_max;
	const setFilterIntakeCompleted = newFilterIntakeCompleted => {
		setFilter(currentFilter => ({
			...currentFilter,
			filters: {
				...currentFilter.filters,
				assessment_completed_min: newFilterIntakeCompleted ? new Date('01/01/1970').toISOString() : undefined,
				assessment_completed_max: newFilterIntakeCompleted ? new Date('12/31/2099').toISOString() : undefined,
			},
		}));
	};

	const openFilter = () => {
		setFilter({ ...filter, isOpen: true });
	};

	const closeFilter = () => {
		setFilter({ ...filter, isOpen: false });
	};

	const updateFilters = useCallback(
		(ev, name) => {
			if (typeof ev === 'object' && typeof name === 'string') {
				setFilter({ ...filter, filters: { ...filter.filters, [name]: ev } });
			} else if (ev.target.type === 'checkbox') {
				setFilter({
					...filter,
					filters: { ...filter.filters, [ev.target.name]: ev.target.checked ? ev.target.value : undefined },
				});
			} else if (ev.target.value !== null && ev.target.value !== undefined && ev.target.value !== '') {
				setFilter({ ...filter, filters: { ...filter.filters, [ev.target.name]: ev.target.value } });
			} else {
				setFilter({ ...filter, filters: { ...filter.filters, [ev.target.name]: undefined } });
			}
		},
		[filter, setFilter],
	);

	const sendAssessment = ev => {
		setMenu({ ...menu, anchor: null });

		CandidatesAPI.sendAssessment(menu.candidate.id, response => {
			if (!response) {
				store.dispatch(SiteActions.showAlert('The skills assessment could not be sent. Please try again.', 'error'));
				return;
			}

			store.dispatch(SiteActions.showAlert('A portal invitation was emailed.', 'success'));
		});
	};

	const headers = [
		{ id: 'options', value: '', noSort: true },
		{ id: 'select', value: '', noSort: true },
		{ id: 'candidates.vendor_candidate_id', value: 'ID', noSort: false },
		{ id: 'candidates.first_name', value: 'Candidate' },
		{ id: 'candidates.professional_experience_years', value: 'Experience' },
		{ id: 'specialties.name', value: 'Specialty' },
		{ id: 'candidates.desired_position', value: 'Desired Position' },
		{ id: 'leadership_experience_roles.label', value: 'Leadership' },
		{ id: 'candidates.domain', value: 'Land the Job Skills', noSort: true },
		{ id: 'candidates.city', value: 'Location Preference', noSort: true },
		{ id: 'owners.name', value: 'Owner' },
		{ id: 'candidate_statuses.name', value: 'Status' },
		{ id: 'candidates.updated_at', value: 'Last Updated' },
		{ id: 'candidates.assessment_completed_at', value: 'Intake Completed' },
		{ id: 'candidates.shared_match_count', value: 'Shared Matches' },
	];
	// if (process.env.REACT_APP_FEATURE_MATCHING) {
	// 	headers.push({ id: 'candidates.matches', value: 'Matches', noSort: true });
	// }

	const formatData = rows => {
		return rows.map(candidate => {
			const candidateRow = [
				<IconButton onClick={ev => setMenu({ anchor: ev.target, candidate })}>
					<Icons.MoreVert color="primary" />
				</IconButton>,
				<>
					<CheckboxInput
						style={{ marginRight: -15 }}
						color="primary"
						checked={selectedItems.includes(candidate.id)}
						// onChange={() => {}}
						onClick={() => {
							if (selectedItems.includes(candidate.id)) {
								setSelectedItems(selectedItems.filter(x => x !== candidate.id));
							} else {
								setSelectedItems([...selectedItems, candidate.id]);
							}
						}}
					/>
				</>,
				<>
					<span>{candidate?.vendor_candidate_id}</span>
				</>,
				<>
					<div>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<NavLink to={`/candidate/${candidate.id}`} className="color-inherit" style={{ minWidth: 'max-content' }}>
								{`${candidate.first_name} ${candidate.last_name}`}
							</NavLink>
							<div style={{ marginLeft: 10 }}>
								<Icons.Share style={{ width: 14, color: candidate?.is_sharable ? '#00bb00' : '#ff2222' }} />
							</div>
						</div>
						{candidate?.user?.claimed_at ? (
							<span className="color-muted">Account: {Utility.formatDate(candidate.user?.claimed_at)}</span>
						) : null}
					</div>
				</>,
				<>
					{candidate.professional_experience_years ? `${candidate.professional_experience_years} Years` : Utility.na}
				</>,
				<>
					{Utility.getItemLabel(data.specialties, candidate.specialty_id) || Utility.na}
					<br />
					<span className="color-muted">
						{/* {candidate?.categories
							?.map(({ id: categoryId }) => Utility.getItemLabel(data.categories, categoryId) || Utility.nb)
							.join(', ')} */}
						{candidate?.specialties
							?.map(
								specialty =>
									Utility.getItemLabel(data.categories, specialty?.category_id || specialty?.category?.id) ||
									Utility.nb,
							)
							.join(', ')}
					</span>
				</>,
				<>
					{candidate.desired_position || Utility.na}
					<br />
					<span className="color-muted">
						{Utility.getItemLabel(data.employmentPreferences, candidate.employment_preference)}
					</span>
				</>,
				<>
					{Utility.getItemLabel(data.leadershipExperienceRoles, candidate.recent_leadership_role_id) || Utility.na}
					<br />
					<span className="color-muted">
						{candidate.total_leadership_years ? `${candidate.total_leadership_years} Years` : null}
					</span>
				</>,
				<>
					{candidate?.skills
						?.filter(s => s.get_the_job)
						.map((s, i, a) => (
							<span key={s?.skill?.label || i}>
								<Tooltip
									className="tooltip"
									title={`${s?.experience_years || 0} Year${s?.experience_years !== 1 ? 's' : ''}`}
									placement="top"
								>
									<span>{s?.skill?.label}</span>
								</Tooltip>
								{i < a.length - 1 ? ', ' : null}
							</span>
						))}
					{[1, 2, 3].filter(s => candidate[`domain_experience_${s}_id`]).length ? null : Utility.na}
				</>,
				<>
					{candidate.work_location_preferences?.length ? (
						<p style={{ marginBottom: 0 }}>
							{Utility.getConsolidatedLocationPreferences(candidate?.work_location_preferences)?.join(', ') || ''}
						</p>
					) : null}

					{candidate.state ? (
						<span className="color-muted">{`${candidate.city || ''}, ${Utility.getFromObj(
							Utility.getIDFromObj(data.states, candidate.state, 'name'),
							'abbreviation',
						)}`}</span>
					) : (
						Utility.na
					)}
					<br />
				</>,
				<>{candidate.owner_id ? Utility.getFromObj(candidate, 'owner', 'name') : Utility.na}</>,
				candidate.candidate_status ? (
					<Chip
						size="small"
						className={`color-${Utility.getCandidateStatusColor(
							Utility.getItemName(data.candidateStatuses, candidate?.candidate_status, 'id'),
						)} no-margins`}
						label={Utility.getItemLabel(data.candidateStatuses, candidate?.candidate_status)}
					/>
				) : (
					Utility.na
				),
				<>
					{Utility.formatDate(candidate.updated_at)}
					<br />
					<span className="color-muted">{Utility.formatTime(candidate.updated_at)}</span>
				</>,
				<>
					{/* <Icons.WorkOutline color="disabled" style={{ fontSize: 13 }} /> {candidate.listing_matches_count}
					<br />
					<span className="color-muted">Job Matched</span> */}
					{candidate?.assessment_completed_at ? Utility.formatDate(candidate.assessment_completed_at) : null}
				</>,

				<>
					<span style={{ display: 'block' }}>{candidate.shared_match_count || 0}</span>
				</>,
			];

			// if (process.env.REACT_APP_FEATURE_MATCHING) {
			// 	candidateRow.push(
			// 		<>
			// 			<NavLink to={`/candidate/${candidate.id}/matches`}>
			// 				<Button
			// 					variant="outlined"
			// 					size="small"
			// 					style={{
			// 						whiteSpace: 'nowrap',
			// 						marginLeft: 0,
			// 						paddingRight: 8,
			// 						paddingLeft: 8,
			// 					}}
			// 					color="primary"
			// 				>
			// 					<Icons.Search style={{ marginRight: 5 }} /> View Matches
			// 				</Button>
			// 			</NavLink>
			// 		</>,
			// 	);
			// }
			return candidateRow;
		});
	};

	const [clearMenuAnchorEl, setClearMenuAnchorEl] = React.useState(null);
	const [tableKey, setTableKey] = React.useState(0);

	const handleClearMenuClick = event => {
		setClearMenuAnchorEl(event.currentTarget);
	};

	const handleClearMenuClose = () => {
		setClearMenuAnchorEl(null);
	};

	const handleClearSort = () => {
		// window.localStorage.setItem('jobs-table', null);
		handleClearMenuClose();
		setTableKey(x => x + 1);
	};
	const handleClearFilter = () => {
		handleClearMenuClose();
		setFilter({ ...filter, filters: {} });
	};
	const handleClearAll = () => {
		handleClearMenuClose();
		handleClearSort();
		setTimeout(() => {
			handleClearFilter();
		}, 500);
	};

	return (
		<Page filterPadding={filter.isOpen}>
			<PageHeader.Header>
				<PageHeader.Left>
					<Typography variant="h1">Candidates</Typography>
				</PageHeader.Left>
				<PageHeader.Right>
					{selectedItems.length ? (
						<NavLink to={`/candidates/details?candidates=${selectedItems?.join(',')}`} className="color-inherit">
							<Button
								variant="contained"
								color="primary"
								// onClick={openSelectedItemOverview}
								startIcon={<Icons.Fullscreen />}
							>
								Open Overview ({selectedItems.length})
							</Button>
						</NavLink>
					) : null}
					<Button
						aria-controls="simple-menu"
						aria-haspopup="true"
						onClick={handleClearMenuClick}
						variant="outlined"
						color="primary"
						endIcon={<Icons.ArrowDropDown />}
					>
						Clear
					</Button>
					<Menu
						id="clear-menu"
						anchorEl={clearMenuAnchorEl}
						keepMounted
						open={Boolean(clearMenuAnchorEl)}
						onClose={handleClearMenuClose}
					>
						<MenuItem onClick={handleClearSort}>Clear Sort</MenuItem>
						<MenuItem onClick={handleClearFilter} disabled={!Object.keys(filter?.filters || {}).length}>
							Clear Filter
						</MenuItem>
						<MenuItem onClick={handleClearAll}>Clear All</MenuItem>
					</Menu>
					<Tooltip className="tooltip" title={`${numFilters} Filter${numFilters > 1 ? 's' : ''}`} placement="top">
						<Button
							style={{
								backgroundColor: numFilters > 0 ? 'rgba(76, 159, 255,.3)' : 'transparent',
							}}
							variant="outlined"
							color="primary"
							onClick={filter.isOpen ? closeFilter : openFilter}
							startIcon={<Icons.FilterList />}
						>
							Filter
						</Button>
					</Tooltip>
					<Button
						variant="contained"
						color="primary"
						onClick={() =>
							store.dispatch(
								SiteActions.showModal(
									<CandidateSyncModal category={menu.category} onSync={table.current.refreshData} />,
								),
							)
						}
						startIcon={<Icons.Sync />}
					>
						Sync Candidate
					</Button>
				</PageHeader.Right>
			</PageHeader.Header>

			<div
				style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingBottom: 15, marginTop: -20 }}
			>
				<Checkbox checked={!!filterIntakeCompleted} onChange={ev => setFilterIntakeCompleted(ev.target.checked)} />
				<Typography variant="body2" style={{ marginBottom: 0 }}>
					Intake Completed
				</Typography>
			</div>

			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}

			<CandidateFilters
				enableSavedFilters
				savedFilters={savedFilters}
				setSavedFilters={setSavedFilters}
				activeSaveFilterId={activeSaveFilterId}
				setActiveSaveFilterId={setActiveSaveFilterId}
				onClose={closeFilter}
				onReset={() => setFilter({ ...filter, filters: {} })}
				data={data}
				updateFilters={updateFilters}
				filter={filter}
				setFilter={setFilter}
				displayFilters={[
					'owner',
					'name',
					'vendor_candidate_id',
					'professional_experience_years_range',
					'salary_expectation_type_range',
					'location_city',
					'location_state',
					'work_location_preferences',
					'desired_locations',
					'resume',
					'professional_category_id',
					'specialty_id',
					'skills',
					'get_the_job_skills',
					'industries',
					'recent_leadership_role_id',
					'total_leadership_years_range',
					'desired_position',
					'current_title',
					'current_employer',
					'work_visa_type_id',
					'status',
					'last_profile_update_range',
					'assessment_completed_range',
					'score_range',
				]}
			/>

			<Table
				stickyStateKey="candidates-table-v9"
				onUpdate={CandidatesAPI.getCandidates}
				filters={filter.filters}
				defaultOrderBy="candidates.created_at"
				defaultSortDirection="desc"
				format={formatData}
				headers={headers}
				ref={table}
				// checkbox
				resetStateKey={tableKey}
			/>

			<Menu
				anchorEl={menu.anchor}
				open={Boolean(menu.anchor)}
				onClose={() => setMenu({ ...menu, anchor: null })}
				keepMounted
			>
				<NavLink to={`/candidate/${menu.candidate.id}`} className="color-inherit" target="_blank">
					<MenuItem>
						<ListItemIcon>
							<Icons.Person color="primary" />
						</ListItemIcon>
						View Candidate
					</MenuItem>
				</NavLink>
				<NavLink to={`/candidate/${menu.candidate.id}/matches`}>
					<MenuItem>
						<ListItemIcon>
							<Icons.Search color="primary" />
						</ListItemIcon>
						View Suggested Matches
					</MenuItem>
				</NavLink>
				{/* <NavLink to={`/candidate/${menu.candidate.id}/match-testing`}>
					<MenuItem>
						<ListItemIcon>
							<Icons.TrendingUp color="primary" />
						</ListItemIcon>
						Testing
					</MenuItem>
				</NavLink> */}

				{/* <NavLink to={`/candidate/${menu.candidate.id}/edit`} className="color-inherit">
					<MenuItem>
						<ListItemIcon>
							<Icons.Create color="primary" />
						</ListItemIcon>
						Edit Candidate
					</MenuItem>
				</NavLink> */}
				<MenuItem disabled={menu.candidate?.user?.claimed_at} onClick={sendAssessment}>
					<ListItemIcon>
						<Icons.Assignment color="primary" />
					</ListItemIcon>
					Portal Invitation
				</MenuItem>
				<MenuItem
					onClick={() => {
						setMenu({ ...menu, anchor: null });
						store.dispatch(
							SiteActions.showModal(
								<CandidateDeleteModal candidate={menu.candidate} onDelete={() => table.current.refreshData()} />,
							),
						);
					}}
				>
					<ListItemIcon>
						<Icons.Delete color="secondary" />
					</ListItemIcon>
					Delete Candidate
				</MenuItem>
			</Menu>
		</Page>
	);
};

export default Candidates;
