import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useStore, useSelector, shallowEqual } from 'react-redux';
import { Grid, Button, Typography, ButtonBase, IconButton } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Panel, PanelHeader, PanelContent } from '../../../components/Panel';
import Fence from '../../../components/Fence';
import LoadingButton from '../../../components/form/LoadingButton';
import YearsDropdown from '../../../components/form/dropdowns/YearsDropdown';
import ElasticSkillDropdown from '../../../components/form/dropdowns/ElasticSkillDropdown';
import VerticalSpacer from '../../../components/VerticalSpacer';
import { ActionCreators as SiteActions } from '../../../store/Site';
import * as CandidatesAPI from '../../../scripts/candidates';
import SkillStoryIcon from '../../../components/icons/SkillStoryIcon';

const EditSkillsModal = ({ candidate, setCandidate }) => {
	const store = useStore();
	const { data } = useSelector(state => state.site, shallowEqual);
	const dispatch = useDispatch();
	// const [validSkills, setValidSkills] = useState([]);
	const [isSaving, setIsSaving] = useState(false);
	const [showHelp, setShowHelp] = useState(false);
	const [updatedCandidate, setUpdatedCandidate] = useState(candidate);

	const getTheJobSkills = useMemo(() => updatedCandidate?.skills?.filter(skill => skill.get_the_job), [
		updatedCandidate.skills,
	]);
	console.log({ getTheJobSkills });
	const enjoyTheJobSkills = useMemo(() => updatedCandidate?.skills?.filter(skill => skill.enjoy_the_job), [
		updatedCandidate.skills,
	]);
	const improveOnTheJobSkills = useMemo(() => updatedCandidate?.skills?.filter(skill => skill.improve_on_job), [
		updatedCandidate.skills,
	]);
	const learnOnTheJobSkills = useMemo(() => updatedCandidate?.skills?.filter(skill => skill.learn_on_job), [
		updatedCandidate.skills,
	]);

	const validGetTheJobSkills = updatedCandidate.skills
		// .filter(
		// 	s => [4, 5].includes(s.skill_experience_id), // expert or advanced skills
		// )
		.map(s => ({ ...s, id: s.skill_id }));

	const validImproveOnJobSkills = updatedCandidate?.skills?.filter(
		x => x.experience && !x.get_the_job && !x.enjoy_the_job && !x.learn_on_job,
	);

	const validEnjoyOnJobSkills = updatedCandidate?.skills?.filter(
		x => x.experience && !x.improve_on_job && !x.learn_on_job,
	);

	// const validLearnSkills = [
	// 	...data.skills
	// 		.filter(skill => {
	// 			// // Valid bonus skills are any skill that doesn't already have a skill story for this job.
	// 			// const skillOnCandidate = updatedCandidate.skills.find(s => s.skill_id === skill.id);
	// 			// if (skillOnCandidate) {
	// 			// 	// If the skill has another skill story, it's not a valid bonus skill.
	// 			// 	if (skillOnCandidate.get_the_job || skillOnCandidate.enjoy_the_job || skillOnCandidate.improve_on_job) {
	// 			// 		return false;
	// 			// 	}
	// 			// }
	// 			// return true;
	// 		})
	// 		?.map(x => ({
	// 			...x,
	// 			category_label: updatedCandidate.skills.find(s => s.skill_id === x.id) ? 'Suggested' : 'All',
	// 		})),
	// ]?.sort((a, b) => a.category_label.localeCompare(b.category_label));

	const selectGetJobSkill = (skillId, replacedSkillId) => {
		const updatedSkills = updatedCandidate.skills.map(s => {
			if (s.skill_id === replacedSkillId) return { ...s, get_the_job: false };
			if (s.skill_id === skillId) return { ...s, get_the_job: true };
			return s;
		});
		setUpdatedCandidate(s => ({ ...s, skills: updatedSkills }));
	};

	const setSkillExperience = (skill, experienceYears) => {
		const updatedSkills = updatedCandidate.skills?.map(s =>
			s.skill_id === skill.skill_id ? { ...skill, experience_years: experienceYears } : s,
		);
		setUpdatedCandidate(c => ({
			...c,
			skills: updatedSkills,
		}));
	};

	const selectSkill = (skillStoryName, skillIds, skillCache) => {
		// create an array of skills that are new; not already in updatedCandidate.skills
		const newSkills = skillIds
			.filter(sid => !updatedCandidate.skills.find(s => s.skill_id === sid))
			?.map(sid => {
				return {
					skill_id: sid,
					[skillStoryName]: true,
					skill_experience_id: 1,
					experience_years: 0,
					skill: skillCache[sid] || { id: sid },
				};
			});

		if (
			(skillStoryName === 'get_the_job' && skillIds.length > 3) ||
			(skillStoryName === 'enjoy_the_job' && skillIds.length > 5) ||
			(skillStoryName === 'improve_on_job' && skillIds.length > 5) ||
			(skillStoryName === 'learn_on_job' && skillIds.length > 5)
		) {
			return null;
		}

		// for each input skill (skillIds) set the candidate.skill story name to true
		const updatedSkills = updatedCandidate.skills
			?.map(s => ({ ...s, [skillStoryName]: skillIds.includes(s.skill_id) ? true : undefined }))
			.concat(newSkills);
		setUpdatedCandidate(c => ({ ...c, skills: updatedSkills }));
		return null;
	};

	const saveCandidate = () => {
		setIsSaving(true);
		CandidatesAPI.addCandidateSkills(candidate.id, updatedCandidate.skills, response => {
			if (!response) {
				setIsSaving(false);
				store.dispatch(SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error'));
				return;
			}
			setCandidate({
				...updatedCandidate,
				skills: response.data.data,
			});
			dispatch(SiteActions.hideModal());
		});
	};

	// useEffect(() => {
	// 	if (!validSkills.length) {
	// 		CandidatesAPI.getValidSkills(candidate.id, response => {
	// 			if (response) setValidSkills(response.data.data);
	// 		});
	// 	}
	// }, []);

	return (
		<>
			<Panel>
				<PanelHeader className="spread">
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<SkillStoryIcon size={26} className="teal-100" />
						<Typography variant="h6" component="h2" className="text-medium" style={{ paddingLeft: 10 }}>
							Skill Story
						</Typography>
					</div>
				</PanelHeader>
				<PanelContent>
					<Typography className="text-bold">Land the Job</Typography>
					<Typography>
						Select up to three high aptitude skills that are most critical to landing your next role. These should be
						skills that will be the foundation of your daily work.
					</Typography>
					<VerticalSpacer height={1} />
					{[1, 2, 3].map((v, i) => {
						return (
							<Grid container spacing={2} key={`getTheJobSkill-${getTheJobSkills[i]?.skill_id || v}`}>
								<Grid item xs={7}>
									<ElasticSkillDropdown
										variant="outlined"
										name="get_the_job"
										value={getTheJobSkills[i]?.skill_id}
										override={validGetTheJobSkills}
										onChange={ev => selectGetJobSkill(ev.target.value, getTheJobSkills[i]?.skill_id)}
										error={false}
										fullWidth
									/>
								</Grid>
								<Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
									<Typography variant="body1" className="text-right text-medium">
										Experience
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<YearsDropdown
										variant="outlined"
										name="get_the_job_years"
										value={getTheJobSkills[i]?.experience_years}
										onChange={ev => setSkillExperience(getTheJobSkills[i], ev.target.value)}
										// disableClearable={false}
										error={getTheJobSkills[i]?.skill_id && !getTheJobSkills[i]?.experience_years}
										fullWidth
									/>
								</Grid>
							</Grid>
						);
					})}
					<VerticalSpacer height={0.5} />
					<ButtonBase onClick={() => setShowHelp(s => !s)} className="button-text-muted">
						Don&lsquo;t See a Skill You&lsquo;re Looking For?
					</ButtonBase>
					{showHelp && (
						<Fence
							style={{
								background: '#E6F1FF',
								borderRadius: 6,
								padding: '46px 30px 28px 30px',
								position: 'relative',
							}}
						>
							<IconButton
								className="modal-close modal-close-primary"
								onClick={() => setShowHelp(false)}
								style={{ marginTop: '-15px', marginRight: '-15px' }}
							>
								<Icons.Close />
							</IconButton>
							<Typography className="text-bold" style={{ marginTop: -18 }}>
								Not seeing a Land the Job Skill?
							</Typography>
							<Typography className="grey-300">
								Skills must be ranked Best or Advanced (in the Skills section) before they can be selected as Land the
								Job skills.
								<br />
								In order to add a new Land the Job skill, you must first rank it accordingly.
							</Typography>
						</Fence>
					)}
					<VerticalSpacer height={3} />
					<Typography className="text-bold">Enjoy Using</Typography>
					<Typography>
						Select skills that you would most like to use regularly on the job. These can overlap with the skills that
						get you the job but they don&rsquo;t have to.
					</Typography>
					<VerticalSpacer height={1} />
					<ElasticSkillDropdown
						tags
						variant="outlined"
						color="primary"
						name="enjoy_the_job"
						value={enjoyTheJobSkills?.map(s => (s.skill_id ? s.skill_id : s))}
						override={validEnjoyOnJobSkills}
						onChange={(ev, skillCache) => selectSkill(ev.target.name, ev.target.value, skillCache)}
						fullWidth
					/>
					<VerticalSpacer height={3} />
					<Typography className="text-bold">Improve</Typography>
					<Typography>
						Select skills you&rsquo;d most like to improve on the job. These are skills you currently have and wish to
						further develop.
					</Typography>
					<VerticalSpacer height={1} />
					<ElasticSkillDropdown
						tags
						variant="outlined"
						color="primary"
						name="improve_on_job"
						value={improveOnTheJobSkills?.map(s => (s.skill_id ? s.skill_id : s))}
						override={validImproveOnJobSkills}
						onChange={(ev, skillCache) => selectSkill(ev.target.name, ev.target.value, skillCache)}
						fullWidth
					/>
					<VerticalSpacer height={3} />
					<Typography className="text-bold">Learn</Typography>
					<Typography>
						Add skills you&rsquo;d most like to acquire on the job. These are skills you don&rsquo;t have but want to
						learn.
					</Typography>
					<VerticalSpacer height={1} />
					<ElasticSkillDropdown
						tags
						variant="outlined"
						color="primary"
						name="learn_on_job"
						value={learnOnTheJobSkills?.map(s => (s.skill_id ? s.skill_id : s))}
						getCategoryLabel={skillId =>
							updatedCandidate.skills.find(s => s.skill_id === skillId) ? 'Suggested' : 'All'
						}
						filter={skillId => {
							// Valid bonus skills are any skill that doesn't already have a skill story for this job.
							const skillOnCandidate = updatedCandidate.skills.find(s => s.skill_id === skillId);
							if (skillOnCandidate) {
								// If the skill has another skill story, it's not a valid bonus skill.
								if (skillOnCandidate.get_the_job || skillOnCandidate.enjoy_the_job || skillOnCandidate.improve_on_job) {
									return false;
								}
							}

							return true;
						}}
						onChange={(ev, skillCache) => selectSkill(ev.target.name, ev.target.value, skillCache)}
						fullWidth
					/>
					<VerticalSpacer height={3} />
					<div className="text-right">
						<Button variant="outlined" color="primary" onClick={() => dispatch(SiteActions.hideModal())}>
							Cancel
						</Button>
						<LoadingButton loading={isSaving} variant="contained" color="primary" onClick={saveCandidate}>
							Save
						</LoadingButton>
					</div>
				</PanelContent>
			</Panel>
		</>
	);
};

EditSkillsModal.propTypes = {
	candidate: PropTypes.shape({ id: PropTypes.string }),
	setCandidate: PropTypes.func,
};

EditSkillsModal.defaultProps = {
	candidate: {},
	setCandidate: () => null,
};

export default EditSkillsModal;
