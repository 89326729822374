import React, { useEffect, useState, useMemo, Fragment } from 'react';
import { Grid, Typography, Button, Chip } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import LabeledField from '../../../components/form/LabeledField';
import * as Utility from '../../../scripts/utility';
import CaseIcon from '../../../components/icons/SectionIcons/CaseIcon';
import ViewBlockWrapper from '../../candidates/CandidateBlocks/ViewBlockWrapper';
import { ActionCreators as SiteActions } from '../../../store/Site';
import EditDetailsModal from './EditDetailsModal';
import ValidationFieldWrapper from '../../../components/matching/ValidationFieldWrapper';
import { SkillChip } from '../../candidates/CandidateBlocks/ViewSkillsBlock';

const Block = ({
	company,
	setCompany,
	job,
	setJob,
	data,
	// minimized,
	// setMinimized,
	comparisonCandidate,
	disableEdit,
	isLimited,
	condensedColumns,
}) => {
	const { states } = data;
	const dispatch = useDispatch();

	return (
		<>
			<ViewBlockWrapper
				icon={CaseIcon}
				title="Company Details"
				// minimized={minimized}
				// setMinimized={setMinimized}
				onEditClick={
					disableEdit
						? null
						: () => {
								dispatch(
									SiteActions.showModal(<EditDetailsModal company={company} setCompany={setCompany} />, {
										className: 'modal-medium modal-no-padding modal-no-shadow',
									}),
								);
						  }
				}
			>
				<Grid container spacing={2}>
					<Grid item xs={condensedColumns ? 6 : 3}>
						<LabeledField label="Industry" className="color-muted" removeMargin close>
							<Typography variant="body2">
								{company?.industries?.length > 0 ? <>{company?.industries.map(x => x.label).join(', ')}</> : null}
							</Typography>
						</LabeledField>
					</Grid>
					<Grid item xs={condensedColumns ? 6 : 3}>
						<LabeledField label="Headquarters" className="color-muted" removeMargin close>
							<Typography variant="body2">
								{company?.city || Utility.nb}
								{company?.city && company?.state ? ',' : ''} {company?.state}
							</Typography>
						</LabeledField>
					</Grid>
					<Grid item xs={condensedColumns ? 6 : 3}>
						<LabeledField label="Business Size" className="color-muted" removeMargin close>
							<Typography variant="body2">
								{company?.business_sizes?.length > 0 ? (
									<>{company.business_sizes.map(x => x.label).join(', ')}</>
								) : null}
							</Typography>
						</LabeledField>
					</Grid>
					<Grid item xs={condensedColumns ? 6 : 3}>
						<LabeledField label="Year Founded" className="color-muted" removeMargin close>
							<Typography variant="body2">{company?.year_founded}</Typography>
						</LabeledField>
					</Grid>
				</Grid>
			</ViewBlockWrapper>
		</>
	);
};

export default Block;
