import React, { useEffect, useState, useMemo, Fragment } from 'react';
import { Grid, Typography, Button, Chip } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import LabeledField from '../../../components/form/LabeledField';
import * as Utility from '../../../scripts/utility';
import Icon from '../../../components/icons/SkillsIcon';
import ViewBlockWrapper from '../../candidates/CandidateBlocks/ViewBlockWrapper';
import { ActionCreators as SiteActions } from '../../../store/Site';
import EditModal from './EditSkillsModal';
import ValidationFieldWrapper from '../../../components/matching/ValidationFieldWrapper';
import { SkillChip } from '../../candidates/CandidateBlocks/ViewSkillsBlock';

import ExpandableTypography from '../../../components/form/ExpandableTypography';

const Block = ({
	company,
	setCompany,
	job,
	setJob,
	data,
	// minimized,
	// setMinimized,
	comparisonCandidate,
	disableEdit,
}) => {
	const { states } = data;
	const dispatch = useDispatch();

	if (!company) {
		return null;
	}
	return (
		<>
			<ViewBlockWrapper
				icon={Icon}
				title="Skills"
				// minimized={minimized}
				// setMinimized={setMinimized}
				onEditClick={
					disableEdit
						? null
						: () => {
								dispatch(
									SiteActions.showModal(<EditModal company={company} setCompany={setCompany} />, {
										className: 'modal-medium modal-no-padding modal-no-shadow',
									}),
								);
						  }
				}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<LabeledField label="" className="color-muted" removeMargin close>
							{(company.skills || []).map(skill => (
								<SkillChip
									baseColor="blue"
									key={skill?.id}
									skill={skill}
									// validationError={
									// 	comparisonCandidate && !comparisonCandidate?.skills?.map(x => x.skill_id)?.includes(skill?.id)
									// }
									// activeSkillId={activeSkillId}
									// setActiveSkillId={setActiveSkillId}
								/>
							))}
						</LabeledField>
					</Grid>
				</Grid>
			</ViewBlockWrapper>
		</>
	);
};

export default Block;
