// import Axios from 'axios';
import API, { isStagingAPI } from './api';
import { demoCandidateMatches } from './demo';
import * as Utility from './utility';

// API ENDPOINTS

export const getCandidates = (parameters, callback) => {
	const filters = parameters;

	if (filters.date_available) filters.date_available = Utility.formatAPIDate(filters.date_available);

	const query = Utility.propsToURL(filters);

	API.get(`/candidates`, { params: filters })
		.then(callback)
		.catch(err => callback(false, err));
};

export const getCandidate = (id, callback) => {
	API.get(`/candidates/${id}`)
		.then(callback)
		.catch(err => callback(false, err));
};

export const syncCatSpec = callback => {
	return API.post(`/sync/categories-specialties`)
		.then(callback)
		.catch(err => callback(false, err));
};

export const getCandidateAuditLog = ({
	id,
	page = 1,
	resultsPerPage = 10,
	userId,
	dateFilter,
	fieldFilter,
	callback,
}) => {
	API.get(
		`/candidates/${id}/audit-log?page=${page}
		&results_per_page=${resultsPerPage}
		${userId ? `&user_id=${userId}` : ''}
		${dateFilter ? `&date_filter=${dateFilter}` : ''}
		${fieldFilter && fieldFilter.length ? `&field_filters=${fieldFilter.join(',')}` : ''}`,
	)
		.then(callback)
		.catch(err => callback(false, err));
};

export const updateCandidate = (id, parameters, callback) => {
	const candidate = parameters;

	if (candidate.date_available) candidate.date_available = Utility.formatAPIDate(candidate.date_available);

	if (candidate.desired_locations?.length && typeof candidate.desired_locations[0] === 'object')
		candidate.desired_locations = candidate.desired_locations.map(l => l.state_id);

	if (candidate.willing_to_relocate === '0') candidate.willing_to_relocate = undefined;

	candidate.last_profile_update = Utility.formatAPIDate(new Date());

	API.patch(`/candidates/${id}`, candidate)
		.then(callback)
		.catch(err => callback(false, err));
};

export const uploadCandidateResume = (id, file, callback) => {
	const form = new FormData();
	form.append('file', file);

	API.post(`/candidates/${id}/upload-resume`, form)
		.then(callback)
		.catch(err => callback(false, err));
};

export const uploadCandidateTextResume = (id, text, callback) => {
	API.post(`/candidates/${id}/upload-text-resume`, text)
		.then(callback)
		.catch(err => callback(false, err));
};

export const addCandidateSkills = (id, skills, callback) => {
	// Force each skill to have an experience_years value.
	// In some cases we get skills with a null value, which throws an API error.
	const newSkills = skills
		// .filter(x => x.skill_experience_id !== undefined && x.skill_experience_id !== null)
		.map(skill => ({
			...skill,
			experience_years: skill.experience_years || 0,
		}));

	API.post(`/candidates/${id}/skills`, newSkills)
		.then(callback)
		.catch(err => callback(false, err));
};

export const syncCandidateSkills = (id, skills, callback) => {
	// Force each skill to have an experience_years value.
	// In some cases we get skills with a null value, which throws an API error.
	const newSkills = skills.map(skill => ({
		...skill,
		experience_years: skill.experience_years || 0,
	}));

	API.patch(`/candidates/${id}/skills/sync`, newSkills)
		.then(callback)
		.catch(err => callback(false, err));
};

export const addCandidate = (parameters, callback) => {
	const candidate = parameters;

	if (Utility.getFromObj(candidate, 'date_available'))
		candidate.date_available = Utility.formatAPIDate(candidate.date_available);

	API.post(`/candidate`, candidate)
		.then(callback)
		.catch(err => callback(false, err));
};

/**
 * Deletes a candidate record
 * @param {Number} id - The ID of the candidate
 * @param {Function} callback - The function called once AJAX call is finished
 */
export const deleteCandidate = (id, callback) => {
	API.delete(`/candidates/${id}`)
		.then(callback)
		.catch(err => callback(false, err));
};

export const getCandidateListings = (id, callback) => {
	API.get(`/candidates/${id}/listings`)
		.then(callback)
		.catch(err => callback(false, err));
};

/**
 * Get the resumes attached to a candidate
 * @param {Number} id - The candidate ID
 * @param {Object} parameters - The parameters for the request
 * @param {Function} callback - The callback function executed on response
 */
export const getCandidateResumes = (id, parameters, callback) => {
	const query = Utility.propsToURL(parameters);

	API.get(`/candidates/${id}/resumes${query}`)
		.then(callback)
		.catch(err => callback(false, err));
};

/**
 * Download a resume an open it in browser
 * @param {Number} candidateID - The candidate's ID
 * @param {Number} documentID - The document's ID
 * @param {String} filename = The name of the file
 * @param {Function} callback - The callback function executed on response
 */

export const getCandidateResumeBlob = (candidateID, documentID, callback) => {
	API.get(`/candidates/${candidateID}/resumes/${documentID}/download`, { responseType: 'blob' })
		.then(response => {
			callback(response);
		})
		.catch(err => callback(false, err));
};

export const downloadCandidateResume = (candidateID, documentID, filename, callback) => {
	getCandidateResumeBlob(candidateID, documentID, response => {
		const file = new Blob([response.data], { type: response.headers['content-type'] });
		const fileURL = window.URL.createObjectURL(file);
		const link = document.createElement('a');
		link.href = fileURL;
		link.download = filename;
		link.click();

		if (typeof callback === 'function') callback(response);
	});
};

export const sendAssessment = (id, callback) => {
	API.post(`/candidates/${id}/send-assessment`)
		.then(callback)
		.catch(err => callback(false, err));
};

export const markAssessmentComplete = (id, callback) => {
	API.post(`/candidates/${id}/complete-assessment`)
		.then(callback)
		.catch(err => callback(false, err));
};

/**
 * Resets the claimed_at for a user
 * @param {Number} id - The ID of the Candidate we are resetting
 * @param {Object} parameters - The parameters for the user
 * @param {Function} callback - The function called once AJAX call is finished
 */
export const resetClaimedAt = id => API.patch(`/candidates/${id}/reset-claimed-at`);

/**
 * Search for a candidate by ID or name
 * @param {String | Number} query - The query string
 * @param {Function} callback - The callback function executed on response
 */
export const searchForCandidate = (query, callback) => {
	API.get(`/sync/candidates/search`, { params: { query } })
		.then(callback)
		.catch(err => callback(false, err));
};

/**
 * Sync a candidate's data from Bullhorn
 * @param {Number} id - The candidate's ID
 * @param {Object} parameters - The parameters for the request
 * @param {Function} callback - The callback function executed on response
 */
export const syncFromBullhorn = (id, parameters, callback) => {
	const query = Utility.propsToURL(parameters);

	API.post(`/sync/candidates/from-bullhorn/${id}${query}`)
		.then(callback)
		.catch(err => callback(false, err));
};

/**
 * Sync a candidate's data to Bullhorn
 * @param {Number} id - The candidate's ID
 * @param {Object} parameters - The parameters for the request
 * @param {Function} callback - The callback function executed on response
 */
export const syncToBullhorn = (id, parameters, callback) => {
	const query = Utility.propsToURL(parameters);

	API.post(`/sync/candidates/to-bullhorn/${id}${query}`)
		.then(callback)
		.catch(err => callback(false, err));
};

/**
 * Get a list of valid skills for a specific candidate
 * @param {Number} id - The candidate's ID
 * @param {Function} callback - The callback function executed on response
 */
export const getValidSkills = (id, callback) => {
	API.get(`/candidates/${id}/available-skills`)
		.then(callback)
		.catch(err => callback(false, err));
};

// FUNCTIONS

/**
 * Returns an updated candidate object with forged attributes
 * @param {Object} updatedCandidate - A listing object
 */
export const getForgedCandidate = candidate => {
	const workLocationPreferences = candidate.work_location_preferences?.map(p => (p.id ? p.id : p));

	return {
		...candidate,
		owner: candidate?.owner?.vendor_owner_id !== candidate?.owner_id ? candidate?.owner_id : null,
		industries: (candidate.industries || []).map(item => (item.id ? item.id : item)),
		business_size_experiences: (candidate.business_size_experiences || []).map(item => (item.id ? item.id : item)),
		categories: (candidate.categories || []).map(item => (item?.id ? item.id : item)),
		specialties: (candidate.specialties || []).map(item => (item?.id ? item.id : item)),
		employment_preferences: candidate.employment_preferences?.map(p => (p.id ? p.id : p)),
		leadership_title:
			candidate.recent_leadership_role_id === null || candidate.recent_leadership_role_id === 1
				? null
				: candidate.leadership_title,
		total_leadership_years:
			candidate?.recent_leadership_role_id === null || candidate.recent_leadership_role_id === 1
				? null
				: candidate.total_leadership_years,
		work_location_preferences: workLocationPreferences,
	};
};

export const getCandidateMatches = async ({ candidateId }) => {
	const results = await API.get(`/candidates/${candidateId}/matches`);
	return results.data.data;
};

export const getCandidateMatch = async ({ candidateId, matchId }) => {
	const results = await API.get(`/candidates/${candidateId}/matches/${matchId}`);
	return results.data.data;
};

export const addCandidateMatch = async ({ candidateId, listingId, accessLevelId }) => {
	const matchList = [
		{
			listing_id: listingId,
			is_published: false,
			access_level_id: accessLevelId !== undefined ? accessLevelId : 1,
		},
	];

	const newMatch = await API.post(`/candidates/${candidateId}/matches`, matchList);

	return newMatch;
};

export const deleteCandidateMatch = async ({ candidateId, matchId }) => {
	await API.delete(`/candidates/${candidateId}/matches/${matchId}`);
};

export const updateCandidateMatch = async ({ candidateId, matchId, match }) => {
	await API.patch(`/candidates/${candidateId}/matches/${matchId}`, match);
};

export const patchCandidateMatchInterest = async ({ candidateId, matchId, interestLevelId }) => {
	return API.patch(`/candidates/${candidateId}/matches/${matchId}/rank`, { interest_level_id: interestLevelId });
};

export const createCandidateMatchShareLink = async ({ candidateId, matchId, match }) => {
	return API.post(`/candidates/${candidateId}/matches/${matchId}/share`);
};

export const getCandidateMatchShareLinks = async ({ candidateId, matchId, match }) => {
	return API.get(`/candidates/${candidateId}/matches/${matchId}/shares`);
};

export const postCandidateMatchView = async ({ candidateId, matchId }) => {
	return API.post(`/candidates/${candidateId}/matches/${matchId}/view`);
};

export const allowShare = async ({ candidateId }) => {
	return API.post(`/candidates/${candidateId}/allow-share`);
};

export const denyShare = async ({ candidateId }) => {
	return API.post(`/candidates/${candidateId}/deny-share`);
};

export const createCandidateDocumentShareLink = async ({ candidateId, documentId }) => {
	return API.post(`/candidates/${candidateId}/documents/${documentId}/share`);
};
export const createCandidateResumeShareLink = async ({ candidateId, documentId }) => {
	return API.post(`/candidates/${candidateId}/resumes/${documentId}/share`);
};

export const getDocuments = async ({ candidateId }) => {
	return API.get(`/candidates/${candidateId}/documents`);
};

export const postDocument = async ({ candidateId, file, type }) => {
	const form = new FormData();
	form.append('file', file);
	form.append('document_type_id', type);

	return API.post(`/candidates/${candidateId}/documents`, form);
};

export const sendMatchEmail = async ({ candidateId, matchId }) => {
	return API.post(`/candidates/${candidateId}/matches/${matchId}/email`);
};

export const getCandidateGraphMatches = async ({ candidateId }) => {
	// if (isStagingAPI) {
	// 	return new Promise(resolve => setTimeout(() => resolve({ data: demoCandidateMatches }), 3000));
	// }
	return API.get(`/candidates/${candidateId}/graph-matches`);
};

export const getCandidateGraphMatch = async ({ candidateId, listingId }) => {
	// if (isStagingAPI) {
	// 	return new Promise(resolve => setTimeout(() => resolve({ data: demoCandidateMatches }), 3000));
	// }
	return API.get(`/candidates/${candidateId}/graph-matches/${listingId}`);
};

export const getCandidateRandomGraphMatches = async ({ candidateId }) => {
	// if (isStagingAPI) {
	// 	return new Promise(resolve => setTimeout(() => resolve({ data: demoCandidateMatches }), 3000));
	// }
	return API.get(`/candidates/${candidateId}/random-graph-matches`);
};
