import Axios from 'axios';
import API from './api';
import { ActionCreators as SiteActions } from '../store/Site';
import * as CategoriesAPI from './categories';
import * as SpecialtiesAPI from './specialties';
import * as SkillsAPI from './skills';

// TODO: instead of loading this on every refresh of a page,
// can we store these values in a JSON file and load them from there?
// This would require just staying consistent with updating the JSON file
// the skill object has over 4000 entries

export const loadSiteData = store => {
	const authentication = JSON.parse(localStorage.getItem('authentication'));

	if (!authentication || authentication.expires <= Date.now()) return false;

	Axios.all([
		API.get('/system/options/all'),
		API.get('/user'),
		// API.get('/skills/tree'),
		API.get('/categories/tree'),
		API.get('/specialties/tree'),
	])
		.then(
			Axios.spread(
				(
					all,
					user,
					// skills,
					categories,
					specialties,
				) => {
					// Compatibility with old system
					sessionStorage.setItem('options', JSON.stringify(all.data.data));
					store.dispatch(SiteActions.updateUser(user.data));

					store.dispatch(
						SiteActions.updateSiteData({
							businessSizeExperiences: all.data.data.business_size_experiences,
							businessSizePreferences: all.data.data.business_size_preferences,
							categories: categories.data.data,
							candidateStatusTypes: all.data.data.candidate_status_types,
							candidateStatuses: all.data.data.candidate_statuses,
							compensationTypes: all.data.data.compensation_types,
							currentEmploymentTypes: all.data.data.current_employment_types,
							desiredSalaries: all.data.data.desired_salaries,
							educationTypes: all.data.data.education_types,
							employmentPreferences: all.data.data.employment_preferences,
							genderIdentities: all.data.data.gender_identities,
							industries: all.data.data.industries,
							interestLevels: all.data.data.interest_levels,
							jobQaQuestions: all.data.data.job_qa_questions,
							jobSearchStages: all.data.data.job_search_stages,
							jobStatuses: all.data.data.job_statuses,
							languages: all.data.data.languages,
							leadershipAllocationTypes: all.data.data.leadership_allocation_types,
							leadershipExperienceRequirements: all.data.data.leadership_experience_requirements,
							leadershipExperienceRoles: all.data.data.leadership_experience_roles,
							leadershipPreferences: all.data.data.leadership_preferences,
							linkTypes: all.data.data.link_types,
							listingPriorities: all.data.data.listing_priorities,
							matchFitRankingReasons: all.data.data.match_fit_ranking_reasons,
							matchFitRankingStatuses: all.data.data.match_fit_ranking_statuses,
							offerRelocation: all.data.data.offer_relocation,
							owners: all.data.data.owners,
							preferredLeadershipAllocations: all.data.data.preferred_leadership_allocations, // ?
							pronounTypes: all.data.data.pronoun_types,
							qaQuestions: all.data.data.qa_questions,
							races: all.data.data.races,
							roleLevelPreferences: all.data.data.role_level_preferences,
							roleTimeline: all.data.data.role_timeline,
							salaryExpectationTypes: all.data.data.salary_expectation_types,
							sharedMatchAccessLevels: all.data.data.shared_match_access_levels,
							// skills: skills.data.data,
							skillExperienceLevels: all.data.data.skill_experience_levels,
							skillStoryTypes: all.data.data.skill_story_types,
							specialties: specialties.data.data,
							states: all.data.data.states,
							studyTypes: all.data.data.study_types,
							timeZones: all.data.data.timezones,
							travelWillingnessTypes: all.data.data.travel_willingness_types,
							userRoles: all.data.data.user_roles,
							userStatuses: all.data.data.user_statuses,
							valuePropositionTypes: all.data.data.value_proposition_types,
							workLocationPreferences: all.data.data.work_location_preferences,
							workVisaTypes: all.data.data.work_visa_types,
						}),
					);
					// return response;
				},
			),
		)
		.catch(err => {
			// store.dispatch(SiteActions.showAlert(err, 'error'));
			return false;
		});

	return true;
};

export const updateCategories = store => {
	CategoriesAPI.getAllCategories({}, response => {
		store.dispatch(SiteActions.updateCategories(response.data.data));
	});
};

export const updateSpecialties = store => {
	SpecialtiesAPI.getAllSpecialties({}, response => {
		store.dispatch(SiteActions.updateSpecialties(response.data.data));
	});
};

export const updateSkills = store => {
	SkillsAPI.getAllSkills({}, response => {
		store.dispatch(SiteActions.updateSkills(response.data.data));
	});
};

export const tofu = true;
