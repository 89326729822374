import React, { useState, useEffect } from 'react';
import { useStore, useSelector, shallowEqual } from 'react-redux';
import { Button, Typography, CircularProgress, Grid } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import GraduationCapIcon from '../icons/GraduationCapIcon';
import LocationPinIcon from '../icons/SectionIcons/LocationPinIcon';
import CandidateStatusChip from '../matching/CandidateStatusChip';
import StickyHeader from '../StickyHeader';
import LabeledField from '../form/LabeledField';
import * as CandidatesAPI from '../../scripts/candidates';
import * as ListingsAPI from '../../scripts/listings';
import Candidate1Background from '../../img/match-share-backgrounds/candidate-1.png';
import CaseIcon from '../icons/SectionIcons/CaseIcon';
import GlobeIcon from '../icons/GlobeIcon';
import * as Utility from '../../scripts/utility';
import { HighlightCard } from './SharedJobModal';
import { palette } from '../../BobcatTheme';
import ViewSkillsBlock, { SkillChip } from '../../pages/candidates/CandidateBlocks/ViewSkillsBlock';
import { Panel } from '../Panel';
import { InterestIcon } from './RateJobClientInterestModal';
import CandidateViewPreferencesBlock from '../../pages/candidates/CandidateBlocks/ViewPreferencesBlock';
import CandidateViewExperienceBlock from '../../pages/candidates/CandidateBlocks/ViewExperienceBlock';
import CandidateViewLocationBlock from '../../pages/candidates/CandidateBlocks/ViewLocationPreferencesBlock';
import ExpandableTypography from '../form/ExpandableTypography';
import { DocumentEmbed } from '../../pages/candidates/CandidateBlocks/ViewDocumentModal';
import useVerifyAuthorization from '../../hooks/useVerifyAuthorization';

const SharedCandidateModal = ({ match, jobId, refetchMatches, adminView, hideRating, ...props }) => {
	const { data } = useSelector(state => state.site, shallowEqual);

	const [activeTab, setActiveTab] = useState('skills');
	const [overrideCandidate, setOverrideCandidate] = useState(props?.overrideCandidate);
	const [profileDoc, setProfileDoc] = useState(null);
	const [profileShareLink, setProfileShareLink] = useState(null);

	const [isLoading, setIsLoading] = useState(true);

	const candidate = overrideCandidate || match?.candidate;
	const isLimited = match?.access_level?.id !== 3 && !adminView;

	useEffect(() => {
		Promise.all([
			ListingsAPI.getListingMatch({
				listingId: jobId,
				matchId: match?.id,
			}).then(newMatch => {
				const newCandidate = newMatch?.data?.data?.candidate;

				if (newCandidate) {
					setOverrideCandidate(newCandidate);
				}
				return null;
			}),
			CandidatesAPI.getDocuments({ candidateId: candidate?.id }).then(results => {
				const documents = results?.data?.data;
				const profileDocument = documents?.sort((a, b) => {
					return +new Date(b.updated_at) - +new Date(a.updated_at);
				})[0];
				if (profileDocument) {
					setProfileDoc(profileDocument);
					return CandidatesAPI.createCandidateDocumentShareLink({
						candidateId: candidate?.id,
						documentId: profileDocument.id,
					}).then(shareLink => {
						console.log({ shareLink });
						setProfileShareLink(shareLink?.data?.url);
					});
				}
				return null;
			}),
		]).finally(() => {
			setIsLoading(false);
		});

		// CandidatesAPI.getCandidate(match?.listing?.id, result => {
		// 	if (!result) {
		// 		return null;
		// 	}
		// 	const newCandidate = result.data.data;
		// 	// console.log({
		// 	// 	newListing,
		// 	// });
		// 	if (newCandidate) {
		// 		setOverrideCandidate(newCandidate);
		// 	}
		// 	return null;
		// });
	}, []);
	const { isAuthorized: isAuthorizedAdmin, loading: isLoadingAuthorization } = useVerifyAuthorization({
		role: 'admin',
	});

	useEffect(() => {
		// Don't trigger a view if the current user is an admin.
		if (!isLoadingAuthorization && !isAuthorizedAdmin) {
			if (match?.id && jobId) {
				// Set the match as viewed.
				ListingsAPI.postListingMatchView({
					listingId: jobId,
					matchId: match?.id,
				})
					.then(() => {
						console.log('Marked match as viewed.');
					})
					.catch(console.log);
			}
		}
	}, [!!match?.id, jobId, isLoadingAuthorization, isAuthorizedAdmin]);

	if (!candidate) {
		return null;
	}

	if (isLoading) {
		return (
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
				<CircularProgress />
			</div>
		);
	}

	return (
		<div
			style={{
				position: 'relative',
			}}
		>
			<div>
				<StickyHeader
					offset={200}
					style={{
						width: 750,
						left: '50%',
						right: 'auto',
						marginLeft: -375,
					}}
					scrollElement={document.querySelector('.modal')}
				>
					{isLimited ? (
						<Typography
							variant="body2"
							style={{
								color: '#fff',
								marginLeft: -5,
								fontWeight: 700,
								fontSize: 14,
								textWrap: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
						>
							{candidate.desired_position ||
								Utility.getItemLabel(data.specialties, candidate.specialty_id) ||
								'Position N/A'}{' '}
						</Typography>
					) : (
						<Typography
							variant="body2"
							style={{
								color: '#fff',
								marginLeft: -5,
								fontWeight: 700,
								fontSize: 14,
								textWrap: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
						>
							{candidate.first_name} {candidate.last_name} | {candidate.desired_position || 'Position N/A'} |{' '}
							{candidate?.salary_expectation_type?.label || 'Salary N/A'}
						</Typography>
					)}
				</StickyHeader>
			</div>
			<div
				style={{
					position: 'absolute',
					top: 20,
					right: -40,
					zIndex: 100,
				}}
			>
				<CandidateStatusChip candidate={candidate} />
			</div>
			<div
				style={{
					margin: '-75px -67px 0 -67px',
					padding: '75px 67px 60px 67px',
					background: `url(${Candidate1Background}) center center no-repeat`,
					backgroundColor: '#105C57',
					backgroundSize: 'cover',
					minHeight: 200,
					color: '#fff',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<div style={{ color: 'var(--teal-50)' }}>
						<Icons.PersonOutlined size={28} style={{ width: 28, height: 28 }} />
					</div>
					<div style={{ width: 10 }} />
					<Typography
						variant="h2"
						className="font-lato-bold"
						style={{ textAlign: 'center', fontSize: 20, paddingBottom: 5, color: '#fff' }}
					>
						{candidate.first_name}
						{isLimited ? null : ` ${candidate.last_name}`}
						{candidate.current_title ? `, ${candidate.current_title}` : ''}
					</Typography>
				</div>
				{candidate.professional_experience_years ? (
					<Typography
						style={{
							// opacity: 0.8,
							color: '#fff',
						}}
					>
						{candidate.professional_experience_years}+ years experience
					</Typography>
				) : null}
				{candidate.total_leadership_years ? (
					<Typography
						style={{
							// opacity: 0.8,
							color: '#fff',
						}}
					>
						{candidate.total_leadership_years}+ years leadership experience
					</Typography>
				) : null}

				<div style={{ display: 'flex', alignItems: 'center' }}>
					<Typography
						style={{
							color: '#fff',
							fontSize: 20,
							fontWeight: 500,
						}}
					>
						{isLimited ? (
							<span
								style={{
									filter: isLimited ? 'blur(5px)' : 'none',
									webkitFilter: isLimited ? 'blur(5px)' : 'none',
								}}
							>
								$999,999
							</span>
						) : (
							<span>{candidate?.salary_expectation_type?.label}</span>
						)}
					</Typography>

					{/* If the job offers a work visa, display it. */}
					{candidate.work_visa_type_id === 1 ? (
						<>
							<div style={{ width: 15 }} />
							<GlobeIcon color="#FFD335" />
							<div style={{ width: 5 }} />
							<Typography
								style={{
									color: '#FFD335',
									fontSize: 20,
									fontWeight: 500,
								}}
							>
								Visa Required
							</Typography>
						</>
					) : null}
				</div>

				<div style={{ height: 10 }} />
				<Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
					{(candidate.skills || [])
						.filter(x => x.get_the_job)
						.map(skill => (
							<SkillChip
								baseColor="blue"
								key={skill?.id}
								colorOverride="#fff"
								classOverride="white-text"
								skill={skill}
								style={{
									background: '#06B7AA',
									filter: 'drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15))',
								}}
							/>
						))}
				</Grid>
				{overrideCandidate && (
					<>
						<div style={{ paddingTop: 10, paddingBottom: 15, position: 'relative', width: '100%' }}>
							{/* <div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ height: 1, flex: 1, backgroundColor: '#fff', marginRight: 20 }} />
								<Typography variant="body2" style={{ color: '#fff', width: 'auto' }}>
									Match Highlights
								</Typography>
								<div style={{ height: 1, flex: 1, backgroundColor: '#fff', marginLeft: 20 }} />
							</div> */}
						</div>

						<Grid container spacing={2} alignContent="center" justifyContent="center">
							{candidate?.employment_preferences?.length ? (
								<Grid item xs={4}>
									<HighlightCard
										color="green"
										icon={<CaseIcon size={20} color="#20867F" />}
										// title="Work Location"
										title={candidate?.employment_preferences
											?.map(x => x?.label)
											?.filter(x => x)
											?.join(', ')}
									/>
								</Grid>
							) : null}
							{candidate?.work_location_preferences?.length ? (
								<Grid item xs={4}>
									<HighlightCard
										color="green"
										icon={<HomeWorkIcon size={20} color="#20867F" style={{ color: '#20867F' }} />}
										// title="Work Location"
										title={Utility.getConsolidatedLocationPreferences(candidate?.work_location_preferences).join(' | ')}
									/>
								</Grid>
							) : null}
							{candidate?.state ? (
								<Grid item xs={4}>
									<HighlightCard
										color="green"
										icon={<LocationPinIcon size={20} color="#20867F" style={{ color: '#20867F' }} />}
										// title="Industry"
										title={`Resides in ${candidate?.state}`}
									/>
								</Grid>
							) : null}
							{candidate?.industries?.length ? (
								<Grid item xs={4}>
									<HighlightCard
										color="green"
										icon={<Icons.Business size={20} style={{ color: '#20867F' }} />}
										// title="Business Size"
										title={`${candidate?.industries?.[0]?.label?.replace('Technology - ', '')}${
											candidate?.industries?.length > 1 ? ' +' : ''
										}`}
									/>
								</Grid>
							) : null}

							{candidate?.study_type ? (
								<Grid item xs={4}>
									<HighlightCard
										color="green"
										icon={<GraduationCapIcon style={{ width: 20 }} color="#20867F" />}
										title={`${candidate?.study_type?.label || 'N/A'}`}
									/>
								</Grid>
							) : null}

							{candidate?.work_visa_type ? (
								<Grid item xs={4}>
									<HighlightCard
										color="green"
										icon={<Icons.Language size={20} style={{ color: '#20867F' }} />}
										// title="Work Visa Sponsorship"
										title={candidate?.work_visa_type?.label === 'No' ? 'Requires a Visa: No' : 'Requires a Visa: Yes'}
									/>
								</Grid>
							) : null}
						</Grid>
					</>
				)}
			</div>
			<div
				style={{
					minHeight: 150,
					backgroundColor: '#fff',
					margin: '0 -67px 0 -67px',
					padding: '25px 67px 25px 67px',
				}}
			>
				<LabeledField label="Candidate Summary" className="color-muted" removeMargin close style={{ minWidth: 120 }}>
					<ExpandableTypography numCharacters={450} text={candidate.candidate_summary || 'N/A'} />
				</LabeledField>
				<div style={{ height: 20 }} />
			</div>
			<div style={{ backgroundColor: 'var(--grey-50)', paddingTop: 5, paddingBottom: 5 }}>
				<CustomTabBar
					activeTab={activeTab}
					setActiveTab={newTab => {
						setActiveTab(newTab);
					}}
					tabs={[
						{
							id: 'skills',
							label: 'Skills',
						},
						{
							id: 'candidate-info',
							label: 'Candidate Info',
						},
						profileShareLink && !isLimited
							? {
									id: 'profile',
									label: 'Profile',
							  }
							: null,
						// {
						// 	id: 'company-info',
						// 	label: 'Company Info',
						// },
					].filter(x => x)}
				/>
				<div style={{ height: 20 }} />

				{activeTab === 'profile' && profileShareLink ? (
					<DocumentEmbed
						key="profile-embed"
						shareLink={profileShareLink}
						height={600}
						fileName={profileDoc?.file_name}
					/>
				) : activeTab === 'skills' ? (
					<Panel topBarColor="teal">
						<ViewSkillsBlock candidate={candidate} setJob={null} data={data} groupByRanking disableEdit />
					</Panel>
				) : activeTab === 'candidate-info' ? (
					<>
						<Panel topBarColor="teal">
							<CandidateViewPreferencesBlock
								disableEdit
								minimized={false}
								setMinimized={null}
								candidate={candidate}
								data={data}
								isLimited={isLimited}
								hideLabelAddendums
							/>
						</Panel>
						<Panel topBarColor="teal">
							<CandidateViewExperienceBlock
								disableEdit
								minimized={false}
								setMinimized={null}
								candidate={candidate}
								data={data}
								hideLabelAddendums
							/>
						</Panel>
						<Panel topBarColor="teal">
							<CandidateViewLocationBlock
								disableEdit
								minimized={false}
								setMinimized={null}
								candidate={candidate}
								data={data}
							/>
						</Panel>
					</>
				) : activeTab === 'company-info' ? (
					<Panel topBarColor="blue">
						<ViewSkillsBlock candidate={candidate} data={data} groupByRanking />
					</Panel>
				) : null}
			</div>
			{!hideRating && (
				<InterestRatingBlock
					match={match}
					onSave={async levelId => {
						// setEditingCandidateMatch(true);
						try {
							await ListingsAPI.patchListingMatchInterest({
								listingId: jobId,
								matchId: match?.id,
								interestLevelId: levelId,
							});

							await refetchMatches({
								jobId,
							});
						} catch (e) {
							console.log(e);
						} finally {
							// setEditingCandidateMatch(false);
							// handleClose();
						}
						console.log('Saving!');
					}}
				/>
			)}
		</div>
	);
};

const CustomTabBar = ({ activeTab, setActiveTab, tabs }) => {
	return (
		<div
			style={{
				borderBottom: '1px solid #ddd',
				paddingTop: 15,
				// paddingLeft: 40,
			}}
		>
			{tabs?.map((tab, index) => {
				const isActive = tab?.id === activeTab;

				return (
					<div
						key={tab?.id}
						style={{
							borderBottom: isActive ? `2px solid ${palette?.primary?.main}` : `2px solid transparent`,
							marginLeft: 0, // index === 0 ? -28 : 0,
							display: 'inline-block',
							marginRight: 20,
						}}
					>
						<Button
							color="primary"
							className="no-border-radius"
							onClick={() => {
								setActiveTab(tab?.id);
							}}
							style={{
								marginLeft: 0,
								marginRight: 0,
								paddingLeft: 0,
								paddingRight: 0,
								background: 'transparent',
								minWidth: 0,
							}}
						>
							<Typography
								variant="body"
								style={{ marginRight: 0, color: isActive ? '' : '#34414E', fontWeight: isActive ? 700 : 300 }}
							>
								{tab?.label}
							</Typography>
						</Button>
					</div>
				);
			})}
		</div>
	);
};

const InterestRatingBlock = ({ match, onSave, ...props }) => {
	const { data } = useSelector(state => state.site, shallowEqual);
	const store = useStore();
	const [selectedInterestLevelId, setSelectedInterestLevelId] = useState(match?.interest_level_id);
	const [isLoading, setLoading] = useState(false);

	const isCandidateMatch = match?.destination_model.toLowerCase().includes('listing');

	return (
		<div
			style={{
				position: 'fixed',
				padding: 15,
				backgroundColor: '#fff',
				left: '760px',
				top: 'calc(50vh - 200px)',
				borderRadius: 8,
				width: 190,
			}}
		>
			{[...(data?.interestLevels || [])]
				.reverse()
				.slice(0, 4)
				.map((level, index) => (
					// eslint-disable-next-line jsx-a11y/no-static-element-interactions
					<div
						className="interest-level"
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							cursor: 'pointer',
							padding: 8,
							opacity: selectedInterestLevelId === level.id ? 1 : 0.7,
						}}
						onClick={async () => {
							if (!isLoading) {
								try {
									setLoading(true);
									setSelectedInterestLevelId(level.id);
									await onSave(level.id);
								} catch (e) {
									console.log(e);
								} finally {
									setLoading(false);
								}
							}
						}}
					>
						<div style={{ width: 28, height: 28, flexGrow: 0 }}>
							<InterestIcon
								interestLevel={level}
								interestLevelId={level?.id}
								size={28}
								level={data.interestLevels?.length - index}
								color={selectedInterestLevelId === level.id ? 'var(--primaryColor)' : 'var(--mutedTextColor)'}
							/>
						</div>
						<div style={{ width: 10 }} />
						<Typography variant="body2" style={{ marginTop: '0.5rem' }}>
							{level?.label}
						</Typography>
					</div>
				))}
		</div>
	);
};

SharedCandidateModal.propTypes = {};

SharedCandidateModal.defaultProps = {};

export default SharedCandidateModal;
