import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Typography } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import LoadingButton from '../form/LoadingButton';
import CheckboxInput from '../form/inputs/CheckboxInput';

import * as CandidatesAPI from '../../scripts/candidates';
import * as ListingsAPI from '../../scripts/listings';
import { useContextStore } from '../../store/ContextStore';

let copyTimeout = null;

const getDaysInFuture = stringDate => {
	const expirationDate = new Date(stringDate);
	const daysInFuture = (expirationDate.getTime() - Date.now()) / (24 * 60 * 60 * 1000);
	return daysInFuture;
};

let saveTimeout = null;

const ShareMatchModal = ({ match, model, onSave, onClose, ...props }) => {
	const { data, user } = useSelector(state => state.site, shallowEqual);
	const { role } = useContextStore();
	const sharedMatchAccessLevels = data.sharedMatchAccessLevels || [];
	const [selectedInteretLevelId, setSelectedInterestLevelId] = useState(match?.interest_level_id);
	const [isLoading, setLoading] = useState(false);

	const [daysInFuture, setDaysInFuture] = useState(null);
	const [shareLink, setShareLink] = useState(null);
	const [isCopied, setIsCopied] = useState(null);
	const [isShareLinkGenerating, setIsShareLinkGenerating] = useState(false);
	const [isPublished, setIsPublished] = useState(match?.is_published);
	const [displaySaved, setDisplaySaved] = useState(false);

	const [isSending, setIsSending] = useState(false);
	const [displaySent, setDisplaySent] = useState(false);

	console.log({ model });
	const matchIsShareable = model
		? model.is_sharable
		: role === 'recruiter'
		? // match?.destination_model?.toLowerCase()?.includes('listing')
		  match?.listing?.is_sharable
		: match?.candidate?.is_sharable;

	const recordSave = () => {
		if (saveTimeout) {
			clearTimeout(saveTimeout);
		}
		setDisplaySaved(true);
		saveTimeout = setTimeout(() => {
			setDisplaySaved(false);
		}, 2000);
	};

	const [accessLevelId, setAccessLevelId] = useState(match?.access_level?.id);

	const isRestricted = accessLevelId !== 3;

	const isCandidateMatch = match.destination_model.toLowerCase().includes('listing');

	const fullShareLink = shareLink
		? `${window.location.origin}/${isCandidateMatch ? 'candidate' : 'contact'}/${
				isCandidateMatch ? match?.candidate_id : match?.listing?.contacts?.[0]?.id
		  }/profile?authUrl=${encodeURIComponent(shareLink)}&matchId=${match?.id}`
		: '';

	useEffect(() => {
		if (isPublished) {
			// Get any already-created share links.
			(isCandidateMatch
				? CandidatesAPI.getCandidateMatchShareLinks({
						candidateId: match?.candidate_id,
						matchId: match.id,
				  })
				: ListingsAPI.getListingMatchShareLinks({
						listingId: match?.listing_id,
						matchId: match.id,
				  })
			)
				.then(response => {
					const responseLinks = response?.data?.data || [];

					// Look for an existing share link.
					let existingLinkFound = false;
					if (responseLinks?.length) {
						responseLinks.forEach(_ => {
							if (!existingLinkFound) {
								// Only use the existing share link if it expires at least 7 days in the future.
								const days = getDaysInFuture(responseLinks[responseLinks?.length - 1]?.expires_at);
								setDaysInFuture(days);

								if (days > 7) {
									// If we found a valid share link, use it and return.
									existingLinkFound = true;
									setShareLink(responseLinks[responseLinks?.length - 1]?.link_hash);
									setDaysInFuture(days);
									console.log("Found existing share link that doesn't expire for at least 7 days.");
									return null;
								}
								console.log(`Share link ignored: it expires in ${days} days.`);
							}
							return null;
						});
					}

					// If we didn't find an existing share link, create a new one.
					if (!existingLinkFound) {
						console.log("Didn't find an existing share link. Creating a new one.");

						setIsShareLinkGenerating(true);
						return (isCandidateMatch
							? CandidatesAPI.createCandidateMatchShareLink({
									candidateId: match?.candidate_id,
									matchId: match.id,
							  })
							: ListingsAPI.createListingMatchShareLink({
									listingId: match?.listing_id,
									matchId: match.id,
							  })
						)
							.then(resp => {
								setShareLink(resp?.data?.data?.link_hash);
								setDaysInFuture(getDaysInFuture(resp?.data?.data?.expires_at));
							})
							.catch(console.log)
							.finally(() => {
								setIsShareLinkGenerating(false);
							});
					}

					return null;
				})
				.catch(console.log);
		}
	}, [isPublished]);

	return (
		<div>
			<Typography variant="h2" style={{ marginBottom: 5 }}>
				Share {isCandidateMatch ? 'Candidate' : 'Client'} Match
			</Typography>
			<Typography variant="body2" style={{ marginBottom: 15 }}>
				Share a {isCandidateMatch ? 'job' : 'candidate'} profile directly with a{' '}
				{isCandidateMatch ? 'candidate' : 'client'} and let them review it from their NauWork profile!
			</Typography>

			{(!isCandidateMatch && match.listing?.owner?.email !== user?.email) ||
			(isCandidateMatch && match.candidate?.owner?.email !== user?.email) ? (
				<Alert severity="error">
					You are not the owner of this {isCandidateMatch ? 'candidate' : 'listing'}.<br /> Are you sure you want to
					share the record anyway?
				</Alert>
			) : null}
			<CheckboxInput
				type="checkbox"
				name="is_published"
				value="true"
				checked={isPublished}
				disabled={!isPublished && !matchIsShareable}
				onChange={async e => {
					setIsPublished(e.target.checked);
					await onSave({
						access_level_id: accessLevelId,
						is_published: e.target.checked,
					});

					recordSave();
				}}
				label={`Share this ${isCandidateMatch ? 'job' : 'candidate'} with the ${
					isCandidateMatch ? 'candidate' : 'client'
				}`}
			/>

			<div style={{ height: 15 }} />

			{isPublished ? (
				<>
					<Typography variant="body2" style={{ marginBottom: 0 }}>
						Limit visibility when viewing this match?
					</Typography>
					<CheckboxInput
						type="checkbox"
						name="visibility"
						value="true"
						checked={isRestricted}
						onChange={async e => {
							const newIsRestricted = e.target.checked;
							const newAccessLevelId = newIsRestricted ? 1 : 3;
							setAccessLevelId(newAccessLevelId);
							await onSave({
								access_level_id: newAccessLevelId,
								is_published: isPublished,
							});

							recordSave();
						}}
						label="Limited"
					/>
					<div style={{ height: 25 }} />
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
						{/* <div style={{ flex: 1 }}>
							<TextField
								value={fullShareLink}
								disabled
								variant="outlined"
								name="share_link"
								placeholder="Generate Share Link"
								fullWidth
							/>
						</div>

						<div style={{ width: 10 }} /> */}

						{!shareLink ? (
							<LoadingButton
								loading={isShareLinkGenerating}
								variant="outlined"
								color="primary"
								disabled={!isPublished}
								style={{ marginLeft: 0 }}
								onClick={async () => {
									// Create a share link.
									setIsShareLinkGenerating(true);
									CandidatesAPI.createCandidateMatchShareLink({
										candidateId: match?.candidate_id,
										matchId: match.id,
									})
										.then(response => {
											console.log({ response });
											setShareLink(response?.data?.data?.link_hash);
										})
										.catch(console.log)
										.finally(() => {
											setIsShareLinkGenerating(false);
										});
								}}
							>
								<Icons.Link /> <div style={{ width: 10 }} />
								Generate Link
							</LoadingButton>
						) : (
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<LoadingButton
									loading={false}
									variant="outlined"
									color="primary"
									disabled={!isPublished}
									style={{ marginLeft: 0 }}
									onClick={async () => {
										// Create a share link.
										navigator.clipboard.writeText(fullShareLink).then(
											() => {
												/* clipboard successfully set */
												setIsCopied(true);

												if (copyTimeout) {
													clearTimeout(copyTimeout);
												}
												copyTimeout = setTimeout(() => {
													setIsCopied(false);
												}, 1000);
											},
											() => {
												/* clipboard write failed */
											},
										);
									}}
								>
									{isCopied ? (
										<>
											<Icons.Check />
											<div style={{ width: 10 }} />
											Copied
										</>
									) : (
										<>
											<Icons.FileCopy />
											<div style={{ width: 10 }} />
											Copy Link
										</>
									)}
								</LoadingButton>
								<div style={{ width: 20 }} />
								<LoadingButton
									variant="outlined"
									color="primary"
									loading={isSending}
									disabled={!isPublished || displaySent}
									style={{ marginLeft: 0 }}
									onClick={async () => {
										if (!isSending) {
											try {
												setIsSending(true);

												if (isCandidateMatch) {
													await CandidatesAPI.sendMatchEmail({
														matchId: match?.id,
														candidateId: match?.candidate_id,
													});
												} else {
													await ListingsAPI.sendMatchEmail({
														matchId: match?.id,
														listingId: match?.listing_id,
													});
												}

												setDisplaySent(true);

												setTimeout(() => {
													setDisplaySent(false);
												}, 2000);
											} catch (e) {
												console.log(e);
											} finally {
												setIsSending(false);
											}
										}
									}}
								>
									{displaySent ? <Icons.Check /> : <Icons.SendOutlined />}
									<div style={{ width: 10 }} />
									{displaySent ? 'Sent' : 'Send Email'}
								</LoadingButton>
							</div>
						)}
					</div>

					{/* 
					{daysInFuture != null ? (
						<div>
							<p style={{ textAlign: 'left', fontStyle: 'italic' }}>
								Link is active for {Math.floor(Math.max(0, daysInFuture))} more days.
							</p>
						</div>
					) : null} 
					*/}
				</>
			) : null}

			<Typography
				variant="body2"
				style={{
					fontStyle: 'italic',
					position: 'absolute',
					bottom: 30,
					right: 30,
					opacity: displaySaved ? 100 : 0,
					transition: '.5s opacity ease',
				}}
			>
				{' '}
				{/* <CheckmarkIcon className="teal-100" size={26} style={{ marginTop: 4, marginRight: 15 }} /> */}✓ Saved
			</Typography>

			{/* <div style={{ height: 40 }} /> */}

			{/* <div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-end',
				}}
			>
				<LoadingButton
					loading={isLoading}
					variant="outlined"
					color="primary"
					onClick={async () => {
						onClose();
					}}
				>
					Cancel
				</LoadingButton>
				<div style={{ width: 10 }} />
				<LoadingButton
					loading={isLoading}
					variant="contained"
					color="primary"
					onClick={async () => {
						setLoading(true);
						await onSave({
							is_published: isPublished,
						});
						setLoading(false);
					}}
				>
					Save
				</LoadingButton>
			</div> */}
		</div>
	);
};

export default ShareMatchModal;
