import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useStore, useSelector, shallowEqual, useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import {
	Typography,
	Card,
	Button,
	Chip,
	IconButton,
	Menu,
	MenuItem,
	ListItemIcon,
	Tooltip,
	Grid,
	Divider,
} from '@material-ui/core';
import { useParams } from 'react-router';
import * as Icons from '@material-ui/icons';
import { Alert, Pagination } from '@material-ui/lab';
import moment from 'moment';

import Page from '../page/Page';
import * as PageHeader from '../page/PageHeader';
import { palette } from '../../BobcatTheme';
import Table from '../Table';
import CandidateSyncModal from '../modals/CandidateSyncModal';
import CandidateDeleteModal from '../modals/CandidateDeleteModal';
import LabeledField from '../form/LabeledField';
import CheckboxInput from '../form/inputs/CheckboxInput';
import CandidateFilters from '../filters/CandidateFilters';
import CandidateMatchCardItem from './CandidateMatchCardItem';
import AddCandidateMatchModal from '../modals/AddCandidateMatchModal';
import ShareMatchModal from '../modals/ShareMatchModal';
import SharedCandidateModal from '../modals/SharedCandidateModal';
import SharedJobModal from '../modals/SharedJobModal';
import MinusIcon from '../icons/MinusIcon';

import { ActionCreators as SiteActions } from '../../store/Site';
import * as ListingsAPI from '../../scripts/listings';
import * as CandidatesAPI from '../../scripts/candidates';
import { Panel, PanelHeader, PanelContent } from '../Panel';
import * as Utility from '../../scripts/utility';
import CombineIcon from '../icons/SectionIcons/CombineIcon';
import { useContextStore } from '../../store/ContextStore';

const CandidateCardList = ({
	jobId,
	contextJob,
	loading,
	matches,
	graphMatches,
	candidates,
	refetchMatches,
	disableLink,
	small,
	title,
	pageSize: _pageSize,
	filterComponent,
	enableRefresh,
	hideAdd,
	hideItemDetails,
	autoSelectAll,
	enableSelectAll,
	clearFilters,
	numFilters,
	roleOverride,
}) => {
	const store = useStore();
	const { data, alert, user } = useSelector(state => state.site, shallowEqual);
	const { role } = useContextStore();

	const [activePageNum, setActivePageNum] = useState(1);

	const numItems = graphMatches?.filter(x => x.candidate)?.length || matches?.length || candidates?.length;
	const pageSize = _pageSize || 4;
	const numPages = Math.ceil(numItems / pageSize);

	const tableCandidates =
		graphMatches?.map(x => x.candidate) ||
		candidates ||
		[...(matches || [])]?.sort((a, b) => b?.id - a?.id)?.map(x => x.candidate) ||
		[];

	const filteredCandidates = tableCandidates.slice((activePageNum - 1) * pageSize, activePageNum * pageSize);

	useEffect(() => {
		setActivePageNum(1);
	}, [tableCandidates?.length]);

	const params = useParams();
	const [selectedCandidateIds, setSelectedCandidateIds] = useState([]);
	const [menu, setMenu] = useState({ anchor: null, candidate: {} });
	const location = useLocation();
	const dispatch = useDispatch();
	const [reloading, setReloading] = useState(false);

	useEffect(() => {
		// Whenever the filtered candidates change, select all of them
		if (autoSelectAll) {
			setSelectedCandidateIds(filteredCandidates?.map(x => x?.id));
		}
	}, [filteredCandidates?.map(x => `${x?.id}`)?.join(',')]);

	// const sendAssessment = ev => {
	// 	setMenu({ ...menu, anchor: null });

	// 	CandidatesAPI.sendAssessment(menu.candidate.id, response => {
	// 		if (!response) {
	// 			store.dispatch(SiteActions.showAlert('The skills assessment could not be sent. Please try again.', 'error'));
	// 			return;
	// 		}

	// 		store.dispatch(SiteActions.showAlert('A portal invitation was emailed.', 'success'));
	// 	});
	// };

	const filteredSelectedCandidateIds = selectedCandidateIds?.filter(x =>
		filteredCandidates?.map(y => y?.id)?.includes(x),
	);

	return (
		<>
			<Panel topBarColor="blue">
				<PanelHeader>
					<Grid container spacing={2} style={{ alignItems: 'flex-start', marginTop: 0 }}>
						<Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
							<CombineIcon size={20} color="var(--blue-500)" />
							<div style={{ width: 10 }} />
							<Typography variant="h3">
								{title || 'Matches'}
								{/* for
											{` 
											${Utility.getFromObj(candidate, 'first_name')} ${Utility.getFromObj(candidate, 'last_name')}`} */}
							</Typography>
							{filterComponent || null}
							{(numFilters || 0) > 0 ? (
								<Button
									variant="outlined"
									color="primary"
									size="small"
									style={{ whiteSpace: 'nowrap', marginBottom: 10, marginLeft: 10 }}
									onClick={clearFilters}
								>
									Clear
								</Button>
							) : null}
							{enableRefresh && (
								// <Button
								// 	variant="outlined"
								// 	color="primary"
								// 	size="small"
								// 	disabled={reloading}
								// 	style={{ whiteSpace: 'nowrap', marginBottom: 10, minWidth: 0, paddingLeft: 10, paddingRight: 10 }}
								// 	onClick={async () => {
								// 		if (!reloading) {
								// 			setReloading(true);
								// 			try {
								// 				await refetchMatches({ listingId: jobId });
								// 			} catch (e) {
								// 				console.log(e);
								// 			} finally {
								// 				setReloading(false);
								// 			}
								// 		}
								// 	}}
								// >
								// 	{reloading ? (
								// 		<span
								// 			style={{
								// 				marginLeft: 5,
								// 				marginRight: 5,
								// 			}}
								// 		>
								// 			Loading...
								// 		</span>
								// 	) : (
								// 		<Icons.Refresh style={{ color: palette.primary.main }} />
								// 	)}
								// </Button>

								<Icons.Refresh
									onClick={async () => {
										if (!reloading) {
											setReloading(true);
											try {
												await refetchMatches({ listingId: jobId });
											} catch (e) {
												console.log(e);
											} finally {
												setReloading(false);
											}
										}
									}}
									style={{
										color: '#aaa',
										marginLeft: 10,
										marginTop: -8,
										cursor: reloading ? 'not-allowed' : 'pointer',
									}}
								/>
							)}
						</Grid>

						<Grid
							item
							xs={8}
							style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}
						>
							{/* <NavLink to={`${location?.pathname}/edit`}> */}
							{enableSelectAll && (
								<Button
									variant="outlined"
									color="primary"
									size="small"
									style={{ whiteSpace: 'nowrap' }}
									onClick={() => {
										if (selectedCandidateIds?.length) {
											setSelectedCandidateIds([]);
										} else {
											setSelectedCandidateIds(filteredCandidates?.filter(x => x).map(x => x?.id));
										}
									}}
									startIcon={
										selectedCandidateIds?.length > 0 ? (
											<Icons.Remove style={{ color: palette.primary.main }} />
										) : (
											<Icons.Add style={{ color: palette.primary.main }} />
										)
									}
								>
									{selectedCandidateIds?.length > 0 ? 'Deselect All' : 'Select All'}
								</Button>
							)}
							{!hideAdd && (
								<Button
									variant="outlined"
									color="primary"
									size="small"
									style={{ whiteSpace: 'nowrap' }}
									onClick={() => {
										dispatch(
											SiteActions.showModal(
												<AddCandidateMatchModal
													// match={menu?.match}
													onClose={() => dispatch(SiteActions.hideModal())}
													onAdd={async candidateId => {
														// setEditingListingMatch(true);

														try {
															await ListingsAPI.addListingMatch({
																candidateId,
																listingId: jobId,
															});
														} catch (e) {
															console.log(e);
														} finally {
															await refetchMatches({ jobId });
															dispatch(SiteActions.hideModal());
															// setEditingListingMatch(false);
															// handleClose();
														}
														// console.log('Saving!');
													}}
												/>,
												{
													className: 'modal-medium modal-add-match',
												},
											),
										);
									}}
								>
									Add Candidate
								</Button>
							)}
							{/* </NavLink> */}
							<NavLink to={`${location?.pathname}/details?candidates=${filteredSelectedCandidateIds?.join(',')}`}>
								<Button
									disabled={!filteredSelectedCandidateIds?.length}
									variant={filteredSelectedCandidateIds?.length ? 'contained' : 'outlined'}
									color="primary"
									size="small"
									style={{ whiteSpace: 'nowrap' }}
								>
									View Selected{' '}
									{filteredSelectedCandidateIds?.length ? `(${filteredSelectedCandidateIds?.length})` : ''}
								</Button>
							</NavLink>
						</Grid>
					</Grid>
				</PanelHeader>
				<PanelContent>
					{loading || reloading ? (
						<Typography variant="body1">Loading...</Typography>
					) : !filteredCandidates?.length ? (
						<Typography variant="body1">No candidates available.</Typography>
					) : (
						<>
							{filteredCandidates
								?.filter(x => x)
								.map((candidate, i) => {
									const selected = selectedCandidateIds?.includes(candidate?.id);
									const graphMatch = graphMatches?.find(x => x.candidate_id === candidate?.id);

									const match =
										matches?.find(x => x.candidate?.id === candidate?.id) ||
										graphMatch?.saved_matches?.find(x =>
											x.origin_model
												?.toLowerCase()
												.includes((roleOverride || role) === 'recruiter' ? 'candidate' : 'listing'),
										);

									// const anchorObject = anchorObject || candidate;

									return (
										<>
											<CandidateMatchCardItem
												hideItemDetails={hideItemDetails}
												small={small}
												selected={selected}
												toggleSelected={() => {
													if (selected) {
														setSelectedCandidateIds(selectedCandidateIds.filter(x => x !== candidate?.id));
													} else {
														setSelectedCandidateIds([...selectedCandidateIds, candidate?.id]);
													}
												}}
												setMenu={setMenu}
												key={candidate?.id || i}
												candidate={candidate}
												job={contextJob}
												match={match}
												graphMatch={graphMatch}
												disableLink={disableLink}
												index={i}
											/>
											<Divider style={{ marginTop: 0, marginBottom: 20 }} />
										</>
									);
								})}
							<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
								<Typography style={{ paddingRight: 10 }}>
									{activePageNum * pageSize - pageSize + 1}-{Math.min(numItems, activePageNum * pageSize)} of {numItems}
								</Typography>
								<Pagination
									key={tableCandidates?.length}
									color="primary"
									shape="rounded"
									count={numPages}
									// page={activePageNum}
									onChange={(event, number) => {
										setActivePageNum(number);
									}}
								/>
							</div>
						</>
					)}

					{menu ? (
						<Menu
							anchorEl={menu?.anchor}
							open={Boolean(menu?.anchor)}
							onClose={() => setMenu({ ...menu, anchor: null })}
							keepMounted
						>
							<NavLink to={`/job/${jobId}/matches/details?candidates=${menu.candidate.id}`} className="color-inherit">
								<MenuItem
									onClick={() => {
										setMenu({ ...menu, anchor: null });
									}}
								>
									<ListItemIcon>
										<CombineIcon size={20} />
									</ListItemIcon>
									Match Overview
								</MenuItem>
							</NavLink>
							{menu?.match && (
								<MenuItem
									onClick={() => {
										setMenu(null);
										dispatch(
											SiteActions.showModal(
												<ShareMatchModal
													match={menu?.match}
													model={role === 'recruiter' ? menu?.candidate : menu?.job}
													onClose={() => dispatch(SiteActions.hideModal())}
													onSave={async matchData => {
														// setEditingListingMatch(true);
														try {
															await ListingsAPI.updateListingMatch({
																listingId: jobId,
																matchId: menu?.match?.id,
																match: {
																	// ...existingCandidateMatch,
																	...matchData,
																},
															});

															await refetchMatches({ jobId });
															// dispatch(SiteActions.hideModal());
														} catch (e) {
															console.log(e);
														} finally {
															// setEditingListingMatch(false);
															// handleClose();
														}
														console.log('Saving!');
													}}
												/>,
												{
													className: 'modal-small',
												},
											),
										);
									}}
								>
									<ListItemIcon>
										<Icons.Share color="#ddd" />
									</ListItemIcon>
									Share to {(roleOverride || role) === 'recruiter' ? 'Candidate' : 'Job'}
								</MenuItem>
							)}
							{menu?.match && (
								<MenuItem
									onClick={() => {
										setMenu(null);
										if ((roleOverride || role) === 'account-executive') {
											dispatch(
												SiteActions.showModal(
													<SharedCandidateModal
														match={menu?.match}
														jobId={jobId}
														overrideListing={null}
														refetchMatches={refetchMatches}
														hideRating
													/>,
													{
														className: 'modal-medium match-share-modal',
														// disableCloseButton: true,
														// disableBackdropClick: true,
														// disableEscapeKey: true,
													},
												),
											);
										} else {
											dispatch(
												SiteActions.showModal(
													<SharedJobModal
														match={menu?.match}
														// jobId={jobId}
														overrideListing={menu?.job}
														refetchMatches={refetchMatches}
														hideRating
														candidateId={menu?.candidate?.id}
													/>,
													{
														className: 'modal-medium match-share-modal',
														// disableCloseButton: true,
														// disableBackdropClick: true,
														// disableEscapeKey: true,
													},
												),
											);
										}
									}}
								>
									<ListItemIcon>
										<Icons.Visibility style={{ width: 20 }} />
									</ListItemIcon>
									Preview Share
								</MenuItem>
							)}
							{menu?.match && (
								<MenuItem
									onClick={async () => {
										setMenu({ ...menu, anchor: null });
										// store.dispatch(SiteActions.showModal(<JobDeleteModal job={menu.listing} onDelete={() => {}} />));
										try {
											await ListingsAPI.deleteListingMatch({
												listingId: jobId,
												matchId: menu?.match?.id,
											});

											await refetchMatches({ jobId });
											dispatch(SiteActions.hideModal());
										} catch (e) {
											console.log(e);
										} finally {
											// setEditingListingMatch(false);
											// handleClose();
										}
									}}
								>
									<ListItemIcon>
										<MinusIcon color="red" />
									</ListItemIcon>
									Remove Match
								</MenuItem>
							)}
						</Menu>
					) : null}
				</PanelContent>
			</Panel>
		</>
	);
};

export default CandidateCardList;
