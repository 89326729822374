import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from './Dropdown';

const TagDropdown = ({ color, ...props }) => {
	return (
		<Dropdown
			multiple
			// freeSolo // Allows entering custom skills
			// filterSelectedOptions={false}
			color={color}
			className="tags-filled"
			ChipProps={{ color }}
			{...props}
		/>
	);
};

TagDropdown.propTypes = {
	color: PropTypes.string,
};

TagDropdown.defaultProps = {
	color: '',
};

export default TagDropdown;
