import React, { useState, useEffect } from 'react';
import { useStore, shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography, Button, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Fence from '../../../../Fence';
import VerticalSpacer from '../../../../VerticalSpacer';
import LoadingButton from '../../../../form/LoadingButton';
import { ActionCreators as SiteActions } from '../../../../../store/Site';
import { ActionCreators as AssessmentActions } from '../../../../../store/Assessment';
import * as CandidatesAPI from '../../../../../scripts/candidates';
import * as Utility from '../../../../../scripts/utility';
import LetsGetStartedForm from './LetsGetStartedForm';
import RoleForm from './Role';
import CompanyForm from './Company';
import WorkLocationForm from './WorkLocation';
import PreferencesIcon from '../../../../icons/PreferencesIcon';

const PreferenceForms = () => {
	const pageNum = 1;
	const [isLoading, setIsLoading] = useState(false);
	const [preferenceStep, setPreferenceStep] = useState(() => {
		const savedStep = localStorage.getItem('PS');
		return savedStep ? JSON.parse(savedStep) : 1;
	});

	const store = useStore();
	const { id: candidateID } = useParams();

	const { alert, data } = useSelector(state => state.site, shallowEqual);
	const { candidate, progress } = useSelector(state => state.assessment, shallowEqual);
	const [candidateBeforeUpdate, setCandidateBeforeUpdate] = useState({});
	const states = useSelector(state => state.site.data.states, shallowEqual);

	const isRemoteOnly =
		Boolean(candidate.work_location_preferences?.length) &&
		candidate.work_location_preferences?.map(p => (p.id ? p.id : p)).every(val => val === 1);

	const prevStep = () => {
		if (preferenceStep === 1) {
			Utility.setLocalStorageItems({ PS: 1 });
			store.dispatch(AssessmentActions.setPage(pageNum - 1));
			return;
		}
		Utility.setLocalStorageItems({ PS: preferenceStep - 1 });
		setPreferenceStep(s => s - 1);
	};

	const nextStep = () => {
		if (!progress.canProceed) {
			store.dispatch(SiteActions.showAlert('Please fill out the required field.', 'error'));
			return;
		}
		store.dispatch(SiteActions.hideAlert());
		store.dispatch(AssessmentActions.setIsSaving(true));

		const forgedOriginalCandidate = CandidatesAPI.getForgedCandidate(candidateBeforeUpdate);
		const forgedCurrentCandidate = CandidatesAPI.getForgedCandidate(candidate);

		let differencesToSubmit;
		// eslint-disable-next-line prefer-const
		differencesToSubmit = Utility.getEntityDifferences(forgedOriginalCandidate, forgedCurrentCandidate);
		if (differencesToSubmit?.industry_preferences) {
			differencesToSubmit.industry_preferences = differencesToSubmit.industry_preferences.map(item => item.id);
		}
		CandidatesAPI.updateCandidate(candidateID, differencesToSubmit, response => {
			if (!response) {
				store.dispatch(AssessmentActions.setIsSaving(false));
				store.dispatch(SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error'));
				return;
			}
			store.dispatch(AssessmentActions.setIsSaving(false));
			if (preferenceStep === 4) {
				Utility.setLocalStorageItems({ PS: 4, ES: 1 });
				store.dispatch(AssessmentActions.addCompletedPage(pageNum));
				store.dispatch(AssessmentActions.setPage(pageNum + 1));
			} else {
				Utility.setLocalStorageItems({ PS: preferenceStep + 1 });
				setPreferenceStep(s => s + 1);
			}
		});
	};

	const setCandidate = ev => {
		const { name, value } = ev.target;
		if (name === 'willing_to_relocate' && value === '2') {
			const selectedState = states.find(state => state.name === candidate.state);
			if (selectedState) {
				store.dispatch(AssessmentActions.updateCandidate('desired_locations', [selectedState.id]));
			}
		}
		if (name === 'work_location_preferences' && Boolean(value.length) && value.every(val => val === 1)) {
			const selectedState = states.find(state => state.name === candidate.state);
			if (selectedState) {
				store.dispatch(AssessmentActions.updateCandidate('desired_locations', [selectedState.id]));
			}
			store.dispatch(AssessmentActions.updateCandidate('willing_to_relocate', '2'));
		}
		if (name === 'job_priority_types' && value.length > 4) {
			return;
		}
		if (name === 'role_level_preferences' && value.length > 2) {
			return;
		}
		if (name === 'state') {
			const selectedState = states.find(state => state.id === value || state.name === value);
			const currentDesiredLocations = candidate.desired_locations || [];
			const newDesiredLocations =
				currentDesiredLocations.length === 1 ? [selectedState.id] : [...currentDesiredLocations, selectedState.id];
			store.dispatch(AssessmentActions.updateCandidate('desired_locations', newDesiredLocations));
		}
		if (name === 'business_size_preferences' && value.some(item => item === 0)) {
			store.dispatch(AssessmentActions.updateCandidate(name, [1, 2, 3, 4, 5, 6, 7, 8]));
			return;
		}
		if (name === 'timezones' && value.some(item => item === 0)) {
			store.dispatch(AssessmentActions.updateCandidate(name, [1, 2, 3, 4, 5, 6]));
			return;
		}

		store.dispatch(AssessmentActions.updateCandidate(name, value));
	};

	// Initialize the page data
	useEffect(() => {
		store.dispatch(SiteActions.hideAlert());
		setIsLoading(true);
		store.dispatch(AssessmentActions.setIsSaving(false));
		CandidatesAPI.getCandidate(candidateID, response => {
			if (!response) {
				store.dispatch(
					SiteActions.showAlert('An error ocurred loading your information. Please refresh the page.', 'error'),
				);
				setIsLoading(false);
				return;
			}
			store.dispatch(AssessmentActions.setCandidate(response.data.data));
			setCandidateBeforeUpdate(response.data.data);
			setIsLoading(false);
		});
	}, []);

	// Check if can proceed
	useEffect(() => {
		let canProceed;
		switch (preferenceStep) {
			// Let's get started
			case 1:
				canProceed = Boolean(candidate?.reason_seeking_new_role);
				break;
			// Role
			case 2:
				canProceed = Boolean(
					candidate?.desired_position &&
						candidate?.categories?.length &&
						candidate?.specialties?.length &&
						candidate?.role_level_preferences?.length &&
						candidate?.employment_preferences?.length &&
						candidate?.leadership_preferences?.length &&
						((candidate?.leadership_preferences || []).map(p => (p.id ? p.id : p)).every(val => val === 1) ||
							candidate?.preferred_leadership_allocation_id) &&
						candidate?.salary_expectation_type_id,
				);
				break;
			// Company
			case 3:
				canProceed = Boolean(
					candidate?.industry_preferences?.length &&
						candidate?.business_size_preferences?.length &&
						candidate?.job_priority_types?.length,
				);
				break;
			// case 4 Work Location
			default:
				canProceed = Boolean(
					candidate?.city &&
						candidate?.state &&
						candidate?.zip &&
						Boolean(candidate?.work_location_preferences?.length > 0) &&
						Boolean(candidate?.travel_willingness_type_id) &&
						((isRemoteOnly && Boolean(candidate?.timezones?.length)) ||
							((candidate?.work_location_preferences || []).map(p => (p.id ? p.id : p)).includes(1) &&
								Boolean(candidate?.timezones?.length) &&
								candidate?.willing_to_relocate === '2') ||
							((candidate?.work_location_preferences || []).map(p => (p.id ? p.id : p)).includes(1) &&
								Boolean(candidate?.timezones?.length) &&
								candidate?.willing_to_relocate === '1' &&
								Boolean(candidate?.desired_locations?.length)) ||
							((candidate?.work_location_preferences || []).map(p => (p.id ? p.id : p)).every(val => val !== 1) &&
								(candidate?.willing_to_relocate === '2' ||
									((candidate?.work_location_preferences || []).map(p => (p.id ? p.id : p)).every(val => val !== 1) &&
										candidate?.willing_to_relocate === '1' &&
										Boolean(candidate?.desired_locations?.length))))),
				);
				break;
		}

		if (canProceed !== progress.canProceed) {
			store.dispatch(AssessmentActions.setCanProceed(canProceed));
		}
	}, [candidate, preferenceStep]);

	if (isLoading || !candidate)
		return (
			<div className="text-center">
				<CircularProgress />
			</div>
		);

	return (
		<>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<PreferencesIcon size={32} className="teal-100" />
				<Typography variant="h6" component="h2" className="text-medium" style={{ paddingLeft: 10 }}>
					Preferences <span className="color-muted">{preferenceStep} of 4</span>
				</Typography>
			</div>
			<VerticalSpacer height={3} />
			{preferenceStep === 1 && <LetsGetStartedForm candidate={candidate} setCandidate={setCandidate} />}
			{preferenceStep === 2 && <RoleForm candidate={candidate} setCandidate={setCandidate} />}
			{preferenceStep === 3 && <CompanyForm candidate={candidate} setCandidate={setCandidate} />}
			{preferenceStep === 4 && <WorkLocationForm candidate={candidate} setCandidate={setCandidate} />}
			<VerticalSpacer height={3} />
			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}
			<Fence className="text-right">
				<Button variant="outlined" color="primary" size="large" onClick={prevStep}>
					Back
				</Button>
				<LoadingButton
					variant="contained"
					loading={progress.isSaving}
					color="primary"
					size="large"
					disabled={!progress.canProceed}
					onClick={progress.canProceed ? nextStep : () => null}
				>
					Next
				</LoadingButton>
			</Fence>
		</>
	);
};

export default PreferenceForms;
