import React, { useEffect, useState, useMemo, useRef, Fragment } from 'react';
import { useStore, useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router';
import { Grid, Typography, Button, Chip, Box, IconButton, ButtonBase, Divider } from '@material-ui/core';
import { Alert, Pagination } from '@material-ui/lab';
import { isMobile } from 'react-device-detect';
import * as Icons from '@material-ui/icons';
import moment from 'moment';
import Page from '../../components/page/Page';
import PageLoading from '../../components/page/PageLoading';
import JobAssessmentModal from '../../components/modals/assessments/jobs/JobAssessmentModal';
import Fence from '../../components/Fence';
import { Panel, PanelHeader, PanelContent } from '../../components/Panel';
import CandidateStatusChip from '../../components/matching/CandidateStatusChip';
import Section from '../../components/Section';
import LabeledField from '../../components/form/LabeledField';
import VerticalSpacer from '../../components/VerticalSpacer';
import EditDetailsModal from './EditJobProfile/EditDetailsModal';
import EditExperienceModal from './EditJobProfile/EditExperienceModal';
import EditLocationModal from './EditJobProfile/EditLocationModal';
import EditSkillsModal from './EditJobProfile/EditSkillsModal';
import EditDescriptionModal from './EditJobProfile/EditDescriptionModal';
import EditSkillStoryModal from './EditJobProfile/EditSkillStoryModal';
import EditContactInfoModal from './EditJobProfile/EditContactInfoModal';
import EmailCircleIcon from '../../components/icons/EmailCircleIcon';
import CaseIcon from '../../components/icons/SectionIcons/CaseIcon';
import LinkedinCircleIcon from '../../components/icons/LinkedinCircleIcon';
import PhoneCircleIcon from '../../components/icons/PhoneCircleIcon';
import RecruiterIcon from '../../components/icons/RecruiterIcon';
import SkillStoryIcon from '../../components/icons/SkillStoryIcon';
import ExperienceIcon from '../../components/icons/ExperienceIcon';
import ResumeIcon from '../../components/icons/ResumeIcon';
import CareerIcon from '../../components/icons/CareerIcon';
import LocationIcon from '../../components/icons/LocationIcon';
import StarCheckmarkIcon from '../../components/icons/StarCheckmarkIcon';
import { ActionCreators as SiteActions } from '../../store/Site';
import { ActionCreators as AssessmentActions } from '../../store/Assessment';
import * as ListingsAPI from '../../scripts/listings';
import * as Utility from '../../scripts/utility';
import MatchIcon from '../../components/icons/MatchIcon';
import ProgressCircle from '../../components/ProgressCircle';
import { SkillChip } from '../candidates/CandidateBlocks/ViewSkillsBlock';
import SharedCandidateModal from '../../components/modals/SharedCandidateModal';
import { useContextStore } from '../../store/ContextStore';
import ExpandableTypography from '../../components/form/ExpandableTypography';

const JobProfile = () => {
	const store = useStore();
	const dispatch = useDispatch();
	const params = useParams();
	const listingID = params.id;
	const { data, user: authUser } = useSelector(state => state.site, shallowEqual);
	const { progress } = useSelector(state => state.assessment, shallowEqual);
	const states = useSelector(state => state.site.data.states, shallowEqual);

	const { jobMatches, loadingMatches, refetchMatches } = useContextStore();
	const [overrideCandidates, setOverrideCandidates] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	const [listing, setListing] = useState({});
	const [descriptions, setDescriptions] = useState([]);
	const [hideCompletionBanner, setHideCompletionBanner] = useState(localStorage.getItem('hide-completion-banner'));
	const listingPoster = listing?.contacts?.[0];
	const listingOwner = listing?.owner;
	const editDetailsBtn = useRef(null);
	const editExperienceBtn = useRef(null);
	const editLocationBtn = useRef(null);
	const editSkillStoryBtn = useRef(null);
	const editSkillsBtn = useRef(null);
	const editDescriptionBtn = useRef(null);

	const requiredSkills = useMemo(() => {
		return listing?.skills?.filter(skill => skill.required_skill);
	}, [listing.skills]);
	const heroSkills = useMemo(() => {
		return listing?.skills?.filter(skill => skill.hero_skill);
	}, [listing.skills]);
	const preferredSkills = useMemo(() => {
		return listing?.skills?.filter(skill => skill.preferred_skill);
	}, [listing.skills]);
	const bonusSkills = useMemo(() => {
		return listing?.skills?.filter(skill => skill.bonus_skill);
	}, [listing.skills]);

	const detailsPanelIsComplete = useMemo(() => {
		return [
			!!listing.position_title,
			!!listing.professional_category_id,
			!!listing.openings,
			!!listing?.other_compensation_types?.length,
			!!listing.work_visa_type_id,
		].every(c => c);
	}, [listing]);

	const experiencePanelIsComplete = useMemo(() => {
		return [!!listing.professional_experience_years, !!listing.education_type_id, listing?.study_types?.length].every(
			c => c,
		);
	}, [listing]);

	const locationPanelIsComplete = useMemo(() => {
		const workLocationPreferenceValue = listing?.work_location_preferences?.map(p => (p.id ? p.id : p));
		const hasLocalSelection = workLocationPreferenceValue?.some(pid => [2, 3, 4, 5].includes(pid));
		const hasRemoteSelection = workLocationPreferenceValue?.includes(1);
		return [
			listing?.work_location_preferences?.length,
			hasRemoteSelection ? listing?.remote_states?.length : true,
			hasLocalSelection ? !!listing.offer_relocation : true,
			hasLocalSelection
				? listing?.office_locations?.length && listing.office_locations.every(l => l.city && l.state_id)
				: true,
		].every(c => c);
	}, [listing]);

	const skillStoryPanelIsComplete = useMemo(() => [requiredSkills, heroSkills].every(arr => arr?.length), [
		listing.skills,
	]);

	const skillsPanelIsComplete = useMemo(() => listing.skills?.find(s => s.skill_experience_id === 5), [listing]);

	const descriptionPanelIsComplete = useMemo(() => !!descriptions?.length, [descriptions]);

	const openDescription = (ev, desc) => {
		ev.preventDefault();
		ListingsAPI.downloadListingDescription(listingID, desc.document_id, desc.file_name);
	};

	const getCompletenessText = complete => {
		if (!listing.assessment_completed_at && !listing?.bypass_intake)
			return "You're getting close to a complete job profile!";
		if (!listing?.bypass_intake && complete < 100) return `Welcome ${listingPoster.first_name}!`;
		return (
			<>
				Your job profile is complete!
				<br /> We are now monitoring for talent matches.
			</>
		);
	};

	const getCompletenessSubtext = complete => {
		if (!listing?.bypass_intake && complete < 100) {
			return 'Please complete the job profile so we can more accurately source and match candidates to your position.';
		}
		return 'Remember to keep your profile up-to-date so we can match you with the right candidates.';
	};

	const closeCompletionBanner = () => {
		localStorage.setItem('hide-completion-banner', true);
		setHideCompletionBanner(true);
	};

	const getJobDescriptions = () => {
		ListingsAPI.getListingDescriptions(
			listingID,
			{ per_page: 1, orderBy: 'sovren_parsed_docs.created_at', direction: 'desc' },
			response => {
				if (!response) return;
				setDescriptions(response.data.data);
			},
		);
	};

	const openAssessment = () => {
		if (listing.assessment_completed_at || isMobile) return;
		const furthestCompletedPage = progress?.completed?.length ? Math.max(...progress.completed) : -1;
		store.dispatch(AssessmentActions.setPage(furthestCompletedPage + 1));
		dispatch(
			SiteActions.showModal(<JobAssessmentModal setJobProfile={setListing} getJobDescriptions={getJobDescriptions} />, {
				className: 'modal-large',
				disableBackdropClick: true,
			}),
		);
	};

	const handleClickCompleteProfile = () => {
		if (!listing.assessment_completed_at) {
			openAssessment();
		} else if (!detailsPanelIsComplete) {
			Utility.scrollTo('Details');
			setTimeout(() => editDetailsBtn.current.click(), 500);
		} else if (!experiencePanelIsComplete) {
			Utility.scrollTo('Experience');
			setTimeout(() => editExperienceBtn.current.click(), 500);
		} else if (!locationPanelIsComplete) {
			Utility.scrollTo('Location');
			setTimeout(() => editLocationBtn.current.click(), 500);
		} else if (!skillStoryPanelIsComplete) {
			Utility.scrollTo('SkillStory');
			setTimeout(() => editSkillStoryBtn.current.click(), 500);
		} else if (!skillsPanelIsComplete) {
			Utility.scrollTo('Skills');
			setTimeout(() => editSkillsBtn.current.click(), 500);
		} else if (!descriptionPanelIsComplete) {
			Utility.scrollTo('Description');
			setTimeout(() => editDescriptionBtn.current.click(), 500);
		}
	};

	// Get page data on load
	useEffect(() => {
		Promise.all([
			ListingsAPI.getListing(listingID, response => {
				if (!response) {
					store.dispatch(SiteActions.showAlert('Could not load the listing. Refresh the page to try again.', 'error'));
					return;
				}
				setListing(response.data.data);
				if (!response.data.data.assessment_completed_at && !response.data.data?.bypass_intake) {
					openAssessment();
				}
			}),
			getJobDescriptions(),
			refetchMatches({ jobId: listingID }).then(results => {
				console.log({ results });
				const matches = results?.[0];

				// After refetching the matches, load each match individually
				// to get the listing detail data that's not included in the matches.
				if (matches?.length) {
					Promise.all(
						matches.map(match => {
							return ListingsAPI.getListingMatch({
								listingId: listingID,
								matchId: match?.id,
							}).then(newMatch => {
								const newCandidate = newMatch?.candidate;

								if (newCandidate) {
									setOverrideCandidates(x => {
										return {
											...x,
											[newCandidate.id]: newCandidate,
										};
									});
								}
								return null;
							});
						}),
					);
				}
			}),
		])
			.then(() => {})
			.catch(console.log)
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	const openAssessmentRedirect = () => {
		dispatch(
			SiteActions.showModal(<AssessmentRedirectModal onContinue={openAssessment} />, {
				className: 'modal-small',
				disableBackdropClick: true,
			}),
		);
	};

	if (isLoading || Object.keys(listing)?.length === 0) {
		return (
			<Page sidebar="none" key="empty-state">
				{alert.show ? (
					<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
						{alert.message}
					</Alert>
				) : (
					<PageLoading />
				)}
			</Page>
		);
	}

	return (
		<Page sidebar="none" topbarContext="contact" centered>
			<Fence width={71.429} minWidth={71.429}>
				<VerticalSpacer height={2.5} />
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Panel id="ContactInfo" style={{ height: 'calc(100% - 2rem)' }}>
							<PanelHeader className="spread">
								<div>
									<Typography variant="h2" className="font-lato-bold">
										{`${listingPoster.first_name || ''} ${listingPoster.last_name || ''}`}
									</Typography>
									<Typography>{listingPoster.company || ''}</Typography>
								</div>
								{listing.assessment_completed_at ? (
									<Button
										variant="outlined"
										color="primary"
										size="small"
										value={1}
										onClick={() => {
											dispatch(
												SiteActions.showModal(<EditContactInfoModal listing={listing} setListing={setListing} />, {
													className: 'modal-medium modal-no-shadow',
												}),
											);
										}}
									>
										Edit
									</Button>
								) : null}
							</PanelHeader>
							<PanelContent>
								<Section>
									<Typography variant="body1">{listingPoster.current_title}</Typography>
								</Section>
								<Section>
									<Typography variant="body1" style={{ marginBottom: 8, display: 'flex', alignItems: 'center' }}>
										<EmailCircleIcon style={{ marginRight: '0.5rem' }} /> {listingPoster.email || Utility.nb}
									</Typography>
									<Typography variant="body1" style={{ marginBottom: 8, display: 'flex', alignItems: 'center' }}>
										<PhoneCircleIcon style={{ marginRight: '0.5rem' }} /> {listingPoster.phone2 || Utility.nb}
									</Typography>
								</Section>
							</PanelContent>
						</Panel>
					</Grid>
					<Grid item xs={6}>
						<Panel id="RecruiterInfo" style={{ height: 'calc(100% - 2rem)' }}>
							<PanelHeader>
								<Typography variant="h6" style={{ display: 'flex', alignItems: 'center' }} className="text-bold">
									<RecruiterIcon size={32} style={{ marginRight: '0.8rem' }} className="teal-100" />
									NauWork Account Executive
								</Typography>
							</PanelHeader>
							<PanelContent>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<Typography variant="body1" style={{ marginBottom: 8 }} className="text-medium">
											{listingOwner.name || Utility.nb}
										</Typography>
										{listingOwner.email ? (
											<Typography variant="body1" style={{ marginBottom: 8, display: 'flex', alignItems: 'center' }}>
												<EmailCircleIcon style={{ marginRight: '0.5rem' }} />
												{listingOwner.email || Utility.nb}
											</Typography>
										) : null}
										{listingOwner?.phone ? (
											<Typography variant="body1" style={{ marginBottom: 8, display: 'flex', alignItems: 'center' }}>
												<PhoneCircleIcon style={{ marginRight: '0.5rem' }} />
												{listingOwner.phone || Utility.nb}
											</Typography>
										) : null}
										{listingOwner?.linkedin ? (
											<Typography variant="body1" style={{ marginBottom: 8, display: 'flex', alignItems: 'center' }}>
												<LinkedinCircleIcon style={{ marginRight: '0.5rem' }} />
												{listingOwner.linkedin ? (
													<a href={listingOwner.linkedin} style={{ fontWeight: 400 }} target="_blank" rel="noreferrer">
														LinkedIn Profile
													</a>
												) : (
													Utility.nb
												)}
											</Typography>
										) : null}
									</Grid>
									{listingOwner?.calendar ? (
										<Grid
											item
											xs={6}
											style={{
												display: 'flex',
												justifyContent: 'flex-end',
												alignItems: 'flex-end',
											}}
										>
											<Button
												variant="outlined"
												color="primary"
												size="small"
												value={1}
												onClick={() => window.open(listingOwner.calendar, '_blank')}
											>
												Schedule A Call
											</Button>
										</Grid>
									) : null}
								</Grid>
							</PanelContent>
						</Panel>
					</Grid>
				</Grid>
				<Typography variant="body1" className="font-lato-bold color-muted">
					{Utility.getItemLabel(data.employmentPreferences, listing.role_type)}
				</Typography>
				<Typography variant="h2" className="font-lato-bold">
					{listing.position_title}
				</Typography>
				<VerticalSpacer height={1.5} />
				{!hideCompletionBanner && (
					<Panel id="CompletionBanner" color="primary" style={{ position: 'relative' }}>
						{listing.completeness >= 100 ? (
							<IconButton className="modal-close modal-close-white" onClick={() => closeCompletionBanner()}>
								<Icons.Close />
							</IconButton>
						) : null}
						<PanelContent
							className={listing.assessment_completed_at ? 'stack-left' : 'text-center'}
							style={{ padding: '3.5rem 5rem' }}
						>
							{listing.assessment_completed_at ? (
								<Box position="relative" display="inline-flex" marginRight="2rem">
									<ProgressCircle size={130} value={Utility.getFromObj(listing, 'completeness')} />
									<Box
										top={0}
										left={0}
										bottom={0}
										right={0}
										position="absolute"
										display="flex"
										alignItems="center"
										justifyContent="center"
										flexDirection="column"
									>
										<Typography variant="h2" component="div" color="inherit">
											{`${listing.completeness || 0}%`}
										</Typography>
										<Typography component="div" color="inherit" className="opacity-60">
											Complete
										</Typography>
									</Box>
								</Box>
							) : null}
							<div style={{ marginLeft: '4rem', marginRight: 60 }}>
								<Typography variant="body2" color="inherit" className="text-bold">
									{getCompletenessText(listing.completeness || 0)}
								</Typography>
								<Typography variant="body2" color="inherit">
									{getCompletenessSubtext(listing.completeness || 0)}
								</Typography>

								<VerticalSpacer height={1.5} />
								{listing?.completeness < 100 && (
									<Button
										variant="contained"
										color="primary"
										className="no-margins color-invert"
										onClick={() => handleClickCompleteProfile()}
									>
										Complete Job Profile
									</Button>
								)}
							</div>
						</PanelContent>
					</Panel>
				)}

				{/* {process.env.REACT_APP_FEATURE_MATCHING ? ( */}
				<CuratedMatchesPanel
					jobId={listingID}
					overrideCandidates={overrideCandidates}
					onClickOverride={listing?.assessment_completed_at || listing?.bypass_intake ? null : openAssessmentRedirect}
				/>
				{/* ) : null} */}

				<Panel id="Details" Icon={<CareerIcon size={32} className="teal-100" />}>
					<PanelHeader className="spread">
						<Typography variant="h6" component="h2" className="text-medium">
							Job Details
						</Typography>
						{!!listing.assessment_completed_at || listing?.bypass_intake ? (
							<Button
								ref={editDetailsBtn}
								variant={detailsPanelIsComplete ? 'outlined' : 'containedTeal'}
								color="primary"
								size="small"
								value={2}
								onClick={() => {
									dispatch(
										SiteActions.showModal(<EditDetailsModal listing={listing} setListing={setListing} />, {
											className: 'modal-medium modal-no-padding modal-no-shadow',
										}),
									);
								}}
							>
								{detailsPanelIsComplete ? 'Edit' : 'Complete'}
							</Button>
						) : null}
					</PanelHeader>
					<PanelContent>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Typography className="grey-250">Title</Typography>
								<Typography>{listing.position_title || Utility.nb}</Typography>
								<VerticalSpacer height={2} />
								<Typography className="grey-250">Category</Typography>
								<Typography>
									{Utility.getItemLabel(data.categories, listing.professional_category_id) || Utility.nb}
								</Typography>
								<VerticalSpacer height={2} />
								<Typography className="grey-250">Openings</Typography>
								<Typography>{listing.openings || Utility.nb}</Typography>
								<VerticalSpacer height={2} />
							</Grid>
							<Grid item xs={6}>
								<Typography className="grey-250">Type</Typography>
								<Typography>
									{Utility.getItemLabel(data.employmentPreferences, listing.role_type) || Utility.nb}
								</Typography>
								<VerticalSpacer height={2} />
								<Typography className="grey-250">Specialty</Typography>
								<Typography>{Utility.getItemLabel(data.specialties, listing.specialty_id) || Utility.nb}</Typography>
								<VerticalSpacer height={2} />
								<Typography className="grey-250">Work Visa Sponsorship</Typography>
								<Typography>{listing?.work_visa_types?.label || Utility.nb}</Typography>
								{/* <Typography className="grey-250">Desired Start Date</Typography>
								<Typography>{Utility.formatDate(listing.start_date, true) || Utility.nb}</Typography> */}
								<VerticalSpacer height={2} />
							</Grid>
							<Grid item xs={12}>
								<Typography className="grey-250">Industry Sector</Typography>

								<VerticalSpacer height={0.5} />
								{listing.industries?.length &&
									listing.industries.map(l => {
										return <Chip key={l.id} label={l.label} />;
									})}
								<VerticalSpacer height={1} />
							</Grid>
							<Grid item xs={6}>
								<Typography className="grey-250">Salary Range</Typography>
								<Typography>
									{Utility.formatMoney(listing.salary_rate_min, { hideDecimal: true }) || Utility.formatMoney(0)} -{' '}
									{Utility.formatMoney(listing.salary_rate_max, { hideDecimal: true }) || Utility.formatMoney(0)}
								</Typography>
								<VerticalSpacer height={2} />
								<Typography className="grey-250">Travel</Typography>
								<Typography>{listing?.travel_willingness_type?.label || Utility.nb}</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className="grey-250">Other Compensation</Typography>
								<Typography>
									{listing?.other_compensation_types?.length
										? listing.other_compensation_types.map(t => t.label).join(', ')
										: Utility.nb}
								</Typography>
								<VerticalSpacer height={2} />
							</Grid>

							<VerticalSpacer height={2} />
							<Grid item xs={12}>
								<Typography className="grey-250">Job Summary (Abstract)</Typography>
								<ExpandableTypography numCharacters={250} variant="body2" text={listing?.abstract || Utility.nb} />
							</Grid>
							<VerticalSpacer height={2} />

							{listing?.additional_job_detail && (
								<Grid item xs={12}>
									<Typography className="grey-250">Additional Info</Typography>
									<ExpandableTypography
										numCharacters={250}
										variant="body2"
										text={listing?.additional_job_detail || Utility.nb}
									/>
									{/* <Typography>{listing?.additional_job_detail || Utility.nb}</Typography> */}
									<VerticalSpacer height={2} />
								</Grid>
							)}
						</Grid>
					</PanelContent>
				</Panel>

				<Panel id="Experience" Icon={<ExperienceIcon size={32} className="teal-100" />}>
					<PanelHeader className="spread">
						<Typography variant="h6" component="h2" className="text-medium">
							Preferred Experience
						</Typography>
						{!!listing.assessment_completed_at || listing?.bypass_intake ? (
							<Button
								ref={editExperienceBtn}
								variant={experiencePanelIsComplete ? 'outlined' : 'containedTeal'}
								color="primary"
								size="small"
								value={2}
								onClick={() => {
									dispatch(
										SiteActions.showModal(<EditExperienceModal listing={listing} setListing={setListing} />, {
											className: 'modal-medium modal-no-padding modal-no-shadow',
										}),
									);
								}}
							>
								{experiencePanelIsComplete ? 'Edit' : 'Complete'}
							</Button>
						) : null}
					</PanelHeader>
					<PanelContent>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Typography className="grey-250">Professional Experience (Years)</Typography>
								<Typography>
									{listing.professional_experience_years
										? `${listing.professional_experience_years} years`
										: Utility.nb}
								</Typography>
								<VerticalSpacer height={2} />
								<Typography className="grey-250">Leadership</Typography>
								<Typography>
									{Utility.getItemLabel(data.leadershipExperienceRoles, listing.recent_leadership_role_id) ||
										Utility.nb}
								</Typography>
								<VerticalSpacer height={2} />
							</Grid>
							<Grid item xs={6}>
								<Typography className="grey-250">Leadership Experience</Typography>
								<Typography>
									{listing.total_leadership_years !== null && listing.total_leadership_years !== undefined
										? `${listing.total_leadership_years} years`
										: Utility.nb}
								</Typography>
								<VerticalSpacer height={2} />
								<Typography className="grey-250">Education</Typography>
								<Typography>
									{Utility.getItemLabel(data.educationTypes, listing.education_type_id) || Utility.nb}
								</Typography>
								<VerticalSpacer height={2} />
								<Typography className="grey-250">Preferred Area(s) of Study</Typography>
								<Typography>
									{listing?.study_types?.length ? listing.study_types.map(s => s.label).join(', ') : Utility.nb}
								</Typography>
								<VerticalSpacer height={2} />
							</Grid>
							{listing?.additional_experience_details && (
								<Grid item xs={12}>
									<Typography className="grey-250">Additional Info</Typography>
									<ExpandableTypography
										numCharacters={250}
										variant="body2"
										text={listing?.additional_experience_details || Utility.nb}
									/>
									{/* <Typography>{listing?.additional_experience_details || Utility.nb}</Typography> */}
									<VerticalSpacer height={2} />
								</Grid>
							)}
						</Grid>
					</PanelContent>
				</Panel>

				<Panel id="Location" Icon={<LocationIcon size={32} className="teal-100" />}>
					<PanelHeader className="spread">
						<Typography variant="h6" component="h2" className="text-medium">
							Work Location Preferences
						</Typography>
						{!!listing.assessment_completed_at || listing?.bypass_intake ? (
							<Button
								ref={editLocationBtn}
								variant={locationPanelIsComplete ? 'outlined' : 'containedTeal'}
								color="primary"
								size="small"
								value={2}
								onClick={() => {
									dispatch(
										SiteActions.showModal(<EditLocationModal listing={listing} setListing={setListing} />, {
											className: 'modal-medium modal-no-padding modal-no-shadow',
										}),
									);
								}}
							>
								{locationPanelIsComplete ? 'Edit' : 'Complete'}
							</Button>
						) : null}
					</PanelHeader>
					<PanelContent>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Typography className="grey-250">Work Locations</Typography>
								<Typography style={{ display: 'block' }}>
									{Utility.getConsolidatedLocationPreferences(listing?.work_location_preferences)?.join(', ') || ''}
								</Typography>
								{/* {(listing.work_location_preferences || []).map(item => (
									<Typography key={item.id} style={{ display: 'block' }}>
										{Utility.getFromObj(item, 'label')}
									</Typography>
								))} */}
								<VerticalSpacer height={2} />
								<Typography className="grey-250">Candidate Relocation</Typography>
								<Typography>
									{data.offerRelocation?.find(o => o.id === listing.offer_relocation)?.label || Utility.nb}
								</Typography>
								<VerticalSpacer height={2} />
							</Grid>
							<Grid item xs={6}>
								<Typography className="grey-250">Approved Remote Locations</Typography>
								<Typography style={{ display: 'block' }}>
									{listing.remote_states?.length
										? listing.remote_states?.length === states.length
											? 'All States'
											: listing.remote_states.map(l => l.name).join(', ')
										: Utility.nb}
								</Typography>
								<VerticalSpacer height={2} />
								<Typography className="grey-250">Eligible Office Sites</Typography>
								<Typography style={{ display: 'block' }}>
									{listing?.office_locations?.length
										? listing.office_locations.map(item => (
												<>
													{`${item?.city}, ${item?.state?.name}`}
													<br />
												</>
										  ))
										: Utility.nb}
								</Typography>
								<VerticalSpacer height={2} />
							</Grid>
							{listing?.additional_work_location_details && (
								<Grid item xs={12}>
									<Typography className="grey-250">Additional Info</Typography>
									<ExpandableTypography
										numCharacters={250}
										variant="body2"
										text={listing?.additional_work_location_details || Utility.nb}
									/>
									{/* <Typography>{listing?.additional_work_location_details || Utility.nb}</Typography> */}
									<VerticalSpacer height={2} />
								</Grid>
							)}
						</Grid>
					</PanelContent>
				</Panel>

				<Panel id="Skills" Icon={<StarCheckmarkIcon size={32} className="teal-100" />}>
					<PanelHeader className="spread">
						<Typography variant="h6" component="h2" className="text-medium">
							Job Skills by Aptitude
						</Typography>
						{!!listing.assessment_completed_at || listing?.bypass_intake ? (
							<Button
								ref={editSkillsBtn}
								variant={skillsPanelIsComplete ? 'outlined' : 'containedTeal'}
								color="primary"
								size="small"
								value={2}
								onClick={() => {
									dispatch(
										SiteActions.showModal(<EditSkillsModal listing={listing} setListing={setListing} />, {
											className: 'modal-medium modal-no-padding modal-no-shadow',
										}),
									);
								}}
							>
								{skillsPanelIsComplete ? 'Edit' : 'Complete'}
							</Button>
						) : null}
					</PanelHeader>
					<PanelContent>
						{[...data.skillExperienceLevels]
							.reverse()
							.filter(x => x.id !== 1) /* Filter out the "Uncategorized" experience level. */
							.map(level => (
								<LabeledField
									key={level.name}
									label={
										{
											Expert: 'Best Skills',
											Advanced: 'Advanced Skills',
											Intermediate: 'Intermediate Skills',
											Novice: 'Basic Skills',
										}[level.label] || level.label
									}
									className="color-muted"
								>
									{(listing.skills || []).map(skill =>
										skill.experience?.name === level?.name ? (
											<Chip key={skill.id} label={Utility.getFromObj(skill, 'skill', 'label')} color="primary" />
										) : null,
									)}
								</LabeledField>
							))}
					</PanelContent>
				</Panel>

				<Panel id="SkillStory" Icon={<SkillStoryIcon size={32} className="teal-100" />}>
					<PanelHeader className="spread">
						<Typography variant="h6" component="h2" className="text-medium">
							Skill Story
						</Typography>
						{!!listing.assessment_completed_at || listing?.bypass_intake ? (
							<Button
								ref={editSkillStoryBtn}
								variant={skillStoryPanelIsComplete ? 'outlined' : 'containedTeal'}
								color="primary"
								size="small"
								onClick={() => {
									dispatch(
										SiteActions.showModal(<EditSkillStoryModal listing={listing} setListing={setListing} />, {
											className: 'modal-medium modal-no-padding modal-no-shadow',
										}),
									);
								}}
							>
								{skillStoryPanelIsComplete ? 'Edit' : 'Complete'}
							</Button>
						) : null}
					</PanelHeader>
					<PanelContent>
						<Typography className="grey-250">Required Skills</Typography>
						<VerticalSpacer height={0.75} />
						{!!requiredSkills &&
							requiredSkills.map(skill => <Chip key={skill.id} label={skill.skill.label} color="primary" />)}
						<VerticalSpacer height={2} />
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Typography className="grey-250">Hero Skills</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className="grey-250">Hero Skills Experience</Typography>
							</Grid>
							{!!heroSkills &&
								heroSkills.map(skill => (
									<Fragment key={skill.skill_id}>
										<Grid item xs={6}>
											<Chip label={skill.skill.label} color="primary" className="no-bottom-margin" />
										</Grid>
										<Grid item xs={6}>
											<Typography>
												{typeof skill.experience_years === 'number'
													? `${skill.experience_years} year${skill.experience_years !== 1 ? 's' : ''}`
													: ''}
											</Typography>
										</Grid>
									</Fragment>
								))}
						</Grid>
						<VerticalSpacer height={3} />
						<Typography className="grey-250">Preferred Skills</Typography>
						<VerticalSpacer height={0.75} />
						{!!preferredSkills &&
							preferredSkills.map(skill => <Chip key={skill.id} label={skill.skill.label} color="primary" />)}
						<VerticalSpacer height={2} />
						<Typography className="grey-250">Bonus Skills</Typography>
						<VerticalSpacer height={0.75} />
						{!!bonusSkills &&
							bonusSkills.map(skill => <Chip key={skill.id} label={skill.skill.label} color="primary" />)}
					</PanelContent>
				</Panel>

				<Panel id="Description" Icon={<ResumeIcon size={32} className="teal-100" />}>
					<PanelHeader className="spread">
						<Typography variant="h6" component="h2" className="text-medium">
							Job Description
						</Typography>
						{!!listing.assessment_completed_at || listing?.bypass_intake ? (
							<Button
								ref={editDescriptionBtn}
								variant={descriptionPanelIsComplete ? 'outlined' : 'containedTeal'}
								color="primary"
								size="small"
								value={2}
								onClick={() => {
									dispatch(
										SiteActions.showModal(
											<EditDescriptionModal
												listing={listing}
												setListing={setListing}
												setDescriptions={setDescriptions}
											/>,
											{ className: 'modal-medium modal-no-padding modal-no-shadow' },
										),
									);
								}}
							>
								{descriptionPanelIsComplete ? 'Update Job Description' : 'Complete'}
							</Button>
						) : null}
					</PanelHeader>
					<PanelContent>
						{descriptions.map(desc => (
							<Grid container spacing={2} key={`resume-${desc.document_id}`}>
								<Grid item xs={6}>
									<Typography className="grey-250">File Name</Typography>
									<a
										href="#0"
										style={{
											fontWeight: 300,
											textDecoration: 'underline',
										}}
										onClick={ev => openDescription(ev, desc)}
									>
										{desc.file_name}
									</a>
								</Grid>
								<Grid item xs={6}>
									<Typography className="grey-250">Date Provided</Typography>
									<Typography>
										<i>{Utility.formatDate(desc.updated_at, false, 'MMMM D, YYYY')}</i>
									</Typography>
								</Grid>
							</Grid>
						))}
						{descriptions?.length === 0 ? 'No Job Description' : ''}
					</PanelContent>
				</Panel>
			</Fence>
		</Page>
	);
};

const CuratedMatchesPanel = ({ jobId, overrideCandidates, onClickOverride }) => {
	const store = useStore();
	const dispatch = useDispatch();

	// const [matches, setMatches] = useState(mockListings);

	// const [loadingMatches, setLoadingMatches] = useState(true);

	const { jobMatches, loadingMatches, refetchMatches } = useContextStore();
	// const [overrideCandidates, setOverrideCandidates] = useState({});

	const [activePageNum, setActivePageNum] = useState(1);
	const numItems = jobMatches?.filter(x => x.is_published)?.length;
	const pageSize = 3;
	const numPages = Math.ceil(numItems / pageSize);

	// Fetch some jobs.
	// Get mock listings list
	useEffect(() => {
		/*
		ListingsAPI.getListings({}, response => {
		// console.log({ response });
		setLoadingListings(false);
		setListings(response?.data?.data);
	});
	*/
	}, []);

	const matches = jobMatches
		?.filter(x => x.is_published)
		?.sort((a, b) => {
			return moment(b?.auth_link?.created_at) - moment(a?.auth_link?.created_at);
		})
		.slice((activePageNum - 1) * pageSize, activePageNum * pageSize);

	if (!matches?.length) {
		return null;
	}

	return (
		<Panel id="CuratedMatches" Icon={<MatchIcon size={32} className="teal-100" />}>
			<PanelHeader className="spread">
				<div>
					<Typography variant="h6" component="h2" className="text-medium">
						Curated Matches
					</Typography>
					<Typography>Here are some matches we&apos;ve identified for you. Please rate your interest.</Typography>
				</div>
			</PanelHeader>
			<PanelContent>
				{loadingMatches ? null : (
					<div>
						{matches?.map((match, index) => (
							<ListingCard
								key={match.id}
								index={index}
								match={match}
								onClick={() => {
									if (onClickOverride) {
										onClickOverride(match);
									} else {
										store.dispatch(
											SiteActions.showModal(
												<SharedCandidateModal
													match={match}
													jobId={jobId}
													overrideCandidate={overrideCandidates[match?.candidate?.id]}
													refetchMatches={refetchMatches}
												/>,
												{
													className: 'modal-medium match-share-modal',
													// disableCloseButton: true,
													// disableBackdropClick: true,
													// disableEscapeKey: true,
												},
											),
										);
									}
								}}
							/>
						))}

						{/* <Divider style={{ marginTop: 20, marginBottom: 25 }} /> */}
						<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
							<Typography style={{ paddingRight: 10 }}>
								{activePageNum * pageSize - pageSize + 1}-{Math.min(numItems, activePageNum * pageSize)} of {numItems}
							</Typography>
							<Pagination
								color="primary"
								shape="rounded"
								count={numPages}
								// page={activePageNum}
								onChange={(event, number) => {
									setActivePageNum(number);
								}}
							/>
						</div>
					</div>
				)}
				<div style={{ marginTop: 0 }} />
			</PanelContent>
		</Panel>
	);
};

const ListingCard = ({ match, index, onClick }) => {
	const isLimited = match?.access_level?.id !== 3;

	const isNew = false; // index === 0;
	const { data } = useSelector(state => state.site, shallowEqual);
	const candidate = match?.candidate;

	if (!candidate) {
		return null;
	}

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div
			className="listing-card"
			style={{
				padding: 20,
				borderRadius: 3,
				// boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.15)',
				marginBottom: 15,
				backgroundColor: isNew ? '#FFFBEC' : '#fff',
				cursor: 'pointer',
				border: '1px solid #ddd',
				position: 'relative',
			}}
			onClick={onClick}
		>
			<div>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div style={{ display: 'flex', flex: 1 }}>
						<div
							style={{
								border: '2px solid var(--teal-200)',
								width: 55,
								height: 55,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								borderRadius: '50%',
								color: 'var(--teal-200)',
							}}
						>
							<Icons.PersonOutline style={{ width: 28, height: 28 }} />
						</div>
						<div style={{ width: 15 }} />
						<div>
							<div style={{ height: 3 }} />
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Typography variant="h2" component="h2" className="text-medium" style={{ fontSize: '1.2rem' }}>
									{isLimited ? (
										// <span
										// 	style={{
										// 		filter: isLimited ? 'blur(4px)' : 'none',
										// 		webkitFilter: isLimited ? 'blur(4px)' : 'none',
										// 	}}
										// >
										// 	Candidate -{' '}
										// </span>
										<span>{candidate.first_name} - </span>
									) : (
										<span>
											{candidate.first_name} {candidate.last_name} -{' '}
										</span>
									)}
									{candidate.current_title}
								</Typography>
								<CandidateStatusChip candidate={candidate} />
							</div>

							<div style={{ height: 5 }} />
							<Typography className="grey-250">
								Salary:{' '}
								{isLimited ? (
									<span
										style={{
											filter: isLimited ? 'blur(3px)' : 'none',
											webkitFilter: isLimited ? 'blur(3px)' : 'none',
										}}
									>
										$999,999
									</span>
								) : (
									<>{candidate?.salary_expectation_type?.label || 'Unknown'}</>
								)}
								<span> | </span>
								{candidate.professional_experience_years || 0}+ years experience | {candidate.state}
							</Typography>

							<div style={{ height: 10 }} />
							<div style={{ display: 'flex', alignItems: 'flex-end' }}>
								<LabeledField
									label="Work Preference"
									className="color-muted"
									removeMargin
									close
									style={{ minWidth: 120 }}
								>
									<Typography variant="body2">
										{/* {candidate?.work_location_preferences?.length
											? candidate.work_location_preferences.map(l => l.label).join(', ')
											: Utility.nb} */}
										{Utility.getConsolidatedLocationPreferences(candidate?.work_location_preferences)?.join(', ') || ''}
									</Typography>
								</LabeledField>
								<Grid item xs={12} style={{ marginBottom: -12 }}>
									{(candidate.skills || [])
										.filter(x => x.get_the_job)
										.map(skill => (
											<SkillChip
												baseColor="blue"
												key={skill?.id}
												skill={{
													...skill,
													enjoy_the_job: true,
												}}
											/>
										))}
								</Grid>
							</div>
						</div>
					</div>
					<div>
						{match?.auth_link?.created_at ? (
							<Typography
								className="grey-250"
								style={{
									fontStyle: 'italic',
									textAlign: 'right',
									marginBottom: 5,
								}}
							>
								Shared on {moment(match?.auth_link?.created_at).format('MM/DD/YY')}
							</Typography>
						) : null}
					</div>
				</div>
			</div>
			{!match?.user_viewed_at ? (
				<div
					style={{
						position: 'absolute',
						left: -1,
						top: 4,
						backgroundColor: '#B3F3EF',
						padding: '3px 6px',
						fontWeight: 500,
						borderTopRightRadius: 4,
						borderBottomRightRadius: 4,
						color: '#105B56',
					}}
				>
					NEW
				</div>
			) : !match?.interest_level_id ? (
				<div
					style={{
						position: 'absolute',
						left: -1,
						top: 4,
						backgroundColor: '#D2D7DE',
						padding: '3px 6px',
						fontWeight: 500,
						borderTopRightRadius: 4,
						borderBottomRightRadius: 4,
					}}
				>
					UNRANKED
				</div>
			) : null}
		</div>
	);
};

const AssessmentRedirectModal = ({ onContinue }) => {
	return (
		<div>
			<Typography variant="h3" component="h3" className="text-medium" style={{ paddingRight: 25 }}>
				One more step!
			</Typography>
			<div style={{ height: 20 }} />
			<Typography className="text-medium" style={{ paddingRight: 25 }}>
				To view the curated matches, please complete your profile.
			</Typography>
			<div style={{ height: 20 }} />
			<Button
				variant="contained"
				color="primary"
				// startIcon={<Icons.Link />}
				endIcon={<Icons.ArrowForward />}
				onClick={() => {
					onContinue();
				}}
				style={{ marginLeft: 0 }}
			>
				Complete My Profile
			</Button>
		</div>
	);
};

export default JobProfile;
