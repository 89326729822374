import React, { useState } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';

import * as Utility from '../../../scripts/utility';
import CandidateDetailsBlock from '../CandidateDetailsBlock';
import CandidateHeaderButtonGroup from '../CandidateHeaderButtonGroup';

const ExperienceTab = ({ currentJob, currentCandidate, data, setCandidate, graphMatch }) => {
	const [isDetailsShown, setIsDetailsShown] = useState(false);

	const mD = graphMatch?.all_match_data?.experience_match_data;

	const tabData = {
		title: {
			label: 'Title',
			job: currentJob.position_title,
			candidate: currentCandidate.current_title,
			score: null,
		},
		employer: {
			label: 'Employer',
			job: currentJob.company,
			candidate: currentCandidate.current_employer,
			score: null,
		},
		type: {
			label: 'Type',
			job: Utility.getItemLabel(data.employmentPreferences, currentJob.role_type),
			candidate: Utility.getItemLabel(data.employmentPreferences, currentCandidate?.current_employment_type_id),
			score: null,
		},
		specialtyAndCategory: {
			label: 'Specialty & Category',
			job: {
				specialty: Utility.getItemLabel(data.specialties, currentJob.specialty_id),
				category: Utility.getItemLabel(data.categories, currentJob.professional_category_id),
			},
			candidate: {
				specialty: currentCandidate?.specialties
					?.map(({ id: specialtyId }) => Utility.getItemLabel(data.specialties, specialtyId) || Utility.nb)
					.join(', '),
				// Utility.getItemLabel(data.specialties, currentCandidate.specialty_id),
				category: currentCandidate?.categories
					?.map(({ id: categoryId }) => Utility.getItemLabel(data.categories, categoryId) || Utility.nb)
					.join(', '),
				// Utility.getItemLabel(data.categories, currentCandidate.professional_category_id),
			},
			score: {
				specialty: mD?.experience_specialty,
				category: mD?.experience_category,
			},
		},
		experience: {
			label: 'Experience',
			job: currentJob.professional_experience_years ? `${currentJob.professional_experience_years} Years` : null,
			candidate: currentCandidate.professional_experience_years
				? `${currentCandidate.professional_experience_years} years`
				: null,
			score: mD?.experience_YOE_pct,
		},
		industries: {
			label: 'Industries',
			job: currentJob.industries,
			candidate: currentCandidate.industries,
			score: mD?.experience_industry,
		},
		businessSize: {
			label: 'Business Size',
			job: currentJob.client_corporation.business_sizes,
			candidate: currentCandidate.business_size_experiences,
			score: mD?.experience_business_size,
		},
		// ---- //
		leadership: {
			label: 'Leadership',
			job: Utility.getItemLabel(data.leadershipExperienceRoles, currentJob.recent_leadership_role_id),
			candidate: Utility.getItemLabel(data.leadershipExperienceRoles, currentCandidate.recent_leadership_role_id),
			score: mD?.experience_leadership_level,
		},
		leadershipExperience: {
			label: 'Leadership Experience (yrs)',
			job: currentJob.total_leadership_years,
			candidate: currentCandidate.total_leadership_years != null && currentCandidate.total_leadership_years,
			score: mD?.experience_leadership_YOE_pct,
		},
		leadershipAllocation: {
			label: 'Leadership Allocation',
			job: Utility.getItemLabel(data.leadershipAllocationTypes, currentJob.leadership_allocation_type_id),
			candidate: Utility.getItemLabel(data.leadershipAllocationTypes, currentCandidate.leadership_allocation_type_id),
			score: mD?.experience_leadership_allocation,
		},
		// ---- //
		education: {
			label: 'Education',
			job: Utility.getItemLabel(data.educationTypes, currentJob.education_type_id),
			candidate: Utility.getItemLabel(data.educationTypes, currentCandidate.education_type_id),
			score: mD?.experience_education_level,
		},
		studyAreas: {
			label: 'Study Areas',
			job: currentJob.study_types,
			candidate: currentCandidate.study_types,
			score: mD?.experience_area_of_study,
		},
		// ---- //
		workAuthorization: {
			label: 'Work Authorization',
			// TODO: check this (job field only)
			job: currentJob?.work_visa_types?.label,
			candidate:
				currentCandidate.us_work_authorization != null && currentCandidate.us_work_authorization === true
					? 'Yes'
					: 'No',
			score: null,
		},
		sponsorship: {
			label: 'Sponsorship',
			job: Utility.getItemLabel(data.workVisaTypes, currentJob.work_visa_type_id),
			candidate: Utility.getItemLabel(data.workVisaTypes, currentCandidate.work_visa_type_id),
			score: null,
		},
	};

	const bottomIndexes = [6, 9, 11];

	const tableTopRowStyle = column => ({
		display: 'flex',
		minHeight: column !== 'right' ? '100%' : null,
		borderRadius: '3px 3px 0 0',
		paddingInline: column !== 'left' ? 20 : 0,
		borderTop:
			column === 'left'
				? '3px solid var(--matchRowBorderColor)'
				: column === 'center'
				? '3px solid var(--blue-500)'
				: '3px solid var(--teal-100)',
		backgroundColor: 'var(--matchRowLightBackgroundColor)',
		flexDirection: column === 'right' ? 'column' : 'row',
		justifyContent: column === 'right' ? 'space-between' : 'flex-start',
	});

	const tableRowStyle = (index, column) => ({
		display: 'flex',
		minHeight: '110%',
		paddingInline: 20,
		marginTop: -4,
		backgroundColor: index % 2 === 0 ? 'var(--matchRowDarkBackgroundColor)' : 'var(--matchRowLightBackgroundColor)',
		borderTop: '1px solid var(--matchRowBorderColor)',
		borderBottomStyle: bottomIndexes.includes(index) ? '1px solid var(--matchRowBorderColor)' : 'none',
		justifyContent: column === 'right' ? 'space-between' : 'flex-start',
	});

	return (
		<>
			<Grid container direction="row" spacing={1} alignItems="stretch">
				<Grid item xs={2}>
					<Box p={1} style={tableTopRowStyle('left')} />
				</Grid>
				<Grid item xs={5}>
					<Box p={1} style={tableTopRowStyle('center')}>
						<Typography variant="h3" style={{ marginTop: 6 }}>
							Desired Job Experience
						</Typography>
					</Box>
				</Grid>
				<Grid item xs={5}>
					<Box p={1} style={tableTopRowStyle('right')}>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<Typography variant="h3" style={{ marginTop: 6 }}>
								{currentCandidate.first_name || ''} {currentCandidate.last_name || ''}
							</Typography>
							<CandidateHeaderButtonGroup
								isDetailsShown={isDetailsShown}
								setIsDetailsShown={setIsDetailsShown}
								graphMatch={graphMatch}
								currentCandidate={currentCandidate}
							/>
						</div>
						{isDetailsShown && (
							<CandidateDetailsBlock currentCandidate={currentCandidate} setCandidate={setCandidate} data={data} />
						)}
					</Box>
				</Grid>
			</Grid>
			{Object.entries(tabData).map(([key, { label, job, candidate, score }], index) => (
				<Grid
					container
					direction="row"
					spacing={1}
					alignItems="stretch"
					key={label}
					style={{ marginBottom: bottomIndexes.includes(index) ? 20 : 0 }}
				>
					<Grid item xs={2}>
						<Box p={1} style={tableRowStyle(index, 'left')}>
							<Typography variant="caption" color="textSecondary">
								{label}
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={5}>
						<Box p={1} style={tableRowStyle(index, 'center')}>
							{key === 'specialtyAndCategory' ? (
								<div>
									<Typography style={{ fontWeight: 'bold' }}>{job.specialty || '-'}</Typography>
									<Typography variant="body1">{job.category || '-'}</Typography>
								</div>
							) : key === 'industries' || key === 'businessSize' || key === 'studyAreas' ? (
								job.length ? (
									<div>
										{job?.map(item => (
											<Typography variant="body1" key={item.label}>
												{item.label}
											</Typography>
										))}
									</div>
								) : (
									'-'
								)
							) : (
								<Typography variant="body1">{job || '-'}</Typography>
							)}
						</Box>
					</Grid>
					<Grid item xs={5}>
						<Box p={1} style={tableRowStyle(index, 'right')}>
							{key === 'specialtyAndCategory' ? (
								<div>
									<Typography style={{ fontWeight: 'bold' }}>{candidate.specialty || '-'}</Typography>
									<Typography variant="body1">{candidate.category || '-'}</Typography>
								</div>
							) : key === 'industries' || key === 'businessSize' || key === 'studyAreas' ? (
								candidate.length ? (
									<div>
										{candidate?.map(item => (
											<Typography variant="body1" key={item.label}>
												{item.label}
											</Typography>
										))}
									</div>
								) : (
									'-'
								)
							) : (
								<Typography variant="body1">{candidate || '-'}</Typography>
							)}
							{/* title, employer, type, workAuthorization, and sponsorship will never have
							a score so we don't want to display a '-', we want to leave the value blank */}
							{key === 'title' ||
							key === 'employer' ||
							key === 'type' ||
							key === 'workAuthorization' ||
							key === 'sponsorship' ? null : key === 'specialtyAndCategory' ? (
								<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
									<Typography variant="body1" style={{ color: Utility.getScoreColor(score.specialty) }}>
										{score.specialty != null && score.specialty >= 0 ? `${Math.round(score.specialty * 100)}%` : '-'}
									</Typography>
									<Typography variant="body1" style={{ color: Utility.getScoreColor(score.category) }}>
										{score.category != null && score.category >= 0 ? `${Math.round(score.category * 100)}%` : '-'}
									</Typography>
								</div>
							) : (
								<Typography variant="body1" style={{ color: Utility.getScoreColor(score) }}>
									{score != null && score >= 0 ? `${Math.round(score * 100)}%` : '-'}
								</Typography>
							)}
						</Box>
					</Grid>
				</Grid>
			))}
		</>
	);
};

export default ExperienceTab;
