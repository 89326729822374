import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { Grid, TextField, Typography } from '@material-ui/core';
import LabeledField from '../../../components/form/LabeledField';
import EditJobBlockModal from './EditJobBlockModal';
import MoreDetailsIcon from '../../../components/icons/SectionIcons/MoreDetailsIcon';
import AddAnotherButton from '../../../components/form/AddAnotherButton';
import QADropdown from '../../../components/form/dropdowns/QADropdown';

const EditModal = ({ job, setJob }) => {
	const { data } = useSelector(state => state.site, shallowEqual);
	return (
		<EditJobBlockModal
			job={job}
			setJob={setJob}
			title="More Details"
			icon={MoreDetailsIcon}
			renderContents={({ updateJob, updatedJob }) => {
				// Initialize the job state with unique IDs for QA questions
				const initialJob = JSON.parse(JSON.stringify(updatedJob));
				initialJob.jobQAQuestions = initialJob.job_qa_questions.map((q, index) => ({ ...q, id: index }));

				const [localLinks, setLocalLinks] = useState(
					initialJob.job_content_links.length > 0 ? initialJob.job_content_links : [{ name: '', url: '' }],
				);
				const [localQAQuestions, setLocalQAQuestions] = useState(
					initialJob.jobQAQuestions.length > 0
						? initialJob.jobQAQuestions
						: [{ id: 0, job_qa_question_id: '', answer: '' }],
				);
				const [nextId, setNextId] = useState(localQAQuestions.length);
				const [qaNumFields, setQaNumFields] = useState(localQAQuestions.length);

				useEffect(() => {
					setLocalLinks(
						initialJob.job_content_links.length > 0 ? initialJob.job_content_links : [{ name: '', url: '' }],
					);
					setLocalQAQuestions(
						initialJob.jobQAQuestions.length > 0
							? initialJob.jobQAQuestions
							: [{ id: 0, job_qa_question_id: '', answer: '' }],
					);
					setNextId(initialJob.jobQAQuestions.length);
					setQaNumFields(initialJob.jobQAQuestions.length);
				}, [updatedJob]);

				const addEmptyLink = () => {
					const newLinks = [...localLinks, { name: '', url: '' }];
					setLocalLinks(newLinks);
					updateJob({
						target: { name: 'job_content_links', value: newLinks },
					});
				};

				const addEmptyQAQuestion = () => {
					const newQAQuestions = [...localQAQuestions, { id: nextId, job_qa_question_id: '', answer: '' }];
					setLocalQAQuestions(newQAQuestions);
					updateJob({
						target: {
							name: 'job_qa_questions',
							value: newQAQuestions.map(({ id, ...rest }) => rest),
						},
					});
					setNextId(prevId => prevId + 1);
					setQaNumFields(prevCount => prevCount + 1);
				};

				const handleLinkChange = (ev, index, field) => {
					const newLinks = [...localLinks];
					newLinks[index][field] = ev.target.value;
					setLocalLinks(newLinks);
					updateJob({
						target: { name: 'job_content_links', value: newLinks },
					});
				};

				const handleQAQuestionChange = (ev, index, field) => {
					const newQAQuestions = [...localQAQuestions];
					newQAQuestions[index][field] = ev.target.value;
					setLocalQAQuestions(newQAQuestions);
					updateJob({
						target: {
							name: 'job_qa_questions',
							value: newQAQuestions.map(({ id, ...rest }) => rest),
						},
					});
				};

				const handleSaveOrUpdateQuestion = (id, questionId, answer) => {
					setLocalQAQuestions(prevQAQuestions => {
						const qaQuestions = prevQAQuestions.map(q =>
							q.id === id ? { ...q, job_qa_question_id: questionId, answer } : q,
						);
						if (!qaQuestions.some(q => q.id === id)) {
							qaQuestions.push({
								id,
								job_qa_question_id: questionId,
								answer,
							});
						}
						updateJob({
							target: {
								name: 'job_qa_questions',
								value: qaQuestions.map(({ id, ...rest }) => rest),
							},
						});
						return qaQuestions;
					});
				};

				const handleDeleteQuestion = id => {
					setLocalQAQuestions(prevQAQuestions => {
						const updatedQuestions = prevQAQuestions.filter(q => q.id !== id);
						updateJob({
							target: {
								name: 'job_qa_questions',
								value: updatedQuestions.map(({ id, ...rest }) => rest),
							},
						});
						return updatedQuestions;
					});
					setQaNumFields(prevCount => prevCount - 1);
				};
				return (
					<>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography variant="body2" color="primary" style={{ fontWeight: 500, marginBottom: '10px' }}>
									Job Q&A Questions
								</Typography>
								{localQAQuestions.map((q, idx) => (
									<QADropdown
										key={q.id}
										data={data}
										onComplete={(id, questionId, answer) => handleSaveOrUpdateQuestion(id, questionId, answer)}
										onDelete={handleDeleteQuestion}
										question={q}
										idx={idx}
										isLastQuestion={localQAQuestions.length <= 1}
									/>
								))}
								<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<AddAnotherButton onClick={addEmptyQAQuestion} text="Add Another Q&A" />
								</div>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body2" color="primary" style={{ fontWeight: 500, marginBottom: '10px' }}>
									Job Content Links
								</Typography>
								{localLinks.map((link, index) => (
									<div key={index} style={{ marginBottom: '10px' }}>
										<LabeledField label="Link Name" required>
											<TextField
												variant="outlined"
												name={`job_content_links_name_${index}`}
												value={link.name}
												onChange={ev => handleLinkChange(ev, index, 'name')}
												error={!link.name}
												fullWidth
												placeholder="Enter link name"
												style={{ marginBottom: '-10px' }}
											/>
										</LabeledField>
										<LabeledField label="Link URL" required>
											<TextField
												variant="outlined"
												name={`job_content_links_url_${index}`}
												value={link.url}
												onChange={ev => handleLinkChange(ev, index, 'url')}
												error={!link.url}
												fullWidth
												placeholder="Enter link URL"
											/>
										</LabeledField>
									</div>
								))}
								<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<AddAnotherButton onClick={addEmptyLink} text="Add Another Link" />
								</div>
							</Grid>
						</Grid>
						<br />
					</>
				);
			}}
		/>
	);
};

EditModal.propTypes = {
	job: PropTypes.shape({ id: PropTypes.string }),
	setJob: PropTypes.func,
};

EditModal.defaultProps = {
	job: {},
	setJob: () => null,
};

export default EditModal;
