import React, { useEffect, useState, useMemo, useRef, Fragment, createRef } from 'react';
import { useStore, useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Grid, Typography, Button, Box, Chip, IconButton, ButtonBase, Divider, Tabs, Tab } from '@material-ui/core';
import { Alert, Pagination } from '@material-ui/lab';
import * as Icons from '@material-ui/icons';
import { useLocation, useParams, useHistory } from 'react-router';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import PageLoading from '../../components/page/PageLoading';
import Page from '../../components/page/Page';
import ListingStatusChip from '../../components/matching/ListingStatusChip';
import CandidateAssessmentModal from '../../components/modals/assessments/candidates/CandidateAssessmentModal';
import EditContactInfoModal from './EditCandidateProfile/EditContactInfoModal';
import EditSkillsModal from './EditCandidateProfile/EditSkillsModal';
import EditSkillStoryModal from './EditCandidateProfile/EditSkillStoryModal';
import EditResumeModal from './EditCandidateProfile/EditResumeModal';
import Fence from '../../components/Fence';
import { Panel, PanelHeader, PanelContent } from '../../components/Panel';
import Section from '../../components/Section';
import VerticalSpacer from '../../components/VerticalSpacer';
import EmailCircleIcon from '../../components/icons/EmailCircleIcon';
import LinkedinCircleIcon from '../../components/icons/LinkedinCircleIcon';
import PhoneCircleIcon from '../../components/icons/PhoneCircleIcon';
import RecruiterIcon from '../../components/icons/RecruiterIcon';
import CaseIcon from '../../components/icons/SectionIcons/CaseIcon';
import ResumeIcon from '../../components/icons/ResumeIcon';
import MatchIcon from '../../components/icons/MatchIcon';
import SkillsIcon from '../../components/icons/SkillsIcon';
import SkillStoryIcon from '../../components/icons/SkillStoryIcon';
import ProgressCircle from '../../components/ProgressCircle';
import VerifyAccountModal from '../../components/modals/VerifyAccountModal';
import ChangeLoginModal from '../../components/modals/ChangeLoginModal';
import { ActionCreators as SiteActions } from '../../store/Site';
import { ActionCreators as AssessmentActions } from '../../store/Assessment';
import * as CandidatesAPI from '../../scripts/candidates';
import * as Utility from '../../scripts/utility';
import * as UserAPI from '../../scripts/users';
import * as AuthenticationAPI from '../../scripts/authentication';

import { SkillChip } from './CandidateBlocks/ViewSkillsBlock';
import SharedJobModal from '../../components/modals/SharedJobModal';
import { useContextStore } from '../../store/ContextStore';
import useCurrentUser from '../../hooks/useCurrentUser';
import ExpiredLinkMessage from '../../components/ExpiredLinkMessage';
import PreferencesBlock from './CandidateBlocks/ViewPublicPreferencesBlock';
import LocationPreferencesBlock from './CandidateBlocks/ViewPublicLocationPreferencesBlock';
import ExperienceBlock from './CandidateBlocks/ViewPublicExperienceBlock';
import AchievementsAndInterests from './CandidateBlocks/ViewPublicAchievementsBlock';
import WorkAuthorization from './CandidateBlocks/ViewPublicWorkAuthorizationBlock';

const CandidateProfile = () => {
	const store = useStore();
	const dispatch = useDispatch();
	const params = useParams();
	const candidateID = params.id;
	const { data, alert } = useSelector(state => state.site, shallowEqual);

	const [isLoading, setIsLoading] = useState(true);

	const { user } = useSelector(state => state.site, shallowEqual);

	const { loadingMatches, refetchMatches } = useContextStore();
	const [overrideListings, setOverrideListings] = useState({});

	const { refetch: refetchUser } = useCurrentUser();
	const authUser = user;

	const { progress } = useSelector(state => state.assessment, shallowEqual);

	const isJobMatchesShown = Object.keys(overrideListings).length > 0;

	// 0 = Job Matches, 1 = Preferences, 2 = Experience,
	const [tab, setTab] = useState(isJobMatchesShown ? 1 : 0);

	const [displayExpiredLinkMessage, setDisplayExpiredLinkMessage] = useState(false);
	const [candidate, setCandidate] = useState({});
	const [resumes, setResumes] = useState([]);
	const [assessmentDeffered, setAssessmentDeffered] = useState(false);
	const [hideCompletionBanner, setHideCompletionBanner] = useState(
		localStorage.getItem(`hide-completion-banner-${candidateID}`),
	);
	const editContactInfoBtn = useRef(null);
	const editPreferencesBtn = createRef(null);
	const editLocationPreferencesBtn = createRef(null);
	const editExperienceBtn = createRef(null);
	const editSkillsBtn = useRef(null);
	const editSkillStoryBtn = useRef(null);
	const editAchievementsBtn = createRef(null);
	const editWorkAuthorizationBtn = createRef(null);
	const editResumeBtn = useRef(null);

	const workLocationPreferenceValue = useMemo(() => candidate?.work_location_preferences?.map(p => (p.id ? p.id : p)), [
		candidate.work_location_preferences,
	]);
	const hasLocalSelection = useMemo(() => workLocationPreferenceValue?.some(pid => [2, 3, 4, 5].includes(pid)), [
		workLocationPreferenceValue,
	]);

	const bestSkills = useMemo(() => {
		return candidate?.skills?.filter(skill => skill.skill_experience_id === 5);
	}, [candidate.skills]);
	const advancedSkills = useMemo(() => {
		return candidate?.skills?.filter(skill => skill.skill_experience_id === 4);
	}, [candidate.skills]);
	const intermediateSkills = useMemo(() => {
		return candidate?.skills?.filter(skill => skill.skill_experience_id === 3);
	}, [candidate.skills]);
	const basicSkills = useMemo(() => {
		return candidate?.skills?.filter(skill => skill.skill_experience_id === 2);
	}, [candidate.skills]);
	const getTheJobSkills = useMemo(() => {
		return candidate?.skills?.filter(skill => skill.get_the_job);
	}, [candidate.skills]);
	const enjoyTheJobSkills = useMemo(() => {
		return candidate?.skills?.filter(skill => skill.enjoy_the_job);
	}, [candidate.skills]);
	const improveOnTheJobSkills = useMemo(() => {
		return candidate?.skills?.filter(skill => skill.improve_on_job);
	}, [candidate.skills]);
	const learnOnTheJobSkills = useMemo(() => {
		return candidate?.skills?.filter(skill => skill.learn_on_job);
	}, [candidate.skills]);

	const contactInfoPanelIsComplete = useMemo(() => {
		const fields = ['first_name', 'last_name', 'email', 'phone'];
		return !fields.filter(f => !candidate[f])?.length;
	}, [candidate]);
	const preferencesPanelIsComplete = useMemo(() => {
		return [
			!!candidate.desired_position,
			candidate?.employment_preferences?.length,
			candidate?.categories?.length,
			candidate?.business_size_preferences?.length,
			candidate?.job_priority_types?.length,
			candidate?.role_level_preferences?.length,
			candidate?.industry_preferences?.length,
			candidate?.specialties?.length,
			!!candidate?.salary_expectation_type_id,
			!!candidate?.reason_seeking_new_role,
		].every(c => c);
	}, [candidate]);
	const locationPreferencesPanelIsComplete = useMemo(() => {
		return [
			!!candidate?.city,
			!!candidate?.state,
			!!candidate?.zip,
			candidate?.timezones?.length,
			candidate?.work_location_preferences?.length,
			hasLocalSelection ? candidate?.willing_to_relocate : true,
			hasLocalSelection ? candidate?.desired_locations?.length : true,
			!!candidate?.travel_willingness_type_id,
		].every(c => c);
	}, [candidate]);
	const experiencePanelIsComplete = useMemo(() => {
		return [
			candidate?.professional_experience_years != null,
			candidate?.industries?.length,
			candidate?.business_size_experiences?.length,
			!!candidate?.current_title,
			!!candidate?.leadership_title,
			!!candidate?.current_employer,
			!!candidate?.current_employment_type_id,
			!!candidate?.leadership_allocation_type_id,
			!!candidate?.recent_leadership_role_id,
			candidate?.recent_leadership_role_id !== 1 ? !!candidate?.leadership_title : true,
			candidate?.recent_leadership_role_id !== 1 ? !!candidate?.total_leadership_years : true,
			candidate.education_type_id === 6 ? true : !!candidate?.field_of_study,
			candidate.education_type_id === 1 ||
			candidate.education_type_id === 6 ||
			candidate.education_type_id === 9 ||
			candidate.education_type_id === 10
				? true
				: candidate?.study_types?.length,
		].every(c => c);
	}, [candidate]);
	const skillsPanelIsComplete = useMemo(() => {
		// const fields = ['professional_category_id'];
		return candidate?.categories?.length && candidate.skills?.find(s => s.skill_experience_id === 5);
	}, [candidate]);
	const skillStoryPanelIsComplete = useMemo(() => {
		return getTheJobSkills?.length > 0 && getTheJobSkills?.every(skill => !!skill.experience_years);
	}, [candidate.skills]);
	const achievementsPanelIsComplete = useMemo(() => {
		return [!!candidate?.achievements, candidate?.qa_questions?.length, candidate?.link_types?.length].every(c => c);
	}, [candidate]);
	const workAuthorizationPanelIsComplete = useMemo(() => {
		return [!!candidate?.work_visa_type_id, candidate?.us_work_authorization != null].every(c => c);
	}, [candidate]);
	const resumePanelIsComplete = useMemo(() => {
		return resumes?.length > 0;
	}, [resumes]);
	const getCompletenessText = complete => {
		if (!candidate.assessment_completed_at) return "You're getting close to a complete profile!";
		if (complete < 100) return `Welcome ${candidate.first_name}!`;
		return (
			<>
				Your profile is complete!
				<br /> We are now monitoring for job matches.
			</>
		);
	};
	const getCompletenessSubtext = complete => {
		if (complete < 100) return 'Complete your profile so we can accurately match and recommend job opportunities.';
		return 'Remember to keep your profile up-to-date so we can match you with the right opportunities.';
	};

	const closeCompletionBanner = () => {
		localStorage.setItem(`hide-completion-banner-${candidateID}`, true);
		setHideCompletionBanner(true);
	};

	const openResume = (ev, resume) => {
		ev.preventDefault();
		CandidatesAPI.downloadCandidateResume(candidateID, resume.document_id, resume.file_name);
	};

	const openUserVerification = () => {
		store.dispatch(
			SiteActions.showModal(<VerifyAccountModal onAdd={() => null} />, {
				className: 'modal-small',
				disableCloseButton: true,
				disableBackdropClick: true,
				disableEscapeKey: true,
			}),
		);
	};
	const openChangeLogin = () => {
		store.dispatch(
			SiteActions.showModal(<ChangeLoginModal />, {
				className: 'modal-small',
			}),
		);
	};

	const getCandidateResumes = () => {
		CandidatesAPI.getCandidateResumes(
			candidateID,
			{ per_page: 1, orderBy: 'sovren_parsed_docs.created_at', direction: 'desc' },
			response => {
				if (!response) {
					store.dispatch(
						SiteActions.showAlert('Could not load the candidate resume. Refresh the page to try again.', 'error'),
					);
					return;
				}
				setResumes(response.data.data);
			},
		);
	};

	const openAssessment = () => {
		if (candidate.assessment_completed_at || isMobile) return;

		// pageNums are 0, 1, 2, 2.5, 3, 3.5, and 4
		function getNextPage(currentPage) {
			let nextPage = 0;

			if (currentPage === -1) {
				return nextPage;
			}

			if (currentPage === 0 || currentPage === 1) {
				nextPage = currentPage + 1;
			} else {
				nextPage = currentPage + 0.5;
			}

			return nextPage;
		}
		const furthestCompletedPage = progress?.completed?.length ? Math.max(...progress.completed) : -1;
		const nextPage = getNextPage(furthestCompletedPage);
		store.dispatch(AssessmentActions.setPage(nextPage));
		dispatch(
			SiteActions.showModal(
				<CandidateAssessmentModal setCandidateProfile={setCandidate} getCandidateResumes={getCandidateResumes} />,
				{
					className: 'modal-large',
					disableBackdropClick: true,
				},
			),
		);
	};

	const openAssessmentRedirect = () => {
		dispatch(
			SiteActions.showModal(
				<AssessmentRedirectModal
					onContinue={openAssessment}
					setCandidateProfile={setCandidate}
					getCandidateResumes={getCandidateResumes}
				/>,
				{
					className: 'modal-small',
					disableBackdropClick: true,
				},
			),
		);
	};
	const handleClickCompleteProfile = () => {
		// if (assessmentDeffered) {
		// 	setAssessmentDeffered(false);
		// 	// openAssessmentRedirect();
		// }
		if (!candidate.assessment_completed_at) {
			openAssessment();
		} else if (!contactInfoPanelIsComplete) {
			Utility.scrollTo('ContactInfo');
			setTimeout(() => editContactInfoBtn.current.click(), 500);
		} else if (!preferencesPanelIsComplete) {
			Utility.scrollTo('Preferences');
			setTimeout(() => editPreferencesBtn.current.click(), 500);
		} else if (!locationPreferencesPanelIsComplete) {
			Utility.scrollTo('LocationPreferences');
			setTimeout(() => editLocationPreferencesBtn.current.click(), 500);
		} else if (!experiencePanelIsComplete) {
			Utility.scrollTo('Experience');
			setTimeout(() => editExperienceBtn.current.click(), 500);
		} else if (!skillsPanelIsComplete) {
			Utility.scrollTo('Skills');
			setTimeout(() => editSkillsBtn.current.click(), 500);
		} else if (!skillStoryPanelIsComplete) {
			Utility.scrollTo('SkillStory');
			setTimeout(() => editSkillStoryBtn.current.click(), 500);
		} else if (!achievementsPanelIsComplete) {
			Utility.scrollTo('Achievements');
			setTimeout(() => editAchievementsBtn.current.click(), 500);
		} else if (!workAuthorizationPanelIsComplete) {
			Utility.scrollTo('WorkAuthorization');
			setTimeout(() => editWorkAuthorizationBtn.current.click(), 500);
		} else if (!resumePanelIsComplete) {
			Utility.scrollTo('Resume');
			setTimeout(() => editResumeBtn.current.click(), 500);
		}
	};

	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		const urlParams = new URLSearchParams(location?.search);
		const url = urlParams.get('authUrl');

		// If we have an authUrl, authenticate with it.
		if (url) {
			// Attempt to manually parse url if unencoded
			// eslint-disable-next-line prefer-destructuring
			// if (!url.includes('timestamp')) url = location?.href?.split('authUrl=')[1];
			UserAPI.jwtLogin(url, (result, err) => {
				if (result && !result?.error) {
					// The user already has a claimed account: redirect them to login.
					if (result?.data?.user?.claimed_at) {
						const from = history.location.pathname.includes('/login')
							? Utility.getFromObj(history, 'location', 'state', 'redirect')
							: history.location.pathname;
						history.push('/candidate-login', { redirect: from });
					} else {
						// If the user has not completed the assessment, defer it.
						if (!result?.data?.user?.assessment_completed_at) {
							setAssessmentDeffered(true);
						}

						// The user does not have a claimed account: log them in.
						console.log('Storing the authentication credentials.');
						AuthenticationAPI.storeAssessmentCredentials(result.data, location.search, store);

						console.log('refetching user');
						refetchUser();
					}
				} else if (
					err.response?.data?.error === 'Issue count exceeded.' ||
					err?.response?.data?.error === 'Link expired'
				) {
					setDisplayExpiredLinkMessage(true);
				} else {
					console.log('Showing an alert.');
					store.dispatch(
						SiteActions.showAlert(
							'Could not authenticate with the provided link. Please try again or contact support.',
							'error',
						),
					);
				}
			});
		}
	}, []);

	// Get page data on load
	useEffect(() => {
		// Wait for the user to load before we get the candidate
		if (authUser && Object.keys(authUser || {}).length) {
			Promise.all([
				CandidatesAPI.getCandidate(candidateID, response => {
					if (!response) {
						// store.dispatch(
						// 	SiteActions.showAlert('Could not load the candidate. Refresh the page to try again.', 'error'),
						// );
						return;
					}
					const newCandidate = response.data.data;
					setCandidate(newCandidate);

					// console.log({
					// 	candidateId: newCandidate.id,
					// 	authUserCandidateId: authUser.candidate_id,
					// 	completedAt: newCandidate.assessment_completed_at,
					// });
					// Only open the candidate assessment if the user is the candidate
					if (
						(authUser.candidate_id === newCandidate.id ||
							authUser?.candidate?.id === newCandidate.id ||
							authUser.candidate_id === newCandidate.parent_record_id) &&
						!newCandidate.assessment_completed_at &&
						!assessmentDeffered
					) {
						openAssessment();
					}
				}),
				getCandidateResumes(),
				refetchMatches({ candidateId: candidateID }).then(async results => {
					const matches = results?.[0];

					// After refetching the matches, load each match individually
					// to get the listing detail data that's not included in the matches.
					if (matches?.length) {
						Promise.all(
							matches.map(match => {
								return CandidatesAPI.getCandidateMatch({
									candidateId: candidateID,
									matchId: match?.id,
								}).then(newMatch => {
									const newListing = newMatch?.listing;

									if (newListing) {
										setOverrideListings(x => {
											return {
												...x,
												[newListing.id]: newListing,
											};
										});
									}
									return null;
								});
							}),
						);
					}
					return null;
				}),
			])
				.then(() => console.log('finished loading all candidate data'))
				.catch(e => console.log(e))
				.finally(() => setIsLoading(false));
		}
	}, [Object.keys(authUser || {}).length, assessmentDeffered]);

	useEffect(() => {
		if (
			candidate?.assessment_completed_at && // Assessment was completed
			Object.keys(authUser || {})?.length > 0 && // The user has been fetched
			!authUser?.claimed_at && // The user has not claimed the account
			authUser.candidate_id === candidate?.id // The user is the candidate
		) {
			openUserVerification();
		}
	}, [candidate, authUser]);

	function TabPanel(props) {
		const { children, value, index, ...other } = props;

		return (
			<div hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
				{value === index && <div>{children}</div>}
			</div>
		);
	}

	if (displayExpiredLinkMessage) {
		return <ExpiredLinkMessage />;
	}

	if (isLoading || loadingMatches || Object.keys(candidate || {})?.length === 0) {
		return (
			<Page sidebar="none" key="empty-state">
				{alert.show ? (
					<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
						{alert.message}
					</Alert>
				) : (
					<PageLoading />
				)}
			</Page>
		);
	}

	return (
		<Page sidebar="none" key="page-state" centered>
			<Fence width={71.429} minWidth={71.429}>
				<VerticalSpacer height={2.5} />
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Panel id="ContactInfo" style={{ height: 'calc(100% - 2rem)' }}>
							<PanelHeader className="spread" style={{ alignItems: 'flex-start' }}>
								<div>
									<Typography variant="h2" className="font-lato-bold">
										{`${candidate.first_name || ''} ${candidate.last_name || ''}`}
									</Typography>
									<Typography>
										{candidate.city ? `${candidate.city}, ` : ''}
										{candidate.state || ''}
									</Typography>
								</div>
								{!!candidate.assessment_completed_at && (
									<Button
										ref={editContactInfoBtn}
										variant="outlined"
										color="primary"
										size="small"
										value={1}
										onClick={() => {
											dispatch(
												SiteActions.showModal(
													<EditContactInfoModal candidate={candidate} setCandidate={setCandidate} />,
													{ className: 'modal-medium modal-no-shadow' },
												),
											);
										}}
									>
										Edit
									</Button>
								)}
							</PanelHeader>
							<PanelContent>
								<VerticalSpacer height={1} />
								<Section>
									<Typography variant="body1" style={{ marginBottom: 8, display: 'flex', alignItems: 'center' }}>
										<EmailCircleIcon style={{ marginRight: '0.5rem' }} /> {candidate.email || Utility.nb}
									</Typography>
									<Typography variant="body1" style={{ marginBottom: 8, display: 'flex', alignItems: 'center' }}>
										<PhoneCircleIcon style={{ marginRight: '0.5rem' }} />{' '}
										{Utility.formatPhone(candidate.phone) || Utility.nb}
									</Typography>
								</Section>
								<Grid container>
									<Grid item xs={6}>
										<ButtonBase className="button-text" onClick={() => openChangeLogin()}>
											Change Login
										</ButtonBase>
									</Grid>
									<Grid item xs={6}>
										<VerticalSpacer height={0.75} />
										<Typography component="p" variant="caption" className="text-right grey-200 text-italic">
											Last updated {Utility.formatDate(candidate.updated_at)}
										</Typography>
									</Grid>
								</Grid>
							</PanelContent>
						</Panel>
					</Grid>
					<Grid item xs={6}>
						<Panel id="RecruiterInfo" style={{ height: 'calc(100% - 2rem)' }}>
							<PanelHeader>
								<Typography variant="h6" style={{ display: 'flex', alignItems: 'center' }} className="text-bold">
									<RecruiterIcon size={32} style={{ marginRight: '0.8rem' }} className="teal-100" />
									NauWork Recruiter
								</Typography>
							</PanelHeader>
							<PanelContent>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<Typography variant="body1" style={{ marginBottom: 8 }} className="text-medium">
											{candidate?.owner?.name || Utility.nb}
										</Typography>
										<Typography variant="body1" style={{ marginBottom: 8, display: 'flex', alignItems: 'center' }}>
											<EmailCircleIcon style={{ marginRight: '0.5rem' }} />
											{candidate?.owner?.email || Utility.nb}
										</Typography>
										<Typography variant="body1" style={{ marginBottom: 8, display: 'flex', alignItems: 'center' }}>
											<PhoneCircleIcon style={{ marginRight: '0.5rem' }} />
											{candidate?.owner?.phone || Utility.nb}
										</Typography>
										<Typography variant="body1" style={{ marginBottom: 8, display: 'flex', alignItems: 'center' }}>
											<LinkedinCircleIcon style={{ marginRight: '0.5rem' }} />
											{candidate?.owner?.linkedin ? (
												<a
													href={candidate?.owner?.linkedin}
													style={{ fontWeight: 400 }}
													target="_blank"
													rel="noreferrer"
												>
													LinkedIn Profile
												</a>
											) : (
												Utility.nb
											)}
										</Typography>
									</Grid>
									<Grid
										item
										xs={6}
										style={{
											display: 'flex',
											justifyContent: 'flex-end',
											alignItems: 'flex-end',
										}}
									>
										{candidate.owner?.calendar && (
											<Button
												variant="outlined"
												color="primary"
												size="small"
												value={1}
												onClick={() => window.open(candidate.owner?.calendar, '_blank')}
											>
												Schedule A Call
											</Button>
										)}
									</Grid>
								</Grid>
							</PanelContent>
						</Panel>
					</Grid>
				</Grid>
				{!hideCompletionBanner && (
					<Panel id="CompletionBanner" color="primary">
						{candidate.completeness >= 100 ? (
							<IconButton className="modal-close modal-close-white" onClick={() => closeCompletionBanner()}>
								<Icons.Close />
							</IconButton>
						) : null}
						<PanelContent
							className={candidate.assessment_completed_at ? 'stack-left' : 'text-center'}
							style={{ padding: '3.5rem 5rem' }}
						>
							{candidate.assessment_completed_at ? (
								<Box position="relative" display="inline-flex" marginRight="2rem">
									<ProgressCircle size={130} value={Utility.getFromObj(candidate, 'completeness') || 0} />
									<Box
										top={0}
										left={0}
										bottom={0}
										right={0}
										position="absolute"
										display="flex"
										alignItems="center"
										justifyContent="center"
										flexDirection="column"
									>
										<Typography variant="h2" component="div" color="inherit">
											{`${candidate.completeness || 0}%`}
										</Typography>
										<Typography variant="body1" component="div" color="inherit">
											Complete
										</Typography>
									</Box>
								</Box>
							) : null}
							<div style={{ marginLeft: `${candidate.assessment_completed_at ? '4rem' : 0}` }}>
								<Typography variant="h6" color="inherit" className="text-bold">
									{getCompletenessText(candidate?.completeness || 0)}
								</Typography>
								<Typography variant="body2" color="inherit">
									{getCompletenessSubtext(candidate?.completeness || 0)}
								</Typography>
								{candidate.completeness < 100 ? (
									<>
										<VerticalSpacer height={1.5} />
										<Button
											variant="contained"
											color="primary"
											className="no-margins color-invert"
											onClick={() => handleClickCompleteProfile()}
										>
											Complete Your Profile
										</Button>
									</>
								) : null}
							</div>
						</PanelContent>
					</Panel>
				)}
				<Tabs
					value={tab}
					onChange={(_, newTab) => setTab(newTab)}
					indicatorColor="primary"
					style={{ borderBottom: '1px solid #ccc', marginBottom: '2rem' }}
				>
					{isJobMatchesShown && (
						<Tab
							label={
								<Typography variant="body1" color={tab === 0 ? 'primary' : 'textSecondary'}>
									Job Matches
								</Typography>
							}
						/>
					)}
					<Tab
						label={
							<Typography variant="body1" color={tab === (isJobMatchesShown ? 1 : 0) ? 'primary' : 'textSecondary'}>
								Preferences
							</Typography>
						}
					/>
					<Tab
						label={
							<Typography variant="body1" color={tab === (isJobMatchesShown ? 2 : 1) ? 'primary' : 'textSecondary'}>
								Experience
							</Typography>
						}
					/>
				</Tabs>
				{isJobMatchesShown && (
					<TabPanel value={tab} index={0}>
						{/* {process.env.REACT_APP_FEATURE_MATCHING ? ( */}
						<CuratedMatchesPanel
							overrideListings={overrideListings}
							candidateId={candidateID}
							onClickOverride={candidate?.assessment_completed_at ? null : openAssessmentRedirect}
						/>
						{/* ) : null} */}
					</TabPanel>
				)}
				<TabPanel value={tab} index={isJobMatchesShown ? 1 : 0}>
					<PreferencesBlock
						candidate={candidate}
						setCandidate={setCandidate}
						buttonRef={editPreferencesBtn}
						isComplete={preferencesPanelIsComplete}
						data={data}
					/>
					<LocationPreferencesBlock
						candidate={candidate}
						setCandidate={setCandidate}
						data={data}
						buttonRef={editLocationPreferencesBtn}
						isComplete={locationPreferencesPanelIsComplete}
					/>
				</TabPanel>
				<TabPanel value={tab} index={isJobMatchesShown ? 2 : 1}>
					<ExperienceBlock
						candidate={candidate}
						setCandidate={setCandidate}
						data={data}
						buttonRef={editExperienceBtn}
						isComplete={experiencePanelIsComplete}
					/>
					{/* Skills */}
					<Panel id="Skills">
						<PanelHeader className="spread">
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<SkillsIcon size={32} className="teal-100" />
								<Typography variant="h6" component="h2" className="text-medium" style={{ paddingLeft: 10 }}>
									Skills
								</Typography>
							</div>
							{!!candidate.assessment_completed_at && (
								<Button
									ref={editSkillsBtn}
									variant={skillsPanelIsComplete ? 'outlined' : 'containedTeal'}
									color="primary"
									size="small"
									onClick={() => {
										dispatch(
											SiteActions.showModal(<EditSkillsModal candidate={candidate} setCandidate={setCandidate} />, {
												className: 'modal-medium modal-no-padding modal-no-shadow',
											}),
										);
									}}
								>
									{skillsPanelIsComplete ? 'Edit' : 'Complete'}
								</Button>
							)}
						</PanelHeader>
						<PanelContent style={{ paddingInline: '63px' }}>
							<VerticalSpacer height={1} />
							{bestSkills?.length > 0 && (
								<>
									<Typography className="grey-250">Best Skills</Typography>
									<VerticalSpacer height={0.75} />
									{!!bestSkills &&
										bestSkills.map(skill => <Chip key={skill.id} label={skill.skill.label} color="primary" />)}
									<VerticalSpacer height={2} />
								</>
							)}
							{advancedSkills?.length > 0 && (
								<>
									<Typography className="grey-250">Advanced Skills</Typography>
									<VerticalSpacer height={0.75} />
									{!!advancedSkills &&
										advancedSkills.map(skill => <Chip key={skill.id} label={skill.skill.label} color="primary" />)}
									<VerticalSpacer height={2} />
								</>
							)}
							{intermediateSkills?.length > 0 && (
								<>
									<Typography className="grey-250">Intermediate Skills</Typography>
									<VerticalSpacer height={0.75} />
									{!!intermediateSkills &&
										intermediateSkills.map(skill => <Chip key={skill.id} label={skill.skill.label} color="primary" />)}
									<VerticalSpacer height={2} />
								</>
							)}
							{basicSkills?.length > 0 && (
								<>
									<Typography className="grey-250">Basic Skills</Typography>
									<VerticalSpacer height={0.75} />
									{!!basicSkills &&
										basicSkills.map(skill => <Chip key={skill.id} label={skill.skill.label} color="primary" />)}
								</>
							)}
						</PanelContent>
					</Panel>

					{/* Skill Story */}
					<Panel id="SkillStory">
						<PanelHeader className="spread">
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<SkillStoryIcon size={32} className="teal-100" />
								<Typography variant="h6" component="h2" className="text-medium" style={{ paddingLeft: 10 }}>
									Skill Story
								</Typography>
							</div>
							{!!candidate.assessment_completed_at && (
								<Button
									ref={editSkillStoryBtn}
									variant={skillStoryPanelIsComplete ? 'outlined' : 'containedTeal'}
									color="primary"
									size="small"
									onClick={() => {
										dispatch(
											SiteActions.showModal(<EditSkillStoryModal candidate={candidate} setCandidate={setCandidate} />, {
												className: 'modal-medium modal-no-padding modal-no-shadow',
											}),
										);
									}}
								>
									{skillStoryPanelIsComplete ? 'Edit' : 'Complete'}
								</Button>
							)}
						</PanelHeader>
						<PanelContent style={{ paddingInline: '63px' }}>
							<VerticalSpacer height={1} />
							{getTheJobSkills?.length > 0 && (
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<Typography className="grey-250">Land the Job</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography className="grey-250">Experience</Typography>
									</Grid>
									{!!getTheJobSkills &&
										getTheJobSkills?.map(skill => (
											<Fragment key={skill.skill_id}>
												<Grid item xs={6}>
													<Chip label={skill.skill.label} color="primary" className="no-bottom-margin" />
												</Grid>
												<Grid item xs={6}>
													<Typography>
														{typeof skill.experience_years === 'number'
															? `${skill.experience_years} year${skill.experience_years !== 1 ? 's' : ''}`
															: ''}
													</Typography>
												</Grid>
											</Fragment>
										))}
								</Grid>
							)}
							{enjoyTheJobSkills?.length > 0 && (
								<>
									<VerticalSpacer height={3} />
									<Typography className="grey-250">Enjoy Using</Typography>
									<VerticalSpacer height={0.75} />
									{!!enjoyTheJobSkills &&
										enjoyTheJobSkills.map(skill => <Chip key={skill.id} label={skill.skill.label} color="primary" />)}
								</>
							)}
							{improveOnTheJobSkills.length > 0 && (
								<>
									<VerticalSpacer height={2} />
									<Typography className="grey-250">Improve</Typography>
									<VerticalSpacer height={0.75} />
									{!!improveOnTheJobSkills &&
										improveOnTheJobSkills.map(skill => (
											<Chip key={skill.id} label={skill.skill.label} color="primary" />
										))}
								</>
							)}
							{learnOnTheJobSkills.length > 0 && (
								<>
									<VerticalSpacer height={2} />
									<Typography className="grey-250">Learn</Typography>
									<VerticalSpacer height={0.75} />
									{!!learnOnTheJobSkills &&
										learnOnTheJobSkills.map(skill => <Chip key={skill.id} label={skill.skill.label} color="primary" />)}
								</>
							)}
						</PanelContent>
					</Panel>

					<AchievementsAndInterests
						candidate={candidate}
						setCandidate={setCandidate}
						buttonRef={editAchievementsBtn}
						isComplete={achievementsPanelIsComplete}
					/>
					<WorkAuthorization
						candidate={candidate}
						setCandidate={setCandidate}
						buttonRef={editWorkAuthorizationBtn}
						isComplete={workAuthorizationPanelIsComplete}
					/>

					{/* Resume */}
					<Panel id="Resume">
						<PanelHeader className="spread">
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<ResumeIcon size={32} className="teal-100" />
								<Typography variant="h6" component="h2" className="text-medium" style={{ paddingLeft: 10 }}>
									Resume
								</Typography>
							</div>
							{!!candidate.assessment_completed_at && (
								<Button
									ref={editResumeBtn}
									variant={resumePanelIsComplete ? 'outlined' : 'containedTeal'}
									color="primary"
									size="small"
									onClick={() => {
										dispatch(
											SiteActions.showModal(
												<EditResumeModal candidate={candidate} setCandidate={setCandidate} setResumes={setResumes} />,
												{ className: 'modal-medium modal-no-padding modal-no-shadow' },
											),
										);
									}}
								>
									{resumePanelIsComplete ? 'Update Resume' : 'Complete'}
								</Button>
							)}
						</PanelHeader>
						<PanelContent style={{ paddingInline: '63px' }}>
							<VerticalSpacer height={1} />
							{resumes.map(resume => (
								<Grid container spacing={2} key={`resume-${resume.document_id}`}>
									<Grid item xs={6} key={resume.document_id}>
										<Typography className="grey-250">File Name</Typography>
										<a
											href="#0"
											style={{
												fontWeight: 300,
												textDecoration: 'underline',
											}}
											onClick={ev => openResume(ev, resume)}
										>
											{resume.file_name}
										</a>
									</Grid>
									<Grid item xs={6}>
										<Typography className="grey-250">Date Provided</Typography>
										<Typography>
											<i>{Utility.formatDate(resume.updated_at, false, 'MMMM D, YYYY')}</i>
										</Typography>
									</Grid>
								</Grid>
							))}
							{resumes?.length === 0 ? 'No Resume' : ''}
						</PanelContent>
					</Panel>
				</TabPanel>
			</Fence>
		</Page>
	);
};

const CuratedMatchesPanel = ({ candidateId, onClickOverride, overrideListings }) => {
	const store = useStore();
	const dispatch = useDispatch();

	// const [matches, setMatches] = useState(mockListings);

	// const [loadingMatches, setLoadingMatches] = useState(true);

	const { candidateMatches, loadingMatches, refetchMatches } = useContextStore();

	const [activePageNum, setActivePageNum] = useState(1);
	const numItems = candidateMatches?.filter(x => x.is_published)?.length;
	const pageSize = 3;
	const numPages = Math.ceil(numItems / pageSize);

	// Fetch some jobs.
	// Get mock listings list
	// useEffect(() => {
	/*
		ListingsAPI.getListings({}, response => {
			// console.log({ response });
			setLoadingListings(false);
			setListings(response?.data?.data);
		});
		*/
	// }, []);

	const matches = candidateMatches
		?.filter(x => x.is_published)
		?.sort((a, b) => {
			return moment(b?.auth_link?.created_at) - moment(a?.auth_link?.created_at);
		})
		.slice((activePageNum - 1) * pageSize, activePageNum * pageSize);

	if (!matches?.length) {
		return null;
	}

	return (
		<Panel id="CuratedMatches" Icon={<MatchIcon size={32} className="teal-100" />}>
			<PanelHeader className="spread">
				<div>
					<Typography variant="h6" component="h2" className="text-medium">
						Curated Matches
					</Typography>
					<Typography>Here are some matches we&apos;ve identified for you. Please rate your interest.</Typography>
				</div>
			</PanelHeader>
			<PanelContent style={{ paddingInline: '63px' }}>
				{loadingMatches ? null : (
					<div>
						{matches

							?.filter(x => x.is_published)
							?.map((match, index) => (
								<ListingCard
									key={match.id}
									index={index}
									match={match}
									overrideListing={overrideListings[match?.listing?.id]}
									onClick={() => {
										if (onClickOverride) {
											onClickOverride(match);
										} else {
											store.dispatch(
												SiteActions.showModal(
													<SharedJobModal
														match={match}
														candidateId={candidateId}
														overrideListing={overrideListings[match?.listing?.id]}
														refetchMatches={refetchMatches}
													/>,
													{
														className: 'modal-medium match-share-modal',
														// disableCloseButton: true,
														// disableBackdropClick: true,
														// disableEscapeKey: true,
													},
												),
											);
										}
									}}
								/>
							))}
						<Divider style={{ marginTop: 20, marginBottom: 25 }} />
						<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
							<Typography style={{ paddingRight: 10 }}>
								{activePageNum * pageSize - pageSize + 1}-{Math.min(numItems, activePageNum * pageSize)} of {numItems}
							</Typography>
							<Pagination
								color="primary"
								shape="rounded"
								count={numPages}
								// page={activePageNum}
								onChange={(event, number) => {
									setActivePageNum(number);
								}}
							/>
						</div>
					</div>
				)}
				<div style={{ marginTop: 0 }} />
			</PanelContent>
		</Panel>
	);
};

const ListingCard = ({ match, overrideListing, index, onClick }) => {
	const isLimited = match?.access_level?.id !== 3;

	const isNew = false; // index === 0;
	const { data } = useSelector(state => state.site, shallowEqual);

	const listing = overrideListing || match?.listing;

	if (!listing) {
		return null;
	}

	const consolidatedLocationPreferences = Utility.getConsolidatedLocationPreferences(
		listing?.work_location_preferences,
	);

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div
			className="listing-card"
			style={{
				padding: 20,
				borderRadius: 3,
				// boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.15)',
				marginBottom: 15,
				backgroundColor: isNew ? '#FFFBEC' : '#fff',
				cursor: 'pointer',
				border: '1px solid #ddd',
				position: 'relative',
			}}
			onClick={onClick}
		>
			<div>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div style={{ display: 'flex', flex: 1 }}>
						<div
							style={{
								border: '2px solid #52a3ff',
								width: 55,
								height: 55,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								borderRadius: '50%',
							}}
						>
							<CaseIcon size={24} color="#52a3ff" />
						</div>
						<div style={{ width: 15 }} />
						<div>
							<div style={{ height: 3 }} />
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Typography variant="h2" component="h2" className="text-medium" style={{ fontSize: '1.2rem' }}>
									{listing.position_title}
									{/* at{' '}
									{isLimited ? (
										<span
											style={{
												filter: isLimited ? 'blur(4px)' : 'none',
												webkitFilter: isLimited ? 'blur(4px)' : 'none',
											}}
										>
											CompanyName
										</span>
									) : (
										<span>{listing.company}</span>
									)} */}
								</Typography>
							</div>

							<div style={{ height: 5 }} />
							<Typography className="grey-250">
								{/* Salary:{' '}
								{isLimited ? (
									<span
										style={{
											filter: isLimited ? 'blur(3px)' : 'none',
											webkitFilter: isLimited ? 'blur(3px)' : 'none',
										}}
									>
										$XXX,XXX - $XXX,XXX
									</span>
								) : (
									<>
										{Utility.formatMoney(listing.salary_rate_min)} - {Utility.formatMoney(listing.salary_rate_max)}
									</>
								)}
								<span> | </span> */}
								{listing.professional_experience_years || 0}+ years experience
								{consolidatedLocationPreferences?.length ? ` | ${consolidatedLocationPreferences?.join(', ')}` : null}
								{/* | {listing.total_leadership_years || 0}+
								years leadership */}
							</Typography>
							<Typography className="grey-250">
								{/* {listing.state ? `| ${listing?.state}` : null} Founded{' '}
								{listing?.client_corporation?.year_founded || 'N/A'} |{' '} */}
								{listing?.client_corporation?.business_sizes?.[0]?.label || ''}
								{listing?.client_corporation?.business_sizes?.[0]?.label &&
								listing?.client_corporation?.industries?.[0]?.label
									? ' | '
									: ''}
								{listing?.industries?.[0]?.label || ''}
							</Typography>

							<div style={{ height: 10 }} />
							<div style={{ display: 'flex', alignItems: 'flex-end' }}>
								{/* <LabeledField
									label="Work Preference"
									className="color-muted"
									removeMargin
									close
									style={{ minWidth: 120 }}
								>
									<Typography variant="body2">
										{Utility.getItemLabel(data.employmentPreferences, listing.role_type) || Utility.nb}
									</Typography>
								</LabeledField> */}
								<Grid item xs={12} style={{ marginBottom: -12 }}>
									{(listing.skills || [])
										.filter(x => x.hero_skill)
										.map(skill => (
											<SkillChip
												baseColor="blue"
												key={skill?.id}
												skill={{
													...skill,
													required_skill: true,
												}}
											/>
										))}
								</Grid>
							</div>
						</div>
					</div>
					<div>
						{match?.auth_link?.created_at ? (
							<Typography
								className="grey-250"
								style={{
									fontStyle: 'italic',
									textAlign: 'right',
									marginBottom: 5,
								}}
							>
								Shared on {moment(match?.auth_link?.created_at).format('MM/DD/YY')}
							</Typography>
						) : null}

						<ListingStatusChip listing={listing} />
					</div>
				</div>
			</div>
			{!match?.user_viewed_at ? (
				<div
					style={{
						position: 'absolute',
						left: -1,
						top: 4,
						backgroundColor: '#B3F3EF',
						padding: '3px 6px',
						fontWeight: 500,
						borderTopRightRadius: 4,
						borderBottomRightRadius: 4,
						color: '#105B56',
					}}
				>
					NEW
				</div>
			) : !match?.interest_level_id ? (
				<div
					style={{
						position: 'absolute',
						left: -1,
						top: 4,
						backgroundColor: '#D2D7DE',
						padding: '3px 6px',
						fontWeight: 500,
						borderTopRightRadius: 4,
						borderBottomRightRadius: 4,
					}}
				>
					UNRANKED
				</div>
			) : null}
		</div>
	);
};

const AssessmentRedirectModal = ({ onContinue }) => {
	return (
		<div>
			<Typography variant="h3" component="h3" className="text-medium" style={{ paddingRight: 25 }}>
				One more step!
			</Typography>
			<div style={{ height: 20 }} />
			<Typography className="text-medium" style={{ paddingRight: 25 }}>
				To view the curated matches, please complete your profile.
			</Typography>
			<div style={{ height: 20 }} />
			<Button
				variant="contained"
				color="primary"
				// startIcon={<Icons.Link />}
				endIcon={<Icons.ArrowForward />}
				onClick={() => {
					onContinue();
				}}
				style={{ marginLeft: 0 }}
			>
				Complete My Profile
			</Button>
		</div>
	);
};

export default CandidateProfile;
