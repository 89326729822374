import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStore, useSelector, shallowEqual, useDispatch } from 'react-redux';
import moment from 'moment';
import {
	Typography,
	TextField,
	Fab,
	CircularProgress,
	Box,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Switch,
	Button,
} from '@material-ui/core';

import * as Icons from '@material-ui/icons';
import LoadingButton from '../form/LoadingButton';
import CheckboxInput from '../form/inputs/CheckboxInput';
import SharedCandidateModal from './SharedCandidateModal';
import SharedJobModal from './SharedJobModal';
import Dropdown from '../form/dropdowns/Dropdown';

import LaughReaction from '../icons/Reactions/Laugh';
import SmileReaction from '../icons/Reactions/Smile';
import NeutralReaction from '../icons/Reactions/Neutral';
import SadReaction from '../icons/Reactions/Sad';

import { ActionCreators as SiteActions } from '../../store/Site';
import * as CandidatesAPI from '../../scripts/candidates';
import * as ListingsAPI from '../../scripts/listings';
import LabeledField from '../form/LabeledField';
import JobAssessmentSidebar from '../page/sidebar/JobAssessmentSidebar';

const SharePermissionsModal = ({ job, setJob, candidate, setCandidate, match, onAdd, onClose, ...props }) => {
	const { data } = useSelector(state => state.site, shallowEqual);
	const dispatch = useDispatch();

	const [jobOptions, setJobOptions] = useState([]);

	const store = useStore();
	const [isLoading, setLoading] = useState(false);

	const { user } = useSelector(state => state.site, shallowEqual);

	const currentObj = job || candidate;
	const [isSharable, setIsSharable] = useState(currentObj?.is_sharable);

	const approvedUser = currentObj?.sharable_allowed_by_user || user;
	const approvedDate = currentObj?.sharable_allowed_at ? moment(currentObj?.sharable_allowed_at) : moment();

	const onSave = async () => {
		if (job) {
			let res;
			if (isSharable) {
				res = await ListingsAPI.allowShare({
					listingId: job.id,
				});
			} else {
				res = await ListingsAPI.denyShare({
					listingId: job.id,
				});
			}
			if (res) {
				console.log(res);
				setJob({
					...res.data.data,
				});
			}
		} else if (candidate) {
			let res;
			if (isSharable) {
				res = await CandidatesAPI.allowShare({
					candidateId: candidate.id,
				});
			} else {
				res = await CandidatesAPI.denyShare({
					candidateId: candidate.id,
				});
			}
			if (res) {
				console.log(res);
				setCandidate({
					...res.data.data,
				});
			}
		}
	};

	return (
		<div style={{ textAlign: 'center' }}>
			<Typography variant="h2" style={{ marginBottom: 5 }}>
				Share Settings
			</Typography>
			<Typography variant="body2" style={{ marginBottom: 15 }}>
				Use the toggle to change the share state of this record.
			</Typography>

			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<LabeledField
					label={isSharable ? 'This record may be shared' : 'This record is not shareable'}
					style={{
						display: 'flex',
						flexDirection: 'row-reverse',
						alignItems: 'flex-end',
						justifyContent: 'flex-end',
					}}
				>
					<Switch
						checked={isSharable}
						disabled={isLoading}
						color="primary"
						onChange={ev => setIsSharable(!isSharable)}
						name="dryRun"
					/>
				</LabeledField>

				<div style={{ paddingTop: 30 }}>
					<Button
						variant="outlined"
						color="primary"
						size="small"
						style={{}}
						startIcon={<Icons.Visibility style={{ width: 20 }} />}
						onClick={() => {
							if (candidate) {
								dispatch(
									SiteActions.showModal(
										<SharedCandidateModal
											candidateId={candidate?.id}
											overrideCandidate={candidate}
											refetchMatches={() => {}}
											hideRating
											adminView
										/>,
										{
											className: 'modal-medium match-share-modal',
											// disableCloseButton: true,
											// disableBackdropClick: true,
											// disableEscapeKey: true,
										},
									),
								);
							} else if (job) {
								dispatch(
									SiteActions.showModal(
										<SharedJobModal
											jobId={job?.id}
											overrideListing={job}
											adminView
											refetchMatches={() => {}}
											hideRating
										/>,
										{
											className: 'modal-medium match-share-modal',
											// disableCloseButton: true,
											// disableBackdropClick: true,
											// disableEscapeKey: true,
										},
									),
								);
							}
						}}
					>
						Preview
					</Button>
				</div>
			</div>

			<Typography variant="caption" style={{ fontStyle: 'italic', width: '100%', display: 'block', textAlign: 'left' }}>
				{isSharable ? (
					<>
						Approved by {approvedUser?.first_name} {approvedUser?.last_name} on {approvedDate.format('MMMM Do YYYY')}
					</>
				) : (
					''
				)}
			</Typography>

			<div style={{ height: 15 }} />
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-end',
				}}
			>
				<LoadingButton
					loading={isLoading}
					variant="outlined"
					color="primary"
					onClick={async () => {
						onClose();
					}}
				>
					Cancel
				</LoadingButton>
				<div style={{ width: 10 }} />
				<LoadingButton
					loading={isLoading}
					variant="contained"
					color="primary"
					disabled={isSharable === currentObj?.is_sharable}
					onClick={async () => {
						setLoading(true);
						try {
							await onSave();
							dispatch(SiteActions.hideModal());
						} catch (e) {
							console.error(e);
						} finally {
							setLoading(false);
						}
					}}
				>
					Save
				</LoadingButton>
			</div>
		</div>
	);
};

export default SharePermissionsModal;
