import React from 'react';
import PropTypes from 'prop-types';
import {
	Typography,
	IconButton,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Chip,
	Switch,
	Button,
	Box,
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';

export const Filter = ({ open, title, children, onClose, onReset, ...props }) => {
	return (
		<div className={`filter ${open ? 'open' : ''}`} {...props}>
			<div className="filter-title spread">
				<Typography variant="h2">{title}</Typography>
				<IconButton onClick={onClose}>
					<Icons.Close color="primary" />
				</IconButton>
			</div>
			{/* {onReset ? (
				<Box className="filter-reset" textAlign="right">
					<Button variant="text" onClick={onReset}>
						Clear All
					</Button>
				</Box>
			) : null} */}
			{children}
		</div>
	);
};

Filter.propTypes = {
	open: PropTypes.bool,
	title: PropTypes.string,
	children: PropTypes.node,
	onClose: PropTypes.func,
	onReset: PropTypes.func,
};

Filter.defaultProps = {
	open: false,
	title: 'Filters',
	children: null,
	onClose: () => {},
	onReset: () => {},
};

export const FilterItem = ({
	setFilter,
	propName,
	hideClear,
	label,
	count,
	active,
	alwaysOpen,
	toggle,
	onToggle,
	children,
	onUpdate,
	...props
}) => {
	return (
		<Accordion square expanded={alwaysOpen ? true : undefined} elevation={0}>
			<AccordionSummary expandIcon={alwaysOpen ? null : <Icons.ExpandMore color="primary" />}>
				<div className="spread" style={{ width: '100%' }}>
					<span className={`filter-item-label ${count || active ? '' : 'color-muted'}`}>{label}</span>
					{count ? <Chip color="primary" size="small" className="no-margins" label={count} /> : null}
					{toggle ? <Switch color="primary" onChange={onToggle} /> : null}
				</div>
			</AccordionSummary>
			<AccordionDetails>
				<div style={{ width: '100%' }}>
					{children}
					{!hideClear ? (
						<Box>
							<Button
								variant="outlined"
								onClick={() => {
									setFilter(filter => {
										const newFilters = { ...filter?.filters };

										// If propName is an array of strings, delete each one.
										if (typeof propName === 'object' && propName?.length) {
											propName.forEach(prop => {
												console.log(`Deleting  ${prop}`);
												delete newFilters[prop];
											});
										} else {
											// Otherwise propName is just a single string.
											delete newFilters[propName];
										}
										return { ...filter, filters: newFilters };
									});
									if (onUpdate) onUpdate();
								}}
								style={{
									outline: 'none',
									border: 'none',
									marginLeft: -20,
									fontWeight: 300,
								}}
							>
								Clear
							</Button>
						</Box>
					) : null}
				</div>
			</AccordionDetails>
		</Accordion>
	);
};

FilterItem.propTypes = {
	label: PropTypes.string,
	count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	active: PropTypes.bool,
	alwaysOpen: PropTypes.bool,
	toggle: PropTypes.bool,
	onToggle: PropTypes.func,
	children: PropTypes.node,
	setFilter: PropTypes.func,
	hideClear: PropTypes.bool,
	propName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

FilterItem.defaultProps = {
	label: null,
	count: null,
	active: null,
	alwaysOpen: null,
	toggle: null,
	onToggle: () => null,
	children: null,
	setFilter: null,
	hideClear: false,
	propName: '',
};

export default Filter;
