import React, { useRef, useState, useEffect } from 'react';
import { useStore, useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import BullhornIcon from '../icons/BullhornIcon';
import LinkedinIcon from '../icons/LinkedinIcon';
import { ActionCreators as SiteActions } from '../../store/Site';
import SharePermissionsModal from '../modals/SharePermissionsModal';

const JobLogoLinks = ({ job, setJob }) => {
	const dispatch = useDispatch();

	if (!job) {
		return null;
	}

	const bid = job?.vendor_job_id;
	const bullhornLink = `https://cls30.bullhornstaffing.com/BullhornSTAFFING/OpenWindow.cfm?Entity=JobOrder&id=${bid}`;

	const isShareable = job?.is_sharable;
	return (
		<div style={{ display: 'inline-block', marginLeft: 10 }}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<div style={{ width: 8 }} />
				{job?.vendor_job_id ? (
					<a href={job?.vendor_job_id ? bullhornLink : '#'} target="_blank" rel="noopener noreferrer">
						<Button
							variant="text"
							color="#000"
							size="small"
							style={{ margin: 0, padding: 4, minWidth: 0, borderRadius: 4 }}
						>
							<BullhornIcon color="#F2591A" width={20} height={20} />
						</Button>
					</a>
				) : (
					<div style={{ opacity: 0.2 }}>
						<Button
							variant="text"
							color="#000"
							size="small"
							style={{ margin: 0, padding: 4, minWidth: 0, borderRadius: 4 }}
						>
							<BullhornIcon color="#F2591A" width={20} height={20} />
						</Button>
					</div>
				)}
				<div style={{ width: 8 }} />

				<Button
					variant="text"
					color="#000"
					size="small"
					style={{ margin: 0, padding: 4, minWidth: 0, borderRadius: 4 }}
					onClick={() => {
						dispatch(
							SiteActions.showModal(
								<SharePermissionsModal job={job} setJob={setJob} onClose={() => dispatch(SiteActions.hideModal())} />,
								{
									className: 'modal-small modal-no-shadow',
								},
							),
						);
					}}
				>
					<Icons.Share style={{ color: isShareable ? '#00bb00' : '#ff2222' }} />
				</Button>
			</div>
		</div>
	);
};

export default JobLogoLinks;
