import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useStore, useSelector, shallowEqual } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
	Typography,
	Card,
	Button,
	Chip,
	IconButton,
	Menu,
	MenuItem,
	ListItemIcon,
	Tooltip,
	Grid,
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import { useParams } from 'react-router';

import LabeledField from '../../components/form/LabeledField';
import Page from '../../components/page/Page';
import * as PageHeader from '../../components/page/PageHeader';
import Table from '../../components/Table';
import JobDeleteModal from '../../components/modals/JobDeleteModal';
import JobFilters from '../../components/filters/JobFilters';
import { SkillChip } from './CandidateBlocks/ViewSkillsBlock';

import { ActionCreators as SiteActions } from '../../store/Site';
import * as ListingsAPI from '../../scripts/listings';
import * as Utility from '../../scripts/utility';
import { palette } from '../../BobcatTheme';
import * as CandidatesAPI from '../../scripts/candidates';
import CheckboxInput from '../../components/form/inputs/CheckboxInput';
import JobMatchCardItem from '../../components/matching/JobMatchCardItem';
import JobCardList from '../../components/matching/JobCardList';
import { useContextStore } from '../../store/ContextStore';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const CandidateMatches = () => {
	const store = useStore();

	useDocumentTitle('Recommended Jobs | NauMatch');

	const { data, alert, user } = useSelector(state => state.site, shallowEqual);
	const [filter, setFilter] = Utility.useStickyState({ isOpen: false, filters: {} }, 'admin-candidate-matches-filter');
	const table = useRef();
	// const [graphMatches, setGraphMatches] = useState([]);

	const [activeSaveFilterId, setActiveSaveFilterId] = Utility.useStickyState(
		null,
		'admin-recommended-jobs-active-save-filter-id',
	);
	const [savedFilters, setSavedFilters] = Utility.useStickyState([], 'admin-recommended-jobs-saved-filtersets');

	const {
		candidateMatches: matches,
		setCandidateMatches: setMatches,
		refetchMatches,
		graphMatches,
		setGraphMatches,
	} = useContextStore();

	const numFilters = Object.values(filter.filters || {}).filter(x => x).length;

	const openFilter = () => {
		setFilter({ ...filter, isOpen: true });
	};

	const closeFilter = () => {
		setFilter({ ...filter, isOpen: false });
	};

	const handleClearFilters = () => {
		setFilter({ ...filter, filters: {} });
	};

	const updateFilters = useCallback(
		(ev, name) => {
			if (typeof ev === 'object' && typeof name === 'string') {
				setFilter({ ...filter, filters: { ...filter.filters, [name]: ev } });
			} else if (ev.target.type === 'checkbox') {
				setFilter({
					...filter,
					filters: {
						...filter.filters,
						[ev.target.name]: ev.target.checked ? ev.target.value : undefined,
					},
				});
			} else if (ev.target.value !== null && ev.target.value !== undefined && ev.target.value !== '') {
				setFilter({
					...filter,
					filters: { ...filter.filters, [ev.target.name]: ev.target.value },
				});
			} else {
				setFilter({ ...filter, filters: { ...filter.filters, [ev.target.name]: undefined } });
			}
		},
		[filter, setFilter],
	);

	// const sendAssessment = ev => {
	// 	setMenu({ ...menu, anchor: null });

	// 	ListingsAPI.sendAssessment(menu.listing.id, (response, err) => {
	// 		if (!response) {
	// 			let message = 'The job assessment could not be sent. Please try again.';
	// 			if (err === 'contact') message = 'A contact could not be found for this job. The assessment was not sent.';

	// 			store.dispatch(SiteActions.showAlert(message, 'error'));
	// 			return;
	// 		}

	// 		store.dispatch(SiteActions.showAlert('The job assessment was sent.', 'success'));
	// 	});
	// };

	const params = useParams();
	const candidateID = params.id;
	const [candidate, setCandidate] = useState(null);
	const [listings, setListings] = useState([]);
	const [loadingListings, setLoadingListings] = useState(true);
	const [menu, setMenu] = useState({ anchor: null, listing: {} });

	// console.log({ filter });

	const filteredGraphMatches = graphMatches?.filter((graphMatch, i) => {
		const filters = filter?.filters;
		const listing = graphMatch?.listing;
		const existingMatch = matches?.find(match => match?.listing?.id === listing?.id);

		if (!filters) {
			return true;
		}

		if (i === 0) {
			console.log({ candidate, filters });
		}

		let passFilter = true;

		if (filters.match_percentage_min !== undefined) {
			try {
				passFilter = passFilter && (graphMatch?.overall_match_score || 0) * 100 >= filters?.match_percentage_min;
			} catch (e) {
				console.log('Error parsing match_percentage_min', e);
			}
		}
		if (filters.completeness_score_overall !== undefined) {
			try {
				passFilter =
					passFilter && (graphMatch?.completeness_score_overall || 0) * 100 >= filters?.completeness_score_overall;
			} catch (e) {
				console.log('Error parsing completeness_score_overall', e);
			}
		}
		if (filters.total_skill_score !== undefined) {
			try {
				passFilter = passFilter && (graphMatch?.total_skill_score || 0) * 100 >= filters?.total_skill_score;
			} catch (e) {
				console.log('Error parsing total_skill_score', e);
			}
		}
		if (filters.is_open !== undefined) {
			passFilter = passFilter && listing?.is_open === filters?.is_open;
		}
		if (filters.position_title !== undefined) {
			passFilter = passFilter && listing?.position_title?.includes(filters?.position_title);
		}
		if (filters.professional_experience_years_max !== undefined) {
			passFilter = passFilter && listing?.professional_experience_years <= filters?.professional_experience_years_max;
		}
		if (filters.professional_experience_years_min !== undefined) {
			passFilter = passFilter && listing?.professional_experience_years <= filters?.professional_experience_years_min;
		}
		if (filters.hero_skills !== undefined && filters?.hero_skills?.length) {
			passFilter = passFilter && listing?.skills?.some(x => filters?.hero_skills?.includes(x?.id) && x?.hero_skill);
		}
		if (filters.skills !== undefined && filters?.skills?.length) {
			passFilter = passFilter && listing?.skills?.some(x => filters?.skills?.includes(x?.id));
		}
		if (filters.approved_remote_locations !== undefined && filters?.approved_remote_locations?.length) {
			passFilter = passFilter && listing?.remote_states?.some(x => filters?.approved_remote_locations?.includes(x?.id));
		}
		if (filters.work_visa_type_id !== undefined && filters?.work_visa_type_id?.length) {
			passFilter = passFilter && filters?.work;
		}
		if (filters.description !== undefined) {
			passFilter = passFilter && listing?.description?.includes(filters?.description);
		}
		if (filters.professional_category_id !== undefined && filters?.professional_category_id?.length) {
			passFilter =
				passFilter && filters?.professional_category_id?.some(x => listing?.categories?.some(y => y.id === x.id));
		}
		if (filters.specialty_id !== undefined && filters?.specialty_id?.length) {
			passFilter = passFilter && filters?.specialty_id?.some(x => listing?.specialties?.some(y => y.id === x.id));
		}
		if (filters.recent_leadership_role_id !== undefined && filters?.recent_leadership_role_id?.length) {
			passFilter = passFilter && filters?.recent_leadership_role_id?.includes(listing?.recent_leadership_role_id);
		}
		if (filters.recent_leadership_years_max !== undefined) {
			passFilter = passFilter && listing?.recent_leadership_years <= filters?.recent_leadership_years_max;
		}
		if (filters.recent_leadership_years_min !== undefined) {
			passFilter = passFilter && listing?.recent_leadership_years >= filters?.recent_leadership_years_min;
		}
		if (filters.industries !== undefined && filters?.industries?.length) {
			passFilter = passFilter && listing?.industries?.some(x => filters?.industries?.includes(x?.id));
		}
		if (filters.status_id !== undefined && filters?.status_id?.lengt) {
			passFilter = passFilter && filters?.status_id?.includes(listing?.status_id);
		}
		if (filters.owner !== undefined && filters?.owner?.length) {
			passFilter = passFilter && filters?.owner?.includes(listing?.owner_id);
		}
		if (filters.match_saved !== undefined) {
			passFilter =
				passFilter && ((filters.match_saved === 1 && existingMatch) || (filters.match_saved === 2 && !existingMatch));
		}
		if (filters.match_shared !== undefined) {
			passFilter =
				passFilter &&
				((filters.match_shared === 1 && existingMatch?.is_published) ||
					(filters.match_shared === 2 && !existingMatch?.is_published));
		}
		if (filters.match_viewed !== undefined) {
			const myUserHasViewed = graphMatch?.views?.some(x => x?.viewed_by === user?.id);

			passFilter =
				passFilter &&
				((filters.match_viewed === 1 && myUserHasViewed) || (filters.match_viewed === 2 && !myUserHasViewed));
		}
		if (filters.fit_ranking?.length) {
			passFilter =
				passFilter &&
				filters.fit_ranking?.some(filterFitRankingId => {
					console.log({ filterFitRankingId });
					if (filterFitRankingId === 'none') {
						return !graphMatch?.fit_rankings?.find(y => y?.created_by === user?.id);
					}
					return graphMatch?.fit_rankings?.find(
						y => y?.created_by === user?.id && y?.match_fit_ranking?.id === filterFitRankingId,
					);
				});
		}

		return passFilter;
	});

	// Get the selected candidate
	useEffect(() => {
		CandidatesAPI.getCandidate(candidateID, response => {
			if (!response) {
				store.dispatch(SiteActions.showAlert('Could not load the candidate. Refresh the page to try again.', 'error'));
				return;
			}
			const newCandidate = response.data.data;
			setCandidate(newCandidate);
		});

		// Get mock listings
		// ListingsAPI.getListings({}, response => {
		// 	console.log({ response });
		// 	setListings(response?.data?.data);
		// 	setLoadingListings(false);
		// });

		CandidatesAPI.getCandidateGraphMatches({ candidateId: candidateID }).then(async response => {
			console.log({ response });

			setGraphMatches(response?.data?.data);
			setLoadingListings(false);
		});

		// Get matches for the current candidate.
		refetchMatches({ candidateId: candidateID });
	}, []);

	if (!candidate) {
		return null;
	}

	console.log({ candidate, filteredGraphMatches });
	return (
		<Page filterPadding={filter.isOpen}>
			<PageHeader.Header>
				{candidate && (
					<Card style={{ width: '100%' }}>
						<Typography variant="h3" style={{ marginBottom: 15 }}>
							<Icons.PersonOutline style={{ color: 'var(--teal-100)', marginRight: 10, marginTop: -4 }} />
							<NavLink to={`/candidate/${candidate.id}`} className="color-inherit">
								{candidate.first_name} {candidate.last_name}
							</NavLink>
						</Typography>

						<Grid container spacing={2} style={{ marginLeft: 25 }}>
							<Grid item xs={6}>
								<LabeledField label="Salary Expectation" className="color-muted" close>
									{candidate?.salary_expectation_type?.label || 'Unknown'}
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Land the Job Skills" className="color-muted" close>
									<Grid item xs={12} style={{ marginBottom: -12, paddingTop: 5 }}>
										{(candidate.skills || [])
											.filter(x => x.get_the_job)
											.map(skill => (
												<SkillChip
													baseColor="blue"
													key={skill?.id}
													skill={{
														...skill,
														// required_skill: true,
													}}
												/>
											))}
									</Grid>
									{/* <Typography variant="body2">
										{(candidate.skills || [])
											.filter(x => x.get_the_job)
											.map(skill => (
												<span>
													{skill?.skill?.label}
													{skill.experience_years ? ` (${skill.experience_years} yrs)` : null},{' '}
												</span>
											))}
									</Typography> */}
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Work & Location Preferences" className="color-muted" close>
									<Typography variant="body2">
										{Utility.getConsolidatedLocationPreferences(candidate?.work_location_preferences)?.join(', ')}
									</Typography>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Leadership" className="color-muted" close>
									<Typography variant="body2">
										{candidate?.recent_leadership_role?.label}
										{candidate.total_leadership_years ? ` (${candidate.total_leadership_years} yrs)` : null}
									</Typography>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Status" className="color-muted" close>
									<Typography variant="body2">{candidate?.status_type?.label}</Typography>
								</LabeledField>
							</Grid>
							<Grid item xs={6}>
								<LabeledField label="Visa Sponsorship" className="color-muted" close>
									<Typography variant="body2">{candidate.work_visa_type?.label || Utility.nb}</Typography>
								</LabeledField>
							</Grid>
						</Grid>
					</Card>
				)}

				{/* <PageHeader.Middle>
					<TextField
						variant="outlined"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Icons.Search style={{ color: 'var(--primaryColor)' }} />
								</InputAdornment>
							),
						}}
						fullWidth
					/>
				</PageHeader.Middle> */}

				<PageHeader.Right>
					{/* <Button
						variant="text"
						color="primary"
						onClick={ev => Utility.notImplemented(store)}
						startIcon={<Icons.GetApp />}
					>
						Export to XLS
					</Button> */}

					{/* <Button
						variant="contained"
						color="primary"
						onClick={() =>
							store.dispatch(
								SiteActions.showModal(<JobSyncModal category={menu.category} onSync={table.current.refreshData} />),
							)
						}
						startIcon={<Icons.Sync />}
					>
						Sync Job
					</Button> */}
				</PageHeader.Right>
			</PageHeader.Header>

			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}

			<JobFilters
				enableSavedFilters
				savedFilters={savedFilters}
				setSavedFilters={setSavedFilters}
				activeSaveFilterId={activeSaveFilterId}
				setActiveSaveFilterId={setActiveSaveFilterId}
				onClose={closeFilter}
				onReset={() => setFilter({ ...filter, filters: {} })}
				data={data}
				updateFilters={updateFilters}
				filter={filter}
				setFilter={setFilter}
				displayFilters={[
					'match_percentage',
					'fit_ranking',
					'completeness_score_overall',
					'total_skill_score',
					'match_saved',
					'match_shared',
					'match_viewed',
					'professional_experience_years_range',
					'hero_skills',
					'skills',
					'salary_range',
					'approved_remote_locations',
					'work_visa_type_id',
					'description',
					'position_title',
					'professional_category_id',
					'specialty_id',
					'recent_leadership_role_id',
					'recent_leadership_years_range',
					'industries',
					'is_open',
					'status_id',
					'owner',
					// "company",
					// "contact",
					// "vendor_job_id",
					// "city",
					// "state",
					// "offer_relocation",
					// "role_type",
					// "last_profile_update_range",
					// "assessment_completed_range"
				]}
			/>

			<JobCardList
				refetchMatches={refetchMatches}
				// matches={matches}
				candidateId={candidateID}
				loading={loadingListings}
				graphMatches={filteredGraphMatches}
				pageSize={25}
				disableLink
				enableSelectAll
				enableRefresh
				numFilters={numFilters}
				clearFilters={handleClearFilters}
				filterComponent={
					<Tooltip className="tooltip" title={`${numFilters} Filter${numFilters > 1 ? 's' : ''}`} placement="top">
						<Button
							style={{
								backgroundColor: numFilters > 0 ? 'rgba(76, 159, 255,.3)' : 'transparent',
								marginBottom: 10,
							}}
							variant="outlined"
							color="primary"
							size="small"
							onClick={filter.isOpen ? closeFilter : openFilter}
							startIcon={<Icons.FilterList />}
						>
							Filter
						</Button>
					</Tooltip>
				}
				// listings={filteredListings}
			/>
		</Page>
	);
};

export default CandidateMatches;
