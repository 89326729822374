import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';

import FormattedText from '../../../components/form/FormattedText';

export const DocumentEmbed = ({ height, textFallback, shareLink, fileName }) => {
	if (!shareLink) return <Typography style={{ opacity: 0.5, fontStyle: 'italic' }}>No document to display.</Typography>;

	const [_shareLink, _setShareLink] = useState(shareLink);

	useEffect(() => {
		// setTimeout(() => {
		// 	console.log({ embedRef: embedRef?.current });
		// 	if (embedRef.current?.contentDocument) {
		// 		console.log('attempting to reload the pdf...');
		// 		const cachedShareLink = _shareLink;
		// 		_setShareLink(null);
		// 		setTimeout(() => {
		// 			_setShareLink(cachedShareLink);
		// 		}, 500);
		// 	}
		// }, 5000);
	}, []);

	return (
		<>
			{fileName?.includes('.pdf') ? (
				// <iframe
				// 	ref={embedRef}
				// 	title="embed"
				// 	src={`https://docs.google.com/viewer?embedded=true&url=${encodeURIComponent(shareLink)}`}
				// 	width="100%"
				// 	height={height}
				// 	frameBorder="0"
				// 	style={{
				// 		height,
				// 	}}
				// />
				<iframe title="resume" height={height} width="100%" src={shareLink} type="application/pdf" />
			) : // <iframe title="resume" height={height} width="100%" src={shareLink} type="application/pdf" />
			fileName?.includes('.txt') && textFallback ? (
				<FormattedText height={20} style={{ whiteSpace: 'normal', maxHeight: 'none' }}>
					{/* <Typography variant="body2">{job.description || Utility.nb}</Typography> */}
					{/* eslint-disable-next-line react/no-danger */}
					<div className="public_description" dangerouslySetInnerHTML={{ __html: textFallback }} />
				</FormattedText>
			) : (
				<iframe
					title="embed"
					src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(shareLink)}`}
					width="100%"
					height={height}
					frameBorder="0"
					style={{
						height,
					}}
				/>
			)}
		</>
	);
};

const ViewDocumentModal = ({ shareLink, document, ...props }) => {
	const height = 'calc(100vh - 125px)';
	return (
		<div style={{ textAlign: 'center' }}>
			<Typography variant="h5" style={{ marginBottom: 20 }}>
				{document?.file_name}
			</Typography>
			{shareLink && <DocumentEmbed height={height} shareLink={shareLink} fileName={document?.file_name} />}
		</div>
	);
};

export default ViewDocumentModal;
