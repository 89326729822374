/* eslint-disable no-restricted-syntax */
/* eslint-disable no-sequences */
import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import LabeledField from '../LabeledField';
import CheckboxInput from '../inputs/CheckboxInput';
import SpecialtyDropdown from './IndustrySpecialtyDropdown';
import IndustryDropdown from './ParentIndustryDropdown';
import AddAnotherButton from '../AddAnotherButton';

const MultiInputIndustryExperienceDropdown = ({ onChange, setModel, model, vertical, labeledField = false }) => {
	const { data } = useSelector(state => state.site, shallowEqual);
	const [minimized, setMinimized] = useState(false);
	const [overrideLeadershipLock, setOverrideLeadershipLock] = useState(true);

	const [fieldPairs, setFieldPairs] = useState([]);

	useEffect(() => {
		const initialPairs = model?.industries?.length
			? model.industries.map((industry, index) => ({
					categoryId: model?.industries?.[index]?.id || null,
					specialtyId: industry?.id || null,
			  }))
			: [{ categoryId: null, specialtyId: null }];
		setFieldPairs(initialPairs);
	}, [model]);

	const leadershipSpecialtyLocked = false; // [3, 4].includes(model.leadership_allocation_type_id);

	const updateModel = newFieldPairs => {
		const findInNestedArray = (array, predicate) => {
			for (const item of array) {
				if (predicate(item)) return item;
				if (item.children) {
					const found = findInNestedArray(item.children, predicate);
					if (found) return found;
				}
			}
			return null;
		};

		const newIndustries = newFieldPairs.map(pair => {
			if (!pair.specialtyId) {
				return findInNestedArray(data?.industries || [], c => c.id === pair.categoryId);
			}
			return findInNestedArray(data?.industries || [], c => c.id === pair.specialtyId);
		});

		if (onChange) {
			onChange({
				target: {
					name: 'industries',
					value: newIndustries,
				},
			});
		} else if (setModel) {
			setModel(currentModel => ({
				...currentModel,
				industries: newIndustries,
			}));
		}
	};

	const removeField = index => {
		const newFieldPairs = fieldPairs.filter((_, i) => i !== index);
		setFieldPairs(newFieldPairs);
		updateModel(newFieldPairs);
	};
	const handleCategoryChange = (e, index) => {
		const newFieldPairs = fieldPairs.map((pair, i) =>
			i === index ? { ...pair, categoryId: e.target.value, specialtyId: null } : pair,
		);
		setFieldPairs(newFieldPairs);
		updateModel(newFieldPairs);
	};

	const handleSpecialtyChange = (e, index) => {
		const newFieldPairs = fieldPairs.map(
			(pair, i) => (i === index ? { ...pair, specialtyId: e.target.value } : pair), // Only update specialtyId
		);
		setFieldPairs(newFieldPairs);
		updateModel(newFieldPairs);
	};

	return (
		<>
			<Grid container spacing={3}>
				{/* <Grid item xs={12}>
					<LabeledField 
						label="Specialty"
						removeMargin
						labelAddendum={specialtyLabel}
						labelAddendumTooltip={specialtyTooltip}
					>
						{!leadershipSpecialtyLocked ? (
							<SpecialtyDropdown
								variant="outlined"
								name="specialty_id"
								value={leadershipSpecialtyLocked ? null : model.specialty_id}
								placeholder="Type to search specialties"
								// category={model.professional_category_id}
								onChange={e => {
									const { value: specialtyId } = e.target;

									const specialty = data.specialties.find(x => x.id === specialtyId);
									const categoryId = specialty?.category?.id;

									onChange({
										target: {
											name: 'specialty_id',
											value: specialtyId,
										},
									});
									onChange({
										target: {
											name: 'professional_category_id',
											value: categoryId,
										},
									});
								}}
								disabled={leadershipSpecialtyLocked}
								fullWidth
							/>
						) : null}
					</LabeledField>
				</Grid> */}
				{/* {!leadershipSpecialtyLocked ? (
					<Grid item xs={12}>
						<Typography
							onClick={() => {
								// setMinimized(!minimized);
							}}
							variant="body1"
							style={{ cursor: 'pointer', userSelect: 'none', marginTop: -10 }}
							className=""
							gutterBottom
						>
							 {minimized ? '▸' : '▾'} Category and specialty lists: 
						</Typography>
					</Grid>
				) : null} */}

				{!minimized || leadershipSpecialtyLocked ? (
					<Grid item xs={12}>
						<div>
							<Grid container spacing={3}>
								{fieldPairs.map((pair, i) => (
									<React.Fragment key={i}>
										<Grid item xs={vertical ? 12 : 6}>
											<div
												style={{
													marginTop: i === 0 ? 0 : -25,
												}}
											>
												<LabeledField
													label={i === 0 ? `Industry Category (max 5)` : null}
													labelStyle={labeledField && { fontWeight: '500', fontSize: 14 }}
													removeMargin
												>
													<IndustryDropdown
														variant="outlined"
														name="industries"
														value={pair.categoryId}
														onChange={e => handleCategoryChange(e, i)}
														// error={!Utility.getFromObj(model, 'professional_category_id')}
														fullWidth
														placeholder="Choose from our list"
													/>
												</LabeledField>
											</div>
										</Grid>
										<Grid item xs={vertical ? 12 : 6}>
											<div style={{ display: 'flex', alignItems: 'flex-end', marginTop: i === 0 ? 0 : -25 }}>
												<div style={{ flex: 1 }}>
													<LabeledField
														label={i === 0 ? `Sector` : null}
														labelStyle={labeledField && { fontWeight: '500', fontSize: 14 }}
														removeMargin
													>
														{/* TODO: if leadership allocation >= 75% then specialty should be "management" */}
														<SpecialtyDropdown
															variant="outlined"
															name="specialties"
															value={pair.specialtyId}
															placeholder={leadershipSpecialtyLocked ? 'Leadership' : 'Choose from our list'}
															category={pair.categoryId}
															firstEntry={pair.categoryId && !pair.specialtyId}
															onChange={e => handleSpecialtyChange(e, i)}
															disabled={!pair.categoryId}
															fullWidth
														/>
													</LabeledField>
												</div>
												<div style={{ width: 40, paddingLeft: 10, marginBottom: 7 }}>
													{i > 0 ? (
														<Button
															variant="text"
															color="#000"
															size="large"
															style={{
																margin: 0,
																padding: 4,
																minWidth: 0,
																borderRadius: 4,
																fontWeight: 400,
															}}
															onClick={() => removeField(i)}
														>
															<div
																style={{
																	display: 'flex',
																	alignItems: 'center',
																}}
															>
																<Icons.Remove color="gray" size={20} />
															</div>
														</Button>
													) : null}
												</div>
											</div>
										</Grid>
									</React.Fragment>
								))}

								{/* Leaadership Override */}
								{/* <Grid item xs={vertical ? 12 : 6} /> */}
								<Grid item xs={vertical ? 12 : 6} style={{ marginTop: -15 }}>
									<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: 4 }}>
										{/* eslint-disable-next-line no-constant-condition */}
										{false && leadershipSpecialtyLocked ? (
											<CheckboxInput
												label="Override leadership specialty"
												color="primary"
												checked={overrideLeadershipLock}
												onChange={ev => setOverrideLeadershipLock(ev.target.checked)}
											/>
										) : (
											<div />
										)}
									</div>
								</Grid>
								<Grid item xs={vertical ? 12 : 6} style={{ marginTop: -15 }}>
									{fieldPairs.length < 5 && (
										<div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
											<AddAnotherButton
												text="Add Another"
												onClick={() => setFieldPairs([...fieldPairs, { categoryId: null, specialtyId: null }])}
											/>
										</div>
									)}
								</Grid>
							</Grid>
						</div>
					</Grid>
				) : null}
			</Grid>
			<br />
		</>
	);
};

export default MultiInputIndustryExperienceDropdown;
