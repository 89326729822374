import React from 'react';
import { Grid, Typography, Link } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import * as Utility from '../../../scripts/utility';
import CaseIcon from '../../../components/icons/SectionIcons/CaseIcon';
import ViewBlockWrapper from '../../candidates/CandidateBlocks/ViewBlockWrapper';
import { ActionCreators as SiteActions } from '../../../store/Site';
import EditRecruiterResourcesModal from './EditRecruiterResourcesModal';
import RichTextReadOnly from '../../../components/form/ReadOnlyTextEditor';
import VerticalSpacer from '../../../components/VerticalSpacer';

const Block = ({ job, setJob, data, minimized, setMinimized, disableEdit, graphMatch }) => {
	const dispatch = useDispatch();

	return (
		<>
			<ViewBlockWrapper
				icon={CaseIcon}
				iconColor="var(--blue-500)"
				title="Recruiter Resources"
				minimized={minimized}
				setMinimized={setMinimized}
				onEditClick={
					disableEdit
						? null
						: () => {
								dispatch(
									SiteActions.showModal(<EditRecruiterResourcesModal job={job} setJob={setJob} />, {
										className: 'modal-medium modal-no-padding modal-no-shadow',
									}),
								);
						  }
				}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<VerticalSpacer height={1} />
						<Typography variant="body2" color="primary" style={{ fontWeight: '500' }}>
							Job Highlights
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<RichTextReadOnly content={job.job_highlights || Utility.nb} />
					</Grid>
					<Grid item xs={12}>
						<VerticalSpacer height={1} />
						<Typography variant="body2" color="primary" style={{ fontWeight: '500' }}>
							Job Notes
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<RichTextReadOnly content={job.job_notes || Utility.nb} />
					</Grid>
					<Grid item xs={12}>
						<VerticalSpacer height={1} />
						<Typography variant="body2" color="primary" style={{ fontWeight: '500' }}>
							Sourcing Guide
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<RichTextReadOnly content={job.sourcing_guidance || Utility.nb} />
					</Grid>
					<Grid item xs={12}>
						<VerticalSpacer height={1} />
						<Typography variant="body2" color="primary" style={{ fontWeight: '500' }}>
							Client Content Links
						</Typography>
					</Grid>
					<Grid item xs={12}>
						{job.client_content_links?.map((link, index) => (
							<div key={index}>
								<Typography style={{ fontWeight: 'bold' }}>{link.name}</Typography>
								<Link
									href={
										link.url.startsWith('http://') || link.url.startsWith('https://') ? link.url : `https://${link.url}`
									}
									target="_blank"
								>
									<Typography>{link.url}</Typography>
								</Link>
								<VerticalSpacer height={0.5} />
							</div>
						))}
					</Grid>
				</Grid>
			</ViewBlockWrapper>
		</>
	);
};

export default Block;
