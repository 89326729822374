/* eslint-disable no-restricted-syntax */
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Dropdown from './Dropdown';

const IndustryDropdown = ({ value, ...props }) => {
	const { data } = useSelector(state => state.site, shallowEqual);

	const getCategories = () => {
		let categories = data.industries || [];

		const uncategorizedCategories = categories.filter(category =>
			category.label.toLowerCase().includes('uncategorized'),
		);
		if (uncategorizedCategories.length > 0) {
			return uncategorizedCategories.map(category => ({ ...category, label: category.label.split(': ')[0] }));
		}

		categories = categories.map(category => {
			return { ...category, label: category.label.split(': ')[0] };
		});

		categories = categories.filter((category, index, self) => {
			return index === self.findIndex(t => t.label === category.label);
		});

		categories = categories.sort((el1, el2) => {
			if (el1.label.toUpperCase() < el2.label.toUpperCase()) return -1;
			if (el1.label.toUpperCase() > el2.label.toUpperCase()) return 1;
			return 0;
		});

		return categories;
	};

	const getReverseCategories = () => {
		const findCategory = (industries, categoryId) => {
			for (const industry of industries) {
				if (industry.id === categoryId) {
					return industry;
				}
				if (industry.children) {
					const foundCategory = findCategory(industry.children, categoryId);
					if (foundCategory) {
						return foundCategory;
					}
				}
			}
			return null;
		};

		const filterMatchingCategories = (industries, selectedCategory) => {
			let matchingCategories = [];

			for (const industry of industries) {
				const [firstPart] = industry.label.split(': ');
				if (firstPart === selectedCategory.label.split(': ')[0]) {
					matchingCategories.push(industry);
				}
				if (industry.children) {
					matchingCategories = matchingCategories.concat(filterMatchingCategories(industry.children, selectedCategory));
				}
			}

			return matchingCategories;
		};
		if (value) {
			const selectedCategory = findCategory(data.industries, value);
			if (selectedCategory) {
				let matchingCategories = filterMatchingCategories(data.industries, selectedCategory);
				matchingCategories = matchingCategories.map(c => {
					const [firstHalf] = c.label.split(': ');
					return {
						...c,
						label: firstHalf ? firstHalf.trim() : c.label,
					};
				});

				matchingCategories = matchingCategories.filter(
					(cat, index, self) => index === self.findIndex(c => c.label.toLowerCase() === cat.label.toLowerCase()),
				);

				matchingCategories = matchingCategories.sort((a, b) =>
					a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
				);

				return matchingCategories;
			}
		}

		return [];
	};

	const getCategoryValue = () => {
		const getReversedCategories = getReverseCategories();
		return getReversedCategories?.[0]?.id;
	};

	return <Dropdown data={getCategories()} value={getCategoryValue()} {...props} />;
};

export default IndustryDropdown;
