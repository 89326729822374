import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { Grid, TextField } from '@material-ui/core';
import LabeledField from '../../../components/form/LabeledField';
import EditJobBlockModal from './EditJobBlockModal';
import MoreDetailsIcon from '../../../components/icons/SectionIcons/MoreDetailsIcon';
import DynamicTextEditor from '../../../components/TextEditor';
import AddAnotherButton from '../../../components/form/AddAnotherButton';

const EditModal = ({ job, setJob }) => {
	const { data } = useSelector(state => state.site, shallowEqual);
	return (
		<EditJobBlockModal
			job={job}
			setJob={setJob}
			title="Recruiter Resources"
			icon={MoreDetailsIcon}
			renderContents={({ updateJob, updatedJob }) => {
				const initialJob = JSON.parse(JSON.stringify(updatedJob));
				const [localLinks, setLocalLinks] = useState(
					initialJob.client_content_links.length > 0 ? initialJob.client_content_links : [{ name: '', url: '' }],
				);

				const handleLinkChange = (ev, index, field) => {
					const newLinks = [...localLinks];
					newLinks[index][field] = ev.target.value;
					setLocalLinks(newLinks);
					updateJob({
						target: { name: 'client_content_links', value: newLinks },
					});
				};

				const addEmptyLink = () => {
					const newLinks = [...localLinks, { name: '', url: '' }];
					setLocalLinks(newLinks);
					updateJob({
						target: { name: 'client_content_links', value: newLinks },
					});
				};

				return (
					<>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<LabeledField label="Sourcing Guide" removeMargin>
									<DynamicTextEditor
										content={updatedJob.sourcing_guidance || ''}
										onChange={updateJob}
										fieldName={'sourcing_guidance'}
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={12}>
								<LabeledField label="Job Highlights" removeMargin>
									<DynamicTextEditor
										fieldName="job_highlights"
										content={updatedJob.job_highlights || ''}
										onChange={updateJob}
									/>
								</LabeledField>
							</Grid>
							<Grid item xs={12}>
								<LabeledField label="Job Notes" removeMargin>
									<DynamicTextEditor fieldName="job_notes" content={updatedJob.job_notes || ''} onChange={updateJob} />
								</LabeledField>
							</Grid>
							<Grid item xs={12}>
								<LabeledField label="Client Content Links" removeMargin>
									{localLinks.map((link, index) => (
										<div key={index} style={{ marginBottom: '10px' }}>
											<LabeledField label="Link Name">
												<TextField
													variant="outlined"
													name={`client_content_links_${index}`}
													value={link.name}
													onChange={ev => handleLinkChange(ev, index, 'name')}
													error={!link.name}
													fullWidth
													placeholder="Enter link name"
													style={{ marginBottom: '-10px' }}
												/>
											</LabeledField>
											<LabeledField label="Link URL">
												<TextField
													variant="outlined"
													name={`client_content_links_url_${index}`}
													value={link.url}
													onChange={ev => handleLinkChange(ev, index, 'url')}
													error={!link.url}
													fullWidth
													placeholder="Enter link URL"
												/>
											</LabeledField>
										</div>
									))}
								</LabeledField>
								<AddAnotherButton onClick={addEmptyLink} />
							</Grid>
						</Grid>
						<br />
					</>
				);
			}}
		/>
	);
};

EditModal.propTypes = {
	job: PropTypes.shape({ id: PropTypes.string }),
	setJob: PropTypes.func,
};

EditModal.defaultProps = {
	job: {},
	setJob: () => null,
};

export default EditModal;
