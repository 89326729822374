import React from 'react';
import Moment from 'moment';
import { ActionCreators as SiteActions } from '../store/Site';

// CONSTANTS

export const na = '';
export const nb = '\xa0';

// FUNCTION

/**
 * Formats a phone number for display
 * @param {String|Number} phone - The phone number to format
 * @returns {String} - The formatted phone
 */
export const formatPhone = phone => {
	const cleaned = `${phone}`.replace(/\D|^1/g, '');

	const match = cleaned.match(/^(\d{1,3})?(\d{1,3})?(\d{1,4})?(.*)?/);

	if (match) {
		let formatted = '';

		if (match[1]) formatted += `(${match[1] || ''}`;
		if (match[2]) formatted += `) ${match[2] || ''}`;
		if (match[3]) formatted += `-${match[3] || ''}`;
		if (match[4]) formatted += ` ${match[4] || ''}`;

		return formatted;
	}

	return '';
};

/**
 * Formats a given date for display
 * @param {String|Number} timestamp - The timestamp in Moment parsable format
 * @param {Boolean} utc - Whether to display the date in UTC time
 * @returns {String} - The formatted date
 */
export const formatDate = (timestamp, utc, format = 'M/DD/YYYY') => {
	if (!timestamp) return '';

	let newDate = Moment(timestamp);

	if (utc) newDate = newDate.utcOffset(0);

	return newDate.format(format);
};

/**
 * Formats a date for the API (No time)
 * @param {String} date - The date to format
 * @returns {String} - The formatted date
 */
export const formatAPIDate = date => {
	if (!date) return null;

	if (typeof date === 'string' && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{6}Z$/.test(date))
		return date.substring(0, 10);

	return Moment(date).format('YYYY-MM-DD');
};

/**
 * Formats a given timestamp for display
 * @param {String} timestamp - The datetime to format
 * @returns {String} - The formatted time
 */
export const formatTime = timestamp => {
	if (!timestamp) return '';
	return Moment(timestamp).format('hh:mm A');
};

/**
 * Formats a given timestamp for display
 * @param {String} timestamp - The datetime to format
 * @returns {String} - The formatted datetime
 */
export const formatDateTime = timestamp => {
	if (!timestamp) return '';

	const date = formatDate(timestamp);
	const time = formatTime(timestamp);
	return `${date} ${time}`;
};

/**
 * Formats a given timestamp for the API
 * @param {String} date - The datetime to format
 * @returns {String} - The formatted datetime
 */
export const formatAPIDateTime = date => {
	if (!date) return null;

	let newDate = date;

	if (typeof newDate === 'object') newDate = newDate.toISOString();

	// return Moment(newDate).format('YYYY-MM-DD[T]HH:mm:ss[Z]');
	return Moment(newDate).format('YYYY-MM-DD HH:mm:ss');
};

export const formatMoney = (amount, options) => {
	// eslint-disable-next-line no-restricted-globals
	if (isNaN(amount) || amount === null) return '';

	const { hideDecimal } = options || {};

	return `$${amount.toLocaleString()}${hideDecimal ? '' : '.00'}`;
};

export const propsToURL = (props, duplicateArrayParams = false) => {
	const url = Object.entries(props)
		.filter(prop => prop[1])
		.map(prop =>
			duplicateArrayParams && Array.isArray(prop[1])
				? prop[1].map(val => `${prop[0]}=${val}`).join('&')
				: `${prop[0]}=${encodeURIComponent(prop[1])}`,
		)
		.join('&');

	return url ? `?${url}` : '';
};

export const getFromObj = (obj, ...props) => {
	if (typeof obj !== 'object') return '';

	let currentProp = obj;

	try {
		for (let i = 0; i < props.length; i += 1) {
			currentProp = currentProp[props[i]];
		}
	} catch (err) {
		return '';
	}

	return currentProp || '';
};

export const getIDFromObj = (obj, id, field) => {
	const identifier = field || 'id';

	if (typeof obj !== 'object' || obj.length === 0 || obj === null) return '';

	const element = obj.find(item => item[identifier] === id) || {};

	return element || '';
};

/**
 * Get the label property from an object with a specified ID
 * @param {Array.<Object>} list - A list of data
 * @param {Number} id - The ID of the object
 * @param {String} identifier - The name of the property containing the ID
 * @returns {String} - The label of the object
 */
export const getItemLabel = (list, id, identifier) => {
	return getFromObj(getIDFromObj(list, id, identifier), 'label');
};

/**
 * Get the name property from an object with a specified ID
 * @param {Array.<Object>} list - A list of data
 * @param {Number} id - The ID of the object
 * @param {String} identifier - The name of the property containing the ID
 * @returns {String} - The name of the object
 */
export const getItemName = (list, id, identifier) => {
	return getFromObj(getIDFromObj(list, id, identifier), 'name');
};

/**
 * This function finds an object in a list by its label and returns the object's id.
 * @param {Array.<Object>} list - A list of data
 * @param {string} label - The label of the object to find.
 * @returns {number|null} - The id of the found object, or null if no object with the given label is found.
 */
export const getIdFromLabel = (list, label) => {
	const item = list.find(obj => obj.label === label);
	return item ? item.id : null;
};

export const getListingStatusColor = status => {
	switch (status) {
		case 'placed':
			return 'default';
		case 'accepting-candidates':
			return 'success';
		case 'offer-out':
			return 'primary';
		case 'lost':
			return 'error';
		case 'archive':
			return 'error';
		case 'on-hold':
			return 'warning';
		case 'filled':
			return 'default';
		case 'not-pursuing':
			return 'error';
		case 'placeholder':
			return 'default';
		case 'pursuing':
			return 'primary';
		case 'not-approved':
			return 'error';

		// Company statuses
		case 'Contracted [Active]':
			return 'success';
		default:
			return 'default';
	}
};

// default - gray/black
// primary - lightblue/darkblue
// secondary - pink/red
// success - green/white
// error - red/white
// warning - tan/brown
// ready - lightgreen/darkgreen
export const getCandidateStatusColor = status => {
	switch (status) {
		case 'sub_ready':
			return 'ready';
		case 'available':
			return 'ready';
		case 'Prospect':
			return 'primary';
		case 'New Lead':
			return 'default';
		case 'Imported':
			return 'default';
		case 'Qualified':
			return 'primary';
		case 'Profile Prep':
			return 'primary';
		case 'Archive':
			return 'default';
		case 'Do Not Hire':
			return 'error';
		case 'Unqualified':
			return 'error';
		case 'Do Not Call':
			return 'error';
		case 'Terminated':
			return 'error';
		case 'Placed':
			return 'error';
		case 'referral-source':
			return 'default';
		default:
			return 'default';
	}
};

export const getJobContactField = (contactList, field, id) => {
	if (!Array.isArray(contactList) || contactList.length === 0) return '';

	return contactList[id || 0][field] || '';
};

export const notImplemented = store => {
	if (store)
		store.dispatch(
			SiteActions.showModal(
				// eslint-disable-next-line react/jsx-filename-extension
				<div className="text-center">
					<h2>Oops...</h2>
					This feature is not implemented yet.
				</div>,
			),
		);
	else alert('This feature is not implemented yet.');
};

export const uniqueIDs = arr => {
	const seen = {};

	return arr.filter(item => {
		const key = item.id;
		// eslint-disable-next-line no-prototype-builtins
		return seen.hasOwnProperty(key) ? false : (seen[key] = true);
	});
};

export const scrollTo = id => {
	document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
};

/**
 * Updates a state with the data from an update event
 * @param {Object} ev - The event object
 * @param {*} state - The current state
 * @param {Function} setState - The state update function
 */
export const updateState = (ev, state, setState, path = []) => {
	const { name } = ev.target;
	const value = ev.target.value !== null && ev.target.value.length ? ev.target.value : undefined;
	const newState = { ...state };

	newState[name] = value;

	setState(newState);
};

/**
 * Attempt to clean up HTML for text display
 * @param {String} text - The HTML or text to display
 * @returns {String} displayText - The formatted string
 */
export const formatDisplayText = text => {
	if (!text) return '';

	let displayText = text;

	displayText = displayText.replace(/<br>|<br \/>/gim, '\n');
	displayText = displayText.replace(/<p>(.*?)<\/p>/gim, '$1\n\n');
	displayText = displayText.replace(/<ul>(.*?)<\/ul>/gim, '\n$1\n');
	displayText = displayText.replace(/<ol>(.*?)<\/ol>/gim, '\n$1\n');
	displayText = displayText.replace(/<li>(.*?)<\/li>/gim, '\t• $1\n');

	return displayText;
};

/**
 * Sort an array of objects alphabetically by a field
 * @param {Object} a - The first comparison value
 * @param {Object} b - The second comparison value
 * @param {String} field - The field to compare, defaults to 'label'
 */
export const alphaSort = (a, b, field = 'label') => {
	if (a[field].toLowerCase() < b[field].toLowerCase()) {
		return -1;
	}
	if (a[field].toLowerCase() > b[field].toLowerCase()) {
		return 1;
	}
	return 0;
};

export const formatFromNow = momentDate => {
	return momentDate
		.fromNow()
		.replace('a day', '1 day')
		.replace('a month', '1 month')
		.replace('a year', '1 year');
};

export const useStickyState = (defaultValue, key) => {
	const [value, setValue] = React.useState(() => {
		const stickyValue = window.localStorage.getItem(key);
		return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
	});
	React.useEffect(() => {
		console.log('Setting item: ', key);
		try {
			window.localStorage.setItem(key, JSON.stringify(value));
		} catch (e) {
			console.log('Error setting localstorage item: ', e);
		}
	}, [key, value]);
	return [value, setValue];
};

/**
 * Get the time zone abbreviation for a given US state abbreviation.
 * @param {string} stateAbbreviation - The abbreviation of the state.
 * @returns {string|undefined} - The time zone abbreviation, or undefined if the state abbreviation is not found.
 */
export const getTimeZone = stateAbbreviation => {
	const timeZones = {
		AK: 'AT',
		AL: 'CT',
		AR: 'CT',
		AS: 'ST',
		AZ: 'MT',
		CA: 'PT',
		CO: 'MT',
		CT: 'ET',
		DC: 'ET',
		DE: 'ET',
		FL: 'ET',
		GA: 'ET',
		GU: 'CT',
		HI: 'HT',
		IA: 'CT',
		ID: 'MT',
		IL: 'CT',
		IN: 'ET',
		KS: 'CT',
		KY: 'ET',
		LA: 'CT',
		MA: 'ET',
		MD: 'ET',
		ME: 'ET',
		MI: 'ET',
		MN: 'CT',
		MO: 'CT',
		MP: 'CT',
		MS: 'CT',
		MT: 'MT',
		NA: 'ET',
		NC: 'ET',
		ND: 'CT',
		NE: 'CT',
		NH: 'ET',
		NJ: 'ET',
		NM: 'MT',
		NV: 'PT',
		NY: 'ET',
		OH: 'ET',
		OK: 'CT',
		OR: 'PT',
		PA: 'ET',
		PR: 'AT',
		RI: 'ET',
		SC: 'ET',
		SD: 'CT',
		TN: 'CT',
		TX: 'CT',
		UT: 'MT',
		VA: 'ET',
		VI: 'AT',
		VT: 'ET',
		WA: 'PT',
		WI: 'CT',
		WV: 'ET',
		WY: 'MT',
	};

	return timeZones[stateAbbreviation];
};

/**
 * Get a match modal score color depending on the score.
 * Score values will be between 0-1, or could be null or undefined.
 * @param {Number|null|undefined} score The score to format
 * @returns {String|null} The color, or null if the score is null or undefined
 */
export const getScoreColor = score => {
	if (score === null || score === undefined) return null;
	if (score >= 0.75) return 'var(--goodMatchTextColor)';
	if (score >= 0.5) return 'var(--neutralMatchTextColor)';
	return 'var(--badMatchTextColor)';
};

/**
 * Get the skill story tag label of a skill based on its properties and a provided list of tags.
 * @param {Array.<Object>} list - The list of tags,
 *  each represented as an object that includes 'model_column_name' and 'label'.
 * @param {Object} skill - The skill object.
 * @returns {Array.<string> | null} - An array of tag labels or null if
 * the skill or data is not provided or if a tag doesn't exist for the given skill.
 */
export const getSkillStoryTagLabel = (list, skill) => {
	if (!list || !skill) return null;
	const tags = [];
	list.forEach(item => {
		if (skill[item.model_column_name]) {
			let { label } = item;
			if (label.endsWith('s')) {
				label = label.slice(0, -1);
			}
			if (label.endsWith('Type')) {
				label = label.slice(0, -5);
			}
			tags.push(label);
		}
	});
	return tags;
};

export const getFiltersFromCandidate = ({ candidate, salaryExpectationTypes }) => {
	const filters = {};

	// Only search open jobs.
	filters.is_open = 1;

	// position_title
	if (candidate.desired_position) {
		filters.position_title = candidate.desired_position;
	}

	// professional_experience_years_min
	if (candidate.professional_experience_years) {
		filters.professional_experience_years_min = candidate.professional_experience_years;
		// filters.professional_experience_years_max = candidate.professional_experience_years + 10;
	}

	// salary_rate_min
	if (candidate.salary_expectation_type_id) {
		const salaryExpectationType = salaryExpectationTypes.find(x => x.id === candidate.salary_expectation_type_id);

		if (salaryExpectationType) {
			try {
				filters.salary_rate_min = Math.parseInt(salaryExpectationType.name);
			} catch (e) {
				console.log('Unable to parse salary expectation type: ', { salaryExpectationType });
			}
		} else {
			console.log('Unable to find matching salary expectation type.');
		}
	}

	// work_visa_type_id
	// If the candidate requires a work visa, search only for jobs that offer it.
	if (candidate.work_visa_type_id === 1) {
		filters.work_visa_type_id = 1;
	}

	// Skills:
	/*
		Find jobs where my candidate's skills cover all of the job's hero skills?
		Or 2 out of 3?
	*/

	// Location:
	/* 
	// Problem: we need results for the remote filter + results for the in-person filter.
	// The current filter queries do not allow us to do this.

	// if candidate is open to remote work, look for jobs where their current state is
	// in the listing's listing's remote states.

	// if the candidate is open to in-person work, look for jobs where the office locations
	// match the candidate's current state.

	// if the candidate is open to relocating, look for jobs where the office locations
	// match the candidate's desired location states.
	*/

	return filters;
};

export const getFiltersFromJob = job => {
	const defaultFilters = {};
};

export const getMatchingValidation = ({ data, candidate, job, propertyName }) => {
	if (!candidate || !job)
		return {
			valid: true,
		};

	// console.log({ candidate, job });

	if (propertyName === 'category') {
		// The candidate's category matches the job's category.
		return {
			valid: candidate?.categories.some(y => job.professional_category_id === y.id),
			// job?.professional_category_id === candidate?.professional_category_id,
			missing: !candidate?.categories?.length || !job?.professional_category_id,
		};
	}
	if (propertyName === 'specialty') {
		// The candidate's specialty matches the job's specialty.
		return {
			valid: candidate?.specialties.some(y => job.specialty_id === y.id),
			// job?.specialty_id === candidate?.specialty_id,
			missing: !candidate?.specialties.length || !job?.specialty_id,
		};
	}

	// TBD: Hero Skills and Skills

	if (propertyName === 'professional_experience_years') {
		// The candidate meets or exceeds the job's professional_experience_years requirement.
		return {
			valid:
				job?.professional_experience_years * 0.75 <= candidate?.professional_experience_years &&
				job?.professional_experience_years * 1.5 >= candidate?.professional_experience_years,
			missing: !candidate?.professional_experience_years || !job?.professional_experience_years,
		};
	}

	if (propertyName === 'leadership_role_id') {
		const jobLeadershipExperienceRank = data?.leadershipExperienceRoles?.find(
			x => x.id === job?.recent_leadership_role_id,
		)?.rank_value;
		const candidateLeadershipExperienceRank = data?.leadershipExperienceRoles?.find(
			x => x.id === candidate?.recent_leadership_role_id,
		)?.rank_value;

		// The candidate's recent leadership role is equal to or greater than the job's leadership role requirement.
		return {
			valid: jobLeadershipExperienceRank <= candidateLeadershipExperienceRank,
			partial: jobLeadershipExperienceRank - 1 <= candidateLeadershipExperienceRank,
			missing: !candidate?.recent_leadership_role_id || !job?.recent_leadership_role_id,
		};
	}
	if (propertyName === 'total_leadership_years') {
		// The candidate's total leadership years is equal to or greater than the job's total leadership years requirement.
		return {
			valid: job?.total_leadership_years * 0.75 <= candidate?.total_leadership_years,
			missing: !candidate?.total_leadership_years || !job?.total_leadership_years,
		};
	}

	if (propertyName === 'leadership_allocation_type') {
		// The candidate's leadership allocation is equal to the job's leadership allocation requirement.
		// (Or the job has no leadership allocation requirement)

		const jobLeadershipAllocationRank = data?.leadershipAllocationTypes?.find(
			x => x.id === job?.leadership_allocation_type_id,
		)?.rank_value;
		const candidateLeadershipAllocationRank = data?.leadershipAllocationTypes?.find(
			x => x.id === candidate?.leadership_allocation_type_id,
		)?.rank_value;

		return {
			valid: job?.leadership_allocation_type_id === candidate?.leadership_allocation_type_id,
			partial: Math.abs(jobLeadershipAllocationRank - candidateLeadershipAllocationRank) <= 1,
			missing: !candidate?.leadership_allocation_type_id || !job?.leadership_allocation_type_id,
		};
	}

	if (propertyName === 'education_type') {
		// The candidate's education type is equal to or greater than the job's education type requirement.
		// Or the job has no education type requirement.

		const jobEducationRank = data?.educationTypes?.find(x => x.id === job?.education_type_id)?.rank_value;
		const candidateEducationRank = data?.educationTypes?.find(x => x.id === candidate?.education_type_id)?.rank_value;

		return {
			valid: jobEducationRank <= candidateEducationRank,
			missing: !candidateEducationRank || !jobEducationRank,
		};
	}
	if (propertyName === 'area_of_study') {
		// The candidate's area of study matches one of the job's area of study requirement options.
		// (Or the job has no area of study requirement)
		return {
			valid: job?.study_types?.map(x => x.id).some(x => candidate?.study_types?.map(y => y.id || y)?.includes(x)),
			missing: !candidate?.study_types?.length || !job?.study_types?.length,
		};
	}

	if (propertyName === 'role_type') {
		const jobEducationRank = data?.employmentPreferences?.find(x => x.id === job?.role_type)?.rank_value;
		const candidateEducationRanks = candidate?.employment_preferences?.map(
			ep => data?.employmentPreferences?.find(x => x.id === ep?.id)?.rank_value,
		);

		// The candidate's employment preferences include the job's role type.
		return {
			valid: candidateEducationRanks?.includes(jobEducationRank),
			partial: candidateEducationRanks?.some(x => Math.abs(x - jobEducationRank) <= 2),
			missing: !candidateEducationRanks?.length || !jobEducationRank,
		};
	}

	if (propertyName === 'salary') {
		// The candidate's salary expectation is less than or equal to the job's salary max.
		try {
			const salaryFloat = parseFloat(candidate?.salary_expectation_type?.name || 0);
			return {
				valid: (salaryFloat > 1000 ? salaryFloat : salaryFloat * 1000) <= job?.salary_rate_max * 1.25,
				missing: !candidate?.salary_expectation_type?.name || !job?.salary_rate_max,
			};
		} catch (e) {
			console.log(e);
			return {
				valid: false,
			};
		}
	}

	if (propertyName === 'business_size_experiences') {
		return {
			valid: job?.client_corporation?.business_sizes?.some(x =>
				candidate?.business_size_experiences?.map(y => y.id || y)?.includes(x.id),
			),
			missing: !candidate?.business_size_experiences?.length || !job?.client_corporation?.business_sizes?.length,
		};
	}

	if (propertyName === 'industries') {
		// The candidate has experience in any of the job's industry experience requirements.
		return {
			valid:
				// job?.industries?.map(x => x.id).filter(x => candidate?.industries?.map(y => y.id).includes(x)).length ===
				// job?.industries?.length,
				job?.industries?.map(x => x.id).some(x => candidate?.industries?.map(y => y.id).includes(x)),
			missing: !candidate?.industries?.length || !job?.industries?.length,
		};
	}

	if (propertyName === 'value_proposition_types') {
		return {
			valid:
				// job?.industries?.map(x => x.id).filter(x => candidate?.industries?.map(y => y.id).includes(x)).length ===
				// job?.industries?.length,
				job?.value_proposition_types
					?.map(x => x?.value_proposition_type?.id)
					.some(x => candidate?.job_priority_types?.map(y => y?.value_proposition_type?.id).includes(x)),
			missing: !candidate?.job_priority_types?.length || !job?.value_proposition_types?.length,
		};
	}

	if (propertyName === 'work_visa') {
		return {
			// Candidate work visa is 2/no or 4/NA, OR job work visa is 1/yes or 3/maybe
			valid: [2, 4].includes(candidate.work_visa_type_id) || [1, 3].includes(job.work_visa_type_id),
			// valid: candidate.work_visa_type_id === job.work_visa_type_id,
			partial: Math.abs(candidate?.work_visa_type_id - job?.work_visa_type_id) <= 1,
			missing: !candidate?.work_visa_type_id || !job?.work_visa_type_id,
		};
	}
	if (propertyName === 'work_location_preferences') {
		const candidateWorkPrefRanks = candidate?.work_location_preferences?.map(
			ep => data?.workLocationPreferences?.find(x => x.id === ep?.id)?.rank_value,
		);
		const jobWorkPrefRanks = job?.work_location_preferences?.map(
			ep => data?.workLocationPreferences?.find(x => x.id === ep?.id)?.rank_value,
		);

		return {
			valid: jobWorkPrefRanks?.filter(x => candidateWorkPrefRanks?.includes(x)).length > 0,
			partial: jobWorkPrefRanks?.filter(x => candidateWorkPrefRanks?.some(y => Math.abs(y - x) <= 1)).length > 0,
			missing: !candidate?.work_location_preferences?.length || !job?.work_location_preferences?.length,
		};
	}
	if (propertyName === 'travel') {
		const jobTravelRank = data?.travelWillingnessTypes?.find(x => x.id === job?.travel_willingness_type_id)?.rank_value;
		const candidateTravelRank = data?.travelWillingnessTypes?.find(x => x.id === candidate?.travel_willingness_type_id)
			?.rank_value;
		// The job has no travel data, or the candidate matches or exceeds the job's travel requirements.
		return {
			valid: candidateTravelRank >= jobTravelRank,
			partial: candidateTravelRank + 1 >= jobTravelRank,
			missing: !candidate?.travel_willingness_type_id || !job?.travel_willingness_type_id,
		};
	}

	// if (propertyName === 'relocation') {
	// 	const jobOffersRelocation = job?.offer_relocation === 1;
	// 	const candidateRelocation = candidate?.willing_to_relocate === '1';
	// 	return {
	// 		valid: candidateRelocation === jobOffersRelocation,
	// 	};
	// }

	return {
		valid: true,
	};
};

export const getConsolidatedLocationPreferences = workLocationPreferences => {
	const consolidatedLocationPreferences = [];
	if (workLocationPreferences?.some(y => y?.name === 'remote' || y?.name === 'wfh-100')) {
		consolidatedLocationPreferences.push('Remote');
	}
	if (workLocationPreferences?.some(x => x.name === 'local-100')) {
		consolidatedLocationPreferences.push('Onsite');
	}
	if (workLocationPreferences?.some(x => x.name === 'wfh-1-2' || x?.name === 'wfh-3-4')) {
		consolidatedLocationPreferences.push('Hybrid');
	}
	return consolidatedLocationPreferences;
};

/**
 *
 * @param {*} currentEntity
 * @param {*} updatedEntity
 * @returns
 */
export function getEntityDifferences(currentEntity, updatedEntity) {
	const diff = {};

	Object.keys(currentEntity).forEach(key => {
		if (
			Object.prototype.hasOwnProperty.call(currentEntity, key) &&
			Object.prototype.hasOwnProperty.call(updatedEntity, key)
		) {
			if (JSON.stringify(currentEntity[key]) !== JSON.stringify(updatedEntity[key])) {
				diff[key] = updatedEntity[key];
			}
		}
	});

	return diff;
}

/**
 * This function sets multiple items in localStorage.
 *
 * @param {Object} items - An object where the keys are the localStorage
 * keys and the values are the values to be stored.
 * @returns {void}
 */
export function setLocalStorageItems(items) {
	Object.entries(items).forEach(([key, value]) => {
		localStorage.setItem(key, JSON.stringify(value));
	});
}
