import React, { useEffect, useState, useMemo, Fragment } from 'react';
import { Grid, Typography, Button, Chip } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import LabeledField from '../../../components/form/LabeledField';
import * as Utility from '../../../scripts/utility';
import NewspaperIcon from '../../../components/icons/SectionIcons/NewspaperIcon';
import ViewBlockWrapper from '../../candidates/CandidateBlocks/ViewBlockWrapper';
import { ActionCreators as SiteActions } from '../../../store/Site';
import EditModal from './EditSummaryModal';
import ValidationFieldWrapper from '../../../components/matching/ValidationFieldWrapper';
import { SkillChip } from '../../candidates/CandidateBlocks/ViewSkillsBlock';

import ExpandableTypography from '../../../components/form/ExpandableTypography';

const Block = ({
	company,
	setCompany,
	job,
	setJob,
	data,
	defaultMinimized,
	// minimized,
	// setMinimized,
	comparisonCandidate,
	disableEdit,
}) => {
	const { states } = data;
	const dispatch = useDispatch();

	if (!company) {
		return null;
	}

	return (
		<>
			<ViewBlockWrapper
				icon={NewspaperIcon}
				title="Company Summary"
				defaultMinimized={defaultMinimized}
				// minimized={minimized}
				// setMinimized={setMinimized}
				onEditClick={
					disableEdit
						? null
						: () => {
								dispatch(
									SiteActions.showModal(<EditModal company={company} setCompany={setCompany} />, {
										className: 'modal-medium modal-no-padding modal-no-shadow',
									}),
								);
						  }
				}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<LabeledField label="Public" className="color-muted" removeMargin close>
							<ExpandableTypography numCharacters={450} text={company.company_summary_external || 'N/A'} />
						</LabeledField>
					</Grid>
					<Grid item xs={12}>
						<LabeledField label="Private" className="color-muted" removeMargin close>
							<ExpandableTypography numCharacters={450} text={company.company_summary_internal || 'N/A'} />
						</LabeledField>
					</Grid>
					<Grid item xs={12}>
						<LabeledField label="Client Docs" className="color-muted" removeMargin close>
							{company?.client_docs_url?.includes('http') ? (
								<a
									href={company.client_docs_url}
									target="_blank"
									rel="noreferrer"
									className="color-inherit text-underline text-normal"
								>
									Client Documents
								</a>
							) : (
								<Typography>{company?.client_docs_url || 'N/A'}</Typography>
							)}
						</LabeledField>
					</Grid>
				</Grid>
			</ViewBlockWrapper>
		</>
	);
};

export default Block;
